import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Grid, Typography } from '@material-ui/core';

import Translate from '../../components/display/translate';
import MetricValue from '../../components/metric/utils/value';

import { Duration } from '../../utils/metrics/index';

function mapStateToProps(state) {
  return ({
    storeStartTimestamp: state.timestampsRecordDisplay.startTimestamp,
    storeEndTimestamp: state.timestampsRecordDisplay.endTimestamp,
  });
}

function IntervalSelected(props) {
  if (props.endTimestamp === props.startTimestamp
    || (props.endTimestamp === props.storeEndTimestamp
    && props.startTimestamp === props.storeStartTimestamp)) {
    return null;
  }

  return (
    <Grid container direction="row" justify="center" alignItems="center" spacing={1}>
      <Grid item>
        <Typography variant="body2" component="p"><Translate>intervalOf</Translate></Typography>
      </Grid>
      <Grid item>
        <MetricValue
          variant="body2"
          metric={Duration}
          value={props.storeEndTimestamp - props.storeStartTimestamp}
        />
      </Grid>
    </Grid>
  );
}

IntervalSelected.propTypes = {
  endTimestamp: PropTypes.number.isRequired,
  startTimestamp: PropTypes.number.isRequired,

  storeStartTimestamp: PropTypes.number.isRequired,
  storeEndTimestamp: PropTypes.number.isRequired,
};

export default connect(mapStateToProps)(IntervalSelected);
