import { combineReducers } from 'redux';

import { FLUSH } from '../actions/types';
import { updateSettings } from './settings';
import intervalDailyGraphs from './interval-daily-graphs';
import timestampsRecordDisplay from './timestamps-record-display';
import userProfile from './user-profile';
import organisation from './organisation';
import snackbarMessages from './snackbar-messages';

const appReducer = combineReducers({
  settings: updateSettings,
  intervalDailyGraphs,
  timestampsRecordDisplay,
  userProfile,
  organisation,
  snackbarMessages,
});

function rootReducer(state, action) {
  if (action.type === FLUSH) {
    return appReducer(undefined, action);
  }

  return appReducer(state, action);
}

export default rootReducer;
