import { useMemo } from 'react';

import { hasPressures } from '../stride';

function useNbPressureStrides(strides) {
  return useMemo(() => strides.reduce((acc, cur) => {
    if (hasPressures(cur)) {
      if (cur.side === 'left') {
        return ({ ...acc, left: acc.left + 1 });
      }
      return ({ ...acc, right: acc.right + 1 });
    }
    return acc;
  }, { left: 0, right: 0 }), [strides.length]);
}

export default useNbPressureStrides;
