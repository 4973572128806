import React from 'react';
import PropTypes from 'prop-types';
import { Grid, Typography } from '@material-ui/core';

import PageSubtitle from '../../components/display/page-subtitle';
import FullDateDisplay from '../../components/display/full-date';
import Translate from '../../components/display/translate';
import DisplayWalkingAid from '../../components/display/walking-aid';

// the code to add a ' - ' on conditions in getRecordDateTime and displayWalkingAids
// should be refactored
function getRecordDateTime(startTime, stopTime) {
  if (stopTime && startTime) {
    return (
      <React.Fragment>
        {' - '}
        <FullDateDisplay>{startTime}</FullDateDisplay>
      </React.Fragment>
    );
  }

  return null;
}

function displayWalkingAids(walkingSpeed, walkingAids) {
  if (walkingAids.length === 0) {
    return null;
  }

  const walkingAidChild = walkingAids
    .map(i => <DisplayWalkingAid key={i.key}>{ i }</DisplayWalkingAid>)
    .reduce((acc, curr) => [acc, ', ', curr]);

  if (walkingSpeed) {
    return (
      <React.Fragment>
        {' -  '}
        { walkingAidChild }
      </React.Fragment>
    );
  }

  return walkingAidChild;
}

function RecordPageSubtitle({ record, walkingSpeed, walkingAids }) {
  return (
    <PageSubtitle>
      <Grid container direction="column" justify="flex-start" alignItems="flex-start">
        <Grid item>
          <Translate>{record.type === 'static' ? record.type : record.recordType}</Translate>
          { getRecordDateTime(record.startTime, record.stopTime) }
        </Grid>
        { (walkingSpeed || walkingAids.length !== 0) && (
          <Grid item>
            <Typography variant="h5">
              { walkingSpeed && (
                <React.Fragment>
                  <Translate>speed</Translate>
                  { ' ' }
                  <span style={{ textTransform: 'lowerCase' }}>
                    <Translate>{ walkingSpeed }</Translate>
                  </span>
                </React.Fragment>
              )}
              { displayWalkingAids(walkingSpeed, walkingAids) }
            </Typography>
          </Grid>
        )}
      </Grid>
    </PageSubtitle>
  );
}

RecordPageSubtitle.propTypes = {
  record: PropTypes.shape({
    recordType: PropTypes.string,
    type: PropTypes.string,
    startTime: PropTypes.number,
    stopTime: PropTypes.number,
  }).isRequired,
  walkingSpeed: PropTypes.string,
  walkingAids: PropTypes.arrayOf(PropTypes.object).isRequired,
};

RecordPageSubtitle.defaultProps = {
  walkingSpeed: undefined,
};

export default RecordPageSubtitle;
