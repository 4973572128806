import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import {
  Card,
  CardContent,
  CardHeader,
} from '@material-ui/core';

import Translate from '../../components/display/translate';
import Radios from '../../components/selection-controls/radios';

import { updateCompareRecordsDisplay } from '../../actions/index';
import { updateCompareRecordsDisplaySettings } from '../../firebase/firestore';

function mapStateToProps(state) {
  return ({
    compareRecordsDisplay: state.settings.compareRecordsDisplay,
  });
}

class CompareRecordsDisplay extends React.Component {
  constructor(props) {
    super(props);

    this.handleChange = this.handleChange.bind(this);
  }

  handleChange(ev) {
    const newDisplay = ev.target.value;
    this.props.dispatch(updateCompareRecordsDisplay(newDisplay));
    updateCompareRecordsDisplaySettings(newDisplay)
      .then(() => this.props.settingsUpdated());
  }

  render() {
    return (
      <Card>
        <CardHeader title={<Translate>recordsComparison</Translate>} />
        <CardContent>
          <Radios
            title="display"
            value={this.props.compareRecordsDisplay}
            options={[{
              key: 'curve-stable-x',
              label: 'curves',
            }, {
              key: 'boxplot',
              label: 'boxPlot',
            }]}
            handleChange={this.handleChange}
          />
        </CardContent>
      </Card>
    );
  }
}

CompareRecordsDisplay.propTypes = {
  compareRecordsDisplay: PropTypes.oneOf(['curve-stable-x', 'boxplot']).isRequired,
  settingsUpdated: PropTypes.func.isRequired,
  dispatch: PropTypes.func.isRequired,
};

export default connect(mapStateToProps)(CompareRecordsDisplay);
