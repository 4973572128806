import React from 'react';
import PropTypes from 'prop-types';
import {
  TableRow,
  TableCell,
} from '@material-ui/core';
import LockIcon from '@material-ui/icons/Lock';

import DateDisplay from '../../display/date';

function RowMember(props) {
  return (
    <TableRow>
      <TableCell>{props.member.isAdmin ? (<LockIcon />) : null}</TableCell>
      <TableCell>{props.member.name}</TableCell>
      <TableCell><DateDisplay>{props.member.joinedAt}</DateDisplay></TableCell>
    </TableRow>
  );
}

RowMember.propTypes = {
  member: PropTypes.shape({
    name: PropTypes.string,
    joinedAt: PropTypes.number,
    isAdmin: PropTypes.bool,
  }).isRequired,
};

export default RowMember;
