import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import {
  Grid,
  Card,
  CardHeader,
  CardContent,
} from '@material-ui/core';

import { Velocity, Cadence, Load } from '../../utils/metrics/index';
import Translate from '../../components/display/translate';
import Radios from '../../components/selection-controls/radios';

import { VELOCITY_UNITS } from '../../utils/metrics/velocity';
import { CADENCE_UNITS } from '../../utils/metrics/cadence';
import { LOAD_UNITS } from '../../utils/metrics/load';

import { updateUnitMetrics } from '../../actions/index';
import { updateUnitMetricsSettings } from '../../firebase/firestore';

function mapStateToProps(state) {
  return ({
    units: state.settings.unitMetrics,
  });
}

class MetricsUnit extends React.Component {
  constructor(props) {
    super(props);

    this.handleChange = this.handleChange.bind(this);
  }

  handleChange(ev, key) {
    const newUnits = {
      ...this.props.units,
      [key]: ev.target.value,
    };
    this.props.dispatch(updateUnitMetrics(newUnits));
    updateUnitMetricsSettings(newUnits)
      .then(() => this.props.settingsUpdated());
  }

  render() {
    return (
      <Card>
        <CardHeader title={<Translate>metricsUnit</Translate>} />
        <CardContent>
          <Grid container direction="row" justify="flex-start" alignItems="flex-start" spacing={3}>
            <Grid item>
              <Radios
                title={Velocity.title}
                value={this.props.units.velocity}
                options={VELOCITY_UNITS.map(i => ({ key: i, label: i }))}
                handleChange={ev => this.handleChange(ev, Velocity.key)}
              />
            </Grid>
            <Grid item>
              <Radios
                title={Cadence.title}
                value={this.props.units.cadence}
                options={CADENCE_UNITS.map(i => ({ key: i, label: i }))}
                handleChange={ev => this.handleChange(ev, Cadence.key)}
              />
            </Grid>
            <Grid item>
              <Radios
                title={Load.title}
                value={this.props.units.load}
                options={LOAD_UNITS.map(i => ({ key: i, label: i }))}
                handleChange={ev => this.handleChange(ev, Load.key)}
              />
            </Grid>
          </Grid>
        </CardContent>
      </Card>
    );
  }
}

MetricsUnit.propTypes = {
  // eslint-disable-next-line react/forbid-prop-types
  units: PropTypes.object.isRequired,
  settingsUpdated: PropTypes.func.isRequired,
  dispatch: PropTypes.func.isRequired,
};

export default connect(mapStateToProps)(MetricsUnit);
