import React from 'react';
import Grid from '@material-ui/core/Grid';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import Typography from '@material-ui/core/Typography';
import InfoIcon from '@material-ui/icons/Info';
import { makeStyles } from '@material-ui/core/styles';

import Translate from '../../components/display/translate';

const useStyles = makeStyles({
  root: {
    boxShadow: 'none',
    backgroundColor: '#F0F4FF',
    color: '#005EDA',
  },
});

function NoReplay() {
  const classes = useStyles();

  return (
    <Card className={classes.root}>
      <CardContent style={{ padding: '16px' }}>
        <Grid container direction="row" justify="flex-start" alignItems="center">
          <Grid item style={{ marginRight: '8px' }}>
            <InfoIcon style={{ verticalAlign: 'middle' }} />
          </Grid>
          <Grid item>
            <Typography>
              <Translate>replayPressureComparingTwoRecords</Translate>
            </Typography>
          </Grid>
        </Grid>
      </CardContent>
    </Card>
  );
}

export default NoReplay;
