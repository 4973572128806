import React from 'react';
import { curveMonotoneX } from 'd3-shape';
import { Area } from '@feetme/d3act';

function CustomArea(props) {
  return (
    <Area
      strokeWidth={0}
      interpolation={curveMonotoneX}
      {...props}
    />
  );
}

export default CustomArea;
