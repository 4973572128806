import React from 'react';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router-dom';
import * as Sentry from '@sentry/browser';

import PageHeader from '../components/display/page-header';
import PageTitle from '../components/display/page-title';
import Translate from '../components/display/translate';
import PatientForm from '../components/form/patient';
import PatientInformationDialog from './components/patient-information-dialog';

import { addPatient, addConditions } from '../firebase/functions';

class NewPatient extends React.Component {
  constructor() {
    super();

    this.state = {
      inProgress: false,
      hasValidatedPatientInfo: false,
      isOpen: false,
      patient: null,
      conditions: [],
    };

    this.onValidate = this.onValidate.bind(this);
    this.onPatientInformationValidate = this.onPatientInformationValidate.bind(this);
    this.onPatientInformationClose = this.onPatientInformationClose.bind(this);
  }

  onValidate({ patient, conditions }) {
    if (this.state.hasValidatedPatientInfo) {
      this.setState({ patient, conditions });
      this.onPatientInformationValidate();
    } else {
      this.setState({
        isOpen: true,
        patient,
        conditions,
      });
    }
  }

  onPatientInformationValidate() {
    this.setState({ inProgress: true, hasValidatedPatientInfo: true, isOpen: false });
    const { conditions, patient } = this.state;
    addPatient({ patient })
      .then((res) => {
        const patientId = res.data;
        if (conditions.length === 0) {
          return Promise.resolve();
        }
        return addConditions({ patientId, conditions });
      })
      .then(() => this.props.history.push('/'))
      .catch((e) => {
        Sentry.captureException(e);
        this.setState({ inProgress: false });
      });
  }

  onPatientInformationClose() {
    this.setState({ hasValidatedPatientInfo: false, isOpen: false });
  }

  render() {
    return (
      <div>
        <PageHeader>
          <PageTitle><Translate>patientCreation</Translate></PageTitle>
        </PageHeader>
        <PatientInformationDialog
          isOpen={this.state.isOpen}
          onValidate={this.onPatientInformationValidate}
          onClose={this.onPatientInformationClose}
        />
        <PatientForm
          textValidate="create"
          waitValidation={this.state.inProgress}
          onValidate={this.onValidate}
        />
      </div>
    );
  }
}

NewPatient.propTypes = {
  // eslint-disable-next-line react/forbid-prop-types
  history: PropTypes.object.isRequired,
};

export default withRouter(NewPatient);
