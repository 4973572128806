import React, { useState, useRef } from 'react';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import { makeStyles } from '@material-ui/core/styles';
import GetAppIcon from '@material-ui/icons/GetApp';

import Translate from '../display/translate';
import ButtonProgress from '../display/button-progress';

import { translateNoDL } from '../../utils/i18n';
import { getTimezone } from '../../utils/date';
import { generateReport } from '../../firebase/functions';

const useStyles = makeStyles({
  anchor: {
    display: 'none',
  },
});

function Report({ recordsId, disableFallingRisk, filename }) {
  const [inProgress, setInProgress] = useState(false);
  const locale = useSelector(state => state.settings.locale);
  const { startTimestamp, endTimestamp } = useSelector(state => state.timestampsRecordDisplay);
  const interval = useSelector(state => state.intervalDailyGraphs);
  const anchorRef = useRef();
  const classes = useStyles();

  async function handleClick() {
    setInProgress(true);
    const pdfBuffer = await generateReport({
      recordsId,
      locale,
      disableFallingRisk,
      startTimestamp,
      endTimestamp,
      interval,
      footerTxt: translateNoDL('reportFooterTxt', locale),
      timezone: getTimezone(),
    });

    const pdfData = atob(pdfBuffer.data.replace(/\s/g, ''));
    const buffer = new ArrayBuffer(pdfData.length);
    const view = new Uint8Array(buffer);
    for (let i = 0; i < pdfData.length; i += 1) {
      view[i] = pdfData.charCodeAt(i);
    }

    const url = URL.createObjectURL(new Blob([view], { type: 'application/octet-stream' }));
    anchorRef.current.href = url;
    anchorRef.current.click();
    setInProgress(false);
  }

  return (
    <React.Fragment>
      <ButtonProgress
        onClick={handleClick}
        variant="outlined"
        color="primary"
        size="small"
        inProgress={inProgress}
        startIcon={<GetAppIcon />}
      >
        <Translate>PDF</Translate>
      </ButtonProgress>
      <a
        download={filename}
        href="/"
        className={classes.anchor}
        ref={anchorRef}
      >
        Download report
      </a>
    </React.Fragment>
  );
}

Report.propTypes = {
  recordsId: PropTypes.arrayOf(PropTypes.string).isRequired,
  disableFallingRisk: PropTypes.bool,
  filename: PropTypes.string.isRequired,
};

Report.defaultProps = {
  disableFallingRisk: false,
};

export default Report;
