import React, { useMemo, useEffect } from 'react';
import PropTypes from 'prop-types';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import Hidden from '@material-ui/core/Hidden';
import { useDispatch } from 'react-redux';
import { extent } from 'd3-array';

import ProgressWait from '../../components/display/progress-wait';
import PageHeader from '../../components/display/page-header';
import RecordPageTitle from './page-title';
import RecordPageSubtitle from './page-subtitle';
import GaugeMetricsCard from './gauge-metrics-card';
import DailyGraphsCard from './daily-graphs-card';
import MetricVariationCard from './metric-variation-card';
import InsolesDisplayCard from './insoles-display-card';
import GaitCycleParametersCard from './gait-cycle-parameters-card';
import AsymmetricalParametersCard from './asymmetrical-parameters-card';
import DownloadMetrics from './download-metrics';
import Report from '../../components/record/report';
import Translate from '../../components/display/translate';

import useStrides from '../../utils/hooks/useStrides';
import useInsoles from '../../utils/hooks/useInsoles';
import { canShowGraph24h, canShowGraphVariation } from '../../utils/record';
import { updateRecordDisplayTimestamps, cleanRecordDisplayTimestamps } from '../../actions/index';
import { arrayToCSVString } from '../../utils/csv';
import { isDevTarget } from '../../utils/env';
import { isSaleTeam } from '../../utils/users';
import { fileDownloadDateFormat } from '../../utils/date';
import { userId } from '../../firebase/auth';

function getCapa(strides, side) {
  return strides
    .filter(stride => stride.capa && stride.capa.length > 0 && side === stride.side)
    .map(stride => ({ x: stride.clientTimestamp, y: stride.capa }));
}

function getCOP(strides, side) {
  return strides
    .filter(stride => stride.cop && side === stride.side)
  // match precision from insoles.json files
    .map(stride => ({ x: stride.clientTimestamp, y: stride.cop.map(i => i * 100) }));
}

function RecordMetric(props) {
  const {
    record,
    patientFetchInProgress,
    disableFallingRisk,
    walkingSpeed,
    walkingAids,
  } = props;

  const [strides, stridesInProgress] = useStrides(record.key);
  const [insoles] = useInsoles(record.key);
  const dispatch = useDispatch();

  const clientTimestampExtent = useMemo(
    () => extent(strides, i => i.clientTimestamp),
    [strides.length],
  );

  const [capaLeft, capaRight] = useMemo(
    () => [getCapa(strides, 'left'), getCapa(strides, 'right')],
    [strides.length],
  );

  const [copLeft, copRight] = useMemo(
    () => [getCOP(strides, 'left'), getCOP(strides, 'right')],
    [strides.length],
  );

  const [sizeLeft, sizeRight, versionHardLeft, versionHardRight] = useMemo(() => {
    const sizes = {};
    const versions = {};

    insoles.forEach((insole) => {
      sizes[insole.side] = insole.size;
      versions[insole.side] = insole.versions ? insole.versions.hardware : undefined;
    });

    return [sizes.left, sizes.right, versions.left, versions.right];
  }, [insoles.length]);

  useEffect(() => {
    dispatch(updateRecordDisplayTimestamps(clientTimestampExtent[0], clientTimestampExtent[1]));
    return () => dispatch(cleanRecordDisplayTimestamps());
  }, [strides.length]);

  if (stridesInProgress || strides.length === 0) {
    return (<ProgressWait />);
  }

  return (
    <React.Fragment>
      <PageHeader>
        <Grid container direction="row" justify="space-between" alignItems="center">
          <Grid item>
            <RecordPageTitle />
            <RecordPageSubtitle
              record={record}
              walkingSpeed={walkingSpeed}
              walkingAids={walkingAids}
            />
          </Grid>
          <Grid item>
            <Grid item container direction="column" justify="flex-start" alignItems="flex-start">
              <Grid item>
                <Typography variant="overline" style={{ color: '#ADB5BD' }} gutterBottom>
                  <Translate>downloadFormat</Translate>
                </Typography>
              </Grid>
              <Grid item>
                <Grid container direction="row" justify="flex-end" alignItems="center" spacing={1}>
                  { strides.length > 0 && (
                    <Grid item>
                      <DownloadMetrics
                        loadData={() => arrayToCSVString(strides, insoles, record, record.key)}
                        filename={`${fileDownloadDateFormat(new Date(record.startTime))}-metrics.csv`}
                      />
                    </Grid>
                  )}
                  { (patientFetchInProgress === false) && (
                    <Grid item>
                      <Report
                        recordsId={[record.key]}
                        disableFallingRisk={disableFallingRisk}
                        filename={`${fileDownloadDateFormat(new Date(record.startTime))}.pdf`}
                      />
                    </Grid>
                  )}
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </PageHeader>
      <Grid container direction="column" justify="flex-start" alignItems="stretch" spacing={2} style={{ marginTop: '24px' }}>
        <Grid item>
          <GaugeMetricsCard
            inProgress={patientFetchInProgress}
            startTime={clientTimestampExtent[0]}
            stopTime={clientTimestampExtent[1]}
            strides={strides}
            disableFallingRisk={disableFallingRisk}
          />
        </Grid>
        {(canShowGraph24h(
          clientTimestampExtent[0],
          clientTimestampExtent[1],
          strides.length,
        ) && (
          <Grid item>
            <DailyGraphsCard
              strides={strides}
              defaultStartTimestamp={clientTimestampExtent[0]}
              defaultEndTimestamp={clientTimestampExtent[1]}
            />
          </Grid>
        ))}
        {(canShowGraphVariation(
          clientTimestampExtent[0],
          clientTimestampExtent[1],
          strides.length,
        ) && (
          <Grid item>
            <MetricVariationCard strides={strides} />
          </Grid>
        ))}
        { !(isDevTarget() && isSaleTeam(userId())) && (
          <Grid item>
            <Hidden smDown>
              <GaitCycleParametersCard strides={strides} />
            </Hidden>
          </Grid>
        )}
        <Grid item>
          <AsymmetricalParametersCard strides={strides} />
        </Grid>
        {(capaLeft.length > 0 || capaRight.length > 0) && (
          <Grid item>
            <InsolesDisplayCard
              sizeLeft={sizeLeft}
              sizeRight={sizeRight}
              versionLeft={versionHardLeft}
              versionRight={versionHardRight}
              capaLeft={capaLeft}
              capaRight={capaRight}
              copLeft={copLeft}
              copRight={copRight}
            />
          </Grid>
        )}
      </Grid>
    </React.Fragment>
  );
}

RecordMetric.propTypes = {
  record: PropTypes.shape({
    key: PropTypes.string,
    recordType: PropTypes.string,
    startTime: PropTypes.number,
  }).isRequired,
  patientFetchInProgress: PropTypes.bool.isRequired,
  disableFallingRisk: PropTypes.bool.isRequired,
  walkingSpeed: PropTypes.string,
  walkingAids: PropTypes.arrayOf(PropTypes.object).isRequired,
};

RecordMetric.defaultProps = {
  walkingSpeed: undefined,
};

export default RecordMetric;
