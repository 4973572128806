import React from 'react';
import PropTypes from 'prop-types';
import Grid from '@material-ui/core/Grid';
import { useSelector } from 'react-redux';

import MetricValue from '../../components/metric/utils/value';
import MetricUnit from '../../components/metric/utils/unit';

import { Load } from '../../utils/metrics';

function LoadDisplay({ value }) {
  const loadUnitStore = useSelector(state => state.settings.unitMetrics[Load.key]);

  if (value === undefined) {
    return null;
  }

  return (
    <Grid container direction="row" justify="center" alignItems="center" spacing={1}>
      <Grid item>
        <MetricValue value={Load.convert(value, loadUnitStore)} metric={Load} variant="h6" />
      </Grid>
      <Grid item>
        <MetricUnit unit={loadUnitStore} disableTranslate />
      </Grid>
    </Grid>
  );
}

LoadDisplay.propTypes = {
  value: PropTypes.number,
};

LoadDisplay.defaultProps = {
  value: undefined,
};

export default LoadDisplay;
