import Metric from './metric';

export default new Metric({
  title: 'supportPhase',
  unit: '%',
  key: 'stancePercentage',
  hasDeviation: true,
  description: () => [{
    txt: ['normativeValue', ': 60%'],
    align: 'center',
  }],
});
