import { useState, useEffect } from 'react';
import * as Sentry from '@sentry/browser';

function useFirestore(fn, defaultValue, dependencies = [], callback = undefined) {
  const [data, setData] = useState(defaultValue);
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    let isCancelled = false;
    async function wrapper() {
      let newData;
      try {
        newData = await fn();
      } catch (err) {
        Sentry.captureException(err);
        newData = defaultValue;
      }
      if (callback !== undefined) {
        newData = callback(newData);
      }
      if (!isCancelled) {
        setData(newData);
        setIsLoading(false);
      }
    }

    setIsLoading(true);
    wrapper();

    return () => {
      isCancelled = true;
    };
  }, dependencies);

  return [data, isLoading];
}

export default useFirestore;
