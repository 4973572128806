import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import Grid from '@material-ui/core/Grid';

import Translate from '../components/display/translate';
import PageTitle from '../components/display/page-title';
import PageHeader from '../components/display/page-header';

import { addSnackbarMessage } from '../actions/index';

import LangSelection from './components/lang-selection';
import MetricsUnit from './components/metrics-unit';
import CompareRecordsDisplay from './components/compare-records-display';
import MetricsSelection from './components/metrics-selection';

class Settings extends React.Component {
  constructor() {
    super();

    this.handleOpen = this.handleOpen.bind(this);
  }

  handleOpen() {
    this.props.dispatch(addSnackbarMessage({ type: 'settings' }));
  }

  render() {
    return (
      <div>
        <PageHeader>
          <PageTitle><Translate>settings</Translate></PageTitle>
        </PageHeader>
        <Grid container direction="column" justfity="center" alignItems="stretch" spacing={2}>
          <Grid item>
            <LangSelection settingsUpdated={this.handleOpen} />
          </Grid>
          <Grid item>
            <MetricsUnit settingsUpdated={this.handleOpen} />
          </Grid>
          <Grid item>
            <MetricsSelection settingsUpdated={this.handleOpen} />
          </Grid>
          <Grid item>
            <CompareRecordsDisplay settingsUpdated={this.handleOpen} />
          </Grid>
        </Grid>
      </div>
    );
  }
}

Settings.propTypes = {
  dispatch: PropTypes.func.isRequired,
};

export default connect()(Settings);
