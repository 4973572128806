import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { withTheme } from '@material-ui/core/styles';

import { select } from 'd3-selection';
import { scaleLinear } from 'd3-scale';
import { axisTop } from 'd3-axis';
import 'd3-transition';
import textures from 'textures';

import { translateNoDL } from '../../utils/i18n';
import { formatNumber } from '../../utils/display';
import { styleAxis } from '../../utils/d3/styling';

const COLORS = {
  support: '#2f4187',
  oscillation: '#0277bd',
};

const ERRORS = {
  support: '#c00000',
  oscillation: '#c00000',
};

function mapStateToProps(state) {
  return ({
    locale: state.settings.locale,
  });
}

class GaitCycleGraph extends React.Component {
  constructor() {
    super();

    this.mouseover = this.mouseover.bind(this);
    this.mouseout = this.mouseout.bind(this);
  }

  componentDidMount() {
    const svg = select(this.node)
      .append('svg');

    const xScale = scaleLinear();

    const texture = {
      support: textures.lines().thicker().stroke(COLORS.support),
      oscillation: textures.lines().thicker().stroke(COLORS.oscillation),
    };

    Object.values(texture).forEach(t => svg.call(t));

    const backgroundContainer = svg.append('g');
    const xContainer = svg.append('g');
    const xContainerTitle = svg.append('g');
    const xContainerSubtitle = svg.append('g');
    const mainContainer = svg.append('g');
    const barsContainer = mainContainer.append('g');
    const mouseContainer = mainContainer.append('rect')
      .attr('fill', 'none')
      .attr('pointer-events', 'all');

    this.el = {
      svg,
      xScale,
      backgroundContainer,
      xContainer,
      xContainerTitle,
      xContainerSubtitle,
      mainContainer,
      barsContainer,
      mouseContainer,
      texture,
    };

    this.sizes = {
      w: this.props.width,
      h: this.props.height,
      margin: {
        top: 24,
        bottom: 24,
        left: this.props.pdfMode ? 60 : 85,
        right: this.props.pdfMode ? 60 : 85,
        boxes: {
          top: 8,
          bottom: 8,
          interGroup: 8,
        },
      },
    };

    if (this.props.groups.length > 0) {
      this.init();
      this.draw();
    }
  }

  componentDidUpdate(prevProps) {
    if (this.props.groups.length !== prevProps.groups.length) {
      this.init();
    }
    this.draw();
  }

  init() {
    const nbInterGroups = this.props.groups.length > 1 ? this.props.groups.length - 1 : 0;

    // some browsers didn't have access to .flat()
    // using an alternative method
    this.nbBars = this.props.groups.reduce((acc, val) => acc.concat(val), []).length;

    if (this.sizes.w === 0) {
      this.sizes.w = this.node.clientWidth - 50;
    }

    this.sizes.boxHeight = (this.sizes.h - this.sizes.margin.boxes.top
      - this.sizes.margin.boxes.bottom - 20 - (nbInterGroups * this.sizes.margin.boxes.interGroup))
      / this.nbBars;

    const width = this.sizes.w - this.sizes.margin.left - this.sizes.margin.right;

    this.el.svg
      .attr('width', this.sizes.w)
      .attr('height', this.sizes.h + this.sizes.margin.top + this.sizes.margin.bottom);

    this.el.backgroundContainer
      .attr('transform', `translate(${this.sizes.margin.left},${this.sizes.margin.top + 40})`);
    this.el.mainContainer
      .attr('transform', `translate(${this.sizes.margin.left},${this.sizes.margin.top + 40})`);

    this.el.xScale
      .domain([0, 100])
      .range([0, width]);

    this.el.mouseContainer
      .attr('width', width)
      .attr('height', this.sizes.h)
      .on('mouseover', this.mouseover)
      .on('mouseout', this.mouseout);

    // draw the support phase container
    this.el.backgroundContainer
      .append('rect')
      .attr('x', this.el.xScale(0))
      .attr('y', 0)
      .attr('width', this.el.xScale(60))
      .attr('height', this.sizes.h)
      .attr('fill', '#eaeefb');

    // draw the oscillation phase container
    this.el.backgroundContainer
      .append('rect')
      .attr('x', this.el.xScale(60))
      .attr('y', 0)
      .attr('width', this.el.xScale(100) - this.el.xScale(60))
      .attr('height', this.sizes.h)
      .attr('fill', '#e1f5fe');

    this.el.xContainerTitle
      .attr('transform', `translate(${this.sizes.margin.left}, ${this.sizes.margin.top})`)
      .call(axisTop(this.el.xScale)
        .tickValues([0, 100])
        .tickFormat(() => translateNoDL('initiateStride', this.props.locale))
        .tickSize(0));
    styleAxis(this.el.xContainerTitle, this.props.theme);
    this.el.xContainerTitle.select('.domain').remove();
    this.el.xContainerTitle.selectAll('text').attr('font-weight', 'bold');

    this.el.xContainerSubtitle
      .attr('transform', `translate(${this.sizes.margin.left}, ${this.sizes.margin.top + 20})`)
      .call(axisTop(this.el.xScale)
        .tickValues([0, 60, 100])
        .tickFormat(d => translateNoDL((d === 60 ? 'toeOff' : 'heelStrike'), this.props.locale))
        .tickSize(0));
    styleAxis(this.el.xContainerSubtitle, this.props.theme);
    this.el.xContainerSubtitle.select('.domain').remove();

    // draw the axis
    // top axis (with tick size)
    this.el.xContainer
      .attr('transform', `translate(${this.sizes.margin.left}, ${this.sizes.margin.top + 40})`)
      .call(axisTop(this.el.xScale)
        .tickValues([...Array(11).keys()].map(i => i * 10))
        .tickFormat(d => `${d}%`)
        .tickSize(-this.sizes.h));
    styleAxis(this.el.xContainer, this.props.theme);
    this.el.xContainer.select('.domain').remove();
    this.el.xContainer.selectAll('text').attr('font-size', this.props.pdfMode ? '10px' : '1rem');
    this.el.xContainer.selectAll('line').attr('stroke-width', '2px');
    // apply style on limits lines (0%, 60%, 100%)
    this.el.xContainer.selectAll('.tick')
      .filter(d => [0, 60, 100].indexOf(d) > -1)
      .select('line').attr('stroke-width', '5px');
    // apply style for axis on the support phase part
    const blue = this.el.xContainer.selectAll('.tick').filter(d => d < 70);
    blue.attr('color', COLORS.support);
    blue.select('line').attr('stroke', COLORS.support);
    this.el.xContainer.selectAll('.tick')
      .filter(d => d > 0 && d < 60)
      .select('line')
      .attr('y2', () => (nbInterGroups * this.sizes.margin.boxes.interGroup
        + (this.nbBars - 1) * this.sizes.boxHeight
        + this.sizes.margin.boxes.top
      ));
    // apply style for axis on the oscillation phase part
    const green = this.el.xContainer.selectAll('.tick').filter(d => d >= 70);
    green.select('line').attr('stroke', COLORS.oscillation);
    green.attr('color', COLORS.oscillation);
    this.el.xContainer.selectAll('.tick').filter(d => d > 60 && d < 100)
      .select('line')
      .attr('y2', () => ((nbInterGroups * this.sizes.margin.boxes.interGroup
        + this.nbBars * this.sizes.boxHeight
        + this.sizes.margin.boxes.top
      )));

    this.props.groups.forEach((group, groupIndex) => group.forEach((line, index) => {
      const { main } = line;

      this.el.barsContainer.append('rect')
        .attr('id', `bar-${main.metric.key}`)
        .attr('rx', 0);

      this.el.barsContainer.append('rect')
        .attr('id', `bar-error-${groupIndex}-${index}`)
        .attr('rx', 0);

      if (this.props.pdfMode) {
        this.el.barsContainer.append('text')
          .attr('id', `text-metric-${main.metric.key}`)
          .attr('class', 'text metric')
          .attr('font-family', this.props.theme.typography.fontFamily)
          .attr('font-size', '12px')
          .attr('fill', 'white')
          .attr('opacity', 1)
          .attr('dy', '-0.7em')
          .style('text-anchor', 'middle')
          .style('dominant-baseline', 'central');

        const text = this.el.barsContainer.append('text')
          .attr('id', `text-value-${main.metric.key}`)
          .attr('class', 'text value')
          .attr('font-family', this.props.theme.typography.fontFamily)
          .attr('fill', 'white')
          .attr('opacity', 1)
          .attr('dy', '0.7em')
          .style('text-anchor', 'middle')
          .style('dominant-baseline', 'central');
        text.append('tspan').attr('id', 'main-value').attr('font-size', '12px');
        text.append('tspan').attr('id', 'std-value').attr('font-size', '10px').attr('padding-left', '4px');
      } else {
        this.el.barsContainer.append('text')
          .attr('id', `text-metric-${main.metric.key}`)
          .attr('class', 'text metric')
          .attr('font-family', this.props.theme.typography.fontFamily)
          .attr('font-size', '18px')
          .attr('fill', 'white')
          .attr('opacity', 1)
          .style('text-anchor', 'middle')
          .style('dominant-baseline', 'central');

        const text = this.el.barsContainer.append('text')
          .attr('id', `text-value-${main.metric.key}`)
          .attr('class', 'text value')
          .attr('font-family', this.props.theme.typography.fontFamily)
          .attr('fill', 'white')
          .attr('opacity', 0)
          .style('text-anchor', 'middle')
          .style('dominant-baseline', 'central');
        text.append('tspan').attr('id', 'main-value').attr('font-size', '18px');
        text.append('tspan').attr('id', 'std-value').attr('font-size', '14px').attr('padding-left', '4px');
      }
    }));
  }

  draw() {
    let groupMargin = 0;
    this.props.groups.forEach((group, groupIndex) => {
      group.forEach((line, index) => {
        const { zone, main, error } = line;
        const y = (index * this.sizes.boxHeight) + groupMargin + this.sizes.margin.boxes.top;

        this.el.barsContainer.select(`rect#bar-${main.metric.key}`)
          .transition().duration(400)
          .attr('fill', COLORS[zone])
          .attr('x', this.el.xScale(main.x))
          .attr('width', this.el.xScale(main.width))
          .attr('y', y)
          .attr('height', this.sizes.boxHeight);

        this.el.barsContainer.select(`rect#bar-error-${groupIndex}-${index}`)
          .transition().duration(400)
          .attr('fill', () => (error.type === 'plus' ? ERRORS[zone] : this.el.texture[zone].url()))
          .attr('x', this.el.xScale(error.x))
          .attr('width', this.el.xScale(error.width))
          .attr('y', y)
          .attr('height', this.sizes.boxHeight);

        this.el.barsContainer.select(`text#text-metric-${main.metric.key}`)
          .transition().duration(400)
          .attr('x', this.el.xScale(main.x) + this.el.xScale(main.width) / 2)
          .attr('y', y + (this.sizes.boxHeight / 2))
          .text(() => translateNoDL(main.metric.title, this.props.locale));

        this.el.barsContainer.select(`text#text-value-${main.metric.key}`)
          .transition().duration(400)
          .attr('x', this.el.xScale(main.x) + this.el.xScale(main.width) / 2)
          .attr('y', y + (this.sizes.boxHeight / 2));
        this.el.barsContainer.select(`#text-value-${main.metric.key}`).select('#main-value')
          .text(() => `${formatNumber(main.value, this.props.locale, main.metric.formatOptions)} % `);
        if (main.deviation) {
          this.el.barsContainer.select(`#text-value-${main.metric.key}`).select('#std-value')
            .text(() => ` [σ: ${formatNumber(main.deviation, this.props.locale, main.metric.formatOptions)}]`);
        }
      });

      // compute the height of the current group (and add another margin)
      // will be used by the next group
      groupMargin += (group.length
        * this.sizes.boxHeight
        + this.sizes.margin.boxes.interGroup);
    });
  }

  mouseover() {
    this.el.barsContainer.selectAll('text.text.value').transition().duration(400).attr('opacity', 1);
    this.el.barsContainer.selectAll('text.text.metric').transition().duration(400).attr('opacity', 0);
  }

  mouseout() {
    this.el.barsContainer.selectAll('text.text.value').transition().duration(400).attr('opacity', 0);
    this.el.barsContainer.selectAll('text.text.metric').transition().duration(400).attr('opacity', 1);
  }

  render() {
    return (
      <div
        style={{ textAlign: 'center' }}
        ref={(node) => {
          this.node = node;
          return undefined;
        }}
      />
    );
  }
}

GaitCycleGraph.propTypes = {
  // eslint-disable-next-line react/forbid-prop-types
  theme: PropTypes.object.isRequired,

  locale: PropTypes.string.isRequired,
  groups: PropTypes.arrayOf(PropTypes.arrayOf(PropTypes.object)).isRequired,
  pdfMode: PropTypes.bool,
  width: PropTypes.number,
  height: PropTypes.number,
};

GaitCycleGraph.defaultProps = {
  width: 0,
  height: 225,
  pdfMode: false,
};

export default connect(mapStateToProps)(withTheme(GaitCycleGraph));
