import {
  UPDATE_SETTINGS,
  UPDATE_UNIT_METRICS,
  UPDATE_COMPARE_RECORDS_DISPLAY,
  UPDATE_LOCALE,
  UPDATE_ASYMMETRICAL_SELECTED_METRICS,
  UPDATE_GAIT_CYCLE_SELECTED_METRICS,
  UPDATE_RECORD_DISPLAY_TIMESTAMPS,
  CLEAN_RECORD_DISPLAY_TIMESTAMPS,
  UPDATE_INTERVAL_DAILY_GRAPHS,
  UPDATE_USER_ORGAID,
  UPDATE_RECORDS_NOT_LINKED,
  ADD_SNACKBAR_MESSAGE,
  UPDATE_SNACKBAR_MESSAGES,
  FLUSH,
} from './types';

function makeActionCreator(type, ...argNames) {
  return (...args) => {
    const action = { type };
    argNames.forEach((arg, index) => {
      action[argNames[index]] = args[index];
      return undefined;
    });

    return action;
  };
}

export const updateSettings = makeActionCreator(UPDATE_SETTINGS, 'settings');
export const updateUnitMetrics = makeActionCreator(UPDATE_UNIT_METRICS, 'unitMetrics');
export const updateCompareRecordsDisplay = makeActionCreator(UPDATE_COMPARE_RECORDS_DISPLAY, 'compareRecordsDisplay');
export const updateLocale = makeActionCreator(UPDATE_LOCALE, 'locale');
export const updateAsymmetricalMetrics = makeActionCreator(UPDATE_ASYMMETRICAL_SELECTED_METRICS, 'asymmetricalMetricsSelected');
export const updateGaitCycleMetrics = makeActionCreator(UPDATE_GAIT_CYCLE_SELECTED_METRICS, 'gaitCycleMetricsSelected');

export const updateRecordDisplayTimestamps = makeActionCreator(UPDATE_RECORD_DISPLAY_TIMESTAMPS, 'startTimestamp', 'endTimestamp');
export const cleanRecordDisplayTimestamps = makeActionCreator(CLEAN_RECORD_DISPLAY_TIMESTAMPS);

export const updateIntervalDailyGraphs = makeActionCreator(UPDATE_INTERVAL_DAILY_GRAPHS, 'intervalMs');

export const updateUserOrgaId = makeActionCreator(UPDATE_USER_ORGAID, 'orgaId');

export const updateRecordsNotLinked = makeActionCreator(UPDATE_RECORDS_NOT_LINKED, 'recordsNotLinked');

export const addSnackbarMessage = makeActionCreator(ADD_SNACKBAR_MESSAGE, 'message');
export const updateSnackbarMessages = makeActionCreator(UPDATE_SNACKBAR_MESSAGES, 'messages');

export const flush = makeActionCreator(FLUSH);
