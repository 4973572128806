import Metric from './metric';

const UNITS = ['stride/min', 'stride/s'];

const Cadence = new Metric({
  title: 'cadence',
  unit: 'stride/min',
  key: 'cadence',
  hasDeviation: true,
  convert: (v, unit) => {
    switch (unit) {
      case 'stride/min':
        return v;
      case 'stride/s':
        return (v / 60);
      default:
        return v;
    }
  },
});

export {
  UNITS as CADENCE_UNITS,
  Cadence as default,
};
