import firebase from 'firebase/app';
import 'firebase/auth';
import 'firebase/firestore';
import 'firebase/functions';
import 'firebase/performance';
import 'firebase/storage';

// eslint-disable-next-line no-undef
firebase.initializeApp(__FIREBASE_CONFIG__);

// init perf object as soon as possible
// instead of doing it in src/firebase/performance.js which might not be called
const perf = firebase.performance();

export default firebase;
export { perf };
