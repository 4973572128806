import React from 'react';
import PropTypes from 'prop-types';
import Typography from '@material-ui/core/Typography';

import Metric from '../../../utils/metrics/metric';
import Translate from '../../display/translate';

import { getMetricDescriptionColor } from '../../../utils/colors';

const styles = {
  text: {
    color: getMetricDescriptionColor(),
  },
};

function MetricDescription(props) {
  return (
    <div style={props.style}>
      { props.metric.description(props.unit).map((line, i) => (
        // eslint-disable-next-line
        <Typography key={i}
          variant={props.variant}
          component="p"
          align={line.align}
          style={styles.text}
        >
          {typeof line.txt === 'string'
            ? (<Translate>{line.txt}</Translate>)
            : (line.txt.map(j => <Translate key={j}>{j}</Translate>))}
        </Typography>
      ))}
    </div>
  );
}

MetricDescription.propTypes = {
  metric: PropTypes.instanceOf(Metric).isRequired,
  unit: PropTypes.string.isRequired,
  variant: PropTypes.string,
  // eslint-disable-next-line react/forbid-prop-types
  style: PropTypes.object,
};

MetricDescription.defaultProps = {
  variant: 'caption',
  style: {},
};

export default MetricDescription;
