import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import Card from '@material-ui/core/Card';
import CardHeader from '@material-ui/core/CardHeader';
import CardContent from '@material-ui/core/CardContent';
import Grid from '@material-ui/core/Grid';
import { max } from 'd3-array';
import { useSelector } from 'react-redux';

import Translate from '../../components/display/translate';
import GraphPTI from '../../components/graph/pti';
import ColormapPressure from '../../components/insole/colormap-pressure';
import RecordInfo from './record-info';
import DataNotAvailable from './data-not-available';
import InsolesDynamicPressures from '../../record/components/insoles-dynamic-pressures';

import usePressureScale from '../../utils/hooks/usePressureScale';
import { Load } from '../../utils/metrics';
import { isMetric } from '../../utils/record';

function PTIs(props) {
  const {
    records,
    insoles,
    walkingAids,
    dynamicData,
    pdf,
  } = props;
  const loadUnitStore = useSelector(state => state.settings.unitMetrics[Load.key]);

  const domain = useMemo(() => {
    const maxDomain = max(dynamicData
      .filter(i => i !== undefined && i[0] !== null)
      .map(([processedPressures]) => max([
        ...(processedPressures.meanPTI.left || []),
        ...(processedPressures.meanPTI.right || []),
      ])));

    // we always want to keep to min domain at 0
    return [0, maxDomain];
  }, [dynamicData.length]);

  const [scale, colorInterpolation] = usePressureScale(domain[0], domain[1]);

  return (
    <Card>
      <CardHeader
        title={<Translate>comparePTI</Translate>}
        subheader={(
          <Translate input={{ UNIT: loadUnitStore }}>
            withMaxLoadHeelStrikeAndToeOff
          </Translate>
        )}
      />
      <CardContent>
        <Grid container direction="row" justify="space-around" alignItems="center" spacing={pdf ? 0 : 5}>
          { dynamicData.map((data, index) => {
            const record = records[index];
            if (isMetric(record) || data === undefined) {
              return (
                <Grid item xs={pdf ? true : 12} md={6} lg={4} key={record.key}>
                  <RecordInfo record={record} walkingAids={walkingAids[record.key]} pdf={pdf} />
                  <InsolesDynamicPressures
                    insoles={insoles[record.key]}
                    leftCop={[]}
                    rightCop={[]}
                    scale={scale}
                    pdf={pdf}
                  />
                  <DataNotAvailable />
                </Grid>
              );
            }

            return (
              <Grid item xs={pdf ? true : 12} md={6} lg={4} key={record.key}>
                <RecordInfo record={record} walkingAids={walkingAids[record.key]} pdf={pdf} />
                <GraphPTI
                  processedPressures={data[0]}
                  processedRecord={data[1]}
                  insoles={insoles[record.key]}
                  scale={scale}
                  pdf={pdf}
                />
                <ColormapPressure
                  unit="kg/cm² × ms"
                  colorInterpolation={colorInterpolation}
                  domain={domain}
                  width={pdf ? 125 : undefined}
                  numberTicks={pdf ? 4 : undefined}
                />
              </Grid>
            );
          })}
        </Grid>
      </CardContent>
    </Card>
  );
}

PTIs.propTypes = {
  records: PropTypes.arrayOf(PropTypes.shape({
    key: PropTypes.string,
  })).isRequired,
  // eslint-disable-next-line react/forbid-prop-types
  insoles: PropTypes.object.isRequired,
  // eslint-disable-next-line react/forbid-prop-types
  walkingAids: PropTypes.object.isRequired,
  dynamicData: PropTypes.arrayOf(PropTypes.arrayOf(PropTypes.any)).isRequired,
  pdf: PropTypes.bool,
};

PTIs.defaultProps = {
  pdf: false,
};

export default PTIs;
