import React from 'react';
import PropTypes from 'prop-types';
import {
  Grid,
  Card,
  CardHeader,
  CardContent,
} from '@material-ui/core';

import {
  ActiveTime,
  Inactivity,
  StrideLength,
  Velocity,
} from '../../utils/metrics/index';

import LargeMetric from '../../components/metric/large';
import MetricGraphHistogramDaily from '../../components/metric/graph-histogram-daily';
import Translate from '../../components/display/translate';

import IntervalSelectionDailyGraphs from './interval-selection-daily-graphs';

import { getArrayXY } from '../../utils/strides';

function DailyGraphsCard(props) {
  // TODO we should try to move this compute of Inactivity to its class definition
  const activeTime = ActiveTime.compute(getArrayXY(props.strides, ActiveTime));
  const duration = (props.strides[props.strides.length - 1].clientTimestamp
    - props.strides[0].clientTimestamp);
  // Inactivity has a metric but we don't really support other parameter than
  // the array of strides data
  const inactivity = ((duration - activeTime) / duration) * 100;

  return (
    <Card>
      <CardHeader title={<Translate>dailyGraph</Translate>} />
      <CardContent>
        <Grid container direction="row" justify="flex-end" alignItems="center">
          <Grid item>
            <IntervalSelectionDailyGraphs
              defaultStartTimestamp={props.defaultStartTimestamp}
              defaultEndTimestamp={props.defaultEndTimestamp}
            />
          </Grid>
        </Grid>
      </CardContent>
      <CardContent>
        <Grid
          container
          direction="row"
          justify="center"
          alignItems="stretch"
          spacing={2}
        >
          <Grid item md={4} xs={12} container direction="column" justify="space-evenly" alignItems="center" spacing={2}>
            <Grid item>
              <LargeMetric metric={ActiveTime} strides={props.strides} />
            </Grid>
            <Grid item>
              <LargeMetric metric={Inactivity} strides={props.strides} value={inactivity} />
            </Grid>
          </Grid>
          <Grid item md={8} xs={12} container direction="column" justify="center" alignItems="stretch" spacing={5}>
            { [Velocity, StrideLength].map(metric => (
              <Grid item key={metric.key} xs id={`metric-${metric.key}-daily-graph-pdf`}>
                <MetricGraphHistogramDaily strides={props.strides} metric={metric} />
              </Grid>
            ))}
          </Grid>
        </Grid>
      </CardContent>
    </Card>
  );
}

DailyGraphsCard.propTypes = {
  strides: PropTypes.arrayOf(PropTypes.object).isRequired,
  defaultStartTimestamp: PropTypes.number.isRequired,
  defaultEndTimestamp: PropTypes.number.isRequired,
};

export default DailyGraphsCard;
