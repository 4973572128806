import { perf } from './index';

function getTraceAndStart(str) {
  const trace = perf.trace(str);
  trace.start();
  return trace;
}

export {
  // eslint-disable-next-line import/prefer-default-export
  getTraceAndStart,
};
