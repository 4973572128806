import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import InfoIcon from '@material-ui/icons/Info';
import Tooltip from '@material-ui/core/Tooltip';
import { withStyles } from '@material-ui/core/styles';
import { useSelector } from 'react-redux';

import FullDateDisplay from '../../components/display/full-date';
import Translate from '../../components/display/translate';
import NumberDisplay from '../../components/display/number';
import useRecordName from '../../utils/hooks/useRecordName';
import { getWalkingSpeed } from '../../utils/record';
import { translateNoDL } from '../../utils/i18n';

const CustomTooltip = withStyles(() => ({
  tooltip: {
    backgroundColor: '#343A40',
    maxWidth: 500,
    borderRadius: '6px',
  },
}))(Tooltip);

function TooltipInfo({ title, value, locale }) {
  return (
    <Typography>
      <span style={{ color: '#ADB5BD', fontSize: '14px' }}>
        { translateNoDL(title, locale) }
      </span>
      {' '}
      <span style={{ color: '#FFFFFF', fontWeight: '600', fontSize: '14px' }}>
        { translateNoDL(value, locale) }
      </span>
    </Typography>
  );
}

TooltipInfo.propTypes = {
  title: PropTypes.string.isRequired,
  value: PropTypes.string.isRequired,
  locale: PropTypes.string.isRequired,
};

function RecordInfo(props) {
  const {
    record,
    walkingAids,
    showPressureInfo,
    isCapa,
    pdf,
  } = props;

  const locale = useSelector(state => state.settings.locale);

  const walkingSpeed = useMemo(() => getWalkingSpeed(record), []);

  const showTooltip = useMemo(() => {
    if (pdf) {
      return false;
    }

    if (walkingSpeed || walkingAids.length > 0 || showPressureInfo) {
      return true;
    }

    return false;
  }, []);

  const [testName] = useRecordName(record);

  return (
    <Grid container direction="column" justify="flex-start" alignItems="center">
      <Grid item>
        <Grid container direction="row" justify="center" alignItems="center" spacing={1}>
          <Grid item>
            <Typography variant="subtitle1">
              <Translate>{record.type === 'static' ? record.staticSpec.protocol : testName}</Translate>
              { record.type === 'static' && (
                <React.Fragment>
                  {' ('}
                  <NumberDisplay options={{ maximumSignificantDigits: 3 }}>
                    {(record.durationPlanned) / 1000}
                  </NumberDisplay>
                  {'s)'}
                </React.Fragment>
              )}
            </Typography>
          </Grid>
          { showTooltip && (
            <Grid item>
              <CustomTooltip
                placement="top"
                title={(
                  <React.Fragment>
                    <TooltipInfo title="walkingSpeed" value={walkingSpeed || '-'} locale={locale} />
                    <TooltipInfo title="walkingAid" value={walkingAids.length > 0 ? walkingAids[0].type : '-'} locale={locale} />
                    { showPressureInfo && (
                      <TooltipInfo
                        title={isCapa ? 'meanPressureTitle' : 'maxPressureTitle'}
                        value={isCapa ? 'meanPressureInfo' : 'maxPressureInfo'}
                        locale={locale}
                      />
                    )}
                  </React.Fragment>
                )}
              >
                <InfoIcon style={{ color: '#005EDA', verticalAlign: 'middle' }} />
              </CustomTooltip>
            </Grid>
          )}
        </Grid>
      </Grid>
      <Grid item>
        <Typography variant="caption" style={{ color: 'rgba(0, 0, 0, 0.54)' }}>
          <FullDateDisplay>{record.startTime}</FullDateDisplay>
        </Typography>
      </Grid>
    </Grid>
  );
}

RecordInfo.propTypes = {
  record: PropTypes.shape({
    type: PropTypes.string,
    recordType: PropTypes.string,
    startTime: PropTypes.number,
    staticSpec: PropTypes.shape({
      protocol: PropTypes.string,
    }),
    durationPlanned: PropTypes.number,
    recordParameters: PropTypes.shape({
      walkingSpeed: PropTypes.oneOf(['fast', 'comfy']),
    }),
    walkingSpeed: PropTypes.oneOf(['fast', 'comfy']),
  }).isRequired,
  walkingAids: PropTypes.arrayOf(PropTypes.object),
  showPressureInfo: PropTypes.bool,
  isCapa: PropTypes.bool,
  pdf: PropTypes.bool,
};

RecordInfo.defaultProps = {
  walkingAids: [],
  showPressureInfo: false,
  isCapa: false,
  pdf: false,
};

export default RecordInfo;
