//
// Localization file
//
// retrieve the locale and set it to the store
//

import { DEFAULT_LOCALE } from '../reducers/settings';
import { updateLocale } from '../actions/index';
import { fetchLangFile } from './i18n';

// Retrieve browser language
function retrieveBrowserLocale() {
  return window.navigator.language || window.navigator.browserLanguage
    || window.navigator.systemLanguage || window.navigator.userLanguage;
}

// Check if the locale is currently supported, otherwise use default locale
function checkLocale(locale) {
  let canonicalLocale = locale;

  if (window.Intl && window.Intl.getCanonicalLocales) {
    [canonicalLocale] = window.Intl.getCanonicalLocales(locale);
  }

  switch (canonicalLocale) {
    case 'en-US':
    case 'fr-FR':
      return canonicalLocale;
    default:
      // eslint-disable-next-line no-console
      console.error(`Unsupported canonical locale: ${canonicalLocale}, from locale ${locale}`);
      return DEFAULT_LOCALE;
  }
}

// return locale in firebase ui locale format
// see https://github.com/firebase/firebaseui-web/blob/master/LANGUAGES.md
// for the full list of theire locale
function parseFirebaseUILocale(locale) {
  if (locale === 'en-US') {
    return 'en';
  }

  return 'fr';
}

function start(dispatch) {
  const locale = checkLocale(retrieveBrowserLocale());

  return fetchLangFile(locale)
    .then(() => dispatch(updateLocale(locale)));
}

export {
  parseFirebaseUILocale,
  start,
};
