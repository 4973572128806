import React, { useMemo, useState } from 'react';
import PropTypes from 'prop-types';
import Grid from '@material-ui/core/Grid';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import CardHeader from '@material-ui/core/CardHeader';
import { extent } from 'd3-array';
import { useSelector } from 'react-redux';

import Translate from '../../components/display/translate';
import GraphSumLoadVariation from '../../components/graph/sum-load-variation';
import GraphSumLoads from '../../components/graph/sum-loads';
import LoadVariationSelection from './load-variation-selection';
import LegendSide from './legend-side';
import LoadVariationMetric from './load-variation-metric';

import { Load } from '../../utils/metrics';

function LoadVariation(props) {
  const {
    leftLoads,
    rightLoads,
    averageLoads,
    isDynamic,
  } = props;

  const [zoom, setZoom] = useState(null);
  const loadUnitStore = useSelector(state => state.settings.unitMetrics[Load.key]);

  // update the x component of the input data. This facilitates the display
  // of ticks as duration.
  // also convert the data from kg/cm² to, either kg or N (based on user
  // settings)
  const [
    leftLoadsNewOrigin,
    rightLoadsNewOrigin,
    averageLoadsNewOrigin,
    xDomainDefault,
  ] = useMemo(() => {
    const leftExtentX = extent(leftLoads, d => d[0]);
    const rightExtentX = extent(rightLoads, d => d[0]);
    const fullExtent = extent([...leftExtentX, ...rightExtentX]);

    return [
      leftLoads.map(([x, y]) => [x - fullExtent[0], Load.convert(y, loadUnitStore)]),
      rightLoads.map(([x, y]) => [x - fullExtent[0], Load.convert(y, loadUnitStore)]),
      (isDynamic
        ? averageLoads.map(({ x, y, ...rest }) => ({
          x: x - fullExtent[0],
          y: Load.convert(y, loadUnitStore),
          ...rest,
        }))
        : []),
      [0, fullExtent[1] - fullExtent[0]],
    ];
  }, [leftLoads.length, rightLoads.length]);

  const [sliderValue, setSliderValue] = useState(xDomainDefault);
  const [rectsSelection, setRectsSelection] = useState([]);
  const [domainValue] = useState(xDomainDefault);

  // when the slider is updated, we compute the data for the selection
  // rectangles used in the graphs
  function handleSliderChange(_, newValue) {
    setSliderValue(newValue);

    const rects = [];
    newValue.forEach((e, idx) => {
      if (idx === 0) {
        if (e === xDomainDefault[0]) {
          return;
        }

        rects.push({ x1: 0, x2: e });
      }

      if (idx === 1) {
        if (e === xDomainDefault[1]) {
          return;
        }

        rects.push({ x1: e, x2: xDomainDefault[1] });
      }
    });
    setRectsSelection(rects);
  }

  function handleZoom(zoomTransform) {
    setZoom(zoomTransform);
  }

  return (
    <Grid container direction="row" justify="space-between" alignItems="flex-end" spacing={2}>
      <Grid item xs={12}>
        <Card>
          <CardHeader
            title={<Translate>loadVariation</Translate>}
            subheader={(
              <Translate input={{ UNIT: loadUnitStore }}>sumLoadAppliedOverTime</Translate>
            )}
          />
          <CardContent>
            <LegendSide />
            <GraphSumLoadVariation
              leftLoads={leftLoadsNewOrigin}
              rightLoads={rightLoadsNewOrigin}
              xDomain={domainValue}
              onZoom={handleZoom}
              zoomTransform={zoom}
              selection={rectsSelection}
              loadUnit={loadUnitStore}
            />
          </CardContent>
          { isDynamic && (
            <React.Fragment>
              <CardHeader
                title={<Translate>meanLoad</Translate>}
                subheader={(
                  <Translate input={{ UNIT: loadUnitStore }}>meanLoadPerStrides</Translate>
                )}
              />
              <CardContent>
                <LegendSide />
                <GraphSumLoads
                  loads={averageLoadsNewOrigin}
                  xDomain={domainValue}
                  onZoom={handleZoom}
                  zoomTransform={zoom}
                  selection={rectsSelection}
                  loadUnit={loadUnitStore}
                />
              </CardContent>
            </React.Fragment>
          )}
          <CardContent>
            <LoadVariationSelection
              sliderValue={sliderValue}
              min={xDomainDefault[0]}
              max={xDomainDefault[1]}
              onChange={handleSliderChange}
            />
          </CardContent>
        </Card>
      </Grid>
      <Grid item xs={6}>
        <LoadVariationMetric
          title={<Translate>leftFoot</Translate>}
          subheader={<Translate>selectionMean</Translate>}
          loads={leftLoadsNewOrigin}
          startTimestamp={sliderValue[0]}
          endTimestamp={sliderValue[1]}
          loadUnit={loadUnitStore}
        />
      </Grid>
      <Grid item xs={6}>
        <LoadVariationMetric
          title={<Translate>rightFoot</Translate>}
          subheader={<Translate>selectionMean</Translate>}
          loads={rightLoadsNewOrigin}
          startTimestamp={sliderValue[0]}
          endTimestamp={sliderValue[1]}
          loadUnit={loadUnitStore}
        />
      </Grid>
    </Grid>
  );
}

LoadVariation.propTypes = {
  leftLoads: PropTypes.arrayOf(PropTypes.arrayOf(PropTypes.number)).isRequired,
  rightLoads: PropTypes.arrayOf(PropTypes.arrayOf(PropTypes.number)).isRequired,
  averageLoads: PropTypes.arrayOf(PropTypes.shape({
    x: PropTypes.number,
    y: PropTypes.number,
    side: PropTypes.oneOf(['left', 'right']),
  })),
  isDynamic: PropTypes.bool,
};

LoadVariation.defaultProps = {
  isDynamic: false,
  averageLoads: null,
};

export default LoadVariation;
