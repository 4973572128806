import React from 'react';
import PropTypes from 'prop-types';
import Button from '@material-ui/core/Button';
import ButtonGroup from '@material-ui/core/ButtonGroup';

import Translate from '../../components/display/translate';

function ButtonGroupMeanStride({ showMean, setShowMean }) {
  return (
    <ButtonGroup disableElevation>
      <Button
        variant="contained"
        color={showMean ? 'primary' : 'default'}
        onClick={() => setShowMean(true)}
      >
        <Translate>meanDisplay</Translate>
      </Button>
      <Button
        variant="contained"
        color={showMean ? 'default' : 'primary'}
        onClick={() => setShowMean(false)}
      >
        <Translate>byStride</Translate>
      </Button>
    </ButtonGroup>
  );
}

ButtonGroupMeanStride.propTypes = {
  showMean: PropTypes.bool.isRequired,
  setShowMean: PropTypes.func.isRequired,
};

export default ButtonGroupMeanStride;
