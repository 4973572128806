import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { withTheme } from '@material-ui/core/styles';

import { select } from 'd3-selection';
import { scaleLinear } from 'd3-scale';
import { histogram, extent, max } from 'd3-array';
import { axisBottom, axisLeft } from 'd3-axis';
import { timeFormat } from 'd3-time-format';

import { statArray } from '../../utils/array';
import { styleAxis } from '../../utils/d3/styling';
import { updateRecordDisplayTimestamps } from '../../actions/index';

function meanBins(bins) {
  return bins.map(bin => ({
    length: statArray(bin.map(i => i.y), 'mean', true) || 0,
    x0: bin.x0,
    x1: bin.x1,
  }));
}

function mapStateToProps(state) {
  return ({
    intervalMs: state.intervalDailyGraphs,
    storeStartTimestamp: state.timestampsRecordDisplay.startTimestamp,
    storeEndTimestamp: state.timestampsRecordDisplay.endTimestamp,
  });
}

class HistogramDaily extends React.Component {
  componentDidMount() {
    const svg = select(this.node)
      .append('svg');

    const xScale = scaleLinear();
    const yScale = scaleLinear();
    const colorScale = scaleLinear()
      .domain(this.props.domain)
      .range(this.props.range)
      .clamp(true);

    const mainContainer = svg.append('g');
    const xContainer = mainContainer.append('g');
    const yContainer = mainContainer.append('g');
    const graphContainer = mainContainer.append('g');

    this.el = {
      svg,
      xScale,
      yScale,
      colorScale,
      mainContainer,
      xContainer,
      yContainer,
      graphContainer,
      histogram: histogram(),
    };

    this.sizes = {
      w: this.props.width,
      h: this.props.height,
      margin: {
        top: 5,
        bottom: this.props.pdf ? 10 : 25,
        left: 50,
        right: 20,
      },
    };

    this.init();

    if (this.props.values.length !== 0) {
      this.draw();
      // Simple check to unsure the timestamp is not the default one from the
      // store, that it has been manually set
      if (this.props.storeStartTimestamp !== 0) {
        this.updateOpacity();
      }
    }
  }

  componentDidUpdate(prevProps) {
    if (prevProps.values.length !== this.props.values.length
      || prevProps.intervalMs !== this.props.intervalMs) {
      this.draw();
    }

    if (prevProps.storeStartTimestamp !== this.props.storeStartTimestamp
    || prevProps.storeEndTimestamp !== this.props.storeEndTimestamp) {
      this.updateOpacity();
    }
  }

  init() {
    if (this.sizes.w === 0) {
      this.sizes.w = this.node.clientWidth - 50;
    }
    const width = this.sizes.w - this.sizes.margin.left - this.sizes.margin.right;
    const height = this.sizes.h - this.sizes.margin.top - this.sizes.margin.bottom;

    this.el.svg
      .attr('width', this.sizes.w + this.sizes.margin.left + this.sizes.margin.right)
      .attr('height', this.sizes.h + this.sizes.margin.top + this.sizes.margin.bottom);

    this.el.mainContainer
      .attr('transform', `translate(${this.sizes.margin.left},${this.sizes.margin.top})`);

    this.el.xScale.range([0, width]);

    this.el.yScale.range([height, 0]);

    this.el.xContainer.attr('transform', `translate(0,${height})`);
  }

  draw() {
    const xExtent = extent(this.props.values, d => d.x);

    this.el.xScale
      .domain([xExtent[0], xExtent[1]]);

    const thresholds = [];
    let cnt = xExtent[0];
    while (cnt < xExtent[1]) {
      thresholds.push(cnt);
      cnt += this.props.intervalMs;
    }

    const bins = meanBins(this.el.histogram
      .value(d => d.x)
      .domain(this.el.xScale.domain())
      .thresholds(thresholds)(this.props.values));

    const yMax = max(bins, el => el.length);
    this.el.yScale.domain([0, yMax + (yMax * 0.2)]);

    // recompute the height for the max height of histogram
    const height = this.sizes.h - this.sizes.margin.top - this.sizes.margin.bottom;
    this.el.graphContainer.selectAll('rect')
      .remove()
      .exit()
      .data(bins)
      .enter()
      .append('rect')
      .attr('cursor', 'pointer')
      .attr('fill', d => this.props.colorRange(this.el.colorScale(d.length)))
      .attr('x', 1)
      .attr('transform', d => `translate(${this.el.xScale(d.x0)},${this.el.yScale(d.length)})`)
      .attr('width', d => Math.max(1, this.el.xScale(d.x1) - this.el.xScale(d.x0) - 1))
      .attr('height', d => height - this.el.yScale(d.length))
      .on('click', (d) => {
        let x0;
        let x1;

        if (d.x0 === this.props.storeStartTimestamp && d.x1 === this.props.storeEndTimestamp) {
          // eslint-disable-next-line prefer-destructuring
          x0 = xExtent[0];
          // eslint-disable-next-line prefer-destructuring
          x1 = xExtent[1];
        } else {
          // eslint-disable-next-line prefer-destructuring
          x0 = d.x0;
          // eslint-disable-next-line prefer-destructuring
          x1 = d.x1;
        }

        this.props.dispatch(updateRecordDisplayTimestamps(x0, x1));
      });

    this.el.xContainer
      .call(axisBottom(this.el.xScale).ticks().tickFormat(d => timeFormat('%H:%M')(d)));
    this.el.xContainer.select('.domain').remove();
    styleAxis(this.el.xContainer, this.props.theme);

    this.el.yContainer
      .call(axisLeft(this.el.yScale).ticks(this.props.pdf && 5));
    styleAxis(this.el.yContainer, this.props.theme);
  }

  updateOpacity() {
    const xExtent = extent(this.props.values, d => d.x);

    const { storeStartTimestamp, storeEndTimestamp } = this.props;

    this.el.graphContainer.selectAll('rect')
      .attr('opacity', (d) => {
        if ((storeStartTimestamp === xExtent[0] && storeEndTimestamp === xExtent[1])
          || (storeStartTimestamp === d.x0 && storeEndTimestamp === d.x1)) {
          return 1;
        }

        return 0.3;
      });
  }

  render() {
    return (
      <div
        ref={(node) => {
          this.node = node;
          return undefined;
        }}
      />
    );
  }
}

HistogramDaily.propTypes = {
  // eslint-disable-next-line react/forbid-prop-types
  theme: PropTypes.object.isRequired,

  values: PropTypes.arrayOf(PropTypes.shape({
    x: PropTypes.number,
    y: PropTypes.number,
  })).isRequired,
  domain: PropTypes.arrayOf(PropTypes.number).isRequired,
  range: PropTypes.arrayOf(PropTypes.number).isRequired,
  colorRange: PropTypes.func.isRequired,
  intervalMs: PropTypes.number.isRequired,
  storeStartTimestamp: PropTypes.number.isRequired,
  storeEndTimestamp: PropTypes.number.isRequired,
  dispatch: PropTypes.func.isRequired,
  width: PropTypes.number,
  height: PropTypes.number,
  pdf: PropTypes.bool,
};

HistogramDaily.defaultProps = {
  width: 0,
  height: 250,
  pdf: false,
};

export default connect(mapStateToProps)(withTheme(HistogramDaily));
