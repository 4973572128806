class FileError extends Error {
  constructor(type, ...params) {
    super(...params);

    this.name = 'FileError';
    this.type = type;
    this.date = new Date();
  }
}

export default FileError;
