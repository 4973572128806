import { useMemo } from 'react';

import { getStdPressureCop } from '../metric';

function usePressureCopStd(processedPressures) {
  return useMemo(() => [
    getStdPressureCop(
      processedPressures.meanCop ? processedPressures.meanCop.left : [],
      processedPressures.stdCop ? processedPressures.stdCop.left : [],
    ),
    getStdPressureCop(
      processedPressures.meanCop ? processedPressures.meanCop.right : [],
      processedPressures.stdCop ? processedPressures.stdCop.right : [],
    ),
  ], []);
}

export default usePressureCopStd;
