import React from 'react';
import PropTypes from 'prop-types';

import { select } from 'd3-selection';
import { scaleLinear } from 'd3-scale';
import { range } from 'd3-array';

import { COLORMAP_GRADIENT } from '../../utils/colors';

class ColormapGraph extends React.Component {
  componentDidMount() {
    const svg = select(this.node)
      .append('svg');

    const mainContainer = svg.append('g');
    const colormapContainer = mainContainer.append('g');

    const scale = scaleLinear();
    const scaleHeatmap = scaleLinear();

    this.el = {
      svg,
      scale,
      scaleHeatmap,
      mainContainer,
      colormapContainer,
    };

    this.sizes = {
      w: this.props.width,
      h: this.props.height,
      margin: {
        top: 5,
        bottom: 0,
        left: this.props.pdf ? 0 : 20,
        right: this.props.pdf ? 0 : 20,
      },
    };

    this.draw();
  }

  draw() {
    if (this.sizes.w === 0) {
      this.sizes.w = this.node.clientWidth;
    }

    const width = this.sizes.w - this.sizes.margin.left - this.sizes.margin.right;
    const height = this.sizes.h - this.sizes.margin.top - this.sizes.margin.bottom;

    this.el.svg
      .attr('width', this.sizes.w)
      .attr('height', this.sizes.h);

    this.el.mainContainer
      .attr('transform', `translate(${this.sizes.margin.left},${this.sizes.margin.top})`);

    this.el.scale
      .domain([0, width])
      .range([0, 1]);

    this.el.scaleHeatmap
      .domain(COLORMAP_GRADIENT.map(i => i.value))
      .range(COLORMAP_GRADIENT.map(i => i.color));

    this.el.colormapContainer.selectAll('rect')
      .data(range(0, width))
      .enter()
      .append('rect')
      .attr('height', height)
      .attr('width', 2)
      .attr('x', d => d)
      .attr('y', 0)
      .attr('fill', d => this.el.scaleHeatmap(this.el.scale(d)));
  }

  render() {
    return (
      <div
        ref={(node) => {
          this.node = node;
          return undefined;
        }}
      />
    );
  }
}

ColormapGraph.propTypes = {
  pdf: PropTypes.bool,
  width: PropTypes.number,
  height: PropTypes.number,
};

ColormapGraph.defaultProps = {
  pdf: false,
  width: 0,
  height: 20,
};

export default ColormapGraph;
