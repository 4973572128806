import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import Card from '@material-ui/core/Card';
import CardHeader from '@material-ui/core/CardHeader';
import CardContent from '@material-ui/core/CardContent';
import { max } from 'd3-array';

import Translate from '../../components/display/translate';
import ColormapPressure from '../../components/insole/colormap-pressure';
import GraphPressureDistribution from '../../components/graph/pressure-distribution';

import useProcessedRecordPressures from '../../utils/hooks/useProcessedRecordPressures';
import usePressureScale from '../../utils/hooks/usePressureScale';

function StaticPressureDistribution(props) {
  const {
    title,
    processedRecord,
    insoles,
    cop,
    recordId,
    pdf,
  } = props;
  const [processedRecordPressures] = useProcessedRecordPressures(recordId);

  const maxPressuresLeft = ((processedRecordPressures || {}).maxPressures || {}).left || [];
  const maxPressuresRight = ((processedRecordPressures || {}).maxPressures || {}).right || [];

  const domain = useMemo(
    () => ([0.13, max([...maxPressuresLeft, ...maxPressuresRight])]),
    [maxPressuresLeft.length, maxPressuresRight.length],
  );

  const [scale, colorInterpolation] = usePressureScale(domain[0], domain[1]);

  return (
    <Card style={{ height: '100%' }}>
      <CardHeader
        title={<Translate>{ title }</Translate>}
        subheader={<Translate>maxStaticPressures</Translate>}
      />
      <CardContent>
        <GraphPressureDistribution
          insoles={insoles}
          cop={cop}
          processedRecord={processedRecord}
          maxPressuresLeft={maxPressuresLeft}
          maxPressuresRight={maxPressuresRight}
          scale={scale}
          pdf={pdf}
        />
        <ColormapPressure
          colorInterpolation={colorInterpolation}
          domain={domain}
        />
      </CardContent>
    </Card>
  );
}

StaticPressureDistribution.propTypes = {
  title: PropTypes.string.isRequired,
  // eslint-disable-next-line react/forbid-prop-types
  processedRecord: PropTypes.object,
  insoles: PropTypes.arrayOf(PropTypes.object).isRequired,
  cop: PropTypes.arrayOf(PropTypes.object).isRequired,
  recordId: PropTypes.string.isRequired,
  pdf: PropTypes.bool,
};

StaticPressureDistribution.defaultProps = {
  pdf: false,
  processedRecord: {},
};

export default StaticPressureDistribution;
