import React, { useEffect } from 'react';
import { useSelector } from 'react-redux';

import useScript from '../../utils/hooks/useScript';

import firebase from '../../firebase/index';
import getConfig from '../../firebase/firebaseui';
import { parseFirebaseUILocale } from '../../utils/l10n';

const FIREBASEUI_CONTAINER_ID = 'firebaseui-auth-container';

// inspired from https://github.com/greg-schrammel/react-firebaseui-localized
// to bypass poor support of localization by https://github.com/firebase/firebaseui-web-react
function FirebaseUIWrapper() {
  const version = '6.0.1';
  const locale = parseFirebaseUILocale(useSelector(state => state.settings.locale));
  const state = useScript(`https://www.gstatic.com/firebasejs/ui/${version}/firebase-ui-auth__${locale}.js`);

  useEffect(() => {
    if (firebase) {
      window.firebase = firebase;
    }
  }, []);

  useEffect(() => {
    if (state.value === 'loading') return () => {};
    if (state.value === 'error') throw state.payload;

    const firebaseui = new window.firebaseui.auth.AuthUI(firebase.auth());
    firebaseui.start(`#${FIREBASEUI_CONTAINER_ID}`, getConfig());
    return () => firebaseui.delete();
  }, [state.value]);

  return (
    <React.Fragment>
      <link
        type="text/css"
        rel="stylesheet"
        href={`https://www.gstatic.com/firebasejs/ui/${version}/firebase-ui-auth.css`}
      />
      <div id={FIREBASEUI_CONTAINER_ID} />
    </React.Fragment>
  );
}

export default FirebaseUIWrapper;
