import {
  deviation,
  min,
  max,
  mean,
  quantile,
} from 'd3-array';

function sortArrayNumber(array) {
  return array.sort((a, b) => a - b);
}

function statArray(i, type, sort = true) {
  let array;
  if ((type === 'quantile1' || type === 'quantile3') && sort) {
    array = sortArrayNumber(i);
  } else {
    array = i;
  }

  switch (type) {
    case 'mean':
      return mean(array);
    case 'deviation':
      return deviation(array);
    case 'median':
      return quantile(array, 0.5);
    case 'min':
      return min(array);
    case 'max':
      return max(array);
    case 'quantile1':
      return quantile(array, 0.25);
    case 'quantile3':
      return quantile(array, 0.75);
    default:
      return 0;
  }
}

export {
  statArray,
  sortArrayNumber,
};
