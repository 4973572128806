import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import { withStyles } from '@material-ui/core/styles';
import {
  Toolbar,
  Grid,
  Button,
  TextField,
  InputAdornment,
} from '@material-ui/core';

import PersonAddIcon from '@material-ui/icons/PersonAdd';
import SearchIcon from '@material-ui/icons/Search';

import Translate from '../../display/translate';

const styles = theme => ({
  button: {
    margin: theme.spacing(1),
  },
  leftIcon: {
    marginRight: theme.spacing(1),
  },
});

class ListToolbar extends React.Component {
  constructor() {
    super();

    this.state = {
      search: '',
    };
  }

  render() {
    const { classes } = this.props;

    return (
      <Toolbar>
        <Grid container direction="row" justify="space-between" alignItems="center">
          <Grid item>
            <TextField
              value={this.state.search}
              label={<Translate>search</Translate>}
              onChange={(ev) => {
                this.setState({ search: ev.target.value });
                this.props.onSearch(ev.target.value);
              }}
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <SearchIcon />
                  </InputAdornment>
                ),
              }}
            />
          </Grid>
          <Grid item>
            { this.props.button === undefined
              ? (
                <Button variant="outlined" component={Link} to="/patient/new" className={classes.button}>
                  <PersonAddIcon className={classes.leftIcon} />
                  <Translate>newPatient</Translate>
                </Button>
              ) : (
                this.props.button
              ) }
          </Grid>
        </Grid>
      </Toolbar>
    );
  }
}

ListToolbar.propTypes = {
  // eslint-disable-next-line react/forbid-prop-types
  classes: PropTypes.object.isRequired,

  onSearch: PropTypes.func.isRequired,
  button: PropTypes.element,
};

ListToolbar.defaultProps = {
  button: undefined,
};

export default withStyles(styles)(ListToolbar);
