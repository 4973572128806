import { useState, useMemo, useEffect } from 'react';

import { firebaseTimestampToDate } from '../date';

// in ms
const TEN_MINUTES = 10 * 60 * 1000;

function useInterpolationState(record) {
  const [manualUpdate, setManualUpdate] = useState(0);

  const [isInterpolationReady, isInterpolationLoading] = useMemo(() => {
    const isReady = 'interpolation' in record && 'updatedAt' in record.interpolation;
    // a record is loading the interpolation if:
    // - it has the field `interpolation.createdAt`
    // - it does not have the field `interpolation.updatedAt` or less
    //   than 10 minutes have passed since `interpolation.createdAt` field
    let isLoading = true;
    if ('interpolation' in record && 'createdAt' in record.interpolation) {
      if ('updatedAt' in record.interpolation) {
        isLoading = false;
      } else {
        isLoading = Date.now()
          - firebaseTimestampToDate(record.interpolation.createdAt).getTime() < TEN_MINUTES;
      }
    }

    return [isReady, isLoading];
  }, [
    Object.keys(record).length,
    'interpolation' in record ? Object.keys(record.interpolation).length : 0,
    'interpolation' in record ? record.interpolation.updatedAt : undefined,
    manualUpdate,
  ]);

  useEffect(() => {
    let timeoutId;
    // we will set a timeout if the record is still loading to detect timeout
    if (isInterpolationLoading && 'interpolation' in record) {
      timeoutId = setTimeout(() => {
        // we've reached the 10 minutes after the creation date, we will
        // check for a timeout
        setManualUpdate(prev => prev + 1);
      }, (firebaseTimestampToDate(record.interpolation.createdAt).getTime() + TEN_MINUTES)
        - Date.now());
    }

    return () => clearTimeout(timeoutId);
  }, [Object.keys(record).length, isInterpolationLoading]);

  return [isInterpolationReady, isInterpolationLoading];
}

export default useInterpolationState;
