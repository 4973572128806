import { parseStrideFlag, parseStrideOverflow } from './stride';

const VERSION_CSV = 6;
const NB_CAPA = 19;
const NB_COP = 16;

const HEADER_UNIT = [
  'clientTimestamp (ms)', 'timestamp (ms)', 'side',
  'strideLength (m)', 'widthMotion (m)',
  'timeHeelStrike1 (ms)', 'timeHeelStrike2 (ms)', 'timeToeOff (ms)',
  'strideDuration (ms)', 'velocity (m/s)', 'cadence (stride/min)',
  'swingDuration (ms)', 'stanceDuration (ms)', 'swingPercentage (%)', 'stancePercentage (%)',
  'stepTime (ms)', 'stepLength (m)', 'asymmetryParamStepTime (ms)', 'asymmetryParamStepLength (m)',
  'singleSupportDuration (ms)', 'doubleSupportDuration (ms)', 'singleSupportPercentage (%)', 'doubleSupportPercentage (%)',
  'asymmetryParamStrideDuration (ms)', 'asymmetryParamStrideLength (m)', 'asymmetryParamSingleSupportPercentage (%)',
  'strideElevation (cm)', 'flag', 'overflow',
];

const METRICS_KEYS = HEADER_UNIT.map(i => i.split(' ')[0]);

function arrayToCSVString(strides, insoles, record, documentId) {
  let str = '';

  // CSV header
  str += `#versionCSV=${VERSION_CSV},authorId=${record.authorId},documentId=${documentId}`;
  if (record.device) {
    str += `,device_product=${record.device.product},device_versionSDK=${record.device.versionSDK},device_model=${record.device.model},device_device=${record.device.device}`;
  }
  if (record.application) {
    str += `,application_name=${record.application.name},application_version=${record.application.version}`;
  }
  str += `,recordType=${record.recordType},startTime=${record.startTime}`;

  insoles.forEach((insole) => {
    const strInsole = `insole_${insole.side}_`;
    if (insole.versions !== undefined) {
      str += `,${strInsole}versions_firmware=${insole.versions.firmware}`;
      str += `,${strInsole}versions_atmel=${insole.versions.atmel}`;
      str += `,${strInsole}versions_hardware=${insole.versions.hardware}`;
      str += `,${strInsole}versions_algoHash=${insole.versions.algoHash}`;
      str += `,${strInsole}versions_firmwareMode=${insole.versions.firmwareMode}`;
      str += `,${strInsole}versions_productID=${insole.versions.productId}`;
      str += `,${strInsole}versions_protocolVersion=${insole.versions.protocolVersion}`;
      str += `,${strInsole}versions_testNumber=${insole.versions.testNumber}`;
    }

    str += `,${strInsole}name=${insole.name}`;
    str += `,${strInsole}macAddress=${insole.macAddress}`;
    str += `,${strInsole}protocolVersion=${insole.protocolVersion}`;
    str += `,${strInsole}number=${insole.number}`;
    str += `,${strInsole}size=${insole.size}`;
  });
  str += '\n';

  // Strides header
  // metrics
  str += HEADER_UNIT.join(',');
  str += ',';
  // capa
  str += [...Array(NB_CAPA).keys()].map(index => `capa${index}`).join(',');
  str += ',';
  // cop
  str += [...Array(NB_COP).keys()].map(index => `cop${index}`).join(',');
  str += '\n';

  // Strides
  strides.forEach((stride) => {
    // metrics
    str += `${METRICS_KEYS.map((v) => {
      if (v === 'flag') {
        return parseStrideFlag(stride[v]);
      }
      if (v === 'overflow') {
        return parseStrideOverflow(stride[v]);
      }

      return stride[v];
    }).join(',')}`;
    str += ',';

    // capa
    if (stride.capa) {
      str += stride.capa.join(',');
    } else {
      str += [...Array(NB_CAPA).keys()].map(() => '').join(',');
    }
    str += ',';

    // cop
    if (stride.cop) {
      str += stride.cop.join(',');
    } else {
      str += [...Array(NB_COP).keys()].map(() => '').join(',');
    }

    str += '\n';
    return undefined;
  });

  return str;
}

export {
  // eslint-disable-next-line import/prefer-default-export
  arrayToCSVString,
};
