import { parseMeta } from './meta';
import { isValidLine, parseStride } from './stride';

import { userId } from '../../firebase/auth';
import { getBatch, commitBatch, getFirestoreDB } from '../../firebase/firestore';
import { uploadRecordPressures, uploadMetricCSV } from '../../firebase/storage';

import {
  recordRef,
  insoleRef,
  strideRef,
} from '../../firebase/ref';
import FileError from './file-error';

const DEFAULT_STOP_BYTE = 50000;
const MAX_SIZE_BATCH = 250;

// Record class
//
// Only parseAndUploadSlice should be called, a promise is returned
// when the whole file has been uploaded.
//
// All update of the database is done via a firestore batch (this.batch).
// The batch is commited when it has reached a number of update (MAX_SIZE_BATCH)
// or if triggered manually.
//
// TODO: proper .catch()
class Record {
  constructor(file, updateProgress, zipFile) {
    this.size = file.size;
    this.startByte = 0;
    this.stopByte = 0;

    this.VERSION_CSV = -1;
    this.documentId = undefined;
    this.type = undefined;

    this.reader = new FileReader();

    this.batch = getBatch();
    this.batchCounter = 0;

    this.counterLeftStride = 0;
    this.counterRightStride = 0;

    this.file = file;
    this.zipFile = zipFile;
    this.updateProgress = updateProgress;
    // used to track version for process of lines (strides)
    this.insoles = {};
  }

  upload() {
    const extension = this.file.name.split('.').pop();
    if (extension.toLowerCase() !== 'csv') {
      // eslint-disable-next-line prefer-promise-reject-errors
      return Promise.reject({ filename: this.file.name });
    }

    return this.parseAndUploadSlice();
  }

  parseAndUploadSlice() {
    this.updateProgress(this.startByte / this.size);
    if (this.stopByte >= this.size) {
      // We come back from a parse and upload of a slice with a stopByte
      // that is >= of the size of the file.
      // We can assume that there is no more data to parse, we can stop now
      //
      // note: startByte should also be equal to stopByte
      return this.endRecord();
    }

    this.stopByte = Math.min(this.startByte + DEFAULT_STOP_BYTE, this.size);
    return this.readFileSlice(this.file, this.startByte, this.stopByte);
  }

  // parse the chunk between start and stop, and read it line by line
  readFileSlice(file, start, stop) {
    return new Promise((resolve, reject) => {
      this.reader.onload = (event) => {
        const data = event.target.result;

        const nbCR = (data.match(/\r/g) || []).length;
        const lines = data.split(/\r\n|\n/);

        // reduce + Promise for the process of each line
        lines.reduce(
          (acc, line, index) => acc.then(() => {
            let promiseRes;
            // When start (starByte) and index is 0 we are on the first line
            // of the first chunk.
            // So the current line contain the meta data of the csv
            if (start === 0 && index === 0) {
              promiseRes = this.processMetaData(line);
            } else if (start !== 0 || (start === 0 && index !== 1)) {
              // don't process the second line of the file, the header line
              if (isValidLine(line, this.versionCSV)) {
                promiseRes = this.processStride(line);
              } else {
                // wait for next chunk
                return Promise.resolve();
              }
            }

            // We don't want to increase the startByte if the line is not
            // valid. We might have cut in the middle of the last line
            // of the chunk and we'll need to increase the stopByte to
            // parse the entire line.
            //
            // But, if there is an issue in a line, in the middle of the
            // chunk, and the next line is correct, we might have some issue.
            // If we want to use Promise.reject (on the previous TODO) we'll
            // have to skip the line (increase the startByte), otherwise we
            // would be stuck in a loop.
            //
            // + 1 for the \n
            this.startByte += (line.length + 1);
            return promiseRes;
          }),
          Promise.resolve(),
        )
          .then(() => {
            // add carriage return found
            this.startByte += nbCR;
            return resolve();
          })
          // eslint-disable-next-line prefer-promise-reject-errors
          .catch(err => reject({ err, filename: this.file.name }));
      };

      this.reader.readAsText(file.slice(start, stop));
    }).then(() => this.parseAndUploadSlice());
    // When the current slice is done, we try to start the next one.
    // If it's the last a Promise.resolve will be returned.
  }

  // Parse the meta data line and upload the record and insoles data
  // We need to remove the '#' at the beginning of the line.
  async processMetaData(line) {
    const meta = parseMeta(line.substring(1).split(','));
    if (meta === undefined || meta.documentInfo.versionCSV === undefined) {
      return Promise.reject(new FileError('incorrectMetaOrVersionCSV', 'Undefined meta or versionCSV'));
    }

    const { documentId, documentInfo, insoles } = meta;
    this.versionCSV = documentInfo.versionCSV;
    this.documentId = documentId;
    this.insoles = insoles;

    if (documentInfo.type === 'dynamic' || documentInfo.type === 'static') {
      if (this.zipFile === undefined) {
        return Promise.reject(new FileError('noZipPressureFile', 'No zip file for the record with pressure data'));
      }
      await uploadRecordPressures(this.documentId, this.zipFile, userId());
    }
    await uploadMetricCSV(this.documentId, this.file, userId());

    // Originally set to 'set' but we we're deleting some data available
    // only when using a real time record (such as number of stride miss)
    // We changed to 'update'.
    //
    // Back to 'set', 'update' to empty record will result in a crash
    return this.updateFirestoreBatch('set', recordRef(getFirestoreDB(), this.documentId), documentInfo, true)
      .then(() => Promise.all(['left', 'right'].map((side) => {
        if (side in insoles) {
          return this.updateFirestoreBatch(
            'set',
            insoleRef(recordRef(getFirestoreDB(), this.documentId), insoles[side].macAddress),
            insoles[side],
          );
        }
        return Promise.resolve();
      })));
  }

  // Parse and upload a stride.
  // Keep track of the number of stride for the counters.
  // Update stopTime as current stride.clientTimestamp, it will be used at
  // the end of the parsing process, to update the record document.
  processStride(line) {
    const stride = parseStride(line.split(','), this.versionCSV, this.insoles);

    if (stride === undefined) {
      return Promise.reject(new FileError('incorrectStride', 'Undefined stride'));
    }

    this.stopTime = stride.clientTimestamp;
    if (stride.side === 'left') {
      this.counterLeftStride += 1;
    } else if (stride.side === 'right') {
      this.counterRightStride += 1;
    } else {
      return Promise.reject(new FileError('unknownSide', `Unknown side: ${stride.side}`));
    }

    const hasKey = 'key' in stride;
    let strideDocument = stride;
    if (hasKey) {
      // we remove the `key` field from the `stride` object
      strideDocument = (({ key, ...rest }) => rest)(stride);
    }

    return this.updateFirestoreBatch(
      'set',
      strideRef(recordRef(getFirestoreDB(), this.documentId), hasKey ? stride.key : `${stride.clientTimestamp}`),
      strideDocument,
    );
  }

  // the file has been parsed
  // we can send the counters and the stopTime
  //
  // send the document id to the caller of
  // the intial function (parseAndUploadSlice) via the promise
  endRecord() {
    return this.updateFirestoreBatch('update', recordRef(getFirestoreDB(), this.documentId), {
      stopTime: this.stopTime,
      counterLeftStride: this.counterLeftStride,
      counterRightStride: this.counterRightStride,
    }, true).then(() => Promise.resolve({ documentId: this.documentId, filename: this.file.name }));
  }

  // Update the current batch.
  // A batch commit is done every MAX_SIZE_BATCH update.
  // Can force the commit if necessary.
  updateFirestoreBatch(variant, ref, data, forceCommit = false) {
    if (variant !== 'set' && variant !== 'update') {
      return Promise.reject(new Error(`Unknown variant: ${variant}, was excepted 'set' or 'update'`));
    }

    this.batchCounter += 1;
    this.batch[variant](ref, data);

    if (this.batchCounter >= MAX_SIZE_BATCH || forceCommit) {
      this.batchCounter = 0;
      return commitBatch(this.batch)
        .then(() => {
          // a write batch cannot be re-used after a commit()
          this.batch = getBatch();
          return Promise.resolve();
        });
    }

    return Promise.resolve();
  }
}

export default Record;
