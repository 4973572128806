function filterDiosRecords(records, fromPorfic = false) {
  return records.filter((record) => {
    if (record === undefined) {
      return false;
    }

    let hasSteps = false;
    // no step, no record
    //
    // It's simpler to check for step than the inverse (the keys, `counterStep`,
    // `counterLeftStride`...) might not be defined
    if (record.counterStep > 0 || record.counterStride > 0
      || record.counterLeftStride > 0 || record.counterRightStride > 0) {
      hasSteps = true;
    }

    // no further check necessary
    if (fromPorfic === false) {
      return hasSteps;
    }

    // since each record has been manually fetch via it's Id we don't have thinner
    // control during the fetch via firebase
    return hasSteps && record.stopTime > 0
      && 'application' in record
      && (record.application.name === 'android-lokara'
        || record.application.name === 'android-rehab');
  });
}

function filterRehabRecords(records) {
  return records.filter((record) => {
    if (record === undefined || !('successPercentage' in record)) {
      return false;
    }

    return record.startTime > 0 && record.stopTime > 0;
  });
}

function sortRecords(records, decreasing = true) {
  return records.sort((a, b) => (decreasing
    ? (b.stopTime - a.stopTime)
    : (a.stopTime - b.stopTime)));
}

export {
  filterDiosRecords,
  filterRehabRecords,
  sortRecords,
};
