import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import Typography from '@material-ui/core/Typography';

// TODO: we could add the target frequency to show if we're under the target
function ReplayPerformance({ wsFrequency, animationFrequency }) {
  return (
    <Fragment>
      <div>
        <Typography variant="caption">{`Reception: ${wsFrequency} Hz`}</Typography>
      </div>
      <div>
        <Typography variant="caption">{`Animation: ${animationFrequency} Hz`}</Typography>
      </div>
    </Fragment>
  );
}

ReplayPerformance.propTypes = {
  wsFrequency: PropTypes.number.isRequired,
  animationFrequency: PropTypes.number.isRequired,
};

export default ReplayPerformance;
