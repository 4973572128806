import React from 'react';
import PropTypes from 'prop-types';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';

import ProgressWait from '../../components/display/progress-wait';
import PageHeader from '../../components/display/page-header';
import RecordPageTitle from './page-title';
import RecordPageSubtitle from './page-subtitle';
import StaticPressureDistribution from './static-pressure-distribution';
import StaticBipodalCOP from './static-bipodal-cop';
import StaticPanel from './static-panel';
import StaticLoadVariation from './static-load-variation';
import StaticReplay from './static-replay';
import InterpolationLoadingWrapper from './interpolation-loading-wrapper';
import Report from '../../components/record/report';
import DownloadPressures from './download-pressures';
import Translate from '../../components/display/translate';

import useInsoles from '../../utils/hooks/useInsoles';
import useStaticCop from '../../utils/hooks/useStaticCop';
import useBipodalCop from '../../utils/hooks/useBipodalCop';
import useProcessedRecord from '../../utils/hooks/useProcessedRecord';
import { fileDownloadDateFormat } from '../../utils/date';
import { getRecordPressuresUrl } from '../../firebase/storage';

function RecordStatic(props) {
  const {
    record,
    walkingSpeed,
    walkingAids,
    isInterpolationReady,
    isInterpolationLoading,
  } = props;

  const [cop, copInProgress] = useStaticCop(record.key, isInterpolationReady);
  const [bipodalCop, bipodalCopInProgress] = useBipodalCop(record.key, isInterpolationReady);
  const [
    processedRecord,
    processedRecordInProgress,
  ] = useProcessedRecord(record.key, isInterpolationReady);
  const [insoles, insoleInProgress] = useInsoles(record.key);

  if ((copInProgress && isInterpolationReady === false)
    || (bipodalCopInProgress && isInterpolationReady === false)
    || (processedRecordInProgress && Object.keys(processedRecord || {}).length === 0)
    || insoleInProgress) {
    return (<ProgressWait />);
  }

  return (
    <React.Fragment>
      <PageHeader>
        <Grid container direction="row" justify="space-between" alignItems="center">
          <Grid item>
            <RecordPageTitle />
            <RecordPageSubtitle
              record={record}
              walkingSpeed={walkingSpeed}
              walkingAids={walkingAids}
            />
          </Grid>
          <Grid item>
            <Grid item container direction="column" justify="flex-start" alignItems="flex-start">
              <Grid item>
                <Typography variant="overline" style={{ color: '#ADB5BD' }} gutterBottom>
                  <Translate>downloadFormat</Translate>
                </Typography>
              </Grid>
              <Grid item>
                <Grid container direction="row" justify="flex-end" alignItems="center" spacing={1}>
                  <Grid item>
                    <DownloadPressures
                      loadData={() => getRecordPressuresUrl(record.key)}
                      filename={`${fileDownloadDateFormat(new Date(record.startTime))}-pressures.zip`}
                    />
                  </Grid>
                  <Grid item>
                    <Report
                      recordsId={[record.key]}
                      filename={`${fileDownloadDateFormat(new Date(record.startTime))}.pdf`}
                    />
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </PageHeader>
      <Grid container direction="row" justify="flex-start" alignItems="stretch" spacing={2} style={{ marginTop: '24px' }}>
        <Grid item xs={12}>
          <StaticPanel record={record} />
        </Grid>
        <Grid item xs={12}>
          <StaticReplay insoles={insoles} recordId={record.key} />
        </Grid>
        <Grid item xs={6}>
          <InterpolationLoadingWrapper
            title="maximumPressureDistribution"
            isLoading={isInterpolationLoading}
            isReady={isInterpolationReady}
            isDataLoading={processedRecordInProgress || copInProgress}
          >
            <StaticPressureDistribution
              title="maximumPressureDistribution"
              processedRecord={processedRecord}
              insoles={insoles}
              cop={cop}
              recordId={record.key}
            />
          </InterpolationLoadingWrapper>
        </Grid>
        <Grid item xs={6}>
          <InterpolationLoadingWrapper
            title="globalCenterPressure"
            isLoading={isInterpolationLoading}
            isReady={isInterpolationReady}
            isDataLoading={processedRecordInProgress
                || bipodalCopInProgress
                || processedRecord?.ellipseBipodalCop === undefined}
          >
            <StaticBipodalCOP
              title="globalCenterPressure"
              bipodalCop={bipodalCop}
              ellipse={processedRecord?.ellipseBipodalCop}
            />
          </InterpolationLoadingWrapper>
        </Grid>
        <Grid item xs={12}>
          <InterpolationLoadingWrapper
            title="loadVariation"
            isLoading={isInterpolationLoading}
            isReady={isInterpolationReady}
            isDataLoading={processedRecordInProgress
              || bipodalCopInProgress
              || processedRecord?.ellipseBipodalCop === undefined}
          >
            <StaticLoadVariation cop={cop} />
          </InterpolationLoadingWrapper>
        </Grid>
      </Grid>
    </React.Fragment>
  );
}

RecordStatic.propTypes = {
  record: PropTypes.shape({
    key: PropTypes.string,
    startTime: PropTypes.number,
  }).isRequired,
  walkingSpeed: PropTypes.string,
  walkingAids: PropTypes.arrayOf(PropTypes.object).isRequired,
  isInterpolationReady: PropTypes.bool.isRequired,
  isInterpolationLoading: PropTypes.bool.isRequired,
};

RecordStatic.defaultProps = {
  walkingSpeed: undefined,
};

export default RecordStatic;
