import React, {
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import PropTypes from 'prop-types';
import Grid from '@material-ui/core/Grid';
import { scaleLinear } from 'd3-scale';
import { max } from 'd3-array';
import { zoom } from 'd3-zoom';
import { select, event as currentEvent } from 'd3-selection';
import addMilliseconds from 'date-fns/addMilliseconds';
import intervalToDuration from 'date-fns/intervalToDuration';

import {
  Graph,
  useGraphDimensions,
  Rects,
} from '@feetme/d3act';

import Axis from './utils/axis';
import Tooltip from './utils/tooltip';
import TooltipInfo from './utils/tooltip-info';
import Translate from '../display/translate';
import { getLeftColor, getRightColor } from '../../utils/colors';
import { LOAD_UNITS } from '../../utils/metrics/load';

function GraphSumLoads(props) {
  const {
    loads,
    xDomain,
    zoomTransform,
    onZoom,
    height,
    width,
    selection,
    loadUnit,
  } = props;

  const [tooltipOpacity, setTooltipOpacity] = useState(0);
  const [tooltipTransform, setTooltipTransform] = useState('');
  const [tooltipValue, setTooltipValue] = useState(32);

  const [ref, dimensions] = useGraphDimensions({
    height,
    width,
  });

  const scrollWarningRef = useRef(null);

  const xScale = useMemo(() => {
    const x = scaleLinear()
      .domain(xDomain)
      .range([0, dimensions.boundedWidth]);

    if (zoomTransform !== null) {
      return zoomTransform.transform.rescaleX(x);
    }

    return x;
  }, [...xDomain, dimensions.boundedWidth, zoomTransform]);

  const xAccessor = d => d.x;
  const yAccessor = d => d.y;

  const yScale = useMemo(() => {
    const maxY = max(loads, yAccessor);
    return scaleLinear()
      .domain([0, maxY])
      .range([dimensions.boundedHeight, 0])
      .nice();
  }, [loads.length]);

  const xAccessorScaled = d => xScale(xAccessor(d));
  const yAccessorScaled = d => yScale(yAccessor(d));
  // empirical width values
  const widthAccessor = () => (loads.length > 50 ? 4 : 12);

  function scrollWarningDisplay(shouldDisplay) {
    if (scrollWarningRef === null) {
      return;
    }

    select(scrollWarningRef.current).transition().duration(250).style('opacity', shouldDisplay ? 1 : 0);
    // if we display the scroll warning we also:
    // - clear the mouse move debounce
    // - hide the tooltip
    setTooltipOpacity(0);
  }

  function zoomed() {
    scrollWarningDisplay(false);
    onZoom(currentEvent);
  }

  useEffect(() => {
    const zoomExtent = [
      [0, 0],
      [dimensions.boundedWidth, dimensions.boundedHeight],
    ];
    const z = zoom()
      // enable the zoom if either the ctrl or meta (Mac OS) key is pressed
      .filter(() => ((currentEvent.ctrlKey || currentEvent.metaKey)
        || (currentEvent.type !== 'wheel' && currentEvent.button === 0)))
      .scaleExtent([1, Infinity])
      .translateExtent(zoomExtent)
      .extent(zoomExtent)
      .on('zoom', zoomed);

    // selecting the svg should be enough but since we're changing the origin
    // of the graph (with the `<g>` in the `<Graph>` component), it's easier to
    // use the g component instead of having to update the zoom transform to
    // ignore the left margin
    const svg = select(ref.current).select('svg').select('g');
    svg
      .call(z)
      .on('wheel', () => {
        // if a scroll event is triggered on the svg, show warning if the ctrl
        // key or meta key is pressed
        if (currentEvent.ctrlKey || currentEvent.metaKey) {
          currentEvent.preventDefault();
        } else {
          scrollWarningDisplay(true);
        }
      })
      .on('mouseleave', () => scrollWarningDisplay(false));

    return () => svg.on('.zoom', null);
  }, [dimensions.boundedWidth, dimensions.boundedHeight]);

  function handleMouseEnter(d) {
    // we want to skip the mouse enter event if the scroll warning is enabled
    if (scrollWarningRef !== null && select(scrollWarningRef.current).style('opacity') === '1') {
      return;
    }

    const x = xAccessorScaled(d) + dimensions.marginLeft + (widthAccessor() / 2);
    const y = yAccessorScaled(d) + dimensions.marginTop;

    setTooltipOpacity(1);
    setTooltipTransform(`translate(calc(-50% + ${x}px), calc(-100% + ${y}px - 15px))`);
    setTooltipValue(Math.round(d.y));
  }

  function handleMouseLeave() {
    setTooltipOpacity(0);
  }

  return (
    <div ref={ref} style={{ position: 'relative', height }}>
      <Tooltip
        opacity={tooltipOpacity}
        transform={tooltipTransform}
      >
        <Grid container direction="column" justify="flex-start" alignItems="stretch">
          <Grid item>
            <TooltipInfo title="load" value={tooltipValue} unit={loadUnit} />
          </Grid>
        </Grid>
      </Tooltip>
      <Graph dimensions={dimensions}>
        {/* catch other pointer events, such as the scroll */}
        <rect
          x={0}
          y={0}
          width={dimensions.boundedWidth}
          height={dimensions.boundedHeight}
          fill="none"
          pointerEvents="all"
        />
        <Axis
          dimension="x"
          scale={xScale}
          keyAccessor={d => d}
          fullTick
          tickStyle={{ strokeDasharray: '5,5' }}
          formatTick={(d) => {
            const start = new Date(0);
            const end = addMilliseconds(new Date(0), d);

            const duration = intervalToDuration({ start, end });
            const zeroPad = num => String(num).padStart(2, '0');

            return `${zeroPad(duration.minutes + duration.hours * 60)}:${zeroPad(duration.seconds)}`;
          }}
        />
        <Axis
          dimension="y"
          scale={yScale}
          keyAccessor={d => d}
        />
        <clipPath id="clip-path">
          <rect
            x={0}
            y={0}
            width={dimensions.boundedWidth}
            height={dimensions.boundedHeight}
          />
        </clipPath>
        <Rects
          data={selection}
          keyAccessor={d => `${d.x1}${d.x2}`}
          xAccessor={d => xScale(d.x1)}
          yAccessor={() => 0}
          widthAccessor={d => xScale(d.x2) - xScale(d.x1)}
          heightAccessor={() => dimensions.boundedHeight}
          fillAccessor="#F0F6FF"
          opacity={0.8}
          clipPath="url(#clip-path)"
        />
        <Rects
          data={loads}
          keyAccessor={xAccessor}
          xAccessor={xAccessorScaled}
          yAccessor={yAccessorScaled}
          widthAccessor={widthAccessor}
          heightAccessor={d => dimensions.boundedHeight - yAccessorScaled(d)}
          fillAccessor={d => (d.side === 'left' ? getLeftColor() : getRightColor())}
          onMouseEnter={handleMouseEnter}
          onMouseLeave={handleMouseLeave}
          clipPath="url(#clip-path)"
        />
        <g
          ref={scrollWarningRef}
          pointerEvents="none"
          opacity={0}
        >
          <rect
            x={0}
            y={0}
            width={dimensions.boundedWidth}
            height={dimensions.boundedHeight}
            opacity={0.6}
          />
          <text
            x={dimensions.boundedWidth / 2}
            y={dimensions.boundedHeight / 2}
            textAnchor="middle"
            fill="white"
            fontSize="32px"
          >
            <Translate>{navigator.userAgent.toLowerCase().includes('mac') ? 'cmdScrollToZoom' : 'ctrlScroolToZoom'}</Translate>
          </text>
        </g>
      </Graph>
    </div>
  );
}

GraphSumLoads.propTypes = {
  loads: PropTypes.arrayOf(PropTypes.shape({
    x: PropTypes.number,
    y: PropTypes.number,
    side: PropTypes.oneOf(['left', 'right']),
  })).isRequired,

  xDomain: PropTypes.arrayOf(PropTypes.number).isRequired,
  // eslint-disable-next-line react/forbid-prop-types
  zoomTransform: PropTypes.object,
  onZoom: PropTypes.func.isRequired,
  selection: PropTypes.arrayOf(PropTypes.shape({
    x1: PropTypes.number,
    x2: PropTypes.number,
  })).isRequired,
  loadUnit: PropTypes.oneOf(LOAD_UNITS).isRequired,

  width: PropTypes.number,
  height: PropTypes.number,
};

GraphSumLoads.defaultProps = {
  width: 0,
  height: 250,
  zoomTransform: null,
};

export default React.memo(GraphSumLoads);
