import React from 'react';
import PropTypes from 'prop-types';
import Card from '@material-ui/core/Card';
import CardHeader from '@material-ui/core/CardHeader';
import CardContent from '@material-ui/core/CardContent';

import Translate from '../../components/display/translate';
import CompareRecordReplays from './replays';

function StaticReplays(props) {
  const {
    records,
    insoles,
  } = props;

  return (
    <Card>
      <CardHeader
        title={<Translate>replayPressureData</Translate>}
        subheader={<Translate>replayPressureDataDescriptionStatic</Translate>}
      />
      <CardContent>
        <CompareRecordReplays
          records={records}
          insoles={insoles}
          mode="static"
          minDomain={0.13}
        />
      </CardContent>
    </Card>
  );
}

StaticReplays.propTypes = {
  records: PropTypes.arrayOf(PropTypes.shape({
    key: PropTypes.string,
  })).isRequired,
  // eslint-disable-next-line react/forbid-prop-types
  insoles: PropTypes.object.isRequired,
};

export default StaticReplays;
