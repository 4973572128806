import React, { useMemo, useState } from 'react';
import PropTypes from 'prop-types';
import Card from '@material-ui/core/Card';
import CardHeader from '@material-ui/core/CardHeader';
import CardContent from '@material-ui/core/CardContent';
import { max } from 'd3-array';
import { useSelector } from 'react-redux';

import Translate from '../../components/display/translate';
import ColormapPressure from '../../components/insole/colormap-pressure';
import ButtonGroupMeanStride from './button-group-mean-stride';
import StrideSlider from './stride-slider';
import GraphMaxPressure from '../../components/graph/max-pressure';

import usePressureScale from '../../utils/hooks/usePressureScale';
import usePressuresStrides from '../../utils/hooks/usePressuresStrides';
import useStridePressureIndex from '../../utils/hooks/useStridePressureIndex';
import useNbPressureStrides from '../../utils/hooks/useNbPressureStrides';
import { Load } from '../../utils/metrics';

function MaxPressureDistribution(props) {
  const {
    title,
    processedPressures,
    processedRecord,
    insoles,
    strides,
    recordId,
  } = props;

  const loadUnitStore = useSelector(state => state.settings.unitMetrics[Load.key]);
  const [showMean, setShowMean] = useState(true);
  const [strideIndex, setStrideIndex] = useState(0);

  const domain = useMemo(() => {
    let maxDomain;
    if (showMean) {
      maxDomain = max([
        ...(processedPressures.meanMaxPressures.left || []),
        ...(processedPressures.meanMaxPressures.right || []),
      ]);
    } else {
      [, maxDomain] = processedPressures.domainMaxPressures;
    }

    // we always want to keep to min domain at 0.13
    return [0.13, maxDomain];
  }, [showMean]);

  const [scale, colorInterpolation] = usePressureScale(domain[0], domain[1]);

  const [leftStride, rightStride] = useStridePressureIndex(strides, strideIndex);
  const [
    leftStridePressures,
    rightStridePressures,
  ] = usePressuresStrides(recordId, leftStride, rightStride);

  // find out the number of strides with pressure data
  const nbPressureStrides = useNbPressureStrides(strides);

  return (
    <Card>
      <CardHeader
        title={<Translate>{ title }</Translate>}
        subheader={(
          <Translate input={{ UNIT: loadUnitStore }}>
            maximumPressureDistributionSubtitle
          </Translate>
        )}
        action={(
          <div style={{ marginTop: '8px', marginRight: '8px' }}>
            <ButtonGroupMeanStride showMean={showMean} setShowMean={setShowMean} />
          </div>
        )}
      />
      <CardContent>
        <GraphMaxPressure
          insoles={insoles}
          processedPressures={processedPressures}
          processedRecord={processedRecord}
          scale={scale}
          showMean={showMean}
          leftStridePressures={leftStridePressures}
          rightStridePressures={rightStridePressures}
          leftStride={leftStride}
          rightStride={rightStride}
        />
        <ColormapPressure
          colorInterpolation={colorInterpolation}
          domain={domain}
        />
        <StrideSlider
          index={strideIndex}
          setIndex={setStrideIndex}
          disabled={showMean}
          nbStrides={Math.max(nbPressureStrides.left, nbPressureStrides.right)}
        />
      </CardContent>
    </Card>
  );
}

MaxPressureDistribution.propTypes = {
  title: PropTypes.string.isRequired,
  processedPressures: PropTypes.shape({
    meanMaxPressures: PropTypes.shape({
      left: PropTypes.arrayOf(PropTypes.number),
      right: PropTypes.arrayOf(PropTypes.number),
    }),
    domainMaxPressures: PropTypes.arrayOf(PropTypes.number),
  }),
  // eslint-disable-next-line react/forbid-prop-types
  processedRecord: PropTypes.object,
  strides: PropTypes.arrayOf(PropTypes.object).isRequired,
  insoles: PropTypes.arrayOf(PropTypes.object).isRequired,
  recordId: PropTypes.string.isRequired,
};

MaxPressureDistribution.defaultProps = {
  processedPressures: {
    meanMaxPressures: {},
  },
  processedRecord: {},
};

export default MaxPressureDistribution;
