import { fetchDiosStrides } from '../../firebase/firestore';
import useFirestore from './useFirestore';
import { filterWithFlag } from '../strides';

function useStrides(recordId, isInterpolationReady) {
  return useFirestore(
    () => fetchDiosStrides(recordId),
    [],
    [recordId, isInterpolationReady],
    strides => filterWithFlag(strides),
  );
}

export default useStrides;
