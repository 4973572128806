import { useMemo } from 'react';

function useStaticRotation(array, insoleMesh, isStatic) {
  return useMemo(() => {
    if (!isStatic || insoleMesh === undefined || array === undefined) {
      return array;
    }

    // We only use the rotation to the left. The rotation to the right is
    // symmetric and it will be done in `<InsoleView />` from
    // `@feetme/insole-view-react`
    return insoleMesh.staticRotations(array, 'left');
  }, [array.length, array[0], insoleMesh !== undefined, isStatic]);
}

export default useStaticRotation;
