import React, { useMemo, useRef, useState } from 'react';
import PropTypes from 'prop-types';
import Grid from '@material-ui/core/Grid';

import InsolesDynamicPressures from '../../record/components/insoles-dynamic-pressures';
import InsolesStaticPressures from '../../record/components/insoles-static-pressures';
import ColormapPressure from '../../components/insole/colormap-pressure';
import ReplayPerformance from '../../record/components/replay-performance';
import ReplaySeek from '../../record/components/replay-seek';
import RecordInfo from './record-info';
import BipodalCopReplayGraph from '../../components/graph/bipodal-cop-replay';
import DataNotAvailable from './data-not-available';

import useAnimationFrame from '../../utils/hooks/useAnimationFrame';
import useReplay from '../../utils/hooks/useReplay';
import usePressureScale from '../../utils/hooks/usePressureScale';
import { isProdTarget } from '../../utils/env';
import { isMetric } from '../../utils/record';

function CompareRecordReplays(props) {
  const {
    records,
    walkingAids,
    insoles,
    mode,
    framerate,
    meshX,
    minDomain,
  } = props;

  const [frame, setFrame] = useState({});
  const previousFrame = useRef(undefined);
  const leftCopsRef = useRef(records.map(() => []));
  const rightCopsRef = useRef(records.map(() => []));
  const [bipodalCopReset, setBipodalCopReset] = useState(0);

  const {
    frameRef,
    recordInfo,
    handlePlay,
    handlePause,
    handleSeek,
    wsFrequency,
    error,
  } = useReplay(records.filter(i => !isMetric(i)).map(i => i.key), framerate, meshX);

  const [scale, colorInterpolation] = usePressureScale(minDomain, recordInfo.maxPressure);

  function resetPaths() {
    leftCopsRef.current = records.map(() => []);
    rightCopsRef.current = records.map(() => []);
    // force an update on the BipodalCopReplayGraph children to clear their
    // bipodal cop
    setBipodalCopReset(i => i + 1);
  }

  function handleSeekWrapper(newIndex) {
    resetPaths();
    return handleSeek(newIndex);
  }

  function frameHasCop(side, frameRecord) {
    return frameRecord[side] && frameRecord[side].cop;
  }

  function newStrideId(side, recordId) {
    return previousFrame.current[recordId][side] && frameRef.current[recordId][side]
      && frameRef.current[recordId][side].strideId
      !== previousFrame.current[recordId][side].strideId;
  }

  const animationFrequency = useAnimationFrame(() => {
    if (frameRef.current === undefined) {
      return;
    }

    if (previousFrame.current !== undefined
      && previousFrame.current.index > frameRef.current.index) {
      // reset paths when we loop over on the record
      resetPaths();
    }

    records.filter(i => !isMetric(i)).forEach((record, index) => {
      const frameRecord = frameRef.current[record.key];
      if (frameHasCop('left', frameRecord)) {
        leftCopsRef.current[index].push(frameRecord.left.cop);
      }
      if (frameHasCop('right', frameRecord)) {
        rightCopsRef.current[index].push(frameRecord.right.cop);
      }
    });

    if (previousFrame.current !== undefined && mode === 'dynamic') {
      records.filter(i => !isMetric(i)).forEach((record, index) => {
        if (newStrideId('left', record.key)) {
          leftCopsRef.current[index] = [];
        }

        if (newStrideId('right', record.key)) {
          rightCopsRef.current[index] = [];
        }
      });
    }

    setFrame(frameRef.current);
    previousFrame.current = frameRef.current;
    frameRef.current = undefined;
  });

  const ReplayComponent = useMemo(
    () => (mode === 'static' ? InsolesStaticPressures : InsolesDynamicPressures),
    [mode],
  );

  return (
    <React.Fragment>
      { !isProdTarget() && (
        <ReplayPerformance
          wsFrequency={wsFrequency}
          animationFrequency={animationFrequency}
        />
      )}
      <Grid container direction="row" justify="space-around" alignItems="center" spacing={5}>
        { records.map((record, index) => {
          const recordFrame = frame[record.key] || {};
          if (isMetric(record)) {
            return (
              <Grid item xs={12} sm={12} md={6} lg={4} key={record.key}>
                <RecordInfo record={record} walkingAids={walkingAids[record.key]} />
                <InsolesDynamicPressures
                  insoles={insoles[record.key]}
                  leftCop={[]}
                  rightCop={[]}
                  scale={scale}
                  disableReport
                />
                <DataNotAvailable isReplay />
              </Grid>
            );
          }
          return (
            <Grid item xs={12} sm={12} md={6} lg={4} key={record.key}>
              <RecordInfo record={record} walkingAids={walkingAids[record.key]} />
              <BipodalCopReplayGraph frame={recordFrame} mode={mode} reset={bipodalCopReset}>
                <ReplayComponent
                  insoles={insoles[record.key]}
                  leftCop={leftCopsRef.current[index]}
                  rightCop={rightCopsRef.current[index]}
                  leftPressures={recordFrame.left ? recordFrame.left.interpolatedFrame : []}
                  rightPressures={recordFrame.right ? recordFrame.right.interpolatedFrame : []}
                  meshX={meshX}
                  meshY={meshX * 3}
                  arcRadius={6}
                  scale={scale}
                  // dynamic props
                  leftLoadHeelStrike={(recordFrame.left && recordFrame.left.loadHeelStrike)
                    ? Math.round(recordFrame.left.loadHeelStrike) : undefined}
                  leftLoadToeOff={(recordFrame.left && recordFrame.left.loadToeOff)
                    ? Math.round(recordFrame.left.loadToeOff) : undefined}
                  rightLoadHeelStrike={(recordFrame.right && recordFrame.right.loadHeelStrike)
                    ? Math.round(recordFrame.right.loadHeelStrike) : undefined}
                  rightLoadToeOff={(recordFrame.right && recordFrame.right.loadToeOff)
                    ? Math.round(recordFrame.right.loadToeOff) : undefined}
                  // static props
                  leftForeFootLoad={recordFrame.left
                    ? Math.round(recordFrame.left.foreFootLoad)
                    : undefined}
                  rightForeFootLoad={recordFrame.right
                    ? Math.round(recordFrame.right.foreFootLoad)
                    : undefined}
                  leftBackFootLoad={recordFrame.left
                    ? Math.round(recordFrame.left.backFootLoad)
                    : undefined}
                  rightBackFootLoad={recordFrame.right
                    ? Math.round(recordFrame.right.backFootLoad)
                    : undefined}
                />
              </BipodalCopReplayGraph>
              <ColormapPressure
                colorInterpolation={colorInterpolation}
                domain={[minDomain, recordInfo.maxPressure]}
              />
            </Grid>
          );
        })}
      </Grid>
      <ReplaySeek
        onPlay={handlePlay}
        onPause={handlePause}
        onSeek={handleSeekWrapper}
        nbFrames={recordInfo.nbFrames}
        isPlaying={recordInfo.isPlaying}
        framerate={framerate}
        index={frame.index}
        loading={recordInfo.nbFrames === undefined}
        hasError={!!error}
      />
    </React.Fragment>
  );
}

CompareRecordReplays.propTypes = {
  records: PropTypes.arrayOf(PropTypes.shape({
    key: PropTypes.string,
  })).isRequired,
  // eslint-disable-next-line react/forbid-prop-types
  insoles: PropTypes.object.isRequired,
  // eslint-disable-next-line react/forbid-prop-types
  walkingAids: PropTypes.object,
  mode: PropTypes.oneOf(['static', 'dynamic']).isRequired,
  framerate: PropTypes.number,
  meshX: PropTypes.number,
  minDomain: PropTypes.number,
};

CompareRecordReplays.defaultProps = {
  walkingAids: {},
  framerate: 20,
  meshX: 15,
  minDomain: 0,
};

export default CompareRecordReplays;
