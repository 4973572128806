import Metric from './metric';

export default new Metric({
  title: 'oscillationPhase',
  unit: '%',
  key: 'swingPercentage',
  hasDeviation: true,
  description: () => [{
    txt: ['normativeValue', ': 40%'],
    align: 'center',
  }],
});
