import firebase from './index';

function getConfig() {
  return ({
    signInFlow: 'popup',
    signInOptions: [
      firebase.auth.GoogleAuthProvider.PROVIDER_ID,
      firebase.auth.EmailAuthProvider.PROVIDER_ID,
      'apple.com',
    ],
    credentialHelper: 'none',
    tosUrl: 'https://feetmehealth.com/terms-of-use/',
    privacyPolicyUrl: 'https://feetmehealth.com/privacy-policy/',
    callbacks: {
      // avoid redirects after sign-in
      signInSuccessWithAuthResult: () => false,
    },
  });
}

export default getConfig;
