import React, { useState } from 'react';
import PropTypes from 'prop-types';
import {
  Card,
  CardHeader,
  CardContent,
} from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown';
import { timeDay, timeMonday, timeMonth } from 'd3-time';
import { subDays, subMonths } from 'date-fns';

import Translate from '../../components/display/translate';
import SimpleMenu from '../../components/selection-controls/simple-menu';
import TimelineActivityExos from '../../components/graph/timeline-activity-exos';
import ScaleTrendLine from '../../components/graph/utils/scale-trend-line';

import { isSameDate } from '../../utils/date';

const OPTIONS = [{
  key: 'day',
  label: '90days',
}, {
  key: 'week',
  label: '26weeks',
}, {
  key: 'month',
  label: '6months',
}];

const useStyles = makeStyles(() => ({
  root: {
    overflow: 'inherit',
  },
}));

function formatRecords(records, type) {
  let lowerDomain;
  let every;
  let tmpRecords = records;

  const today = new Date();
  if (type === 'day') {
    // 90 days
    lowerDomain = subDays(today, 90);
  } else if (type === 'week' || type === 'month') {
    // 6 months
    lowerDomain = subMonths(today, 6);
  }

  if (type === 'day') {
    every = timeDay.every(1).range(lowerDomain, today);
  } else if (type === 'week') {
    every = timeMonday.every(1).range(lowerDomain, today);
  } else if (type === 'month') {
    every = timeMonth.every(1).range(lowerDomain, today);
  }

  // we want them from high to low
  every = every.reverse();

  return every.map((d) => {
    const res = {
      x: d,
      y: 0,
      number: 0,
    };

    let startIndex = -1;

    for (let i = 0; i < tmpRecords.length; i += 1) {
      if (isSameDate(new Date(tmpRecords[i].stopTime), d, type)) {
        res.number += 1;
        res.y += (tmpRecords[i].duration || (tmpRecords[i].stopTime - tmpRecords[i].startTime));
        startIndex = i;
      } else {
        break;
      }
    }

    if (startIndex < tmpRecords.length) {
      tmpRecords = tmpRecords.slice(startIndex + 1);
    }

    // ms to min
    res.y = Math.round(res.y / (60 * 1000));

    return res;
  });
}

function ActivityExos({ records }) {
  const [displayMode, setDisplayMode] = useState('week');

  const classes = useStyles();

  let foundData = false;
  const data = formatRecords(records, displayMode).reverse().filter((d) => {
    if (d.y > 0) {
      foundData = true;
    }

    return foundData;
  });

  return (
    <Card className={classes.root}>
      <CardHeader
        title={<Translate>activity</Translate>}
        action={(
          <SimpleMenu
            id="display-selection"
            value={displayMode}
            options={OPTIONS}
            onSelect={display => setDisplayMode(display)}
            endIcon={<KeyboardArrowDownIcon />}
          />
        )}
      />
      <CardContent>
        <TimelineActivityExos
          data={data}
          displayMode={displayMode}
        />
        <ScaleTrendLine />
      </CardContent>
    </Card>
  );
}

ActivityExos.propTypes = {
  records: PropTypes.arrayOf(PropTypes.object).isRequired,
};

export default ActivityExos;
