import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Link, withRouter } from 'react-router-dom';
import { withStyles } from '@material-ui/core/styles';
import {
  AppBar,
  Divider,
  Drawer,
  Hidden,
  IconButton,
  List,
  Toolbar,
  Typography,
} from '@material-ui/core';

import PeopleIcon from '@material-ui/icons/People';
import DomainIcon from '@material-ui/icons/Domain';
import ListIcon from '@material-ui/icons/List';
import CloudUploadIcon from '@material-ui/icons/CloudUpload';
import SettingsIcon from '@material-ui/icons/SettingsRounded';
import AccountCircleIcon from '@material-ui/icons/AccountCircle';
import MenuIcon from '@material-ui/icons/Menu';
import LinkOffIcon from '@material-ui/icons/LinkOff';
import DirectionsRunIcon from '@material-ui/icons/DirectionsRun';
import VerifiedUserIcon from '@material-ui/icons/VerifiedUser';

import Translate from '../../components/display/translate';

import DrawerLink from './drawer-link';

import { updateRecordsNotLinked } from '../../actions/index';
import { fetchValidAuthorizations } from '../../firebase/firestore';
import { listRecordsNotLinked } from '../../firebase/functions';

const DRAWER_WIDTH = 230;

const styles = theme => ({
  toolbar: {
    ...theme.mixins.toolbar,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-start',
    paddingLeft: theme.spacing(2),
  },
  link: {
    textDecoration: 'none',
    outline: 'none',
  },
  title: {
    color: theme.palette.text.secondary,
    '&:hover': {
      color: theme.palette.primary.main,
    },
  },
  drawer: {
    [theme.breakpoints.up('sm')]: {
      width: DRAWER_WIDTH,
      flexShrink: 0,
    },
  },
  appBar: {
    marginLeft: DRAWER_WIDTH,
    [theme.breakpoints.up('sm')]: {
      width: `calc(100% - ${DRAWER_WIDTH}px)`,
    },
  },
  menuButton: {
    marginRight: 20,
    [theme.breakpoints.up('sm')]: {
      display: 'none',
    },
  },
  drawerPaper: {
    width: DRAWER_WIDTH,
  },
});

function pageToTitle(page, isInOrga) {
  let title;
  const splitted = page.split('/');

  if (splitted.length === 0 || splitted[1] === '') {
    title = isInOrga ? 'patients' : 'records';
  } else if (splitted.includes('record')) {
    title = 'record';
  } else if (splitted.includes('compare')) {
    title = 'recordsComparison';
  } else if (splitted.includes('upload')) {
    title = 'upload';
  } else if (splitted.includes('records-not-linked')) {
    title = 'recordsNotLinked';
  } else if (splitted.includes('exercises')) {
    title = 'exercises';
  } else {
    // eslint-disable-next-line prefer-destructuring
    title = splitted[1];
  }

  return (<Translate>{title}</Translate>);
}

function mapStateToProps(state) {
  return ({
    isInOrga: state.userProfile.orgaId !== undefined,
    recordsNotLinked: state.organisation.recordsNotLinked,
  });
}

class ToolBarDrawer extends React.Component {
  constructor() {
    super();

    this.state = {
      mobileOpen: false,
      hasAuhorizations: false,
    };

    this.handleDrawerToggle = this.handleDrawerToggle.bind(this);
    this.handleDrawerClose = this.handleDrawerClose.bind(this);
  }

  componentDidMount() {
    if (this.props.isInOrga) {
      this.fetchRecordsNotLinked();
      this.intervalId = setInterval(() => this.fetchRecordsNotLinked(), 60 * 60 * 1000);
    } else {
      fetchValidAuthorizations().then(authorizations => this.setState({
        hasAuhorizations: authorizations.length > 0,
      }));
    }
  }

  componentWillUnmount() {
    clearInterval(this.intervalId);
  }

  handleDrawerToggle() {
    this.setState(state => ({ mobileOpen: !state.mobileOpen }));
  }

  handleDrawerClose() {
    this.setState({ mobileOpen: false });
  }

  fetchRecordsNotLinked() {
    listRecordsNotLinked({ recordType: 'dios' })
      .then(res => this.props.dispatch(updateRecordsNotLinked(res.data)));
  }

  render() {
    const { classes } = this.props;

    const drawer = (
      <div>
        <div className={classes.toolbar}>
          <Link className={classes.link} to="/" onClick={this.handleDrawerClose}>
            <Typography className={classes.title} variant="h6" color="inherit">
              <Translate>FeetMeDashboard</Translate>
            </Typography>
          </Link>
        </div>
        <Divider />
        <List>
          { this.props.isInOrga && (
            <div>
              <DrawerLink to="/patients" text={<Translate>patients</Translate>} onClick={this.handleDrawerClose}>
                <PeopleIcon />
              </DrawerLink>
              <DrawerLink to="/organisation" text={<Translate>organisation</Translate>} onClick={this.handleDrawerClose}>
                <DomainIcon />
              </DrawerLink>
            </div>
          )}
          { !this.props.isInOrga && (
            <div>
              <DrawerLink to="/records" text={<Translate>records</Translate>} onClick={this.handleDrawerClose}>
                <ListIcon />
              </DrawerLink>
              <DrawerLink to="/exercises" text={<Translate>exercises</Translate>} onClick={this.handleDrawerClose}>
                <DirectionsRunIcon />
              </DrawerLink>
              <DrawerLink to="/upload" text={<Translate>upload</Translate>} onClick={this.handleDrawerClose}>
                <CloudUploadIcon />
              </DrawerLink>
            </div>
          )}
          { this.props.recordsNotLinked.length > 0 && (
            <DrawerLink to="/records-not-linked" text={<Translate>recordsNotLinked</Translate>} onClick={this.handleDrawerClose}>
              <LinkOffIcon />
            </DrawerLink>
          )}
          { this.state.hasAuhorizations && (
            <DrawerLink to="/authorizations" text={<Translate>authorizations</Translate>} onClick={this.handleDrawerClose}>
              <VerifiedUserIcon />
            </DrawerLink>
          )}
          <DrawerLink to="/user" text={<Translate>user</Translate>} onClick={this.handleDrawerClose}>
            <AccountCircleIcon />
          </DrawerLink>
          <DrawerLink to="/settings" text={<Translate>settings</Translate>} onClick={this.handleDrawerClose}>
            <SettingsIcon />
          </DrawerLink>
        </List>
      </div>
    );

    return (
      <div>
        <AppBar position="fixed" className={classes.appBar}>
          <Toolbar>
            <IconButton
              color="inherit"
              aria-label="open drawer"
              onClick={this.handleDrawerToggle}
              className={classes.menuButton}
            >
              <MenuIcon />
            </IconButton>
            <Typography variant="h6" color="inherit" noWrap>
              { pageToTitle(this.props.location.pathname, this.props.isInOrga) }
            </Typography>
          </Toolbar>
        </AppBar>
        <nav className={classes.drawer}>
          <Hidden smUp implementation="css">
            <Drawer
              variant="temporary"
              anchor="left"
              open={this.state.mobileOpen}
              onClose={this.handleDrawerToggle}
              classes={{ paper: classes.drawerPaper }}
            >
              {drawer}
            </Drawer>
          </Hidden>
          <Hidden xsDown implementation="css">
            <Drawer
              classes={{ paper: classes.drawerPaper }}
              variant="permanent"
              open
            >
              {drawer}
            </Drawer>
          </Hidden>
        </nav>
      </div>
    );
  }
}

ToolBarDrawer.propTypes = {
  // eslint-disable-next-line react/forbid-prop-types
  classes: PropTypes.object.isRequired,
  // eslint-disable-next-line react/forbid-prop-types
  location: PropTypes.object.isRequired,
  dispatch: PropTypes.func.isRequired,

  isInOrga: PropTypes.bool.isRequired,
  recordsNotLinked: PropTypes.arrayOf(PropTypes.string).isRequired,
};

export default withRouter(connect(mapStateToProps)(withStyles(styles)(ToolBarDrawer)));
