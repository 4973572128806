import React from 'react';
import PropTypes from 'prop-types';
import Card from '@material-ui/core/Card';
import CardHeader from '@material-ui/core/CardHeader';
import CardContent from '@material-ui/core/CardContent';

import Translate from '../../components/display/translate';
import Replay from './replay';

function StaticReplay({ insoles, recordId }) {
  return (
    <Card>
      <CardHeader
        title={<Translate>replayPressureData</Translate>}
        subheader={<Translate>replayPressureDataDescriptionStatic</Translate>}
      />
      <CardContent>
        <Replay
          recordId={recordId}
          insoles={insoles}
          mode="static"
          minDomain={0.13}
        />
      </CardContent>
    </Card>
  );
}

StaticReplay.propTypes = {
  recordId: PropTypes.string.isRequired,
  insoles: PropTypes.arrayOf(PropTypes.object).isRequired,
};

export default StaticReplay;
