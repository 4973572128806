import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import {
  FormControl,
  FormLabel,
  FormControlLabel,
  FormGroup,
  Checkbox,
} from '@material-ui/core';

import Translate from '../display/translate';

const styles = theme => ({
  formControl: {
    margin: theme.spacing(3),
  },
  group: {
    margin: `${theme.spacing(1)}px 0`,
  },
});

function Checkboxes(props) {
  const { classes } = props;
  return (
    <FormControl component="fieldset" className={classes.formControl}>
      <FormLabel component="legend"><Translate>{props.title}</Translate></FormLabel>
      <FormGroup className={classes.group}>
        {props.options.map(option => (
          <FormControlLabel
            key={option.key}
            control={(
              <Checkbox
                checked={option.checked}
                onChange={ev => props.handleChange(option.key, ev)}
                value={option.key}
              />
            )}
            label={<Translate>{option.label}</Translate>}
          />
        ))}
      </FormGroup>
    </FormControl>
  );
}

Checkboxes.propTypes = {
  // eslint-disable-next-line react/forbid-prop-types
  classes: PropTypes.object.isRequired,

  title: PropTypes.string.isRequired,
  options: PropTypes.arrayOf(PropTypes.shape({
    key: PropTypes.string,
    label: PropTypes.string,
    checked: PropTypes.bool,
  })).isRequired,
  handleChange: PropTypes.func.isRequired,
};

export default withStyles(styles)(Checkboxes);
