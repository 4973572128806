import {
  UPDATE_RECORDS_NOT_LINKED,
} from '../actions/types';

const DEFAULT_ORGANISATION_DATA = {
  recordsNotLinked: [],
};

function updateOrganisation(state = DEFAULT_ORGANISATION_DATA, action) {
  switch (action.type) {
    case UPDATE_RECORDS_NOT_LINKED:
      return { recordsNotLinked: action.recordsNotLinked };
    default:
      return state;
  }
}

export default updateOrganisation;
