import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import Typography from '@material-ui/core/Typography';
import Tooltip from '@material-ui/core/Tooltip';
import {
  Checkbox,
  TableRow,
  TableCell,
} from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { useSelector } from 'react-redux';

import Translate from '../../display/translate';
import DateDisplay from '../../display/date';
import TimeDisplay from '../../display/time';

import useInsoles from '../../../utils/hooks/useInsoles';
import useWalkingAids from '../../../utils/hooks/useWalkingAids';
import useRecordName from '../../../utils/hooks/useRecordName';
import { getWalkingSpeed, isStatic } from '../../../utils/record';
import { formatNumber, displayWalkingAids } from '../../../utils/display';
import { translateNoDL } from '../../../utils/i18n';

const useStyles = makeStyles({
  root: {
    cursor: 'pointer',
  },
  conditionWrap: {
    width: '500px',
    maxWidth: '500px',
  },
});

function RowRecord({ record, isSelected, onSelectClick }) {
  const classes = useStyles();
  const [insoles, loadingInsoles] = useInsoles(record.key);
  const [walkingAids] = useWalkingAids(record.key);
  const walkingSpeed = useMemo(() => getWalkingSpeed(record), []);
  const locale = useSelector(state => state.settings.locale);

  const [testName, type] = useRecordName(record);

  const textConditions = useMemo(() => {
    let res = '';

    // static
    if (isStatic(record)) {
      return `${formatNumber(
        record.durationPlanned / 1000,
        locale,
        { maximumSignificantDigits: 3 },
      )}s / ${translateNoDL(record.staticSpec.protocol, locale)}`;
    }

    // dynamic (& metric)
    if (walkingAids.length === 0 && !walkingSpeed) {
      return undefined;
    }

    res = displayWalkingAids(walkingAids, locale);
    if (walkingSpeed) {
      if (walkingAids.length > 0) {
        res += ' / ';
      }
      res += translateNoDL(`${walkingSpeed}Speed`, locale);
    }

    return res;
  }, [walkingAids.length, walkingSpeed]);

  return (
    <TableRow
      hover
      onClick={onSelectClick}
      aria-checked={isSelected}
      selected={isSelected}
      className={classes.root}
    >
      <TableCell padding="checkbox">
        <Checkbox checked={isSelected} />
      </TableCell>

      <TableCell><Translate>{testName}</Translate></TableCell>
      <TableCell><Translate>{type}</Translate></TableCell>

      <TableCell><DateDisplay>{record.startTime}</DateDisplay></TableCell>
      <TableCell><TimeDisplay>{record.startTime}</TimeDisplay></TableCell>
      <TableCell className={classes.conditionWrap}>
        { textConditions === undefined
          ? (<Typography>-</Typography>)
          : (
            <Tooltip title={textConditions} placement="top-start">
              <Typography noWrap>{ textConditions }</Typography>
            </Tooltip>
          ) }
      </TableCell>
      <TableCell>{ (loadingInsoles || insoles.length === 0) ? '' : insoles[0].size }</TableCell>
    </TableRow>
  );
}

RowRecord.propTypes = {
  record: PropTypes.shape({
    key: PropTypes.string,
    startTime: PropTypes.number,
    type: PropTypes.string,
    recordType: PropTypes.string,
    durationPlanned: PropTypes.number,
    staticSpec: PropTypes.shape({
      protocol: PropTypes.string,
    }),
  }).isRequired,
  isSelected: PropTypes.bool.isRequired,
  onSelectClick: PropTypes.func.isRequired,
};

export default RowRecord;
