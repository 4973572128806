function ellipseArea(rx, ry) {
  return Math.PI * (rx / 100) * (ry / 100);
}

function ellipseMedioLateralRange(medioLateralWidth) {
  return medioLateralWidth / 100;
}

function ellipseAnteroPosteriorRange(anteroPosteriorWidth) {
  return anteroPosteriorWidth / 100;
}

function rombergQuotient(areaEyesClosed, areaEyesOpened) {
  if (areaEyesOpened === 0) {
    return 0;
  }
  return areaEyesClosed / areaEyesOpened;
}

export {
  ellipseArea,
  ellipseMedioLateralRange,
  ellipseAnteroPosteriorRange,
  rombergQuotient,
};
