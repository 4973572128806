import React from 'react';
import PropTypes from 'prop-types';
import Typography from '@material-ui/core/Typography';

import NumberDisplay from '../../display/number';
import DurationDisplay from '../../display/duration';

import Metric from '../../../utils/metrics/metric';

function formatChild(metric, value, overwriteOptions) {
  const options = { ...metric.formatOptions, ...overwriteOptions };
  switch (metric.formatType) {
    case 'number':
      return (<NumberDisplay options={options}>{value}</NumberDisplay>);
    case 'duration':
      return (<DurationDisplay>{value}</DurationDisplay>);
    default:
      // default to number display
      return (<NumberDisplay options={options}>{value}</NumberDisplay>);
  }
}

function MetricValue(props) {
  return (
    <Typography variant={props.variant} component="p">
      { formatChild(props.metric, props.value, props.options) }
    </Typography>
  );
}

MetricValue.propTypes = {
  metric: PropTypes.instanceOf(Metric).isRequired,
  value: PropTypes.number,
  variant: PropTypes.string,
  // eslint-disable-next-line react/forbid-prop-types
  options: PropTypes.object,
};

MetricValue.defaultProps = {
  variant: 'body1',
  options: undefined,
  value: 0,
};

export default MetricValue;
