import firebase from './index';
import { isLocalTarget } from '../utils/env';

const EUROPE_WEST1 = 'europe-west1';

if (isLocalTarget()) {
  firebase.functions().useFunctionsEmulator('http://localhost:5001');
}

const addLink = firebase.functions().httpsCallable('link-addLink');
const processLink = firebase.functions().httpsCallable('link-processLink');

const leaveOrganisation = firebase.functions().httpsCallable('organisation-leaveOrganisation');

const stopAuthorization = firebase.functions().httpsCallable('authorization-stopAuthorization');

const listPatients = firebase.app().functions(EUROPE_WEST1).httpsCallable('porfic-listPatientsEuropeWest1');
const getPatient = firebase.app().functions(EUROPE_WEST1).httpsCallable('porfic-getPatientEuropeWest1');
const addPatient = firebase.app().functions(EUROPE_WEST1).httpsCallable('porfic-addPatientEuropeWest1');
const listRecordsPatient = firebase.app().functions(EUROPE_WEST1).httpsCallable('porfic-listRecordsPatientEuropeWest1');
const listRecordsNotLinked = firebase.app().functions(EUROPE_WEST1).httpsCallable('porfic-listRecordsNotLinkedEuropeWest1');
const searchPatient = firebase.app().functions(EUROPE_WEST1).httpsCallable('porfic-searchPatientEuropeWest1');

const addConditions = firebase.functions().httpsCallable('porfic-addConditions');
const updateCondition = firebase.functions().httpsCallable('porfic-updateCondition');
const deleteConditions = firebase.functions().httpsCallable('porfic-deleteConditions');
const addObservations = firebase.functions().httpsCallable('porfic-addObservations');
const updateObservation = firebase.functions().httpsCallable('porfic-updateObservation');
const deleteObservations = firebase.functions().httpsCallable('porfic-deleteObservations');
const updateRecordLinkPatient = firebase.functions().httpsCallable('porfic-updateRecordLinkPatient');

const generateReport = firebase.app().functions(EUROPE_WEST1).httpsCallable('pdf-report');

export {
  addLink,
  processLink,

  leaveOrganisation,

  stopAuthorization,

  addPatient,
  addConditions,
  updateCondition,
  deleteConditions,
  addObservations,
  updateObservation,
  deleteObservations,
  listPatients,
  searchPatient,
  getPatient,
  listRecordsPatient,
  listRecordsNotLinked,
  updateRecordLinkPatient,

  generateReport,
};
