import { select } from 'd3-selection';

// We cannot use svg.attr('class', ...) with Material-UI classes style
// block in html2canvas who cannot seem to load style
function styleAxis(g, theme) {
  return g
    .attr('font-family', theme.typography.fontFamily)
    .attr('font-size', theme.typography.body2.fontSize);
}

function stylePath(g, theme) {
  return g
    .attr('fill', 'none')
    .attr('stroke', theme.palette.primary.dark)
    .attr('stroke-linejoin', 'round')
    .attr('stroke-linecap', 'round')
    .attr('stroke-width', 1.5);
}

// Style and init some elements
// We could move this function to another file
function styleFocusContainer(g, theme) {
  g.style('display', 'none')
    .attr('fill', theme.palette.secondary.main)
    .attr('stroke', theme.palette.secondary.main);
  g.append('circle')
    .attr('r', 4.5);
  g.append('text')
    .attr('y', -12)
    .attr('font-family', theme.typography.fontFamily)
    .style('text-anchor', 'middle');
  return g;
}

// Style and init some elements
// We could move this function to another file
function styleTooltip(g, theme) {
  g.style('display', 'none')
    .attr('fill', theme.palette.secondary.main)
    .attr('stroke', theme.palette.secondary.main);
  g.append('circle')
    .attr('r', 4.5);
  g.append('text')
    .attr('y', -12)
    .attr('stroke', 'none')
    .attr('font-family', theme.typography.fontFamily)
    .style('font-size', '14px')
    .style('text-anchor', 'middle');
  return g;
}

// Should this function be moved to another file ?
//
// Split a text into new lines for each space, used when a tick is a full date
// ie:
//     YYYY-MM-DD HH:MM  becomes  YYYY-MM-DD
//                                  HH:MM
function splitTextTick(g) {
  g.selectAll('text').each(function splitTick() {
    const el = select(this);
    const words = el.text().split(' ');
    el.text('');
    words.forEach((word, i) => {
      const tspan = el.append('tspan').text(word);
      if (i > 0) {
        tspan.attr('x', 0).attr('dy', 15);
      }
    });
  });

  return g;
}

export {
  styleAxis,
  stylePath,
  styleFocusContainer,
  styleTooltip,

  splitTextTick,
};
