import { useEffect, useState } from 'react';

function useDisableFallingRisk(patient, patientId, isLoadingPatient) {
  // by default, we disable the falling risk if there is a patientId
  // we might enable it if we found that the patient data is compatible
  // with the falling risk
  const [disableFallingRisk, setDisableFallingRisk] = useState(patientId !== undefined);

  useEffect(() => {
    if (isLoadingPatient === false && Object.keys(patient).length > 0) {
      setDisableFallingRisk(patient.conditions.length > 0);
    }
  }, [isLoadingPatient, Object.keys(patient).length]);

  return [disableFallingRisk];
}

export default useDisableFallingRisk;
