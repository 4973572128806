import React from 'react';
import PropTypes from 'prop-types';

import {
  TableRow,
  TableCell,
} from '@material-ui/core';

import DateDisplay from '../../display/date';

import { displayName } from '../../../utils/patient';

const styles = {
  root: {
    cursor: 'pointer',
  },
};

function RowPatient(props) {
  return (
    <TableRow
      hover
      style={styles.root}
      onClick={() => props.handleClick(props.patient)}
    >
      <TableCell>{displayName(props.patient)}</TableCell>
      <TableCell><DateDisplay disableTimeZone>{props.patient.birthdate}</DateDisplay></TableCell>
    </TableRow>
  );
}

RowPatient.propTypes = {
  patient: PropTypes.shape({
    id: PropTypes.string,
    birthdate: PropTypes.string,
    firstname: PropTypes.string,
    surname: PropTypes.string,
    anonymous: PropTypes.string,
    isAnonymous: PropTypes.bool,
  }).isRequired,
  handleClick: PropTypes.func.isRequired,
};

export default RowPatient;
