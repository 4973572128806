import React from 'react';
import PropTypes from 'prop-types';
import Grid from '@material-ui/core/Grid';
import Slider from '@material-ui/core/Slider';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';
import addMilliseconds from 'date-fns/addMilliseconds';
import intervalToDuration from 'date-fns/intervalToDuration';

const useStyles = makeStyles({
  root: {
    backgroundColor: '#F8F9FA',
    borderRadius: 12,
    marginTop: '24px',
    padding: '0 12px',
  },
  timestamp: {
    color: '#868E96',
    fontSize: '12px',
    fontWeight: 500,
  },
});

function LoadVariationSelection(props) {
  const {
    sliderValue,
    min,
    max,
    onChange,
  } = props;

  const classes = useStyles();

  // compute the slider duration string display
  const start = new Date(0);
  const end = addMilliseconds(new Date(0), sliderValue[1]);
  const duration = intervalToDuration({ start, end });
  const zeroPad = num => String(num).padStart(2, '0');
  const sliderDuration = `${zeroPad(duration.minutes + duration.hours * 60)}:${zeroPad(duration.seconds)}`;

  return (
    <div className={classes.root}>
      <Grid container direction="row" alignItems="center" spacing={3}>
        <Grid item>
          <Typography className={classes.timestamp}>
            00:00
          </Typography>
        </Grid>
        <Grid item xs>
          <Slider
            value={sliderValue}
            onChange={onChange}
            min={min}
            max={max}
          />
        </Grid>
        <Grid item>
          <Typography className={classes.timestamp}>
            { sliderDuration }
          </Typography>
        </Grid>
      </Grid>
    </div>
  );
}

LoadVariationSelection.propTypes = {
  sliderValue: PropTypes.arrayOf(PropTypes.number).isRequired,
  min: PropTypes.number.isRequired,
  max: PropTypes.number.isRequired,
  onChange: PropTypes.func.isRequired,
};

export default LoadVariationSelection;
