import React, { useRef, useEffect } from 'react';
import PropTypes from 'prop-types';

const styles = {
  root: {
    height: '10px',
    borderRadius: '10px',
    overflow: 'hidden',
  },
};

function ColormapPressureGraph({ colorInterpolation, width, height }) {
  const ref = useRef(null);

  useEffect(() => {
    const canvas = ref.current;
    const context = canvas.getContext('2d');
    for (let i = 0; i < canvas.width; i += 1) {
      const color = colorInterpolation(i / (canvas.width - 1));
      context.fillStyle = color;
      context.fillRect(i, 0, 1, 20);
    }
  }, []);

  return (
    <div style={styles.root}>
      <canvas height={height} width={width} ref={ref} />
    </div>
  );
}

ColormapPressureGraph.propTypes = {
  colorInterpolation: PropTypes.func.isRequired,
  width: PropTypes.number.isRequired,
  height: PropTypes.number,
};

ColormapPressureGraph.defaultProps = {
  height: 20,
};

export default ColormapPressureGraph;
