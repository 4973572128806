import {
  formatDuration,
  formatNumber,
} from './display';

const TWO_HOURS_MS = 2 * 60 * 60 * 1000;

// TODO move to utils/metric.js
function formatMetricValue(metric, value, locale) {
  // could be refactored with `src/components/metric/utils/value.jsx`
  switch (metric.formatType) {
    case 'number':
      return formatNumber(value, locale, metric.formatOptions);
    case 'duration':
      // force locale, otherwise we'll have 'AM', 'PM' in display
      return formatDuration(value);
    default:
      // default to number display
      return formatNumber(value, locale, metric.formatOptions);
  }
}

function canShowGraph24h(startTime, stopTime, nbStrides) {
  return ((stopTime - startTime) >= TWO_HOURS_MS) && nbStrides > 2;
}

function canShowGraphVariation(startTime, stopTime, nbStrides) {
  return ((stopTime - startTime) < TWO_HOURS_MS) && nbStrides > 2;
}

function isMetric(record) {
  return record.type === 'dios' || (record.type === 'rehab' && record.recordType === 'walkingtest');
}

function isDynamic(record) {
  return record.type === 'dynamic';
}

function isStatic(record) {
  return record.type === 'static';
}

function pressurePipelineComplete(record) {
  return ('interpolation' in record) && ('updatedAt' in record.interpolation);
}

function getWalkingSpeed(record) {
  // The walkingSpeed was previous saved in `recordParameters.walkingSpeed`.
  // With newer version of the mobile application it is saved in `walkingSpeed`
  if (record.recordParameters) {
    return record.recordParameters.walkingSpeed;
  }

  return record.walkingSpeed;
}

const EXO_RECORD_TYPES = [{
  // support transfer
  key: 'posturofeedback',
  label: 'posturoFeedback',
}, {
  // rhythmic stimulation
  key: 'clockedwalk',
  label: 'rhythmicWalking',
}, {
  // heel strike (fr: déroulé du pas) - endurance
  key: 'heelstrike',
  label: 'heelstrikeDetection',
}, {
  // stabilization
  key: 'stabilisation',
  label: 'stabilization',
}, {
  // balance & speed
  key: 'velocity',
  label: 'staticStabilityAndSpeed',
}, {
  // plantar flexion
  key: 'plantarflexion',
  label: 'plantarFlexion',
}, {
  // big steps (fr: marche à grands pas)
  key: 'stridelength',
  label: 'bigSteps',
}, {
  // free walking
  key: 'walkingtest',
  label: 'freeWalking',
}, {
  // heel strike (fr: déroulé du pas) - gait quality
  key: 'heelstrikegait',
  label: 'heelstrikeGaitQuality',
}, {
  key: 'sittostand',
  label: 'sitToStand',
}];

export {
  formatMetricValue,
  canShowGraph24h,
  canShowGraphVariation,
  isMetric,
  isDynamic,
  isStatic,
  pressurePipelineComplete,
  getWalkingSpeed,

  EXO_RECORD_TYPES,
};
