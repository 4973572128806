import React from 'react';
import PropTypes from 'prop-types';
import { withTheme } from '@material-ui/core/styles';

import { mouse, select } from 'd3-selection';
import { scaleLinear } from 'd3-scale';
import { curveMonotoneX, line } from 'd3-shape';
import { bisector, extent } from 'd3-array';
import { axisBottom, axisLeft } from 'd3-axis';
import { timeFormat } from 'd3-time-format';
import 'd3-transition';
import textures from 'textures';

import {
  stylePath,
  styleAxis,
  styleFocusContainer,
  splitTextTick,
} from '../../utils/d3/styling';

class CurveInactivity extends React.Component {
  constructor() {
    super();

    this.mouseover = this.mouseover.bind(this);
    this.mouseout = this.mouseout.bind(this);
    this.mousemove = this.mousemove.bind(this);
  }

  componentDidMount() {
    const svg = select(this.node)
      .append('svg');

    const texture = textures.lines().thicker();

    svg.call(texture);

    const xScale = scaleLinear();
    const yScale = scaleLinear();
    const path = line()
      .curve(curveMonotoneX);

    const mainContainer = svg.append('g');
    const xContainer = mainContainer.append('g');
    const yContainer = mainContainer.append('g');
    const graphContainer = stylePath(mainContainer.append('path'), this.props.theme);
    const rectContainer = mainContainer.append('g');
    const focusContainer = mainContainer.append('g');
    const mouseContainer = mainContainer.append('rect')
      .attr('fill', 'none')
      .attr('pointer-events', 'all');

    this.el = {
      svg,
      line: path,
      xScale,
      yScale,
      texture,
      mainContainer,
      xContainer,
      yContainer,
      rectContainer,
      focusContainer,
      mouseContainer,
      graphContainer,
      bisector: bisector(d => d.x).left,
      rects: [],
    };

    styleFocusContainer(this.el.focusContainer, this.props.theme);

    this.sizes = {
      w: this.props.width,
      h: this.props.height,
      margin: {
        top: 5,
        bottom: 35,
        left: 30,
        right: 20,
      },
    };

    if (this.props.values.length !== 0) {
      this.draw();
    }
  }

  componentDidUpdate(prevProps) {
    if (prevProps.values.length !== this.props.values.length) {
      this.draw();
    }
  }

  updateFocusContainers(d) {
    this.el.focusContainer
      .attr('transform', `translate(${this.el.xScale(d.x)}, ${this.el.yScale(d.y)})`);
    this.el.focusContainer
      .select('text')
      .text(Number((d.y).toFixed(1)));
  }

  draw() {
    if (this.sizes.w === 0) {
      this.sizes.w = this.node.clientWidth - 50;
    }
    const width = this.sizes.w - this.sizes.margin.left - this.sizes.margin.right;
    const height = this.sizes.h - this.sizes.margin.top - this.sizes.margin.bottom;

    this.el.svg
      .attr('width', this.sizes.w)
      .attr('height', this.sizes.h);

    this.el.mainContainer
      .attr('transform', `translate(${this.sizes.margin.left},${this.sizes.margin.top})`);

    this.el.mouseContainer
      .attr('width', width)
      .attr('height', height)
      .on('mouseover', this.mouseover)
      .on('mouseout', this.mouseout)
      .on('mousemove', this.mousemove);

    const xExtent = extent(this.props.values, d => d.x);
    const xRange = xExtent[1] - xExtent[0];

    const yExtent = extent(this.props.values, d => d.y);
    let yRange = yExtent[1] - yExtent[0];
    if (yRange === 0) {
      yRange = 1;
    }

    this.el.xScale
      .domain([xExtent[0] - (xRange * 0.05), xExtent[1] + (xRange * 0.1)])
      .rangeRound([0, width]);

    this.el.yScale
      .domain([0, yExtent[1] + (yRange * 0.2)])
      .rangeRound([height, 0]);

    this.el.rects = [];

    this.el.line
      .defined((d, i, data) => {
        if (i === 0) {
          return true;
        }

        if (data[i].x - data[i - 1].x < this.props.intervalDefinedMs) {
          return true;
        }

        this.el.rects.push({
          x: this.el.xScale(data[i - 1].x),
          y: 0,
          width: this.el.xScale(data[i].x) - this.el.xScale(data[i - 1].x),
          height,
        });

        return false;
      })
      .x(d => this.el.xScale(d.x))
      .y(d => this.el.yScale(d.y));

    this.el.xContainer
      .attr('transform', `translate(0, ${height})`)
      .call(axisBottom(this.el.xScale).ticks(4).tickFormat(d => timeFormat('%d/%m/%y %H:%M')(new Date(d))));
    this.el.xContainer.select('.domain').remove();
    splitTextTick(this.el.xContainer);
    styleAxis(this.el.xContainer, this.props.theme);

    this.el.yContainer
      .call(axisLeft(this.el.yScale));
    styleAxis(this.el.xContainer, this.props.theme);

    this.el.graphContainer
      .datum(this.props.values)
      .transition()
      .duration(400)
      .attr('d', this.el.line);

    this.drawRects();
  }

  drawRects() {
    this.el.rectContainer.selectAll('rect')
      .remove()
      .exit()
      .data(this.el.rects)
      .enter()
      .append('rect')
      .attr('fill', this.el.texture.url())
      .attr('opacity', 0.7)
      .attr('x', d => d.x)
      .attr('y', d => d.y)
      .attr('width', d => d.width)
      .attr('height', d => d.height);
  }

  // mouse enters the mouseContainer
  mouseover() {
    // display focus container
    this.el.focusContainer.style('display', null);

    this.el.rectContainer.selectAll('rect')
      .transition()
      .duration(250)
      .attr('opacity', 0.05);
  }

  // mouse leaves the mouseContainer
  mouseout() {
    // hide focus container
    this.el.focusContainer.style('display', 'none');

    this.el.rectContainer.selectAll('rect')
      .transition()
      .duration(250)
      .attr('opacity', 0.7);
  }

  // mouse moves in the mouseContainer
  mousemove() {
    const mX = mouse(this.el.mouseContainer.node())[0];
    let i = this.el.bisector(this.props.values, this.el.xScale.invert(mX), 1);
    const d0 = this.props.values[i - 1];
    const d1 = this.props.values[i];
    let d;
    if (d1 === undefined) {
      d = d0;
    } else if (d0 === undefined) {
      d = d1;
    } else {
      const lengthd0 = Math.abs(this.el.xScale(d0.x) - mX);
      const lengthd1 = Math.abs(this.el.xScale(d1.x) - mX);
      d = lengthd0 > lengthd1 ? d1 : d0;
    }

    if (d === d0) {
      i -= 1;
    }

    if (d !== undefined) {
      this.updateFocusContainers(d);
    }
  }

  render() {
    return (
      <div
        ref={(node) => {
          this.node = node;
          return undefined;
        }}
      />
    );
  }
}

CurveInactivity.propTypes = {
  // eslint-disable-next-line react/forbid-prop-types
  theme: PropTypes.object.isRequired,

  width: PropTypes.number,
  height: PropTypes.number,

  values: PropTypes.arrayOf(PropTypes.shape({
    x: PropTypes.number.isRequired,
    y: PropTypes.number.isRequired,
  })).isRequired,
  intervalDefinedMs: PropTypes.number,
};

CurveInactivity.defaultProps = {
  // 5 seconds
  intervalDefinedMs: (5 * 1000),
  width: 0,
  height: 200,
};

export default withTheme(CurveInactivity);
