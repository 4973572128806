import Metric from './metric';
import StrideLength from './stride-length';
import { statArray } from '../array';
import { riskFallingColor } from '../colors';

export default new Metric({
  title: 'riskOfFalling',
  unit: 'fall/year',
  domain: {
    'fall/year': [0, 0.44, 1, 2],
  },
  range: [0, 33, 66, 100],
  key: 'fallingRisk',
  keyCompute: StrideLength.key,
  colorRange: i => riskFallingColor(i <= 33),
  compute: (arrayStrideLength) => {
    const values = arrayStrideLength.map(i => i.y);
    const meanStrideLength = statArray(values, 'mean');
    const stdStrideLength = statArray(values, 'deviation');

    if (meanStrideLength === 0 || stdStrideLength === 0 || stdStrideLength === undefined) {
      return 0.44;
    }

    const f = (stdStrideLength / meanStrideLength) * 100;

    // convert the percentage in a value
    // ie: 10% => 1
    //     20% => 2
    //     ...
    const n = Math.floor(f / 10);
    return (0.44 * (1.086 ** n));
  },
  description: () => [{
    txt: 'Verghese J et al., 2009.',
    align: 'center',
  }, {
    txt: 'adults>70YearsOld',
    align: 'center',
  }, {
    txt: 'defaultRiskOfFalling',
    align: 'center',
  }],
  formatOptions: {
    maximumFractionDigits: 2,
  },
});
