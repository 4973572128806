import React from 'react';
import PropTypes from 'prop-types';

import MetricGraphContainer from '../metric/utils/graph-container';
import CurveStableX from '../d3/curve-stable-x';

import Metric from '../../utils/metrics/metric';

function RecordGraphStable(props) {
  const {
    metric,
    values,
    pdf,
  } = props;

  return (
    <MetricGraphContainer metric={metric}>
      <CurveStableX
        values={values}
        displayDeviationValues={metric.hasDeviation}
        width={pdf ? 180 : undefined}
        height={pdf ? 300 : undefined}
        pdf={pdf}
      />
    </MetricGraphContainer>
  );
}

RecordGraphStable.propTypes = {
  metric: PropTypes.instanceOf(Metric).isRequired,
  values: PropTypes.arrayOf(PropTypes.shape({
    x: PropTypes.number.isRequired,
    y: PropTypes.number.isRequired,
    deviation: PropTypes.number,
  })).isRequired,
  pdf: PropTypes.bool,
};

RecordGraphStable.defaultProps = {
  pdf: false,
};

export default RecordGraphStable;
