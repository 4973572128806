import React from 'react';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router-dom';
import * as Sentry from '@sentry/browser';

import PageHeader from '../components/display/page-header';
import PageTitle from '../components/display/page-title';
import Translate from '../components/display/translate';
import ProgressWait from '../components/display/progress-wait';
import PatientForm from '../components/form/patient';

import {
  getPatient,
  addConditions,
  updateCondition,
  deleteConditions,
  addObservations,
  updateObservation,
  deleteObservations,
} from '../firebase/functions';

// centralize debug logs (used in dry run) to remove eslint warning
function debugLog(...log) {
  // eslint-disable-next-line no-console
  console.log(log);
}

class EditPatient extends React.Component {
  constructor() {
    super();

    this.state = {
      patient: undefined,
      conditions: undefined,
      patientIsLoading: true,
      waitValidation: false,
    };

    this.onValidate = this.onValidate.bind(this);
  }

  componentDidMount() {
    getPatient({
      patientId: this.props.match.params.patientId,
      getConditions: true,
    }).then((res) => {
      this.setState({
        patient: res.data.patient,
        conditions: res.data.conditions,
        patientIsLoading: false,
      });
    });
  }

  // We are checking each condition (pathology) and observation (scale)
  // for differences and sorting them into three groups
  // - added
  // - deleted
  // - updated (only for observations)
  //
  // We are then calling firebase function for each changes and waiting
  // for all the promise.
  onValidate(data) {
    const DRY_RUN = false;

    if (DRY_RUN === false) {
      this.setState({ waitValidation: true });
    }

    const { patientId } = this.props.match.params;
    const { conditions } = data;

    // array of ids of conditions from the server
    const serverConditionsIds = this.state.conditions.map(i => i.id);
    // array of ids of conditions from the update
    const conditionsIds = conditions.map(i => i.id);

    // array of ids, deleted conditions
    const deleted = serverConditionsIds.filter(i => !conditionsIds.includes(i));
    // array of conditions, newly created conditions
    const created = conditions.filter(({ id }) => !serverConditionsIds.includes(id));

    // array of conditions, existing conditions, their scales might
    // have been updated
    const checkHasBeenUpdated = conditions.filter(({ id }) => serverConditionsIds.includes(id));

    const promises = [];

    if (checkHasBeenUpdated.length > 0) {
      checkHasBeenUpdated.forEach((condition) => {
        const serverScales = this.state.conditions.find(({ id }) => id === condition.id).scales;

        // the forms will return empty scales, we need to filter them
        // to properly a deletion when comparing with the server
        const scales = condition.scales.filter(({ code }) => code !== '');
        const serverScalesId = serverScales.map(i => i.id);
        const scalesId = scales.map(i => i.id);

        // array of id of deleted scales
        const deletedScales = serverScalesId.filter(id => !scalesId.includes(id));

        // array of object of created scales
        const createdScales = scales.filter(({ id }) => !serverScalesId.includes(id));

        const updatedScales = scales.filter((scale) => {
          const serverScale = serverScales.find(({ id }) => id === scale.id);

          if (serverScale === undefined) {
            return false;
          }

          return (serverScale.code !== scale.code || serverScale.value !== scale.value);
        });

        if (DRY_RUN === false) {
          // delete the scales
          if (deletedScales.length > 0) {
            promises.push(deleteObservations({
              observationsId: deletedScales,
            }));
          }

          if (createdScales.length > 0) {
            // add the new scales
            promises.push(addObservations({
              patientId,
              observations: createdScales,
            }).then((res) => {
              const newIds = res.data;
              // we check if scales were deleted
              const observationsId = [].concat(
                serverScalesId.filter(i => !deletedScales.includes(i)),
                newIds,
              );

              // update (add and maybe delete) ids of scales in the condition
              return updateCondition({
                conditionId: condition.id,
                observationsId,
              });
            }));
          } else if (deletedScales.length > 0) {
            // update (delete) ids of scales in the condition
            promises.push(updateCondition({
              conditionId: condition.id,
              observationsId: serverScalesId.filter(i => !deletedScales.includes(i)),
            }));
          }

          if (updatedScales.length > 0) {
            updatedScales.forEach(observation => promises.push(updateObservation({
              observation,
              observationId: observation.id,
            })));
          }
        }

        if (DRY_RUN === true) {
          debugLog('deleted observation: ', deletedScales);
          debugLog('created observation: ', createdScales);
          debugLog('updated observation: ', updatedScales);
        }
      });
    }

    if (DRY_RUN === true) {
      debugLog('created condition: ', created);
      debugLog('deleted condition: ', deleted);
    }

    if (DRY_RUN === false) {
      if (created.length > 0) {
        promises.push(addConditions({
          patientId,
          conditions: created,
        }));
      }

      if (deleted.length > 0) {
        promises.push(deleteConditions({ conditionsId: deleted }));
      }

      return Promise.all(promises)
        .then(() => this.props.history.push(`/patient/${this.props.match.params.patientId}/`))
        .catch(e => Sentry.captureException(e));
    }

    return Promise.resolve();
  }

  render() {
    return (
      <div>
        <PageHeader>
          <PageTitle><Translate>editPatient</Translate></PageTitle>
        </PageHeader>
        { this.state.patientIsLoading
          ? <ProgressWait marginTop={20} />
          : (
            <PatientForm
              textValidate="edit"
              waitValidation={this.state.waitValidation}
              onValidate={this.onValidate}
              patient={this.state.patient}
              conditions={
                // we have to copy the scales objects
                // otherwise the compare, with server data, on update will
                // not work
                this.state.conditions.map(condition => ({
                  ...condition,
                  scales: condition.scales.map(scale => ({ ...scale })),
                }))
              }
              patientReadOnly
            />
          )}
      </div>
    );
  }
}

EditPatient.propTypes = {
  match: PropTypes.shape({
    params: PropTypes.shape({
      patientId: PropTypes.string.isRequired,
    }),
  }).isRequired,
  // eslint-disable-next-line react/forbid-prop-types
  history: PropTypes.object.isRequired,
};

export default withRouter(EditPatient);
