import { interpolateRdYlGn } from 'd3-scale-chromatic';

import Metric from './metric';

import { formatMetricValue } from '../record';

function convert(v, unit) {
  // convert from m to cm
  if (unit === 'cm') {
    return (v * 100);
  }
  return v;
}

export default new Metric({
  title: 'strideLength',
  unit: 'm',
  domain: {
    cm: [0, 100, 128, 146],
  },
  range: [0, 50, 75, 100],
  colorRange: (i => interpolateRdYlGn(i / 100)),
  key: 'strideLength',
  convert,
  hasDeviation: true,
  // eslint-disable-next-line
  description: function(unit, locale) {
    return [{
      txt: 'John H. Hollman et al., 2011.',
      align: 'center',
    }, {
      txt: 'normativeStrideLengthInOlderAdults:',
      align: 'justify',
    }, {
      txt: [`> ${formatMetricValue(this, convert(1.28, unit), locale)} ${unit}: `, 'perfect'],
      align: 'left',
    }, {
      txt: [`> ${formatMetricValue(this, convert(1, unit), locale)} ${unit}: `, 'warning'],
      align: 'left',
    }, {
      txt: [`< ${formatMetricValue(this, convert(1, unit), locale)} ${unit}: `, 'danger'],
      align: 'left',
    }];
  },
});
