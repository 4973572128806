import React from 'react';
import PropTypes from 'prop-types';
import { withTheme } from '@material-ui/core/styles';

import { select } from 'd3-selection';
import { scaleLinear } from 'd3-scale';
import { extent, histogram, min } from 'd3-array';
import { axisBottom, axisLeft } from 'd3-axis';
import { timeFormat } from 'd3-time-format';

import { styleAxis, splitTextTick } from '../../utils/d3/styling';

class Histogram extends React.Component {
  componentDidMount() {
    const svg = select(this.node)
      .append('svg');

    const xScale = scaleLinear();
    const yScale = scaleLinear();

    const mainContainer = svg.append('g');
    const xContainer = mainContainer.append('g');
    const yContainer = mainContainer.append('g');
    const graphContainer = mainContainer.append('g')
      .attr('fill', this.props.theme.palette.primary.dark);

    this.el = {
      svg,
      xScale,
      yScale,
      mainContainer,
      xContainer,
      yContainer,
      graphContainer,
      histogram: histogram(),
    };

    this.sizes = {
      w: this.props.width,
      h: this.props.height,
      margin: {
        top: 5,
        bottom: 25,
        left: this.props.pdf ? 20 : 40,
        right: 20,
      },
    };

    if (this.props.values.length !== 0) {
      this.draw();
    }
  }

  componentDidUpdate(prevProps) {
    if (prevProps.values.length !== this.props.values.length) {
      this.draw();
    }
  }

  draw() {
    if (this.sizes.w === 0) {
      this.sizes.w = this.node.clientWidth;
    }
    const width = this.sizes.w - this.sizes.margin.left - this.sizes.margin.right;
    const height = this.sizes.h - this.sizes.margin.top - this.sizes.margin.bottom;

    this.el.svg
      .attr('width', this.sizes.w + this.sizes.margin.left + this.sizes.margin.right)
      .attr('height', this.sizes.h + this.sizes.margin.top + this.sizes.margin.bottom);

    this.el.mainContainer
      .attr('transform', `translate(${this.sizes.margin.left},${this.sizes.margin.top})`);

    this.el.xScale
      .domain([0, this.props.values.length])
      .rangeRound([0, width])
      .nice(this.props.values.length);

    const yExtent = extent(this.props.values, d => d.y);
    let yRange = yExtent[1] - yExtent[0];

    if (yRange === 0) {
      yRange = 1;
    }

    this.el.yScale
      .domain([yExtent[0] - (yRange * 0.2), yExtent[1] + (yRange * 0.2)])
      .rangeRound([height, 0]);

    this.el.xContainer
      .attr('transform', `translate(0, ${height})`)
      .call(axisBottom(this.el.xScale)
        .ticks(Math.min(this.props.values.length, 4))
        .tickFormat((d) => {
          const { x } = this.props.values[min([Math.floor(d), this.props.values.length - 1])];
          return timeFormat('%d/%m/%y %H:%M')(new Date(x));
        }));
    this.el.xContainer.select('.domain').remove();
    splitTextTick(this.el.xContainer);
    styleAxis(this.el.xContainer, this.props.theme);

    this.el.yContainer
      .call(axisLeft(this.el.yScale));
    styleAxis(this.el.yContainer, this.props.theme);

    this.el.histogram
      .domain(this.el.xScale.domain())
      .value((d, i) => i)
      .thresholds(this.el.xScale.ticks(this.props.values.length));

    const bins = this.el.histogram(this.props.values);

    const bar = this.el.graphContainer.selectAll('.bar')
      .remove()
      .exit()
      .data(bins)
      .enter()
      .append('g')
      .attr('class', 'bar')
      .attr('transform', (d) => {
        if (d.length === 1 && d['0']) {
          return `translate(${this.el.xScale(d.x0)}, ${this.el.yScale(d['0'].y)})`;
        }

        return 'translate(0, 0)';
      });

    bar.append('rect')
      .attr('x', 1)
      .attr('width', (d) => {
        const rectWidth = (this.el.xScale(d.x1) - this.el.xScale(d.x0));
        // add space between each bars
        return (rectWidth > 0 ? rectWidth - 1 : 0);
      })
      .attr('height', (d) => {
        if (d.length === 1 && d['0']) {
          return height - this.el.yScale(d['0'].y);
        }

        return 0;
      });
  }

  render() {
    return (
      <div
        ref={(node) => {
          this.node = node;
          return undefined;
        }}
      />
    );
  }
}

Histogram.propTypes = {
  // eslint-disable-next-line react/forbid-prop-types
  theme: PropTypes.object.isRequired,

  width: PropTypes.number,
  height: PropTypes.number,

  values: PropTypes.arrayOf(PropTypes.shape({
    x: PropTypes.number,
    y: PropTypes.number,
  })).isRequired,
  pdf: PropTypes.bool,
};

Histogram.defaultProps = {
  width: 0,
  height: 480,
  pdf: false,
};

export default withTheme(Histogram);
