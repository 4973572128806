import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import { formatDate } from '../../utils/display';

function mapStateToProps(state) {
  return ({
    locale: state.settings.locale,
  });
}

function DateDisplay(props) {
  const {
    children,
    locale,
    options,
    disableTimeZone,
  } = props;

  const dateOptions = options || {};
  if (disableTimeZone === true) {
    dateOptions.timeZone = 'UTC';
  }

  return formatDate(children, locale, dateOptions);
}

DateDisplay.propTypes = {
  locale: PropTypes.string.isRequired,

  children: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  // eslint-disable-next-line react/forbid-prop-types
  options: PropTypes.object,
  disableTimeZone: PropTypes.bool,
};

DateDisplay.defaultProps = {
  options: undefined,
  disableTimeZone: false,
};

export default connect(mapStateToProps)(DateDisplay);
