import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import * as Sentry from '@sentry/browser';
import {
  Card,
  CardContent,
  CardActions,
  Typography,
  LinearProgress,
  Button,
  List,
  Grid,
} from '@material-ui/core';

import RecordUploadedLink from './components/record-uploaded-link';

import Record from '../utils/upload/record';
import Translate from '../components/display/translate';
import { setPatientRecord } from '../firebase/firestore';

import { reduce } from '../utils/promise';

function mapStateToProps(state) {
  return ({
    orgaId: state.userProfile.orgaId,
  });
}

function getFilePrefix(filename) {
  const match = filename.match(/^(\d{4}-\d{2}-\d{2}_\d{2}-\d{2}-\d{2})_/);
  if (match === null) {
    return undefined;
  }

  return match[1];
}

class Upload extends React.Component {
  constructor() {
    super();

    this.state = {
      // is there an upload in progress
      inProgress: false,
      // number of files to be uploaded
      nbFiles: 0,
      // array of { filename, documentId, isSuccess } for the uploaded records
      // reset when an upload is started
      recordsDone: [],
      // value (0, 100) of the progress bar
      value: 0,
      // value (0, 100) of the buffer progress bar
      buffer: 0,
    };

    this.onClick = this.onClick.bind(this);
    this.handleFiles = this.handleFiles.bind(this);
    this.updateBufferProgress = this.updateBufferProgress.bind(this);

    this.dropHandler = this.dropHandler.bind(this);
  }

  // event call on selection of files
  handleFiles(event) {
    this.uploadFiles(event.target.files);
  }

  onClick() {
    if (this.node !== undefined) {
      this.node.click();
    }
  }

  // Update the buffer progress value
  //
  // value is between 0 and 1
  updateBufferProgress(value) {
    const lowValue = this.state.value;
    const highValue = Math.round(((this.state.recordsDone.length + 1) / this.state.nbFiles) * 100);
    this.setState({ buffer: Math.round(lowValue + (value * (highValue - lowValue))) });
  }

  dropHandler(ev) {
    ev.preventDefault();

    let files = [];

    if (ev.dataTransfer.items) {
      for (let i = 0; i < ev.dataTransfer.items.length; i += 1) {
        // we could also check the .type
        if (ev.dataTransfer.items[i].kind === 'file') {
          files.push(ev.dataTransfer.items[i].getAsFile());
        }
      }
    } else {
      // eslint-disable-next-line prefer-destructuring
      files = ev.dataTransfer.files;
    }

    if (ev.dataTransfer.items) {
      ev.dataTransfer.items.clear();
    } else {
      ev.dataTransfer.clearData();
    }

    this.uploadFiles(files);
  }

  // start the upload file by file, waiting for an upload before starting
  // the next one
  uploadFiles(files) {
    // files is a FileList, not an array
    const csvFiles = Array.from(files).filter(file => file.name.endsWith('.csv'));
    const zipFiles = Array.from(files).filter(file => file.name.endsWith('.zip'));

    if (csvFiles.length === 0 && zipFiles.length > 0) {
      return this.uploadFileDone(zipFiles[0].name, '', 'noCSVMetricFile', false);
    }

    this.setState({
      inProgress: true,
      recordsDone: [],
      nbFiles: csvFiles.length,
      value: 0,
      buffer: 0,
    });

    // Use reduce + Promise to wait for each upload to be completed
    // before starting the next one
    return reduce(csvFiles, file => this.uploadFile(file, zipFiles))
      .then(() => this.uploadDone());
  }

  // Upload a file (File object)
  // the state (progress, list of records uploaded) is updated on upload
  uploadFile(file, zipFiles) {
    const zipFile = zipFiles.find(i => getFilePrefix(i.name) === getFilePrefix(file.name));

    const record = new Record(file, this.updateBufferProgress, zipFile);
    return record.upload()
      .then(({ filename, documentId }) => new Promise((resolve) => {
        // if the patient id is present,
        // we're adding the link record patient to firestore
        if (this.props.match.params.patientId) {
          return setPatientRecord(
            this.props.orgaId,
            documentId,
            this.props.match.params.patientId,
          ).then(() => resolve());
        }

        return resolve();
      }).then(() => this.uploadFileDone(filename, documentId, '', true)))
      .catch((error) => {
        Sentry.captureException(error);
        const { err, filename } = error;
        const errorType = err === undefined ? '' : err.type;
        return this.uploadFileDone(filename, '', errorType, false);
      });
  }

  // When all files have been uploaded
  uploadDone() {
    this.setState({ inProgress: false });
  }

  uploadFileDone(filename, documentId, errorType, isSuccess = true) {
    this.setState((prevState) => {
      const { recordsDone, nbFiles } = prevState;
      const newPercent = Math.round(((recordsDone.length + 1) / nbFiles) * 100);

      return ({
        recordsDone: [...recordsDone, {
          filename,
          documentId,
          errorType,
          isSuccess,
        }],
        value: newPercent,
        buffer: newPercent,
      });
    });
  }

  render() {
    return (
      <Card onDrop={ev => this.dropHandler(ev)} onDragOver={ev => ev.preventDefault()}>
        <CardContent>
          <Typography gutterBottom variant="h5" component="h2">
            <Translate>uploadRecords</Translate>
          </Typography>
        </CardContent>

        <CardContent style={{ minHeight: '60px' }}>
          { !this.state.inProgress && (
            <div>
              <Typography style={{ paddingBottom: '20px' }}>
                <Translate>dragDropFiles</Translate>
              </Typography>
              <Typography variant="caption">
                <Translate>pathCSVMobile</Translate>
              </Typography>
            </div>
          )}
          { this.state.inProgress && (
            <Grid container direction="column" justify="flex-start" alignItems="stretch">
              <Grid item>
                <LinearProgress variant="buffer" value={this.state.value} valueBuffer={this.state.buffer} />
              </Grid>
            </Grid>
          )}
        </CardContent>
        <CardContent>
          { this.state.recordsDone.length > 0 && (
            <Typography>
              <Translate>records</Translate>
              :
            </Typography>
          )}
          <List>
            { this.state.recordsDone.map(({
              filename,
              documentId,
              errorType,
              isSuccess,
            }) => (
              <RecordUploadedLink
                key={filename}
                filename={filename}
                documentId={documentId}
                errorType={errorType}
                isSuccess={isSuccess}
                patientId={this.props.match.params.patientId}
              />
            ))}
          </List>
        </CardContent>

        <CardActions>
          <Grid container direction="row" justify="flex-end" alignItems="center">
            <Grid item>
              <Button onClick={this.onClick}>
                <Translate>selectFiles</Translate>
              </Button>
            </Grid>
          </Grid>
        </CardActions>

        <div style={{ display: 'none' }}>
          <input
            type="file"
            accept=".csv,.zip"
            multiple
            onChange={this.handleFiles}
            ref={(node) => {
              this.node = node;
              return undefined;
            }}
          />
        </div>
      </Card>
    );
  }
}

Upload.propTypes = {
  orgaId: PropTypes.string,
  match: PropTypes.shape({
    params: PropTypes.shape({
      patientId: PropTypes.string,
    }),
  }),
};

Upload.defaultProps = {
  orgaId: undefined,
  match: {
    params: {
      patientId: undefined,
    },
  },
};

export default connect(mapStateToProps)(Upload);

export {
  getFilePrefix,
};
