import { useEffect, useState } from 'react';

import { fetchRecord } from '../../firebase/firestore';

function useRecords(recordsId) {
  const [records, setRecords] = useState([]);
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    async function wrapper() {
      const fetchedRecords = await Promise.all(recordsId.map(recordId => fetchRecord(recordId)));
      setRecords(fetchedRecords.filter(i => i !== undefined));
      setIsLoading(false);
    }

    wrapper();
  }, []);

  return [records, isLoading];
}

export default useRecords;
