import {
  UPDATE_SETTINGS,
  UPDATE_UNIT_METRICS,
  UPDATE_COMPARE_RECORDS_DISPLAY,
  UPDATE_LOCALE,
  UPDATE_ASYMMETRICAL_SELECTED_METRICS,
  UPDATE_GAIT_CYCLE_SELECTED_METRICS,
} from '../actions/types';

import {
  Velocity,
  Cadence,
  SupportPhase,
  StrideLength,
  SingleSupportPercentage,
  StrideDuration,
  Load,
} from '../utils/metrics/index';

const DEFAULT_LOCALE = 'fr-FR';

const DEFAULT_SETTINGS = {
  locale: DEFAULT_LOCALE,
  asymmetricalMetricsSelected: [
    SingleSupportPercentage.key,
    SupportPhase.key,
  ],
  gaitCycleMetricsSelected: [],
  unitMetrics: {
    [Velocity.key]: Velocity.unit,
    [StrideLength.key]: 'cm',
    [StrideDuration.key]: 'ms',
    [Cadence.key]: 'stride/min',
    [Load.key]: 'kg',
  },
  compareRecordsDisplay: 'curve-stable-x',
};

function updateSettings(state = DEFAULT_SETTINGS, action) {
  switch (action.type) {
    case UPDATE_SETTINGS: {
      const newState = {
        ...DEFAULT_SETTINGS,
        ...state,
        ...action.settings,
      };
      if (action.settings) {
        newState.unitMetrics = { ...DEFAULT_SETTINGS.unitMetrics, ...action.settings.unitMetrics };
      }
      return newState;
    }
    case UPDATE_UNIT_METRICS:
      return {
        ...state,
        unitMetrics: action.unitMetrics,
      };
    case UPDATE_COMPARE_RECORDS_DISPLAY:
      return {
        ...state,
        compareRecordsDisplay: action.compareRecordsDisplay,
      };
    case UPDATE_LOCALE:
      return {
        ...state,
        locale: action.locale,
      };
    case UPDATE_ASYMMETRICAL_SELECTED_METRICS:
      return {
        ...state,
        asymmetricalMetricsSelected: action.asymmetricalMetricsSelected,
      };
    case UPDATE_GAIT_CYCLE_SELECTED_METRICS:
      return {
        ...state,
        gaitCycleMetricsSelected: action.gaitCycleMetricsSelected,
      };
    default:
      return state;
  }
}

export {
  updateSettings,
  DEFAULT_LOCALE,
};
