import React from 'react';
import {
  TableHead,
  TableRow,
  TableCell,
} from '@material-ui/core';

import Translate from '../../display/translate';

function ListTableHead() {
  return (
    <TableHead>
      <TableRow>
        <TableCell><Translate>name</Translate></TableCell>
        <TableCell><Translate>birthdate</Translate></TableCell>
      </TableRow>
    </TableHead>
  );
}

export default ListTableHead;
