import React, {
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import PropTypes from 'prop-types';
import Grid from '@material-ui/core/Grid';
import Slider from '@material-ui/core/Slider';
import Typography from '@material-ui/core/Typography';
import IconButton from '@material-ui/core/IconButton';
import PauseIcon from '@material-ui/icons/Pause';
import InfoIcon from '@material-ui/icons/Info';
import PlayArrowIcon from '@material-ui/icons/PlayArrow';
import { makeStyles } from '@material-ui/core/styles';

import Translate from '../../components/display/translate';
import DurationDisplay from '../../components/display/duration';

const useStyles = makeStyles({
  root: {
    backgroundColor: '#F8F9FA',
    borderRadius: 12,
    marginTop: '24px',
    padding: '0 12px',
  },
  rootError: {
    backgroundColor: '#F0F4FF',
    color: '#005EDA',
    borderRadius: 12,
    marginTop: '24px',
    padding: '16px',
  },
  replay: {
    backgroundColor: '#F1F3F5',
    borderRadius: 20,
    fontWeight: 500,
    padding: 6,
    fontSize: '14px',
  },
  timestamp: {
    color: '#868E96',
    fontSize: '12px',
    fontWeight: 500,
  },
});

function ReplaySeek(props) {
  const {
    onPause,
    onPlay,
    onSeek,
    isPlaying,
    nbFrames,
    framerate,
    index,
    loading,
    handleReplayDuration,
    hasError,
  } = props;

  const classes = useStyles();

  // duration in ms
  const replayDuration = useMemo(() => (nbFrames / framerate) * 1000, [nbFrames]);

  function handleClick() {
    if (isPlaying) {
      onPause();
    } else {
      onPlay();
    }
  }
  const [localIndex, setLocalIndex] = useState(0);
  const wasPlaying = useRef(false);
  const isSeeking = useRef(false);

  useEffect(() => {
    if (nbFrames > 0 && handleReplayDuration !== undefined) {
      handleReplayDuration(replayDuration);
    }
  }, [replayDuration]);

  function handleSeek(ev, newValue) {
    isSeeking.current = true;
    setLocalIndex(newValue);
    if (isPlaying) {
      wasPlaying.current = isPlaying;
      onPause();
    }
  }

  function handleSeekCommitted(ev, newValue) {
    isSeeking.current = false;
    setLocalIndex(newValue);
    onSeek(newValue);
    if (wasPlaying.current) {
      onPlay();
    }
    wasPlaying.current = false;
  }

  if (hasError) {
    return (
      <div className={classes.rootError}>
        <Grid container direction="row" justify="flex-start" alignItems="center">
          <Grid item style={{ marginRight: '4px' }}>
            <InfoIcon />
          </Grid>
          <Grid item>
            <Typography>
              <Translate>replayError</Translate>
            </Typography>
          </Grid>
        </Grid>
      </div>
    );
  }

  return (
    <div className={classes.root}>
      <Grid container direction="row" alignItems="center" spacing={3}>
        <Grid item>
          <Typography className={classes.replay}>
            Replay
          </Typography>
        </Grid>
        <Grid item>
          <IconButton size="small" disableFocusRipple onClick={handleClick} disabled={loading}>
            { isPlaying ? <PauseIcon /> : <PlayArrowIcon /> }
          </IconButton>
        </Grid>
        <Grid item xs>
          <Slider
            value={isSeeking.current ? localIndex : index}
            onChange={handleSeek}
            onChangeCommitted={handleSeekCommitted}
            max={nbFrames}
          />
        </Grid>
        <Grid item>
          { nbFrames > 0 && (
            <Typography className={classes.timestamp}>
              <DurationDisplay hideHours={replayDuration < 3600000}>
                { (index / framerate) * 1000 }
              </DurationDisplay>
              { ' / ' }
              <DurationDisplay hideHours={replayDuration < 3600000}>
                { replayDuration }
              </DurationDisplay>
            </Typography>
          )}
        </Grid>
      </Grid>
    </div>
  );
}

ReplaySeek.propTypes = {
  onPause: PropTypes.func.isRequired,
  onPlay: PropTypes.func.isRequired,
  onSeek: PropTypes.func.isRequired,
  framerate: PropTypes.number.isRequired,
  loading: PropTypes.bool.isRequired,
  isPlaying: PropTypes.bool,
  nbFrames: PropTypes.number,
  index: PropTypes.number,
  handleReplayDuration: PropTypes.func,
  hasError: PropTypes.bool.isRequired,
};

ReplaySeek.defaultProps = {
  isPlaying: false,
  index: 0,
  nbFrames: 0,
  handleReplayDuration: undefined,
};

export default ReplaySeek;
