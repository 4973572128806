import firebase from 'firebase/app';

import {
  format,
  startOfDay,
  startOfWeek,
  startOfMonth,
  endOfDay,
  isSameWeek,
  isSameMonth,
  isSameDay,
} from 'date-fns';

function getTimestampsMs(date) {
  return date.getTime();
}

function getStartOfDay(date) {
  return getTimestampsMs(startOfDay(new Date(date)));
}

function getStartOfWeek(date) {
  return getTimestampsMs(startOfWeek(new Date(date)));
}

function getStartOfMonth(date) {
  return getTimestampsMs(startOfMonth(new Date(date)));
}

function getEndOfDay(date) {
  return getTimestampsMs(endOfDay(new Date(date)));
}

function isSameDate(d1, d2, type) {
  switch (type) {
    case 'week':
      return isSameWeek(d1, d2);
    case 'month':
      return isSameMonth(d1, d2);
    default:
      // default to day
      return isSameDay(d1, d2);
  }
}

function toISOFormat(date) {
  return format(date, 'yyyy-MM-dd');
}

function toPorficFormat(date) {
  return toISOFormat(date);
}

function getDateFormat(locale) {
  // from https://stackoverflow.com/questions/2388115/get-locale-short-date-format-using-javascript
  // only supporting the locale we support (src/utils/l10n)
  switch (locale) {
    case 'fr-FR':
      return 'dd/MM/yyyy';
    case 'en-US':
      return 'MM/dd/yyyy';
    default:
      return 'dd/MM/yyyy';
  }
}

// apply the offset to the time, this allow to display a Date object in UTC
// without having to use the timezone option with `.toLocaleDateString`
// This is necessary for the <DatePicker /> component
function applyOffset(timestamp) {
  const date = new Date(timestamp);
  return new Date(date.getTime() + (date.getTimezoneOffset() * 60000));
}

function fileDownloadDateFormat(date) {
  return format(date, 'dd-MM-yyyy-HH-mm-ss');
}

function firebaseTimestampToDate(timestamp) {
  return (new firebase.firestore.Timestamp(timestamp.seconds, timestamp.nanoseconds)).toDate();
}

function getTimezone() {
  return new window.Intl.DateTimeFormat().resolvedOptions().timeZone;
}

export {
  getTimestampsMs,
  getStartOfDay,
  getStartOfWeek,
  getStartOfMonth,
  getEndOfDay,
  isSameDate,
  toISOFormat,
  toPorficFormat,
  getDateFormat,
  applyOffset,
  fileDownloadDateFormat,
  firebaseTimestampToDate,
  getTimezone,
};
