import React from 'react';
import PropTypes from 'prop-types';
import Button from '@material-ui/core/Button';
import { withStyles } from '@material-ui/core/styles';
import GetAppIcon from '@material-ui/icons/GetApp';

import Translate from '../../components/display/translate';

const styles = theme => ({
  anchor: {
    display: 'none',
  },
  leftIcon: {
    marginRight: theme.spacing(1),
  },
});

class DownloadMetrics extends React.Component {
  constructor() {
    super();
    this.handleClick = this.handleClick.bind(this);
  }

  handleClick() {
    const data = this.props.loadData();
    const blob = new Blob([data]);
    if (this.node) {
      this.node.href = URL.createObjectURL(blob);
      this.node.click();
    }
  }

  render() {
    const { classes } = this.props;
    return (
      <div>
        <a
          download={this.props.filename}
          type="text/csv"
          href="/"
          className={classes.anchor}
          ref={(node) => {
            this.node = node;
            return undefined;
          }}
        >
          download metrics
        </a>
        <Button onClick={this.handleClick} variant="outlined" color="primary" size="small">
          <GetAppIcon className={classes.leftIcon} />
          <Translate>stridesData</Translate>
        </Button>
      </div>
    );
  }
}

DownloadMetrics.propTypes = {
  filename: PropTypes.string.isRequired,
  loadData: PropTypes.func.isRequired,
  // eslint-disable-next-line react/forbid-prop-types
  classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(DownloadMetrics);
