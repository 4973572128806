async function wsRecordReplay(recordId, framerate, meshX, meshY) {
  // eslint-disable-next-line no-undef
  const wsURL = __WS_URL__;
  return new WebSocket(`${wsURL}/v1?recordsId=${recordId}&framerate=${framerate}&meshX=${meshX}&meshY=${meshY}`);
}

export {
  // eslint-disable-next-line import/prefer-default-export
  wsRecordReplay,
};
