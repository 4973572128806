import { useMemo } from 'react';

function useSplitCop(cops) {
  return useMemo(() => cops.reduce((acc, cur) => {
    if (cur.side === 'left') {
      acc[0].push([cur.x, cur.y]);
    } else {
      acc[1].push([cur.x, cur.y]);
    }

    return acc;
  }, [[], []]), [cops.length]);
}

export default useSplitCop;
