import PropTypes from 'prop-types';

import { formatDuration } from '../../utils/display';

function DurationDisplay({ children, hideHours }) {
  return formatDuration(children, hideHours);
}

DurationDisplay.propTypes = {
  children: PropTypes.number.isRequired,
  hideHours: PropTypes.bool,
};

DurationDisplay.defaultProps = {
  hideHours: false,
};

export default DurationDisplay;
