import React from 'react';
import PropTypes from 'prop-types';
import Grid from '@material-ui/core/Grid';

import MetricValue from '../../components/metric/utils/value';
import MetricUnit from '../../components/metric/utils/unit';

import { LoadPercentage } from '../../utils/metrics';

function LoadPercentageDisplay({ value }) {
  if (value === undefined) {
    return null;
  }

  return (
    <Grid container direction="row" justify="center" alignItems="center" spacing={1}>
      <Grid item>
        <MetricValue value={value} metric={LoadPercentage} variant="h6" />
      </Grid>
      <Grid item>
        <MetricUnit unit={LoadPercentage.unit} disableTranslate />
      </Grid>
    </Grid>
  );
}

LoadPercentageDisplay.propTypes = {
  value: PropTypes.number,
};

LoadPercentageDisplay.defaultProps = {
  value: undefined,
};

export default LoadPercentageDisplay;
