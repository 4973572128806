import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { scaleLinear, scaleTime } from 'd3-scale';
import { bisector, extent, max } from 'd3-array';
import {
  Grid,
} from '@material-ui/core';
import {
  Graph,
  Circles,
  Gradient,
  MouseContainer,
  useGraphDimensions,
} from '@feetme/d3act';

import Path from './utils/path';
import Area from './utils/area';
import Axis from './utils/axis';
import Trend from './utils/trend';
import Tooltip from './utils/tooltip';
import TooltipInfo from './utils/tooltip-info';

import { formatDate } from '../../utils/display';
import { getTimestampsMs } from '../../utils/date';
import { bisectData, tickFormatHourMinutes } from '../../utils/d3';
import { useUniqueId } from '../../utils/hooks';

function formatTickX(displayMode, locale, dataDays) {
  if (displayMode === 'week') {
    return d => formatDate(d, locale, { month: 'short', day: 'numeric' });
  }

  return (d) => {
    if (dataDays.length === 0 || (dataDays.length - 1) < d) {
      return null;
    }
    return formatDate(dataDays[Math.floor(d)].x, locale, { weekday: 'short', month: 'short', day: 'numeric' });
  };
}

function mapStateToProps(state) {
  return ({
    locale: state.settings.locale,
  });
}

function TimelineActivityExo(props) {
  const {
    dataDays,
    dataWeeks,
    width,
    height,
    locale,
    displayMode,
    recordType,
  } = props;

  const [tooltipOpacity, setTooltipOpacity] = useState(0);
  const [tooltipTransform, setTooltipTransform] = useState('');
  const [successPercentage, setSuccessPercentage] = useState(0);
  const [numberExercises, setNumberExercises] = useState(0);
  const [timeSpend, setTimeSpend] = useState(0);
  const [selectedIndex, setSelectedIndex] = useState(-1);
  const gradientId = useUniqueId('TimelineActivityExo');

  const [ref, dimensions] = useGraphDimensions({
    marginLeft: 80,
    marginTop: 10,
    marginRight: 20,
    height,
    width,
  });
  const bisect = displayMode === 'week' ? bisector(d => getTimestampsMs(d.x)).left : bisector(d => d.x).left;
  const xAccessor = displayMode === 'week' ? d => d.x : (d, i) => i;
  const yAccessor = d => d.y;

  const data = displayMode === 'week' ? dataWeeks : dataDays;
  const xScale = displayMode === 'week'
    ? scaleTime().domain(extent(data, xAccessor)).range([0, dimensions.boundedWidth])
    : scaleLinear().domain([0, data.length - 1]).range([0, dimensions.boundedWidth]);
  const yScale = scaleLinear()
    .domain(displayMode === 'week' ? [0, max([...data, { y: 5 }], yAccessor)] : [0, 100])
    .range([dimensions.boundedHeight, 0]);

  const xAccessorScaled = (d, i) => xScale(xAccessor(d, i));
  const yAccessorScaled = (d, i) => yScale(yAccessor(d, i));

  function handleMouseEnter() {
    setTooltipOpacity(1);
  }

  function handleMouseMove(evt, mouseX) {
    const [d, i] = bisectData(
      bisect,
      displayMode === 'week' ? data : data.map(({ y, duration }, j) => ({ x: j, y, duration })),
      mouseX,
      displayMode === 'week' ? getTimestampsMs(xScale.invert(mouseX)) : xScale.invert(mouseX),
      xAccessorScaled,
    );

    const x = xAccessorScaled(d, i) + dimensions.marginLeft;
    const y = yAccessorScaled(d, i) + dimensions.marginTop;

    setTooltipTransform(`translate(calc(-50% + ${x}px), calc(-100% + ${y}px - 15px))`);
    setSuccessPercentage(displayMode === 'week' ? d.meanSuccess : d.y);
    setTimeSpend(displayMode === 'week' ? d.y : d.duration);
    if (displayMode === 'week') {
      setNumberExercises(d.number);
    }
    setSelectedIndex(i);
  }

  function handleMouseLeave() {
    setTooltipOpacity(0);
    setSelectedIndex(-1);
  }

  return (
    <div ref={ref} style={{ position: 'relative', height }}>
      <Tooltip
        opacity={tooltipOpacity}
        transform={tooltipTransform}
      >
        <Grid container direction="column" justify="flex-start" alignItems="stretch">
          { (displayMode === 'week' && selectedIndex === data.length - 1) && (
            <Grid item>
              <TooltipInfo title={`current${displayMode}`} />
            </Grid>
          )}
          { (displayMode === 'day' && selectedIndex !== -1) && (
            <Grid item>
              <TooltipInfo
                title="date"
                value={formatDate(data[selectedIndex].x, locale, { weekday: 'short', month: 'short', day: 'numeric' })}
              />
            </Grid>
          )}
          <Grid item>
            <TooltipInfo title="meanSuccessPercentage" value={successPercentage} unit="%" />
          </Grid>
          { (displayMode !== 'day' && recordType !== 'sittostand') && (
            <Grid item>
              <TooltipInfo title="timeSpend" value={timeSpend} unit="min" />
            </Grid>
          )}
          { displayMode === 'week' && (
            <Grid item>
              <TooltipInfo title="numberExercises" value={numberExercises} />
            </Grid>
          )}
          { (displayMode === 'day' && recordType === 'sittostand' && selectedIndex !== -1) && (
            <React.Fragment>
              <Grid item>
                <TooltipInfo title="performedSeries" value={`${data[selectedIndex].repetitions}/${data[selectedIndex].repetitionsPlanned}`} />
              </Grid>
              <Grid item>
                <TooltipInfo title="timeSpend" value={`${data[selectedIndex].duration} min/${data[selectedIndex].durationPlanned} min`} />
              </Grid>
              <Grid item>
                <TooltipInfo title="objectivePerSeries" value={data[selectedIndex].sitToStandPerf} />
              </Grid>
              <Grid item>
                <TooltipInfo title="meanNbMovesPerSeries" value={data[selectedIndex].sitToStandMean} />
              </Grid>
              <Grid item>
                <TooltipInfo title="totalNbMoves" value={data[selectedIndex].sitToStand} />
              </Grid>
            </React.Fragment>
          )}
        </Grid>
      </Tooltip>
      <Graph dimensions={dimensions}>
        <defs>
          <Gradient
            id={gradientId}
            stops={[
              { offset: '0%', color: '#2986FF', opacity: '1' },
              { offset: '100%', color: 'white', opacity: '0' },
            ]}
            x1={0}
            x2={0}
            y1={0}
            y2={1}
          />
        </defs>
        <Axis
          dimension="x"
          scale={xScale}
          formatTick={formatTickX(displayMode, locale, dataDays)}
          tickValues={data.map(xAccessor)}
          keyAccessor={d => d}
        />
        <Axis
          dimension="y"
          scale={yScale}
          formatTick={displayMode === 'week' ? tickFormatHourMinutes : d => d}
          keyAccessor={d => d}
        />
        <Path
          data={displayMode === 'week' ? data.slice(0, -1) : data}
          xAccessor={xAccessorScaled}
          yAccessor={yAccessorScaled}
          stroke="#2986FF"
        />
        <Area
          data={displayMode === 'week' ? data.slice(0, -1) : data}
          xAccessor={xAccessorScaled}
          y0Accessor={dimensions.boundedHeight}
          y1Accessor={yAccessorScaled}
          fill={`url(#${gradientId})`}
        />
        { displayMode === 'week' && (
          <Path
            data={data.slice(-2)}
            xAccessor={xAccessorScaled}
            yAccessor={yAccessorScaled}
            stroke="#727886"
            strokeDasharray="4,4"
          />
        )}
        <Circles
          data={data}
          radiusAccessor={(d, i) => (i === selectedIndex ? 6 : 4)}
          keyAccessor={d => `${d.x}${d.y}`}
          xAccessor={xAccessorScaled}
          yAccessor={yAccessorScaled}
          fillAccessor={(d, i) => ((i === data.length - 1) && (displayMode === 'week') ? 'none' : '#2986FF')}
          strokeAccessor={(d, i) => (i === data.length - 1 ? '#727886' : 'none')}
          strokeWidthAccessor={(d, i) => (i === data.length - 1 ? 2 : 0)}
        />
        <Trend
          data={displayMode === 'week' ? (
            data.slice(0, -1).map(({ x, y }) => ({
              x: getTimestampsMs(x),
              y,
            }))) : data.map(({ y }, i) => ({ x: i, y }))}
          xAccessor={xAccessor}
          xScale={xScale}
          yScale={yScale}
        />
        { data.length > 0 && (
          <MouseContainer
            rootRef={ref}
            onMouseEnter={handleMouseEnter}
            onMouseMove={handleMouseMove}
            onMouseLeave={handleMouseLeave}
          />
        )}
      </Graph>
    </div>
  );
}

TimelineActivityExo.propTypes = {
  locale: PropTypes.string.isRequired,

  dataWeeks: PropTypes.arrayOf(PropTypes.shape({
    x: PropTypes.instanceOf(Date),
    y: PropTypes.number,
    meanSuccess: PropTypes.number,
    number: PropTypes.number,
  })).isRequired,
  dataDays: PropTypes.arrayOf(PropTypes.shape({
    x: PropTypes.number,
    y: PropTypes.number,
    duration: PropTypes.number,
  })).isRequired,
  displayMode: PropTypes.oneOf(['week', 'day']).isRequired,
  recordType: PropTypes.string.isRequired,

  width: PropTypes.number,
  height: PropTypes.number,
};

TimelineActivityExo.defaultProps = {
  width: 0,
  height: 250,
};

export default connect(mapStateToProps)(TimelineActivityExo);
