import Metric from './metric';

export default new Metric({
  title: 'doubleSupportPercentage',
  unit: '%',
  key: 'doubleSupportPercentage',
  isDoubleSupport: true,
  hasDeviation: true,
  description: () => [{
    txt: ['normativeValue', ': 20%'],
    align: 'center',
  }],
});
