import React from 'react';
import PropTypes from 'prop-types';
import Typography from '@material-ui/core/Typography';

import Translate from '../../display/translate';

import { getMetricUnitColor } from '../../../utils/colors';

const styles = {
  root: {
    color: getMetricUnitColor(),
  },
};

function MetricUnit(props) {
  return (
    <Typography variant={props.variant} component="p" style={styles.root}>
      { props.disableTranslate ? props.unit : (<Translate>{ props.unit || '' }</Translate>) }
    </Typography>
  );
}

MetricUnit.propTypes = {
  unit: PropTypes.string,
  variant: PropTypes.string,
  disableTranslate: PropTypes.bool,
};

MetricUnit.defaultProps = {
  variant: 'body2',
  unit: undefined,
  disableTranslate: false,
};

export default MetricUnit;
