import React from 'react';
import PropTypes from 'prop-types';
import Card from '@material-ui/core/Card';
import CardHeader from '@material-ui/core/CardHeader';
import CardContent from '@material-ui/core/CardContent';
import { useSelector } from 'react-redux';

import Replay from './replay';
import Translate from '../../components/display/translate';
import { Load } from '../../utils/metrics';

function DynamicReplay({ insoles, recordId, handleReplayDuration }) {
  const loadUnitStore = useSelector(state => state.settings.unitMetrics[Load.key]);

  return (
    <Card>
      <CardHeader
        title={<Translate>replayPressureData</Translate>}
        subheader={(
          <Translate input={{ UNIT: loadUnitStore }}>
            replayPressureDataDescriptionDynamic
          </Translate>
        )}
      />
      <CardContent>
        <Replay
          recordId={recordId}
          insoles={insoles}
          mode="dynamic"
          minDomain={0.13}
          handleReplayDuration={handleReplayDuration}
        />
      </CardContent>
    </Card>
  );
}

DynamicReplay.propTypes = {
  recordId: PropTypes.string.isRequired,
  insoles: PropTypes.arrayOf(PropTypes.object).isRequired,
  handleReplayDuration: PropTypes.func.isRequired,
};

export default DynamicReplay;
