import { min } from 'd3-array';

import { filterWithTimestamps, filterStridesWithoutDoubleSupport, getArrayXY } from '../strides';
import { statArray } from '../array';

// TODO: should we filter with strides (?)
function computeValueWithStrides(box, strides, startTimestamp, endTimestamp) {
  const { metric } = box;

  let arrayXY = strides;
  if (metric.isDoubleSupport) {
    arrayXY = filterStridesWithoutDoubleSupport(strides);
  }

  arrayXY = filterWithTimestamps(getArrayXY(arrayXY, metric), startTimestamp, endTimestamp);
  return {
    ...box,
    value: metric.convert(metric.compute(arrayXY)),
    deviation: metric.convert(statArray(arrayXY.map(i => i.y), 'deviation')),
  };
}

// return the box object with two more properties:
// x: start of the box
// width: width of the box
//
// 2020-02-13: we've added the possibility to have a widthNorm between to
//             values (eg: the norm for the support phase would between 60
//             and 65%). This create more complexity. A metric value is valid
//             while inside this interval. If the value is lower, we need to
//             take the low limit. If the value is higher, we need to take the
//             high limit.
//             This update does not support the reverse flag.
function computeMainBoxCoordinate(box) {
  let widthNorm;
  if (Array.isArray(box.widthNorm)) {
    if (box.value >= box.widthNorm[0] && box.value <= box.widthNorm[1]) {
      widthNorm = box.value;
    } else if (box.value > box.widthNorm[1]) {
      // eslint-disable-next-line prefer-destructuring
      widthNorm = box.widthNorm[1];
    } else {
      // eslint-disable-next-line prefer-destructuring
      widthNorm = box.widthNorm[0];
    }
  } else {
    widthNorm = box.widthNorm;
  }

  const x = box.reverse ? (box.fix - min([box.value, widthNorm])) : box.fix;
  return {
    ...box,
    x,
    width: box.reverse ? Math.abs(x - box.fix) : min([widthNorm, box.value]),
  };
}

function addErrorBox(line) {
  const {
    fix,
    value,
    x,
    reverse,
    widthNorm,
  } = line.main;

  let type;
  let firstPoint;
  let secondPoint;

  let baseWidthNorm;
  if (Array.isArray(widthNorm)) {
    if (value >= widthNorm[0] && value <= widthNorm[1]) {
      baseWidthNorm = value;
    } else if (value > widthNorm[1]) {
      // eslint-disable-next-line prefer-destructuring
      baseWidthNorm = widthNorm[1];
    } else {
      // eslint-disable-next-line prefer-destructuring
      baseWidthNorm = widthNorm[0];
    }
  } else {
    baseWidthNorm = widthNorm;
  }

  if (line.main.value === baseWidthNorm) {
    type = 'equal';
    firstPoint = 0;
    secondPoint = 0;
  } else if (line.main.value > baseWidthNorm) {
    type = 'plus';
    firstPoint = reverse ? fix - value : fix + baseWidthNorm;
    secondPoint = reverse ? fix - baseWidthNorm : x + value;
  } else {
    type = 'minus';
    firstPoint = reverse ? fix - baseWidthNorm : x + value;
    secondPoint = reverse ? x : fix + baseWidthNorm;
  }

  return {
    ...line,
    error: {
      type,
      x: firstPoint,
      width: Math.abs(firstPoint - secondPoint),
    },
  };
}

export {
  computeValueWithStrides,
  computeMainBoxCoordinate,
  addErrorBox,
};
