import { useEffect, useState } from 'react';

import { getPatient } from '../../firebase/functions';

function usePatient(patientId, getConditions = false) {
  const [patient, setPatient] = useState({});
  const [isLoading, setIsLoading] = useState(patientId !== undefined);

  useEffect(() => {
    if (patientId !== undefined) {
      getPatient({ patientId, getConditions })
        .then((res) => {
          setPatient(res.data);
          setIsLoading(false);
        });
    }
  }, [patientId]);

  return [patient, isLoading];
}

export default usePatient;
