import React from 'react';
import PropTypes from 'prop-types';
import { scaleLinear } from 'd3-scale';
import { symbol, symbolCross } from 'd3-shape';
import { extent } from 'd3-array';
import {
  Graph,
  Circles,
  useGraphDimensions,
} from '@feetme/d3act';

import Translate from '../display/translate';

// due to the rotation of the ellipse we need to ensure we have the same ratio
// on the xScale and yScale depending on the width and height of the svg.
// Otherwise the rotation of the ellipse will display wrong values.
// Another solution would be to force the width/height of the svg to be 1:1.
// Or to generate the path of the ellipse to prevent any rotation.
function scaleDomains(xDomain, yDomain, width, height) {
  const xDistance = Math.abs(xDomain[1] - xDomain[0]);
  const yDistance = Math.abs(yDomain[1] - yDomain[0]);

  // ensure both domains are using the same ratio
  let newXDomain = xDistance > yDistance
    ? xDomain
    : [
      xDomain[0] - (yDistance - xDistance) / 2,
      xDomain[1] + (yDistance - xDistance) / 2,
    ];

  let newYDomain = yDistance > xDistance
    ? yDomain
    : [
      yDomain[0] - (xDistance - yDistance) / 2,
      yDomain[1] + (xDistance - yDistance) / 2,
    ];

  // update the domains depending on the with/heigh of the svg
  newXDomain = newXDomain.map(i => ((width > height && height > 0)
    ? i * (width / height)
    : i
  ));

  newYDomain = newYDomain.map(i => ((height > width && width > 0)
    ? i * (height / width)
    : i
  ));

  return [
    newXDomain,
    newYDomain,
  ];
}

function BipodalCop(props) {
  const {
    data,
    ellipse,
    height,
    width,
    pdf,
  } = props;
  const [ref, dimensions] = useGraphDimensions({
    marginLeft: pdf ? 25 : 80,
    marginRight: pdf ? 0 : 60,
    height,
    width,
  });

  const xAccessor = d => d.x;
  const yAccessor = d => d.y;

  const extentData = data.concat(
    { x: 0, y: 0 },
  );

  const { boundedWidth, boundedHeight } = dimensions;

  const [xDomain, yDomain] = scaleDomains(
    extent(extentData, xAccessor),
    extent(extentData, yAccessor),
    boundedWidth,
    boundedHeight,
  );

  const xScale = scaleLinear()
    .domain(xDomain)
    .range([0, boundedWidth]);
  const yScale = scaleLinear()
    .domain(yDomain)
    .range([boundedHeight, 0]);

  const xAccessorScaled = d => xScale(xAccessor(d));
  const yAccessorScaled = d => yScale(yAccessor(d));

  return (
    <div ref={ref} style={{ position: 'relative', height, textAlign: 'center' }}>
      <Graph dimensions={dimensions}>
        <ellipse
          cx={xScale(ellipse.cx)}
          cy={yScale(ellipse.cy)}
          rx={Math.abs(xScale(ellipse.cx) - xScale(ellipse.cx - ellipse.rx))}
          ry={Math.abs(yScale(ellipse.cy) - yScale(ellipse.cy - ellipse.ry))}
          transform={`rotate(${-ellipse.rotation}, ${xScale(ellipse.cx)}, ${yScale(ellipse.cy)})`}
          fill="#F0F6FF"
          stroke="#B9D8FF"
        />
        <g>
          <text
            textAnchor="middle"
            y={-15}
            transform={`translate(0, ${yScale(0)})rotate(-90)`}
          >
            <Translate>medioLateral</Translate>
          </text>
          <line
            style={{ stroke: '#e0e0e0' }}
            x2={boundedWidth}
            y1={yScale(0)}
            y2={yScale(0)}
          />
        </g>
        <g>
          <text
            textAnchor="middle"
            transform={`translate(${xScale(0)}, 0)`}
            y={-10}
          >
            <Translate>anteroPosterior</Translate>
          </text>
          <line
            style={{ stroke: '#e0e0e0' }}
            y2={boundedHeight}
            x1={xScale(0)}
            x2={xScale(0)}
          />
        </g>
        <g>
          <Circles
            data={data}
            keyAccessor={d => d.key}
            xAccessor={xAccessorScaled}
            yAccessor={yAccessorScaled}
            fillAccessor="#2986FF"
          />
        </g>
        <path
          d={symbol(symbolCross, 32)()}
          transform={`rotate(45,${xScale(ellipse.cx)},${yScale(ellipse.cy)}) translate(${xScale(ellipse.cx)},${yScale(ellipse.cy)})`}
          fill="#1945B8"
        />
      </Graph>
    </div>
  );
}

BipodalCop.propTypes = {
  data: PropTypes.arrayOf(PropTypes.shape({
    x: PropTypes.number,
    y: PropTypes.number,
    key: PropTypes.string,
  })).isRequired,
  ellipse: PropTypes.shape({
    rx: PropTypes.number,
    ry: PropTypes.number,
    cx: PropTypes.number,
    cy: PropTypes.number,
    rotation: PropTypes.number,
  }).isRequired,
  width: PropTypes.number,
  height: PropTypes.number,
  pdf: PropTypes.bool,
};

BipodalCop.defaultProps = {
  width: 0,
  height: 500,
  pdf: false,
};

export default BipodalCop;
