import React from 'react';
import { Redirect } from 'react-router-dom';

import PageHeader from '../components/display/page-header';
import PageTitle from '../components/display/page-title';
import Translate from '../components/display/translate';
import DialogAreYouSure from '../components/dialog/are-you-sure';
import ListAuthorizations from '../components/list/authorizations/index';

import { fetchOrga, fetchValidAuthorizations } from '../firebase/firestore';
import { stopAuthorization } from '../firebase/functions';

class Authorizations extends React.Component {
  constructor() {
    super();

    this.state = {
      inProgress: true,
      authorizations: [],

      isDialogOpen: false,
      isDialogWaiting: false,
    };

    this.onDelete = this.onDelete.bind(this);
    this.onValidateStopAuthorization = this.onValidateStopAuthorization.bind(this);
    this.onCancelStopAuthorization = this.onCancelStopAuthorization.bind(this);
  }

  componentDidMount() {
    this.fetchAuthorizations();
  }

  onDelete(authorizationId) {
    this.setState({ isDialogOpen: true });
    this.authorizationId = authorizationId;
  }

  onValidateStopAuthorization() {
    this.setState({ isDialogWaiting: true });
    stopAuthorization({ authorizationId: this.authorizationId })
      .then(() => {
        this.setState({ inProgress: true, isDialogOpen: false, isDialogWaiting: false });
        this.fetchAuthorizations();
      });
  }

  onCancelStopAuthorization() {
    this.setState({ isDialogOpen: false });
  }

  fetchAuthorizations() {
    fetchValidAuthorizations()
      .then(auths => Promise.all(auths.map(authorization => fetchOrga(authorization.key)
        .then(orga => ({ orgaName: orga.name, ...authorization })))))
      .then(authorizations => this.setState({ inProgress: false, authorizations }));
  }

  render() {
    if (this.state.inProgress === false && this.state.authorizations.length === 0) {
      return (<Redirect to="/" />);
    }

    return (
      <div>
        <DialogAreYouSure
          isOpen={this.state.isDialogOpen}
          isWaiting={this.state.isDialogWaiting}
          onYes={this.onValidateStopAuthorization}
          onNo={this.onCancelStopAuthorization}
          text="areYouSureYouWantToStopTheAuthorization"
          textNo="keepAuthorization"
          textYes="stopAuthorization"
        />
        <PageHeader>
          <PageTitle><Translate>authorizations</Translate></PageTitle>
        </PageHeader>
        <ListAuthorizations
          handleDelete={this.onDelete}
          authorizations={this.state.authorizations}
          inProgress={this.state.inProgress}
        />
      </div>
    );
  }
}

export default Authorizations;
