import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import { formatTime } from '../../utils/display';

function mapStateToProps(state) {
  return ({
    locale: state.settings.locale,
  });
}

function TimeDisplay({ children, locale, options }) {
  return formatTime(children, locale, options);
}

TimeDisplay.propTypes = {
  locale: PropTypes.string.isRequired,

  children: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  // eslint-disable-next-line react/forbid-prop-types
  options: PropTypes.object,
};

TimeDisplay.defaultProps = {
  options: undefined,
};

export default connect(mapStateToProps)(TimeDisplay);
