import React from 'react';
import PropTypes from 'prop-types';
import {
  LinearProgress,
  Paper,
  Table,
} from '@material-ui/core';

const styles = {
  root: {
    width: '100%',
  },
  table: {
    minWidth: 700,
  },
  tableWrapper: {
    overflowX: 'auto',
  },
};

function ListContainer(props) {
  return (
    <Paper style={styles.root}>
      { props.inProgress && (
        <LinearProgress />
      )}
      { props.titlebar }
      <div style={styles.tableWrapper}>
        <Table style={styles.table}>
          { props.children }
        </Table>
      </div>
    </Paper>
  );
}

ListContainer.propTypes = {
  inProgress: PropTypes.bool.isRequired,
  titlebar: PropTypes.element,
  children: PropTypes.arrayOf(PropTypes.element).isRequired,
};

ListContainer.defaultProps = {
  titlebar: null,
};

export default ListContainer;
