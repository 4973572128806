import * as Sentry from '@sentry/browser';

import { statArray } from './array';
import {
  SupportPhase,
  StrideDuration,
  SingleSupportDuration,
  SingleSupportPercentage,
  DoubleSupportPercentage,
} from './metrics/index';

const SG = require('ml-savitzky-golay-generalized');

function applyScaleCapa(capa, scale) {
  return capa.map(stride => stride.map(i => scale(i)));
}

function computeMeanCapa(capa) {
  const res = [];

  if (capa.length === 0) {
    return res;
  }

  // it can happen that the mobile return a stride with no capa
  // the cause is unknown yet
  const capaFiltered = capa.filter(i => i.length !== 0);

  if (capaFiltered.length === 0) {
    return res;
  }

  if (capaFiltered.length !== capa.length) {
    Sentry.captureException(new Error('At least one stride as no capa'));
  }

  // we're supposing that the first stride has a correct number of capa
  const nbSensors = capaFiltered[0].length;
  const nbStrides = capaFiltered.length;

  [...Array(nbSensors).keys()].forEach((i) => {
    let sum = 0;
    capaFiltered.forEach((stride) => {
      sum += stride[i];
      return undefined;
    });

    res.push(sum / nbStrides);
  });

  return res;
}

function formatCOP(cop, saveStrides = true) {
  const res = {
    mean: [],
    std: [],
  };

  if (saveStrides) {
    res.strides = cop;
  }

  if (cop.length === 0) {
    return res;
  }

  const NB_SPLIT_COP = 16;
  [...Array(NB_SPLIT_COP).keys()].forEach((i) => {
    const copFixedIndex = [];
    // FIXME proper forEach/filter
    cop.forEach((stride) => {
      if (stride[i] !== 0) {
        copFixedIndex.push(stride[i]);
      }
    });
    if (copFixedIndex.length > 0) {
      res.std.push(statArray(copFixedIndex, 'deviation') || 0);
      res.mean.push(statArray(copFixedIndex, 'median'));
    }
  });

  return res;
}

function computeStd(array, stdArray, type) {
  const op = (type === 'min' ? -1 : 1);
  return array.map((v, i) => {
    if (typeof v === 'number') {
      return v + (op * stdArray[i]);
    }

    return v;
  });
}

// data is an array of object with fields x and y
function savitzkyGolayFilter(data) {
  const windowSize = 25;

  if (data.length <= windowSize) {
    return data;
  }

  return SG(data.map(i => i.y), data.map(i => i.x), {
    windowSize,
    derivative: 0,
    polynomial: 3,
  }).map((elt, i) => ({ x: data[i].x, y: elt }));
}

function getAllAsymmetricalMetrics() {
  return ([
    SupportPhase,
    SingleSupportPercentage,
    DoubleSupportPercentage,
  ]);
}

function getAllGaitCycleMetrics() {
  return ([
    SingleSupportDuration,
    StrideDuration,
  ]);
}

function parsePressureCop(cop) {
  return cop.map(({ x, y }) => [x, y]);
}

function getStdPressureCop(cop = [], std = []) {
  return cop.map((i, index) => ({
    x0: (i.x - 1.96 * std[index].x),
    x1: (i.x + 1.96 * std[index].x),
    y: i.y,
  }));
}

export {
  applyScaleCapa,
  computeMeanCapa,

  formatCOP,
  computeStd,

  savitzkyGolayFilter,

  getAllAsymmetricalMetrics,
  getAllGaitCycleMetrics,

  parsePressureCop,
  getStdPressureCop,
};
