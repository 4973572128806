import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { withTheme } from '@material-ui/core/styles';

import { mouse, select } from 'd3-selection';
import { scaleLinear } from 'd3-scale';
import { curveMonotoneX, line } from 'd3-shape';
import { bisector, extent } from 'd3-array';
import { axisBottom, axisLeft } from 'd3-axis';
import { timeFormat } from 'd3-time-format';
import 'd3-transition';

import { stylePath, styleAxis, styleTooltip } from '../../utils/d3/styling';
import { formatDuration } from '../../utils/display';
import { translateNoDL } from '../../utils/i18n';

function mapStateToProps(state) {
  return ({
    locale: state.settings.locale,
  });
}

class CurveRehab extends React.Component {
  constructor() {
    super();

    this.mouseover = this.mouseover.bind(this);
    this.mouseout = this.mouseout.bind(this);
    this.mousemove = this.mousemove.bind(this);
  }

  componentDidMount() {
    const svg = select(this.node)
      .append('svg');

    const xScale = scaleLinear();
    const yScale = scaleLinear();

    const mainContainer = svg.append('g');
    const xContainer = mainContainer.append('g');
    const yContainer = mainContainer.append('g');
    const graphContainer = stylePath(mainContainer.append('path'), this.props.theme);
    const tooltipBackground = mainContainer.append('rect')
      .attr('fill', 'white')
      .attr('stroke', 'white')
      .attr('opacity', 0.5);
    const tooltip = mainContainer.append('g');
    const mouseContainer = mainContainer.append('rect')
      .attr('fill', 'none')
      .attr('pointer-events', 'all');

    this.el = {
      svg,
      line: line().curve(curveMonotoneX),
      xScale,
      yScale,
      mainContainer,
      xContainer,
      yContainer,
      tooltip,
      tooltipBackground,
      mouseContainer,
      graphContainer,
      bisector: bisector(d => d.x).left,
    };

    styleTooltip(this.el.tooltip, this.props.theme);

    this.sizes = {
      w: 0,
      h: 200,
      margin: {
        top: 70,
        bottom: 35,
        left: 30,
        right: 25,
      },
    };

    if (this.props.values.length !== 0) {
      this.draw();
    }
  }

  componentDidUpdate(prevProps) {
    if (prevProps.values.length !== this.props.values.length) {
      this.draw();
    }
  }

  updateTooltip(d) {
    this.el.tooltip
      .attr('transform', `translate(${this.el.xScale(d.x)}, ${this.el.yScale(d.y)})`);
    this.el.tooltip
      .select('text')
      .html(() => {
        const nbRecordLabel = translateNoDL('numberExercises', this.props.locale);
        const nbRecord = `<tspan x=0 y=${-50}>${nbRecordLabel}: <tspan font-weight="bold">${Number((d.y).toFixed(1))}</tspan></tspan>`;

        const recordDurationLabel = translateNoDL('Duration', this.props.locale);
        const recordDuration = `<tspan x=0 y=${-30}>${recordDurationLabel}: <tspan font-weight="bold">${formatDuration(d.timeSpend)}</tspan></tspan>`;

        const date = `<tspan x=0 y=${-10}>Date: <tspan font-weight="bold">${timeFormat('%d/%m/%y')(new Date(d.x))}</tspan></tspan>`;

        return nbRecord + recordDuration + date;
      });

    const { width, height } = this.el.tooltip.node().getBoundingClientRect();
    this.el.tooltipBackground
      .attr('transform', `translate(${this.el.xScale(d.x) - (width / 2)}, ${this.el.yScale(d.y) - height})`)
      .attr('width', width)
      .attr('height', height);
  }

  draw() {
    this.sizes.w = this.node.clientWidth;
    const width = this.sizes.w - this.sizes.margin.left - this.sizes.margin.right;
    const height = this.sizes.h - this.sizes.margin.top - this.sizes.margin.bottom;

    this.el.svg
      .attr('width', this.sizes.w)
      .attr('height', this.sizes.h);

    this.el.mainContainer
      .attr('transform', `translate(${this.sizes.margin.left},${this.sizes.margin.top})`);

    this.el.mouseContainer
      .attr('width', width)
      .attr('height', height)
      .on('mouseover', this.mouseover)
      .on('mouseout', this.mouseout)
      .on('mousemove', this.mousemove);

    const xExtent = extent(this.props.values, d => d.x);
    let xRange = xExtent[1] - xExtent[0];
    if (xRange === 0) {
      xRange = 1;
    }

    const yExtent = extent(this.props.values, d => d.y);
    let yRange = yExtent[1] - yExtent[0];
    if (yRange === 0) {
      yRange = 1;
    }

    this.el.xScale
      .domain([xExtent[0] - (xRange * 0.04), xExtent[1] + (xRange * 0.04)])
      .rangeRound([0, width]);

    this.el.yScale
      .domain([0, yExtent[1] + (yRange * 0.2)])
      .rangeRound([height, 0]);

    this.el.line
      .x(d => this.el.xScale(d.x))
      .y(d => this.el.yScale(d.y));

    this.el.xContainer
      .attr('transform', `translate(0, ${height})`)
      .call(axisBottom(this.el.xScale).tickFormat(d => timeFormat('%d/%m/%y')(new Date(d))));
    this.el.xContainer.select('.domain').remove();
    styleAxis(this.el.xContainer, this.props.theme);

    this.el.yContainer
      .call(axisLeft(this.el.yScale)
        .ticks(Math.min(7, [...new Set(this.props.values.map(i => i.y))].length)));
    styleAxis(this.el.xContainer, this.props.theme);

    this.el.graphContainer
      .datum(this.props.values)
      .transition()
      .duration(400)
      .attr('d', this.el.line);
  }

  // mouse enters the mouseContainer
  mouseover() {
    // display tooltip
    this.el.tooltip.style('display', null);
    this.el.tooltipBackground.style('display', null);
  }

  // mouse leaves the mouseContainer
  mouseout() {
    // hide tooltip
    this.el.tooltip.style('display', 'none');
    this.el.tooltipBackground.style('display', 'none');
  }

  // mouse moves in the mouseContainer
  mousemove() {
    const mX = mouse(this.el.mouseContainer.node())[0];
    let i = this.el.bisector(this.props.values, this.el.xScale.invert(mX), 1);
    const d0 = this.props.values[i - 1];
    const d1 = this.props.values[i];
    let d;
    if (d1 === undefined) {
      d = d0;
    } else if (d0 === undefined) {
      d = d1;
    } else {
      const lengthd0 = Math.abs(this.el.xScale(d0.x) - mX);
      const lengthd1 = Math.abs(this.el.xScale(d1.x) - mX);
      d = lengthd0 > lengthd1 ? d1 : d0;
    }

    if (d === d0) {
      i -= 1;
    }

    if (d !== undefined) {
      this.updateTooltip(d);
    }
  }

  render() {
    return (
      <div
        ref={(node) => {
          this.node = node;
          return undefined;
        }}
      />
    );
  }
}

CurveRehab.propTypes = {
  // eslint-disable-next-line react/forbid-prop-types
  theme: PropTypes.object.isRequired,
  locale: PropTypes.string.isRequired,

  values: PropTypes.arrayOf(PropTypes.shape({
    x: PropTypes.number.isRequired,
    y: PropTypes.number.isRequired,
    timeSpend: PropTypes.number.isRequired,
  })).isRequired,
};

export default connect(mapStateToProps)(withTheme(CurveRehab));
