import Metric from './metric';

export default new Metric({
  title: 'walkingTime',
  key: 'walkingTime',
  formatType: 'duration',
  // we're using a dummy key, the duration is simply the difference between
  // the first and the last stride
  keyCompute: 'clientTimestamp',
  compute: (arrayClientTimestamps) => {
    if (arrayClientTimestamps.length < 1) {
      return 0;
    }

    return arrayClientTimestamps[arrayClientTimestamps.length - 1].y - arrayClientTimestamps[0].y;
  },
});
