import React from 'react';
import PropTypes from 'prop-types';
import {
  Checkbox,
  TableHead,
  TableRow,
  TableCell,
  Tooltip,
  TableSortLabel,
} from '@material-ui/core';

import Translate from '../../display/translate';

function ListTableHead(props) {
  return (
    <TableHead>
      <TableRow>
        <TableCell padding="checkbox">
          <Checkbox
            indeterminate={props.numSelected > 0 && props.numSelected < props.rowCount}
            checked={props.rowCount !== 0 && props.numSelected === props.rowCount}
            onChange={props.onSelectAllClick}
          />
        </TableCell>
        <TableCell><Translate>testName</Translate></TableCell>
        <TableCell><Translate>type</Translate></TableCell>
        <TableCell sortDirection={props.order}>
          <Tooltip
            title={<Translate>sort</Translate>}
            placement="top"
            enterDelay={300}
          >
            <TableSortLabel
              active
              direction={props.order}
              onClick={() => props.onRequestOrder()}
            >
              <Translate>date</Translate>
            </TableSortLabel>
          </Tooltip>
        </TableCell>
        <TableCell><Translate>time</Translate></TableCell>
        <TableCell style={{ width: '500px' }}><Translate>condition</Translate></TableCell>
        <TableCell><Translate>shoeSize</Translate></TableCell>
      </TableRow>
    </TableHead>
  );
}

ListTableHead.propTypes = {
  numSelected: PropTypes.number.isRequired,
  rowCount: PropTypes.number.isRequired,
  onSelectAllClick: PropTypes.func.isRequired,
  order: PropTypes.oneOf(['asc', 'desc']).isRequired,
  onRequestOrder: PropTypes.func.isRequired,
};

export default ListTableHead;
