import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import Checkboxes from '../../components/selection-controls/checkboxes';

import { updateAsymmetricalMetrics } from '../../actions/index';
import { updateAsymmetricalMetricsSettings } from '../../firebase/firestore';
import { getAllAsymmetricalMetrics } from '../../utils/metric';

function mapStateToProps(state) {
  return ({
    selected: state.settings.asymmetricalMetricsSelected,
  });
}

function AsymmetricalMetricsSelection({ selected, settingsUpdated, dispatch }) {
  function handleChange(key) {
    let newSelected;
    if (selected.indexOf(key) === -1) {
      // add the new element
      newSelected = [...selected, key];
    } else {
      // remove the element
      const eltIndex = selected.indexOf(key);
      newSelected = [
        ...selected.slice(0, eltIndex),
        ...selected.slice(eltIndex + 1),
      ];
    }

    dispatch(updateAsymmetricalMetrics(newSelected));
    updateAsymmetricalMetricsSettings(newSelected).then(() => settingsUpdated());
  }

  return (
    <Checkboxes
      title="asymmetricalMetrics"
      options={getAllAsymmetricalMetrics().map(metric => ({
        key: metric.key,
        label: metric.title,
        checked: selected.indexOf(metric.key) > -1,
      }))}
      handleChange={handleChange}
    />
  );
}

AsymmetricalMetricsSelection.propTypes = {
  selected: PropTypes.arrayOf(PropTypes.string).isRequired,
  settingsUpdated: PropTypes.func.isRequired,
  dispatch: PropTypes.func.isRequired,
};

export default connect(mapStateToProps)(AsymmetricalMetricsSelection);
