import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import isMonday from 'date-fns/isMonday';
import { scaleLinear, scaleTime } from 'd3-scale';
import { bisector, extent } from 'd3-array';
import {
  Grid,
} from '@material-ui/core';
import {
  Graph,
  Circles,
  Gradient,
  MouseContainer,
  useGraphDimensions,
} from '@feetme/d3act';

import Axis from './utils/axis';
import Area from './utils/area';
import Path from './utils/path';
import Trend from './utils/trend';
import Tooltip from './utils/tooltip';
import TooltipInfo from './utils/tooltip-info';

import { formatDate } from '../../utils/display';
import { getTimestampsMs } from '../../utils/date';
import { bisectData, tickFormatHourMinutes } from '../../utils/d3';
import { useUniqueId } from '../../utils/hooks';

function formatTickX(displayMode, locale) {
  if (displayMode === 'day') {
    // in day display - display a label only on Monday
    return (d) => {
      if (isMonday(d)) {
        return formatDate(d, locale, { weekday: 'short', month: 'short', day: 'numeric' });
      }

      return '';
    };
  }

  if (displayMode === 'week') {
    return d => formatDate(d, locale, { month: 'short', day: 'numeric' });
  }

  return d => formatDate(d, locale, { month: 'short' });
}

function mapStateToProps(state) {
  return ({
    locale: state.settings.locale,
  });
}

function TimelineActivityExos(props) {
  const {
    data,
    width,
    height,
    locale,
    displayMode,
  } = props;

  const [tooltipOpacity, setTooltipOpacity] = useState(0);
  const [tooltipTransform, setTooltipTransform] = useState('');
  const [timeSpend, setTimeSpend] = useState('');
  const [numberExercises, setNumberExercises] = useState(0);
  const [selectedIndex, setSelectedIndex] = useState(-1);
  const gradientId = useUniqueId('TimelineActivityExos');

  const [ref, dimensions] = useGraphDimensions({ marginLeft: 80, height, width });
  const xAccessor = d => d.x;
  const yAccessor = d => d.y;
  const bisect = bisector(d => getTimestampsMs(d.x)).left;

  const xScale = scaleTime()
    .domain(extent(data, xAccessor))
    .range([0, dimensions.boundedWidth]);

  const yScale = scaleLinear()
    .domain(extent(data, yAccessor))
    .range([dimensions.boundedHeight, 0]);

  const xAccessorScaled = d => xScale(xAccessor(d));
  const yAccessorScaled = d => yScale(yAccessor(d));

  function handleMouseEnter() {
    setTooltipOpacity(1);
  }

  function handleMouseMove(evt, mouseX) {
    const [d, i] = bisectData(
      bisect,
      data,
      mouseX,
      getTimestampsMs(xScale.invert(mouseX)),
      xAccessorScaled,
    );

    const x = xAccessorScaled(d) + dimensions.marginLeft;
    const y = yAccessorScaled(d) + dimensions.marginTop;

    setTooltipTransform(`translate(calc(-50% + ${x}px), calc(-100% + ${y}px - 15px))`);
    setTimeSpend(d.y);
    setNumberExercises(d.number);
    setSelectedIndex(i);
  }

  function handleMouseLeave() {
    setTooltipOpacity(0);
    setSelectedIndex(-1);
  }

  return (
    <div ref={ref} style={{ position: 'relative', height }}>
      <Tooltip
        opacity={tooltipOpacity}
        transform={tooltipTransform}
      >
        <Grid container direction="column" justify="flex-start" alignItems="stretch">
          { (selectedIndex === data.length - 1) && (
            <Grid item>
              <TooltipInfo title={`current${displayMode}`} />
            </Grid>
          )}
          { (displayMode === 'day' && selectedIndex !== -1) && (
            <Grid item>
              <TooltipInfo
                title="date"
                value={formatDate(xAccessor(data[selectedIndex], selectedIndex), locale, { weekday: 'short', month: 'short', day: 'numeric' })}
              />
            </Grid>
          )}
          <Grid item>
            <TooltipInfo title="timeSpend" value={tickFormatHourMinutes(timeSpend)} />
          </Grid>
          <Grid item>
            <TooltipInfo title="numberExercises" value={numberExercises} />
          </Grid>
        </Grid>
      </Tooltip>
      <Graph dimensions={dimensions}>
        <defs>
          <Gradient
            id={gradientId}
            stops={[
              { offset: '0%', color: '#2986FF', opacity: '1' },
              { offset: '100%', color: 'white', opacity: '0' },
            ]}
            x1={0}
            x2={0}
            y1={0}
            y2={1}
          />
        </defs>
        <Axis
          dimension="x"
          scale={xScale}
          formatTick={formatTickX(displayMode, locale)}
          tickValues={data.map(xAccessor)}
          keyAccessor={d => d.getTime()}
        />
        <Axis
          dimension="y"
          scale={yScale}
          formatTick={tickFormatHourMinutes}
          keyAccessor={d => d}
        />
        <Path
          data={data.slice(0, -1)}
          xAccessor={xAccessorScaled}
          yAccessor={yAccessorScaled}
          stroke="#2986FF"
        />
        <Area
          data={data.slice(0, -1)}
          xAccessor={xAccessorScaled}
          y0Accessor={dimensions.boundedHeight}
          y1Accessor={yAccessorScaled}
          fill={`url(#${gradientId})`}
        />
        <Path
          data={data.slice(-2)}
          xAccessor={xAccessorScaled}
          yAccessor={yAccessorScaled}
          stroke="#727886"
          strokeDasharray="4,4"
        />
        <Circles
          data={data}
          radiusAccessor={(d, i) => (i === selectedIndex ? 6 : 4)}
          keyAccessor={d => `${d.x}${d.y}`}
          xAccessor={xAccessorScaled}
          yAccessor={yAccessorScaled}
          fillAccessor={(d, i) => (i === data.length - 1 ? 'none' : '#2986FF')}
          strokeAccessor={(d, i) => (i === data.length - 1 ? '#727886' : 'none')}
          strokeWidthAccessor={(d, i) => (i === data.length - 1 ? 2 : 0)}
        />
        <Trend
          data={data.slice(0, -1).map(({ x, y }) => ({
            x: getTimestampsMs(x),
            y,
          }))}
          xAccessor={xAccessor}
          xScale={xScale}
          yScale={yScale}
        />
        <MouseContainer
          rootRef={ref}
          onMouseEnter={handleMouseEnter}
          onMouseMove={handleMouseMove}
          onMouseLeave={handleMouseLeave}
        />
      </Graph>
    </div>
  );
}

TimelineActivityExos.propTypes = {
  locale: PropTypes.string.isRequired,

  data: PropTypes.arrayOf(PropTypes.shape({
    x: PropTypes.instanceOf(Date),
    y: PropTypes.number,
    number: PropTypes.number,
  })).isRequired,
  displayMode: PropTypes.oneOf(['day', 'week', 'month']).isRequired,

  width: PropTypes.number,
  height: PropTypes.number,
};

TimelineActivityExos.defaultProps = {
  width: 0,
  height: 250,
};

export default connect(mapStateToProps)(TimelineActivityExos);
