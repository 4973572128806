import React from 'react';
import PropTypes from 'prop-types';
import Grid from '@material-ui/core/Grid';
import Card from '@material-ui/core/Card';
import CardHeader from '@material-ui/core/CardHeader';
import CardContent from '@material-ui/core/CardContent';

import Translate from '../../components/display/translate';
import BipodalCop from '../../components/graph/bipodal-cop';
import EllipseStats from '../../components/record/ellipse-stats';

function StaticBipodalCOP(props) {
  const {
    title,
    ellipse,
    bipodalCop,
    pdf,
  } = props;
  return (
    <Card>
      <CardHeader
        title={<Translate>{ title }</Translate>}
        subheader={<Translate>distributionAndEllipse</Translate>}
      />
      <CardContent>
        <Grid container direction="column" justify="flex-start" alignItems="stretch">
          <Grid item>
            <BipodalCop
              data={bipodalCop}
              ellipse={ellipse}
              width={pdf ? 250 : undefined}
              height={pdf ? 250 : undefined}
            />
          </Grid>
          <Grid item>
            <EllipseStats ellipse={ellipse} />
          </Grid>
        </Grid>
      </CardContent>
    </Card>
  );
}

StaticBipodalCOP.propTypes = {
  title: PropTypes.string.isRequired,
  // eslint-disable-next-line react/forbid-prop-types
  ellipse: PropTypes.object,
  bipodalCop: PropTypes.arrayOf(PropTypes.object).isRequired,
  pdf: PropTypes.bool,
};

StaticBipodalCOP.defaultProps = {
  pdf: false,
  ellipse: {},
};

export default StaticBipodalCOP;
