function displayName(patient) {
  if (patient.isAnonymous) {
    return patient.anonymous;
  }

  return `${patient.firstname} ${patient.surname}`;
}

export {
  // eslint-disable-next-line import/prefer-default-export
  displayName,
};
