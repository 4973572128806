import { createMuiTheme } from '@material-ui/core/styles';

import { getPalette } from './utils/colors';

const defaultTheme = {
  overrides: {
    MuiCard: {
      root: {
        boxShadow: '0px 4px 8px rgba(36, 46, 66, 0.25)',
        borderRadius: '10px',
      },
    },
  },
  palette: getPalette(),
};

export default createMuiTheme(defaultTheme);
export const pdfTheme = createMuiTheme({
  ...defaultTheme,
  typography: {
    fontSize: 12,
    h3: {
      fontSize: 24,
    },
    body1: {
      fontSize: 10,
    },
    body2: {
      fontSize: 10,
    },
    subtitle1: {
      fontSize: 10,
    },
    caption: {
      fontSize: 8,
    },
  },
  overrides: {
    MuiCard: {
      root: {
        boxShadow: 'none',
        border: 'none',
      },
    },
    MuiCardHeader: {
      root: {
        padding: 10,
        marginBottom: 10,
      },
    },
    MuiCardContent: {
      root: {
        padding: '0 !important',
      },
    },
  },
});
