import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import {
  FormControl,
  FormLabel,
  FormControlLabel,
  RadioGroup,
  Radio,
} from '@material-ui/core';

import Translate from '../display/translate';

const styles = theme => ({
  formControl: {
    margin: theme.spacing(3),
  },
  group: {
    margin: `${theme.spacing(1)}px 0`,
  },
});

function Radios(props) {
  const { classes } = props;
  return (
    <FormControl component="fieldset" className={classes.formControl}>
      <FormLabel component="legend"><Translate>{props.title}</Translate></FormLabel>
      <RadioGroup
        aria-label={props.title}
        name={props.title}
        value={props.value}
        className={classes.group}
        onChange={ev => props.handleChange(ev)}
      >
        {props.options.map(option => (
          <FormControlLabel
            key={option.key}
            value={option.key}
            control={<Radio />}
            disabled={props.disabled}
            label={<Translate>{option.label}</Translate>}
          />
        ))}
      </RadioGroup>
    </FormControl>
  );
}

Radios.propTypes = {
  // eslint-disable-next-line react/forbid-prop-types
  classes: PropTypes.object.isRequired,

  title: PropTypes.string.isRequired,
  value: PropTypes.string.isRequired,
  options: PropTypes.arrayOf(PropTypes.shape({
    key: PropTypes.string,
    label: PropTypes.string,
  })).isRequired,
  handleChange: PropTypes.func.isRequired,

  disabled: PropTypes.bool,
};

Radios.defaultProps = {
  disabled: false,
};

export default withStyles(styles)(Radios);
