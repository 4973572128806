import { userId } from '../../firebase/auth';

function parseMetaInsole(meta, side) {
  const versions = {
    firmware: meta[`insole_${side}_versions_firmware`],
    atmel: meta[`insole_${side}_versions_atmel`],
    hardware: meta[`insole_${side}_versions_hardware`],
    algoHash: meta[`insole_${side}_versions_algoHash`],
    firmwareMode: meta[`insole_${side}_versions_firmwareMode`],
    productId: Number(meta[`insole_${side}_versions_productID`]),
    protocolVersion: Number(meta[`insole_${side}_versions_protocolVersion`]),
    testNumber: Number(meta[`insole_${side}_versions_testNumber`]),
  };

  const res = {
    name: meta[`insole_${side}_name`],
    macAddress: meta[`insole_${side}_macAddress`],
    protocolVersion: Number(meta[`insole_${side}_protocolVersion`]),
    number: Number(meta[`insole_${side}_number`]),
    size: Number(meta[`insole_${side}_size`]),
    side,
    // filter undefined values
    versions: Object.keys(versions).reduce((acc, key) => {
      const value = versions[key];
      if (value !== undefined && !Number.isNaN(value)) {
        acc[key] = value;
      }

      return acc;
    }, {}),
  };

  return res;
}

function parseWalkingAids(walkingAids) {
  return walkingAids.split(';').map((walkingAid) => {
    const splitted = walkingAid.split('/');
    return {
      type: splitted[0],
      left: splitted[1].length > 0,
      right: splitted[2].length > 0,
      // TODO decode url
      notes: decodeURIComponent(splitted[3]),
    };
  });
}

function parseMetaBase(meta) {
  const insoles = {};

  const { documentId } = meta;

  const documentInfo = {
    // we're replacing the authorId of the csv
    authorId: userId() || meta.authorId,
    versionCSV: Number(meta.versionCSV),
    device: {
      product: meta.device_product,
      versionSDK: Number(meta.device_versionSDK),
      model: meta.device_model,
      device: meta.device_device,
    },
    application: {
      name: meta.application_name,
      version: meta.application_version,
    },
    recordType: meta.recordType,
    startTime: Number(meta.startTime),
    // force the type
    type: 'dios',
  };

  ['left', 'right'].forEach((side) => {
    if (Object.keys(meta).find(key => key.includes(side)) !== undefined) {
      insoles[side] = parseMetaInsole(meta, side);
    }
  });

  if (meta.meta_tags) {
    documentInfo.tags = meta.meta_tags.split(';');
  }

  if (meta.meta_walkingAids) {
    documentInfo.walkingAids = parseWalkingAids(meta.meta_walkingAids);
  }

  return { documentId, documentInfo, insoles };
}

function parseMetaVersion8(meta) {
  const documentInfo = {
    type: meta.type,
  };

  if (meta.staticSpec_protocol) {
    documentInfo.staticSpec = {
      protocol: meta.staticSpec_protocol,
    };
  }

  if (meta.durationPlanned) {
    documentInfo.durationPlanned = meta.durationPlanned;
  }

  return documentInfo;
}

function parseMeta(line) {
  const meta = {};
  // split the line (array of string, eg: ['key1=value1', 'key2=value2']) into
  // object { key1: 'value1', key2: 'value2' }
  line.forEach((i) => {
    const [key, value] = i.split('=');
    meta[key.trim()] = value.trim();
  });

  const version = Number(meta.versionCSV);

  // the versionCSV is still a string at this point
  // it will be converted to a Number at the end of this function
  if (version === 4 || version === 5 || version === 6 || version === 7) {
    return parseMetaBase(meta);
  }

  if (version === 8 || version === 9) {
    const metaParsed = parseMetaBase(meta);
    const documentInfo = { ...metaParsed.documentInfo, ...parseMetaVersion8(meta) };
    return { ...metaParsed, documentInfo };
  }

  return undefined;
}

export {
  // eslint-disable-next-line import/prefer-default-export
  parseMeta,
};
