import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import Card from '@material-ui/core/Card';
import CardHeader from '@material-ui/core/CardHeader';
import CardContent from '@material-ui/core/CardContent';
import Typography from '@material-ui/core/Typography';

import Translate from '../../components/display/translate';
import NumberDisplay from '../../components/display/number';

import { ellipseArea, rombergQuotient } from '../../utils/ellipse';

function Romberg({ records, recordsData }) {
  const quotient = useMemo(() => {
    const eyesOpenedIndex = records.findIndex(i => i.staticSpec.protocol === 'eyesOpened');
    const eyesClosedIndex = records.findIndex(i => i.staticSpec.protocol === 'eyesClosed');

    const eyesOpenedArea = ellipseArea(
      recordsData[eyesOpenedIndex][1].ellipseBipodalCop.rx,
      recordsData[eyesOpenedIndex][1].ellipseBipodalCop.ry,
    );
    const eyesClosedArea = ellipseArea(
      recordsData[eyesClosedIndex][1].ellipseBipodalCop.rx,
      recordsData[eyesClosedIndex][1].ellipseBipodalCop.ry,
    );

    return rombergQuotient(eyesClosedArea, eyesOpenedArea);
  }, [records.length]);

  return (
    <Card>
      <CardHeader
        title={<Translate>rombergQuotient</Translate>}
        subheader={<Translate>rombergQuotientDescription</Translate>}
      />
      <CardContent>
        <Typography variant="h4" component="p">
          <NumberDisplay options={{ maximumFractionDigits: 2 }}>{ quotient }</NumberDisplay>
        </Typography>
      </CardContent>
    </Card>
  );
}

Romberg.propTypes = {
  records: PropTypes.arrayOf(PropTypes.shape({
    staticSpec: PropTypes.shape({
      protocol: PropTypes.string,
    }),
  })).isRequired,
  recordsData: PropTypes.arrayOf(PropTypes.arrayOf(PropTypes.any)).isRequired,
};

export default Romberg;
