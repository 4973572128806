import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import {
  Button,
  Grid,
  Card,
  Switch,
  TextField,
  CardHeader,
  CardActions,
  CardContent,
  FormControlLabel,
  CircularProgress,
} from '@material-ui/core';

import Radios from '../selection-controls/radios';
import SimpleMenu from '../selection-controls/simple-menu';
import DatePicker from '../pickers/date';
import Translate from '../display/translate';
import PatientConditionForm from './patient-condition';

import { hasValueField, checkValueField } from '../../utils/fhir/scales';
import { PATHOLOGIES } from '../../utils/fhir/pathologies';
import { toPorficFormat, applyOffset } from '../../utils/date';

const styles = () => ({
  progress: {
    position: 'absolute',
    top: '50%',
    left: '50%',
    marginTop: -12,
    marginLeft: -12,
  },
});

function getEmptyScale(type) {
  return ({
    type,
    code: '',
    value: '',
  });
}

const DEFAULT_SCALES = [getEmptyScale('clinical'), getEmptyScale('pathology')];

// The form need to have a full scale object (type, code and value) but
// the server will return empty scales array if there was none to save
//
// We initialize the scale when necessary
function cleanScales(condition) {
  if (condition.scales === undefined) {
    return { ...condition, scales: DEFAULT_SCALES };
  }

  const scales = DEFAULT_SCALES
    .map((scale) => {
      const existingScale = condition.scales.find(i => i.type === scale.type);
      if (existingScale !== undefined) {
        return { ...scale, ...existingScale };
      }

      return scale;
    });

  return { ...condition, scales };
}

class PatientForm extends React.Component {
  constructor(props) {
    super();

    this.state = {
      firstname: props.patient.firstname || '',
      surname: props.patient.surname || '',
      anonymous: props.patient.anonymous || '',
      isAnonymous: props.patient.isAnonymous || false,
      gender: props.patient.gender || 'unknown',
      birthdate: props.patient.birthdate ? applyOffset(props.patient.birthdate) : new Date(),
      conditions: props.conditions.map(condition => cleanScales(condition)),

      anonymousHasChanged: false,
      firstnameHasChanged: false,
      surnameHasChanged: false,
    };

    this.handleChange = this.handleChange.bind(this);
    this.onValidate = this.onValidate.bind(this);
  }

  handleChange(name) {
    return ((ev) => {
      this.setState({ [name]: ev.target.value });
      if (name === 'firstname' || name === 'surname' || name === 'anonymous') {
        this.setState({ [`${name}HasChanged`]: true });
      }
    });
  }

  handleConditionChange(index, scales) {
    this.setState(state => ({
      conditions: state.conditions.map((i, j) => {
        if (j !== index) {
          return i;
        }

        return { ...i, scales };
      }),
    }));
  }

  onValidate() {
    const {
      isAnonymous,
      anonymous,
      firstname,
      surname,
      gender,
      birthdate,
    } = this.state;

    this.props.onValidate({
      patient: {
        isAnonymous,
        anonymous,
        firstname,
        surname,
        gender,
        birthdate: toPorficFormat(birthdate),
      },
      // we remove empty scale that were automatically created
      conditions: this.state.conditions.map(condition => ({
        ...condition,
        scales: condition.scales.filter(i => i.code.length > 0),
      })),
    });
  }

  isDisabled() {
    const areConditionsKO = this.state.conditions.map(condition => condition.scales
      .filter(scale => scale.code && scale.code.length > 0)
      .some(scale => hasValueField(scale.code)
        && checkValueField(scale.value, scale.code) === false)).some(res => res === true);
    const nameError = this.state.isAnonymous === false && (
      this.state.firstname.length < 2 || this.state.surname.length < 2);
    const anonymousError = this.state.isAnonymous === true && (
      this.state.anonymous.length < 2);
    const birthdateError = (this.state.birthdate === null
      || this.state.birthdate.length === 0);
    // TODO we could check if date is between a specific interval [-100Y; today]
    return nameError
      || anonymousError
      || birthdateError
      || areConditionsKO;
  }

  hasError(name) {
    return this.state[name].length < 2 && this.state[`${name}HasChanged`] === true;
  }

  render() {
    const { classes, patientReadOnly } = this.props;

    const button = (
      <Button
        color="primary"
        variant="outlined"
        onClick={this.onValidate}
        disabled={this.isDisabled() || this.props.waitValidation}
      >
        <Translate>{ this.props.textValidate }</Translate>
        { this.props.waitValidation && (
          <CircularProgress size={24} className={classes.progress} />
        )}
      </Button>
    );

    return (
      <Card>
        <form autoComplete="off">
          <CardHeader
            title={<Translate>patientInfo</Translate>}
            action={button}
          />
          <CardContent>
            <Grid container direction="row" justify="space-around" alignItems="center">
              <Grid item>
                <FormControlLabel
                  label={<Translate>anonymousPatient</Translate>}
                  control={(
                    <Switch
                      disabled={patientReadOnly}
                      checked={this.state.isAnonymous}
                      onChange={ev => this.setState({ isAnonymous: ev.target.checked })}
                      value={this.state.isAnonymous}
                    />
                  )}
                />
              </Grid>
              { this.state.isAnonymous
                ? (
                  <Grid item>
                    <TextField
                      required
                      id="anonymous"
                      disabled={patientReadOnly}
                      error={this.hasError('anonymous')}
                      label={<Translate>ID</Translate>}
                      value={this.state.anonymous}
                      onChange={this.handleChange('anonymous')}
                    />
                  </Grid>
                ) : (
                  <React.Fragment>
                    <Grid item>
                      <TextField
                        required
                        id="firstname"
                        disabled={patientReadOnly}
                        error={this.hasError('firstname')}
                        label={<Translate>firstname</Translate>}
                        value={this.state.firstname}
                        onChange={this.handleChange('firstname')}
                      />
                    </Grid>
                    <Grid item>
                      <TextField
                        required
                        id="surname"
                        disabled={patientReadOnly}
                        error={this.hasError('surname')}
                        label={<Translate>surname</Translate>}
                        value={this.state.surname}
                        onChange={this.handleChange('surname')}
                      />
                    </Grid>
                  </React.Fragment>
                ) }
              <Grid item>
                <DatePicker
                  required
                  id="date"
                  label="birthdate"
                  disabled={patientReadOnly}
                  minDate={new Date('1600-01-01')}
                  maxDate={new Date('5000-31-12')}
                  value={this.state.birthdate}
                  onDateChange={date => this.setState({ birthdate: date })}
                />
              </Grid>
              <Grid item>
                <Radios
                  title="gender"
                  value={this.state.gender}
                  disabled={patientReadOnly}
                  options={['male', 'female', 'unknown'].map(i => ({
                    key: i,
                    label: i,
                  }))}
                  handleChange={this.handleChange('gender')}
                />
              </Grid>
            </Grid>
          </CardContent>
          <CardHeader title={<Translate>pathologies</Translate>} />
          <CardContent>
            {this.state.conditions.map((i, index) => (
              <PatientConditionForm
                key={i.id}
                condition={i}
                onChange={value => this.handleConditionChange(index, value)}
                onDelete={() => this.setState(state => ({
                  // remove the conditions at the index j
                  conditions: state.conditions.filter((i2, j2) => j2 !== index),
                }))}
              />
            ))}
            <SimpleMenu
              id="select-pathology"
              buttonChild={<Translate>addPathology</Translate>}
              options={PATHOLOGIES}
              onSelect={code => this.setState(state => ({
                conditions: [...state.conditions, {
                  id: Date.now(),
                  code,
                  scales: DEFAULT_SCALES,
                }],
              }))}
            />
          </CardContent>
          <CardActions>
            <Grid container direction="row" justify="flex-end" alignItems="center">
              <Grid item>
                { button }
              </Grid>
            </Grid>
          </CardActions>
        </form>
      </Card>
    );
  }
}

PatientForm.propTypes = {
  // eslint-disable-next-line react/forbid-prop-types
  classes: PropTypes.object.isRequired,

  // eslint-disable-next-line react/forbid-prop-types
  patient: PropTypes.object,
  conditions: PropTypes.arrayOf(PropTypes.object),
  textValidate: PropTypes.string.isRequired,
  onValidate: PropTypes.func.isRequired,
  waitValidation: PropTypes.bool.isRequired,
  patientReadOnly: PropTypes.bool,
};

PatientForm.defaultProps = {
  patient: {},
  conditions: [],
  patientReadOnly: false,
};

export default withStyles(styles)(PatientForm);
