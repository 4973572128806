import React from 'react';
import PropTypes from 'prop-types';
import {
  TableBody,
} from '@material-ui/core';

import ListContainer from '../container';

import ListToolbar from './list-toolbar';
import ListTableHead from './list-table-head';
import RowRecord from './row-record';

function desc(a, b) {
  // fixed orderBy
  const orderBy = 'startTime';
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }

  if (b[orderBy] > a[orderBy]) {
    return 1;
  }

  return 0;
}

function getSorting(order) {
  return order === 'desc' ? (a, b) => desc(a, b) : (a, b) => -desc(a, b);
}

class ListRecords extends React.Component {
  constructor() {
    super();

    this.state = {
      selected: [],
      order: 'desc',
    };

    this.handleSelectClick = this.handleSelectClick.bind(this);
    this.handleSelectAllClick = this.handleSelectAllClick.bind(this);
  }

  handleSelectClick(event, record) {
    const { selected } = this.state;
    const selectedIndex = selected.findIndex(i => i.key === record.key);
    let newSelected = [];

    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selected, record);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selected.slice(1));
    } else if (selectedIndex === selected.length - 1) {
      newSelected = newSelected.concat(selected.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(
        selected.slice(0, selectedIndex),
        selected.slice(selectedIndex + 1),
      );
    }

    this.setState({ selected: newSelected });
  }

  handleSelectAllClick(event, checked) {
    if (checked) {
      this.setState({ selected: this.props.records });
      return;
    }

    this.setState({ selected: [] });
  }

  isSelected(recordId) {
    return this.state.selected.findIndex(i => i.key === recordId) !== -1;
  }

  render() {
    const sortingFn = getSorting(this.state.order);

    return (
      <ListContainer
        inProgress={this.props.inProgress}
        titlebar={(
          <ListToolbar
            selected={this.state.selected}
            onDateSelection={this.props.onDateSelection}
            onLinkRecord={this.props.onLinkRecord}
          />
        )}
      >
        <ListTableHead
          numSelected={this.state.selected.length}
          rowCount={this.props.records.length}
          onSelectAllClick={this.handleSelectAllClick}
          order={this.state.order}
          onRequestOrder={() => this.setState(prevState => (
            { order: prevState.order === 'asc' ? 'desc' : 'asc' }))}
        />
        <TableBody>
          { this.props.records.sort((a, b) => sortingFn(a, b)).map(record => (
            <RowRecord
              key={record.key}
              record={record}
              onSelectClick={ev => this.handleSelectClick(ev, record)}
              isSelected={this.isSelected(record.key)}
            />
          ))}
        </TableBody>
      </ListContainer>
    );
  }
}

ListRecords.propTypes = {
  records: PropTypes.arrayOf(PropTypes.object).isRequired,
  inProgress: PropTypes.bool.isRequired,
  onDateSelection: PropTypes.func,
  onLinkRecord: PropTypes.func,
};

ListRecords.defaultProps = {
  onDateSelection: undefined,
  onLinkRecord: undefined,
};

export default ListRecords;
