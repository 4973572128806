import {
  UPDATE_RECORD_DISPLAY_TIMESTAMPS,
  CLEAN_RECORD_DISPLAY_TIMESTAMPS,
} from '../actions/types';

const DEFAULT_TIMESTAMPS = {
  startTimestamp: 0,
  endTimestamp: Number.MAX_SAFE_INTEGER,
};

function updateTimestamps(state = DEFAULT_TIMESTAMPS, action) {
  switch (action.type) {
    case UPDATE_RECORD_DISPLAY_TIMESTAMPS:
      return {
        ...state,
        startTimestamp: action.startTimestamp,
        endTimestamp: action.endTimestamp,
      };
    case CLEAN_RECORD_DISPLAY_TIMESTAMPS:
      return DEFAULT_TIMESTAMPS;
    default:
      return state;
  }
}

export default updateTimestamps;
