import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import {
  Button,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
  CircularProgress,
  TextField,
} from '@material-ui/core/';

import Translate from '../../components/display/translate';

import { getDisplayName } from '../../firebase/auth';
import { fetchCurrentUserProfile, updateUserProfile } from '../../firebase/firestore';

const styles = () => ({
  buttonProgress: {
    position: 'absolute',
    top: '50%',
    left: '50%',
    marginTop: -12,
    marginLeft: -12,
  },
});

class DialogFormDisplayName extends React.Component {
  constructor() {
    super();

    this.state = {
      open: false,
      displayName: '',
      sendingToServer: false,
    };

    fetchCurrentUserProfile().then(userProfile => this.setState({
      open: userProfile === undefined || userProfile.displayName === undefined,
      displayName: userProfile === undefined ? '' : (userProfile.displayName || getDisplayName() || ''),
    }));

    this.handleChange = this.handleChange.bind(this);
    this.handleClose = this.handleClose.bind(this);
  }

  handleChange(ev) {
    this.setState({ displayName: ev.target.value });
  }

  handleClose() {
    this.setState({ sendingToServer: true });
    updateUserProfile({ displayName: this.state.displayName })
      .then(() => this.setState({ open: false }));
  }

  render() {
    return (
      <Dialog
        open={this.state.open}
        onClose={this.handleClose}
        disableBackdropClick
        disableEscapeKeyDown
        aria-labelledby="form-dialog-display-name-title"
      >
        <DialogTitle id="form-dialog-display-name-title">
          <Translate>enterYourName</Translate>
        </DialogTitle>
        <DialogContent>
          <DialogContentText>
            <Translate>checkNameWillBeUsed</Translate>
          </DialogContentText>
          <TextField
            autoFocus
            margin="dense"
            id="displayName"
            label={<Translate>displayName</Translate>}
            value={this.state.displayName}
            onChange={this.handleChange}
            type="text"
            fullWidth
          />
        </DialogContent>
        <DialogActions>
          <Button
            disabled={this.state.displayName.length === 0 || this.state.sendingToServer}
            onClick={this.handleClose}
            color="primary"
          >
            <Translate>validate</Translate>
            { this.state.sendingToServer && (
              <CircularProgress size={24} className={this.props.classes.buttonProgress} />
            )}
          </Button>
        </DialogActions>
      </Dialog>
    );
  }
}

DialogFormDisplayName.propTypes = {
  // eslint-disable-next-line react/forbid-prop-types
  classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(DialogFormDisplayName);
