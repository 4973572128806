import {
  UPDATE_INTERVAL_DAILY_GRAPHS,
} from '../actions/types';

// default interval of one hour
const DEFAULT_INTERVAL = 60 * 60 * 1000;

function updateIntervalDailyGraphs(state = DEFAULT_INTERVAL, action) {
  switch (action.type) {
    case UPDATE_INTERVAL_DAILY_GRAPHS:
      return action.intervalMs;
    default:
      return state;
  }
}

export default updateIntervalDailyGraphs;
