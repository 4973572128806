import React from 'react';
import PropTypes from 'prop-types';
import {
  Grid,
  Card,
  CardContent,
  LinearProgress,
} from '@material-ui/core';

import {
  StrideCounter,
  Duration,
  Cadence,
  Distance,
  Velocity,
  StrideLength,
  RiskFalling,
} from '../../utils/metrics/index';
import GaugeMetric from '../../components/metric/gauge';
import LargeMetric from '../../components/metric/large';
import FallingRiskHover from '../../components/metric/falling-risk-hover';

import IntervalSelected from './interval-selected';

function GaugeMetricsCard(props) {
  return (
    <Card>
      { props.inProgress && (
        <LinearProgress />
      )}
      <CardContent>
        <Grid container direction="row" justify="space-between" alignItems="center">
          <Grid item container direction="column" justify="center" alignItems="center" xs={12} sm={6} md={6} lg={2}>
            <Grid item>
              { props.duration > 0 && (
                <LargeMetric metric={Duration} strides={props.strides} value={props.duration} />
              )}
              { props.duration === undefined && (
                // fallback for metric records (records without replay)
                <LargeMetric metric={Duration} strides={props.strides} />
              )}
              <IntervalSelected
                startTimestamp={props.startTime}
                endTimestamp={props.stopTime}
              />
            </Grid>
            <Grid item>
              <LargeMetric metric={Distance} strides={props.strides} strideTimestampFilter />
            </Grid>
            <Grid item>
              <LargeMetric metric={StrideCounter} strides={props.strides} />
            </Grid>
          </Grid>
          <Grid item xs={12} sm={6} md={6} lg={2}>
            <GaugeMetric
              metric={Velocity}
              strides={props.strides}
              strideTimestampFilter
            />
          </Grid>
          <Grid item xs={12} sm={6} md={6} lg={2}>
            <GaugeMetric
              metric={StrideLength}
              strides={props.strides}
              strideTimestampFilter
            />
          </Grid>
          <Grid item xs={12} sm={6} md={6} lg={2}>
            <LargeMetric metric={Cadence} strides={props.strides} strideTimestampFilter />
          </Grid>
          <FallingRiskHover xs={12} sm={6} md={6} lg={2} disable={props.disableFallingRisk}>
            <GaugeMetric
              metric={RiskFalling}
              strides={props.strides}
              strideTimestampFilter
            />
          </FallingRiskHover>
        </Grid>
      </CardContent>
    </Card>
  );
}

GaugeMetricsCard.propTypes = {
  inProgress: PropTypes.bool.isRequired,
  startTime: PropTypes.number.isRequired,
  stopTime: PropTypes.number.isRequired,
  strides: PropTypes.arrayOf(PropTypes.object).isRequired,

  disableFallingRisk: PropTypes.bool,
  // We can inject directly the record duration, this allow us to change the
  // duration on dynamic record to match the record duraion and not the walking
  // duration.
  // See DEFECT-627 for more info.
  // We hide negatif duration, this will be used to wait for the duration from
  // the replay backend.
  duration: PropTypes.number,
};

GaugeMetricsCard.defaultProps = {
  disableFallingRisk: false,
  duration: undefined,
};

export default GaugeMetricsCard;
