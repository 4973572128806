import {
  SCALE_CODE_PRIMARY_PROGRESSIVE,
  SCALE_CODE_SECONDARY_PROGRESSIVE,
  SCALE_CODE_RELAPSING_REMITTING,
  SCALE_CODE_HY,
  SCALE_CODE_ACUTE,
  SCALE_CODE_SUBACUTE,
  SCALE_CODE_CHRONIC,
  SCALE_CODE_EDSS,
  SCALE_CODE_UPDRS,
} from './codes';

const SCALE_MSCLEROSIS = [
  { key: SCALE_CODE_PRIMARY_PROGRESSIVE, label: 'primaryProgressive' },
  { key: SCALE_CODE_SECONDARY_PROGRESSIVE, label: 'secondaryProgressive' },
  { key: SCALE_CODE_RELAPSING_REMITTING, label: 'relapsingRemitting' },
];
const SCALE_HY = [
  { key: SCALE_CODE_HY, label: 'h&y' },
];
const SCALE_STROKE = [
  { key: SCALE_CODE_ACUTE, label: 'acute' },
  { key: SCALE_CODE_SUBACUTE, label: 'subacute' },
  { key: SCALE_CODE_CHRONIC, label: 'chronic' },
];
const SCALE_EDSS = [
  { key: SCALE_CODE_EDSS, label: 'edss' },
];
const SCALE_UPDRS = [
  { key: SCALE_CODE_UPDRS, label: 'updrs3' },
];

function getKeys(scales) {
  return scales.reduce(((acc, scale) => [...acc, ...scale.map(e => e.key)]), []);
}

const SCALES_PATHOLOGY = getKeys([SCALE_MSCLEROSIS, SCALE_HY, SCALE_STROKE]);
const SCALES_CLINICAL = getKeys([SCALE_EDSS, SCALE_UPDRS]);

const SCALES_RULES = {
  [SCALE_CODE_HY]: {
    min: 1,
    max: 5,
    step: 1,
  },
  [SCALE_CODE_EDSS]: {
    min: 0,
    max: 10,
    step: 0.5,
  },
  [SCALE_CODE_UPDRS]: {
    min: 1,
    max: 56,
    step: 1,
  },
};

function isPathologyScale(scaleCode) {
  return SCALES_PATHOLOGY.includes(scaleCode);
}

function isClinicalScale(scaleCode) {
  return SCALES_CLINICAL.includes(scaleCode);
}

function hasValueField(scaleCode) {
  return Object.keys(SCALES_RULES).includes(scaleCode);
}

function getMinValueField(scaleCode) {
  return SCALES_RULES[scaleCode].min;
}

function getMaxValueField(scaleCode) {
  return SCALES_RULES[scaleCode].max;
}

function getStepValueField(scaleCode) {
  return SCALES_RULES[scaleCode].step;
}

function checkValueField(value, scaleCode) {
  const input = parseFloat(value);
  if (Number.isNaN(input)) {
    return false;
  }

  if (hasValueField(scaleCode)) {
    return (input <= SCALES_RULES[scaleCode].max
      && input >= SCALES_RULES[scaleCode].min
      && input % SCALES_RULES[scaleCode].step === 0);
  }

  return true;
}

export {
  SCALE_MSCLEROSIS,
  SCALE_EDSS,
  SCALE_HY,
  SCALE_UPDRS,
  SCALE_STROKE,

  isPathologyScale,
  isClinicalScale,
  hasValueField,
  getMinValueField,
  getMaxValueField,
  getStepValueField,
  checkValueField,
};
