import {
  UPDATE_USER_ORGAID,
} from '../actions/types';

const DEFAULT_USER_PROFILE = {
  orgaId: undefined,
};

function updateUserProfile(state = DEFAULT_USER_PROFILE, action) {
  switch (action.type) {
    case UPDATE_USER_ORGAID:
      return { orgaId: action.orgaId };
    default:
      return state;
  }
}

export default updateUserProfile;
