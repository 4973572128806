import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import Grid from '@material-ui/core/Grid';
import Card from '@material-ui/core/Card';
import CardHeader from '@material-ui/core/CardHeader';
import CardContent from '@material-ui/core/CardContent';

import Translate from '../../components/display/translate';
import CurveStableX from '../../components/d3/curve-stable-x';

import { ellipseArea, ellipseMedioLateralRange, ellipseAnteroPosteriorRange } from '../../utils/ellipse';

function EllipseInfos({ records, recordsData, pdf }) {
  const areas = useMemo(
    () => recordsData.map((data, index) => ({
      x: records[index].startTime,
      y: ellipseArea(data[1].ellipseBipodalCop.rx, data[1].ellipseBipodalCop.ry),
    })),
    [recordsData.length],
  );

  const medioLateralRanges = useMemo(
    () => recordsData.map((data, index) => ({
      x: records[index].startTime,
      y: ellipseMedioLateralRange(data[1].ellipseBipodalCop.medioLateralWidth),
    })),
    [recordsData.length],
  );

  const anteroPosteriorRanges = useMemo(
    () => recordsData.map((data, index) => ({
      x: records[index].startTime,
      y: ellipseAnteroPosteriorRange(data[1].ellipseBipodalCop.anteroPosteriorWidth),
    })),
    [recordsData.length],
  );

  return (
    <Grid container direction="row" justify="space-around" alignItems="flex-start" spacing={pdf ? 5 : 2}>
      <Grid item xs={12}>
        <Card>
          <CardHeader
            title={<Translate>confidenceEllipse</Translate>}
            subheader={<Translate>surface</Translate>}
          />
          <CardContent>
            <CurveStableX values={areas} height={pdf ? 200 : undefined} pdf={pdf} />
          </CardContent>
        </Card>
      </Grid>
      <Grid item xs={pdf ? 6 : 12} md={6}>
        <Card>
          <CardHeader
            title={<Translate>medioLateral</Translate>}
            subheader={<Translate>range</Translate>}
          />
          <CardContent>
            <CurveStableX values={medioLateralRanges} height={pdf ? 200 : undefined} pdf={pdf} />
          </CardContent>
        </Card>
      </Grid>
      <Grid item xs={pdf ? 6 : 12} md={6}>
        <Card>
          <CardHeader
            title={<Translate>anteroPosterior</Translate>}
            subheader={<Translate>range</Translate>}
          />
          <CardContent>
            <CurveStableX values={anteroPosteriorRanges} height={pdf ? 200 : undefined} pdf={pdf} />
          </CardContent>
        </Card>
      </Grid>
    </Grid>
  );
}

EllipseInfos.propTypes = {
  records: PropTypes.arrayOf(PropTypes.shape({
    key: PropTypes.string,
    startTime: PropTypes.number,
  })).isRequired,
  recordsData: PropTypes.arrayOf(PropTypes.arrayOf(PropTypes.any)).isRequired,
  pdf: PropTypes.bool,
};

EllipseInfos.defaultProps = {
  pdf: false,
};

export default EllipseInfos;
