import React from 'react';
import PropTypes from 'prop-types';
import TableBody from '@material-ui/core/TableBody';

import ListContainer from '../container';

import ListToolbar from './list-toolbar';
import ListTableHead from './list-table-head';
import RowPatient from './row-patient';

import { listPatients, searchPatient } from '../../../firebase/functions';
import { getTraceAndStart } from '../../../firebase/performance';

class ListPatients extends React.Component {
  constructor() {
    super();

    this.state = {
      patients: [],
      inProgress: true,
      // use to compare with previous search
      searchString: '',
    };

    this.timeoutId = undefined;
    this.searchWrapper = this.searchWrapper.bind(this);
  }

  componentDidMount() {
    this.listPatients();
  }

  componentWillUnmount() {
    clearTimeout(this.timeoutId);
  }

  search(searchString) {
    if (this.timeoutId !== undefined) {
      clearTimeout(this.timeoutId);
      this.timeoutId = undefined;
    }

    this.timeoutId = setTimeout(() => {
      this.setState({ inProgress: true });
      searchPatient({ patientName: searchString })
        .then((res) => {
          this.timeoutId = undefined;
          this.setState({ patients: res.data, inProgress: false, searchString });
        });
    }, 500);
  }

  searchWrapper(searchString) {
    // the server only when at least 3 chars are send in the request
    // we still want to search if the search input is less than 3 char
    // if the user erase the search input (we want to re-list all patients)
    if (searchString.length < 3 && this.state.searchString === '') {
      return;
    }

    // if the search input is the same as the previous search
    // we can cancel the one in debounce.
    if (this.state.searchString === searchString) {
      clearTimeout(this.timeoutId);
      this.timeoutId = undefined;
      return;
    }

    // when the search input is less than 3 chars (and we already
    // performed a search) we want to list all patients
    if (searchString.length < 3) {
      this.listPatients();
    } else {
      this.search(searchString);
    }
  }

  listPatients() {
    this.setState({ inProgress: true });
    const trace = getTraceAndStart('listPatients');
    listPatients()
      .then((res) => {
        trace.stop();
        this.setState({ patients: res.data, inProgress: false, searchString: '' });
      });
  }

  render() {
    return (
      <ListContainer
        inProgress={this.state.inProgress}
        titlebar={(
          <ListToolbar
            onSearch={this.searchWrapper}
            button={this.props.titlebarRightButton}
          />
        )}
      >
        <ListTableHead />
        <TableBody>
          { this.state.patients.map(patient => (
            <RowPatient
              key={patient.id}
              patient={patient}
              handleClick={this.props.handleRowClick}
            />
          ))}
        </TableBody>
      </ListContainer>
    );
  }
}

ListPatients.propTypes = {
  handleRowClick: PropTypes.func.isRequired,
  titlebarRightButton: PropTypes.element,
};

ListPatients.defaultProps = {
  titlebarRightButton: undefined,
};

export default ListPatients;
