import React from 'react';

import PageTitle from '../components/display/page-title';
import PageHeader from '../components/display/page-header';
import Translate from '../components/display/translate';
import RehabPage from '../components/rehab/index';

import { fetchExercicesRecords } from '../firebase/firestore';
import { userId } from '../firebase/auth';

class Exercises extends React.Component {
  constructor() {
    super();

    this.state = {
      inProgress: true,
      records: [],
    };
  }

  componentDidMount() {
    fetchExercicesRecords(userId(), 50).then(records => this.setState({
      records,
      inProgress: false,
    }));
  }

  render() {
    return (
      <div>
        <PageHeader>
          <PageTitle><Translate>exercises</Translate></PageTitle>
        </PageHeader>
        <RehabPage loading={this.state.inProgress} records={this.state.records} />
      </div>
    );
  }
}

export default Exercises;
