import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import Card from '@material-ui/core/Card';
import CardHeader from '@material-ui/core/CardHeader';
import CardContent from '@material-ui/core/CardContent';
import Grid from '@material-ui/core/Grid';
import { extent, max } from 'd3-array';
import { useSelector } from 'react-redux';
import { scaleLinear } from 'd3-scale';

import GraphMaxPressure from '../../components/graph/max-pressure';
import Colormap from '../../components/insole/colormap';
import ColormapPressure from '../../components/insole/colormap-pressure';
import InsoleViewWrapper from '../../components/insole/insole-view-wrapper';
import Translate from '../../components/display/translate';
import RecordInfo from './record-info';
import { isDynamic, isMetric } from '../../utils/record';
import { getLeft, getRight } from '../../utils/insoles';
import { Load } from '../../utils/metrics';

import usePressureScale from '../../utils/hooks/usePressureScale';

function MaxPressures(props) {
  const {
    records,
    insoles,
    walkingAids,
    dynamicData,
    recordsData,
    pdf,
  } = props;
  const loadUnitStore = useSelector(state => state.settings.unitMetrics[Load.key]);

  const metricRecords = useMemo(
    () => records.filter(record => isMetric(record)),
    [records.length],
  );

  const dynamicDomain = useMemo(() => {
    const maxDomain = max(dynamicData
      .filter(i => i !== undefined && i[0] !== null)
      .map(([processedPressures]) => max([
        ...(processedPressures.meanMaxPressures.left || []),
        ...(processedPressures.meanMaxPressures.right || []),
      ])));

    // we always want to keep to min domain at 0.13
    return [0.13, maxDomain];
  }, [dynamicData.length]);

  const metricScale = useMemo(() => {
    const domain = extent([].concat(...(recordsData.map((recordData) => {
      if (recordData.meanCapa) {
        return extent([].concat(recordData.meanCapa.left, recordData.meanCapa.right));
      }

      return [];
    }))));

    return scaleLinear()
      .domain(domain)
      .range([0, 1]);
  }, [metricRecords.length]);

  const [scale, colorInterpolation] = usePressureScale(dynamicDomain[0], dynamicDomain[1]);

  return (
    <Card>
      <CardHeader
        title={<Translate>compareMaxPressures</Translate>}
        subheader={(
          <Translate input={{ UNIT: loadUnitStore }}>
            {metricRecords.length > 0 ? 'compareMaxPressuresSubtitle' : 'maximumPressureDistributionSubtitle'}
          </Translate>
        )}
      />
      <CardContent>
        <Grid container direction="row" justify="space-around" alignItems="flex-start" spacing={pdf ? 0 : 5}>
          { records.map((record, index) => (
            <Grid item xs={pdf ? true : 12} md={6} lg={4} key={record.key}>
              <RecordInfo
                record={record}
                walkingAids={walkingAids[record.key]}
                showPressureInfo
                isCapa={isMetric(record)}
                pdf={pdf}
              />
              { isMetric(record) && (
                <React.Fragment>
                  <div
                    style={{
                      display: 'flex',
                      flexWrap: 'nowrap',
                      justifyContent: 'center',
                      gap: pdf ? '5px' : '30px',
                      // add a padding to keep the same height with dynamic
                      // insoles and their load
                      paddingTop: pdf ? '27px' : '50px',
                    }}
                  >
                    <InsoleViewWrapper
                      size={getLeft(insoles[record.key]).size}
                      version={getLeft(insoles[record.key]).versions.hardware}
                      side="left"
                      capa={recordsData[index].meanCapa.left.map(i => metricScale(i))}
                      displayCopStd={false}
                      height={pdf ? 150 : 400}
                      pdf={pdf}
                    />
                    <InsoleViewWrapper
                      size={getRight(insoles[record.key]).size}
                      version={getRight(insoles[record.key]).versions.hardware}
                      side="right"
                      capa={recordsData[index].meanCapa.right.map(i => metricScale(i))}
                      displayCopStd={false}
                      height={pdf ? 150 : 400}
                      pdf={pdf}
                    />
                  </div>
                  <div style={{ paddingTop: pdf ? '35px' : '10px' }}>
                    <Colormap pdf={pdf} width={pdf ? 50 : undefined} />
                  </div>
                </React.Fragment>
              )}
              { (isDynamic(record) && dynamicData[index]) && (
                <React.Fragment>
                  <GraphMaxPressure
                    processedPressures={dynamicData[index][0]}
                    processedRecord={dynamicData[index][1]}
                    insoles={insoles[record.key]}
                    scale={scale}
                    pdf={pdf}
                  />
                  <ColormapPressure
                    colorInterpolation={colorInterpolation}
                    domain={dynamicDomain}
                    width={pdf ? 125 : undefined}
                  />
                </React.Fragment>
              )}
            </Grid>
          ))}
        </Grid>
      </CardContent>
    </Card>
  );
}

MaxPressures.propTypes = {
  records: PropTypes.arrayOf(PropTypes.shape({
    key: PropTypes.string,
  })).isRequired,
  // eslint-disable-next-line react/forbid-prop-types
  insoles: PropTypes.object.isRequired,
  // eslint-disable-next-line react/forbid-prop-types
  walkingAids: PropTypes.object.isRequired,
  dynamicData: PropTypes.arrayOf(PropTypes.arrayOf(PropTypes.any)).isRequired,
  recordsData: PropTypes.arrayOf(PropTypes.object).isRequired,
  pdf: PropTypes.bool,
};

MaxPressures.defaultProps = {
  pdf: false,
};

export default MaxPressures;
