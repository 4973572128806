import React from 'react';
import PropTypes from 'prop-types';
import { withTheme } from '@material-ui/core/styles';

import { select } from 'd3-selection';
import { scaleLinear } from 'd3-scale';
import { histogram, max, min } from 'd3-array';
import { axisBottom, axisLeft } from 'd3-axis';
import { timeFormat } from 'd3-time-format';
import 'd3-transition';

import { styleAxis, splitTextTick } from '../../utils/d3/styling';

function hideFocusContainers(container) {
  container.selectAll('text').style('display', 'none');
}

class BoxPlot extends React.Component {
  componentDidMount() {
    const svg = select(this.node)
      .append('svg');

    const xScale = scaleLinear();
    const yScale = scaleLinear();

    const mainContainer = svg.append('g');
    const xContainer = mainContainer.append('g');
    const yContainer = mainContainer.append('g');
    const graphContainer = mainContainer.append('g');
    const selectContainer = mainContainer.append('g');
    const focusContainer = mainContainer.append('g');

    this.el = {
      svg,
      xScale,
      yScale,
      mainContainer,
      xContainer,
      yContainer,
      graphContainer,
      selectContainer,
      focusContainer,
    };

    this.sizes = {
      w: this.props.width,
      h: this.props.height,
      margin: {
        top: 10,
        bottom: 25,
        left: this.props.pdf ? 20 : 40,
        right: 20,
      },
    };

    if (this.props.values.length !== 0) {
      this.draw();
    }
  }

  componentDidUpdate(prevProps) {
    if (prevProps.values.length !== this.props.values.length) {
      this.draw();
    }
  }

  getBarWidth() {
    // use histogram to compute with of box plots
    const histo = histogram()
      .domain(this.el.xScale.domain())
      .value((d, i) => i)
      .thresholds(this.el.xScale.ticks(this.props.values.length));

    const bins = histo(this.props.values);

    return this.el.xScale(bins[0].x1) - this.el.xScale(bins[0].x0);
  }

  getLines(barWidth) {
    return ([
      {
        // vertical
        x1: (d, i) => this.el.xScale(i),
        x2: (d, i) => this.el.xScale(i),
        y1: d => this.el.yScale(d.min),
        y2: d => this.el.yScale(d.max),
        before: true,
      }, {
        // top horizontal
        x1: (d, i) => this.el.xScale(i) - (barWidth / 2),
        x2: (d, i) => this.el.xScale(i) + (barWidth / 2),
        y1: d => this.el.yScale(d.max),
        y2: d => this.el.yScale(d.max),
        before: true,
      }, {
        // bottom horizontal
        x1: (d, i) => this.el.xScale(i) - (barWidth / 2),
        x2: (d, i) => this.el.xScale(i) + (barWidth / 2),
        y1: d => this.el.yScale(d.min),
        y2: d => this.el.yScale(d.min),
        before: true,
      }, {
        // middle horizontal
        x1: (d, i) => this.el.xScale(i) - (barWidth / 2),
        x2: (d, i) => this.el.xScale(i) + (barWidth / 2),
        y1: d => this.el.yScale(d.median),
        y2: d => this.el.yScale(d.median),
        stroke: 'white',
      },
    ]);
  }

  // We want to hide some text labels if:
  // - the difference between quantile 1 and quantile2 is too small
  // - the difference between min, max and median is too small
  canDisplayTextFocusContainer(elt, type) {
    switch (type) {
      case 'min':
        return Math.abs(this.el.yScale(elt.median) - this.el.yScale(elt.min)) > 10;
      case 'max':
        return Math.abs(this.el.yScale(elt.max) - this.el.yScale(elt.median)) > 10;
      case 'quantile1':
      case 'quantile3':
        return Math.abs(this.el.yScale(elt.quantile3) - this.el.yScale(elt.quantile1)) > 10;
      default:
        return true;
    }
  }

  updateFocusContainers(elt, index, barWidth) {
    const texts = (['min', 'max', 'median', 'quantile1', 'quantile3']).map(j => ({
      v: elt[j],
      left: j === 'quantile1' || j === 'quantile3',
      display: this.canDisplayTextFocusContainer(elt, j) ? null : 'none',
    }));

    const text = this.el.focusContainer.selectAll('text')
      .data(texts);

    text.enter().append('text')
      .attr('font-family', 'Roboto')
      .attr('font-size', '0.875rem')
      .attr('dy', '.35em')
      .merge(text)
      .style('display', d => d.display)
      .attr('transform', d => `translate(${this.el.xScale(index) + ((d.left ? -1 : 1) * barWidth)}, ${this.el.yScale(d.v)})`)
      .attr('text-anchor', d => (d.left ? 'end' : 'start'))
      .text(d => Number((d.v).toFixed(1)));
  }

  draw() {
    if (this.sizes.w === 0) {
      this.sizes.w = this.node.clientWidth;
    }
    const width = this.sizes.w - this.sizes.margin.left - this.sizes.margin.right;
    const height = this.sizes.h - this.sizes.margin.top - this.sizes.margin.bottom;

    this.el.svg
      .attr('width', this.sizes.w + this.sizes.margin.left + this.sizes.margin.right)
      .attr('height', this.sizes.h + this.sizes.margin.top + this.sizes.margin.bottom);

    this.el.mainContainer
      .attr('transform', `translate(${this.sizes.margin.left},${this.sizes.margin.top})`);

    const xExtent = [0, this.props.values.length - 1];
    const xRange = xExtent[1] - xExtent[0];

    const yExtent = [min(this.props.values, d => d.min), max(this.props.values, d => d.max)];
    const yRange = yExtent[1] - yExtent[0];

    this.el.xScale
      .domain([xExtent[0] - (xRange * 0.1), xExtent[1] + (xRange * 0.1)])
      .rangeRound([0, width]);

    this.el.yScale
      .domain([yExtent[0] - (yRange * 0.2), yExtent[1] + (yRange * 0.2)])
      .rangeRound([height, 0]);

    this.el.xContainer
      .attr('transform', `translate(0, ${height})`)
      .call(axisBottom(this.el.xScale)
        // if the svg container is too small, we reduce the max number of ticks
        .ticks(Math.min(this.props.values.length, width < 300 ? 2 : 4))
        .tickFormat((d) => {
          // 2020-09-14: quickly fixed, we should definitely rewrite this
          // component using d3act
          if (!Number.isInteger(d)) {
            return undefined;
          }
          // eslint-disable-next-line
          const { x } = this.props.values[min([Math.abs(Math.floor(d)), this.props.values.length - 1])];
          return timeFormat('%d/%m/%y %H:%M')(new Date(x));
        }));
    this.el.xContainer.select('.domain').remove();
    splitTextTick(this.el.xContainer);
    styleAxis(this.el.xContainer, this.props.theme);

    this.el.yContainer
      .call(axisLeft(this.el.yScale));
    styleAxis(this.el.yContainer, this.props.theme);

    const barWidth = this.getBarWidth();
    const linesData = this.getLines(barWidth);

    const linesEnter = this.el.graphContainer.selectAll('line')
      .remove()
      .exit()
      .data(this.props.values)
      .enter();

    linesData
      .filter(line => line.before)
      .forEach(line => linesEnter.append('line')
        .attr('class', 'eltOpacity')
        .attr('x1', line.x1)
        .attr('x2', line.x2)
        .attr('y1', line.y1)
        .attr('y2', line.y2)
        .attr('stroke', 'black')
        .attr('stroke-width', 1)
        .attr('fill', 'none'));

    this.el.graphContainer.selectAll('rect')
      .remove()
      .exit()
      .data(this.props.values)
      .enter()
      .append('rect')
      .attr('class', 'eltOpacity')
      .attr('width', barWidth)
      .attr('height', d => Math.abs(this.el.yScale(d.quantile3) - this.el.yScale(d.quantile1)))
      .attr('x', (d, i) => this.el.xScale(i) - (barWidth / 2))
      .attr('y', d => this.el.yScale(d.quantile3))
      .attr('fill', this.props.theme.palette.primary.dark);

    linesData
      .filter(line => !line.before)
      .forEach(line => linesEnter.append('line')
        .attr('class', 'eltOpacity')
        .attr('x1', line.x1)
        .attr('x2', line.x2)
        .attr('y1', line.y1)
        .attr('y2', line.y2)
        .attr('stroke', 'white')
        .attr('stroke-width', 1)
        .attr('fill', 'none'));

    this.el.selectContainer.selectAll('rect')
      .remove()
      .exit()
      .data(this.props.values)
      .enter()
      .append('rect')
      .attr('fill', 'none')
      .attr('pointer-events', 'all')
      .attr('width', barWidth)
      .attr('height', d => Math.abs(this.el.yScale(d.max) - this.el.yScale(d.min)))
      .attr('x', (d, i) => this.el.xScale(i) - (barWidth / 2))
      .attr('y', d => this.el.yScale(d.max))
      .on('mouseover', (d, i) => {
        this.el.graphContainer.selectAll('.eltOpacity').transition().style('opacity', e => (d.x === e.x ? 1 : 0.1));
        this.el.yContainer.transition().style('opacity', 0.1);
        this.updateFocusContainers(d, i, (barWidth / 2) + ((barWidth / 2) * 0.2));
      })
      .on('mouseout', () => {
        this.el.graphContainer.selectAll('.eltOpacity').transition().style('opacity', 1);
        this.el.yContainer.transition().style('opacity', 1);
        hideFocusContainers(this.el.focusContainer);
      });
  }

  render() {
    return (
      <div
        ref={(node) => {
          this.node = node;
          return undefined;
        }}
      />
    );
  }
}

BoxPlot.propTypes = {
  // eslint-disable-next-line react/forbid-prop-types
  theme: PropTypes.object.isRequired,

  width: PropTypes.number,
  height: PropTypes.number,

  values: PropTypes.arrayOf(PropTypes.shape({
    x: PropTypes.number,
    median: PropTypes.number,
    min: PropTypes.number,
    max: PropTypes.number,
    quantile1: PropTypes.number,
    quantile3: PropTypes.number,
  })).isRequired,
  pdf: PropTypes.bool,
};

BoxPlot.defaultProps = {
  width: 0,
  height: 480,
  pdf: false,
};

export default withTheme(BoxPlot);
