const UPDATE_SETTINGS = 'UPDATE_SETTINGS';
const UPDATE_UNIT_METRICS = 'UPDATE_UNIT_METRICS';
const UPDATE_COMPARE_RECORDS_DISPLAY = 'UPDATE_COMPARE_RECORDS_DISPLAY';
const UPDATE_LOCALE = 'UPDATE_LOCALE';
const UPDATE_ASYMMETRICAL_SELECTED_METRICS = 'UPDATE_ASYMMETRICAL_SELECTED_METRICS';
const UPDATE_GAIT_CYCLE_SELECTED_METRICS = 'UPDATE_GAIT_CYCLE_SELECTED_METRICS';

const UPDATE_RECORD_DISPLAY_TIMESTAMPS = 'UPDATE_RECORD_DISPLAY_TIMESTAMPS';
const CLEAN_RECORD_DISPLAY_TIMESTAMPS = 'CLEAN_RECORD_DISPLAY_TIMESTAMPS';

const UPDATE_INTERVAL_DAILY_GRAPHS = 'UPDATE_INTERVAL_DAILY_GRAPHS';

const UPDATE_USER_ORGAID = 'UPDATE_USER_ORGAID';

const UPDATE_RECORDS_NOT_LINKED = 'UPDATE_RECORDS_NOT_LINKED';

const ADD_SNACKBAR_MESSAGE = 'ADD_SNACKBAR_MESSAGE';
const UPDATE_SNACKBAR_MESSAGES = 'UPDATE_SNACKBAR_MESSAGES';

const FLUSH = 'FLUSH';

export {
  UPDATE_SETTINGS,
  UPDATE_UNIT_METRICS,
  UPDATE_COMPARE_RECORDS_DISPLAY,
  UPDATE_LOCALE,
  UPDATE_ASYMMETRICAL_SELECTED_METRICS,
  UPDATE_GAIT_CYCLE_SELECTED_METRICS,

  UPDATE_RECORD_DISPLAY_TIMESTAMPS,
  CLEAN_RECORD_DISPLAY_TIMESTAMPS,

  UPDATE_INTERVAL_DAILY_GRAPHS,

  UPDATE_USER_ORGAID,

  UPDATE_RECORDS_NOT_LINKED,

  ADD_SNACKBAR_MESSAGE,
  UPDATE_SNACKBAR_MESSAGES,

  FLUSH,
};
