import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import Grid from '@material-ui/core/Grid';

import Gauge from '../d3/gauge';
import MetricTitle from './utils/title';
import MetricValue from './utils/value';
import MetricUnit from './utils/unit';
import MetricDescription from './utils/description';
import MetricStandardDeviation from './utils/standard-deviation';

import Metric from '../../utils/metrics/metric';
import { filterWithTimestamps, getArrayXY } from '../../utils/strides';
import { statArray } from '../../utils/array';

function mapStateToProps(state, ownProps) {
  return ({
    unit: state.settings.unitMetrics[ownProps.metric.key] || ownProps.metric.unit,
    startTimestamp: state.timestampsRecordDisplay.startTimestamp,
    endTimestamp: state.timestampsRecordDisplay.endTimestamp,
  });
}

function GaugeMetric(props) {
  const {
    strides,
    unit,
    metric,
    startTimestamp,
    endTimestamp,
    strideTimestampFilter,
    pdf,
  } = props;

  let deviation;
  let arrayXY = getArrayXY(strides, metric);
  if (strideTimestampFilter) {
    arrayXY = filterWithTimestamps(arrayXY, startTimestamp, endTimestamp);
  }

  const mean = metric.convert(metric.compute(arrayXY), unit) || 0;
  if (metric.hasDeviation) {
    deviation = metric.convert(statArray(arrayXY.map(i => i.y), 'deviation'), unit);
  }

  return (
    <Grid container direction="column" justify="center" alignItems="center">
      <Grid item xs>
        <Gauge
          value={mean}
          domain={metric.domain(unit)}
          range={metric.range}
          colorRange={metric.colorRange}
          width={pdf ? 200 : undefined}
          pdfMode={pdf}
        />
      </Grid>
      <Grid item>
        <MetricTitle metric={metric} variant="subtitle1" />
      </Grid>
      <Grid item container direction="row" justify="center" alignItems="center" spacing={1}>
        <Grid item>
          <MetricValue metric={metric} value={mean} variant="h3" />
        </Grid>
        <Grid item>
          <MetricUnit unit={unit} variant="h6" />
        </Grid>
      </Grid>
      <Grid item>
        <MetricStandardDeviation metric={metric} unit={unit} value={deviation} pdf={pdf} />
      </Grid>
      { pdf === false && (
        <Grid item>
          <MetricDescription metric={metric} unit={unit} style={{ paddingTop: '10px', minHeight: '135px' }} />
        </Grid>
      )}
    </Grid>
  );
}

GaugeMetric.propTypes = {
  // TODO could be simplified with only an arrayXYSide
  strides: PropTypes.arrayOf(PropTypes.object).isRequired,
  startTimestamp: PropTypes.number.isRequired,
  endTimestamp: PropTypes.number.isRequired,
  metric: PropTypes.instanceOf(Metric).isRequired,
  unit: PropTypes.string.isRequired,
  strideTimestampFilter: PropTypes.bool,
  pdf: PropTypes.bool,
};

GaugeMetric.defaultProps = {
  strideTimestampFilter: false,
  pdf: false,
};

export default connect(mapStateToProps)(GaugeMetric);
