import React from 'react';
import PropTypes from 'prop-types';
import { withTheme } from '@material-ui/core/styles';

import { mouse, select } from 'd3-selection';
import { scaleLinear } from 'd3-scale';
import { area, curveMonotoneX, line } from 'd3-shape';
import {
  bisector,
  extent,
  max,
  min,
} from 'd3-array';
import { axisBottom, axisLeft } from 'd3-axis';
import { timeFormat } from 'd3-time-format';
import 'd3-transition';

import {
  stylePath,
  styleAxis,
  styleFocusContainer,
  splitTextTick,
} from '../../utils/d3/styling';

class CurveStableX extends React.Component {
  constructor() {
    super();

    this.mousemove = this.mousemove.bind(this);
  }

  componentDidMount() {
    const svg = select(this.node)
      .append('svg');

    const xScale = scaleLinear();
    const yScale = scaleLinear();

    const mainContainer = svg.append('g');
    const xContainer = mainContainer.append('g');
    const yContainer = mainContainer.append('g');
    const areaContainer = mainContainer.append('path')
      .attr('fill', this.props.theme.palette.primary.light);
    const graphContainer = stylePath(mainContainer.append('path'), this.props.theme);

    if (this.props.displayDeviationValues) {
      graphContainer.attr('stroke-dasharray', '3, 3');
    }
    const focusContainer = mainContainer.append('g');
    const mouseContainer = mainContainer.append('rect')
      .attr('fill', 'none')
      .attr('pointer-events', 'all');

    this.el = {
      svg,
      line: line().curve(curveMonotoneX),
      area: area().curve(curveMonotoneX),
      xScale,
      yScale,
      mainContainer,
      xContainer,
      yContainer,
      focusContainer,
      mouseContainer,
      graphContainer,
      areaContainer,
      bisector: bisector(d => d.x).left,
    };

    styleFocusContainer(this.el.focusContainer, this.props.theme);
    if (this.props.displayDeviationValues) {
      this.el.focusContainerStdUp = mainContainer.append('g');
      this.el.focusContainerStdDown = mainContainer.append('g');

      styleFocusContainer(this.el.focusContainerStdUp, this.props.theme);
      styleFocusContainer(this.el.focusContainerStdDown, this.props.theme);
    }

    this.sizes = {
      w: this.props.width,
      h: this.props.height,
      margin: {
        top: 10,
        bottom: 35,
        left: this.props.pdf ? 50 : 50,
        right: 20,
      },
    };

    if (this.props.values.length !== 0) {
      this.draw();
    }
  }

  componentDidUpdate(prevProps) {
    if (prevProps.values.length !== this.props.values.length) {
      this.draw();
    }
  }

  displayFocusContainers() {
    this.el.focusContainer.style('display', null);
    if (this.props.displayDeviationValues) {
      this.el.focusContainerStdUp.style('display', null);
      this.el.focusContainerStdDown.style('display', null);
    }
  }

  hideFocusContainers() {
    this.el.focusContainer.style('display', 'none');
    if (this.props.displayDeviationValues) {
      this.el.focusContainerStdUp.style('display', 'none');
      this.el.focusContainerStdDown.style('display', 'none');
    }
  }

  updateFocusContainers(d, i) {
    this.el.focusContainer
      .attr('transform', `translate(${this.el.xScale(i)}, ${this.el.yScale(d.y)})`);
    this.el.focusContainer
      .select('text')
      .text(Number((d.y).toFixed(1)));

    if (this.props.displayDeviationValues) {
      const valueUp = d.y + d.deviation;
      const valueDown = d.y - d.deviation;

      if (Math.abs(this.el.yScale(valueUp) - this.el.yScale(valueDown)) < 50) {
        // if the deviation is too small, we do not display the deviation values
        this.el.focusContainerStdUp.style('display', 'none');
        this.el.focusContainerStdDown.style('display', 'none');
        return;
      }

      // the deviation containers might have been hidden previously
      this.el.focusContainerStdUp.style('display', null);
      this.el.focusContainerStdDown.style('display', null);

      this.el.focusContainerStdUp
        .attr('transform', `translate(${this.el.xScale(i)}, ${this.el.yScale(valueUp)})`);
      this.el.focusContainerStdDown
        .attr('transform', `translate(${this.el.xScale(i)}, ${this.el.yScale(valueDown)})`);

      this.el.focusContainerStdUp
        .select('text')
        .text(Number((valueUp).toFixed(1)));
      this.el.focusContainerStdDown
        .select('text')
        .text(Number((valueDown).toFixed(1)));
    }
  }

  draw() {
    if (this.sizes.w === 0) {
      this.sizes.w = this.node.clientWidth;
    }
    const width = this.sizes.w - this.sizes.margin.left - this.sizes.margin.right;
    const height = this.sizes.h - this.sizes.margin.top - this.sizes.margin.bottom;

    this.el.svg
      .attr('width', this.sizes.w)
      .attr('height', this.sizes.h);

    this.el.mainContainer
      .attr('transform', `translate(${this.sizes.margin.left},${this.sizes.margin.top})`);

    this.el.mouseContainer
      .attr('width', width)
      .attr('height', height)
      .on('mouseover', () => this.displayFocusContainers())
      .on('mouseout', () => this.hideFocusContainers())
      .on('mousemove', this.mousemove);

    const xExtent = [0, this.props.values.length - 1];
    const xRange = xExtent[1] - xExtent[0];

    let yExtent;
    if (this.props.displayDeviationValues) {
      yExtent = [
        min(this.props.values, d => (d.y - d.deviation)),
        max(this.props.values, d => (d.y + d.deviation)),
      ];
    } else {
      yExtent = extent(this.props.values, d => d.y);
    }

    let yRange = yExtent[1] - yExtent[0];
    if (yRange === 0) {
      yRange = 1;
    }

    this.el.xScale
      .domain([xExtent[0] - (xRange * 0.05), xExtent[1] + (xRange * 0.1)])
      .rangeRound([0, width]);

    this.el.yScale
      .domain([yExtent[0] - (yRange * 0.2), yExtent[1] + (yRange * 0.2)])
      .rangeRound([height, 0]);

    this.el.line
      .x((d, i) => this.el.xScale(i))
      .y(d => this.el.yScale(d.y));

    if (this.props.displayDeviationValues) {
      this.el.area
        .x((d, i) => this.el.xScale(i))
        .y0(d => this.el.yScale(d.y + (d.deviation || 0)))
        .y1(d => this.el.yScale(d.y - (d.deviation || 0)));
    }

    this.el.xContainer
      .attr('transform', `translate(0, ${height})`)
      .call(axisBottom(this.el.xScale)
        // if the svg container is too small, we reduce the max number of ticks
        .ticks(Math.min(this.props.values.length, width < 300 ? 2 : 4))
        .tickFormat((d) => {
          // 2020-09-07: quickly fixed, we should definitely rewrite this
          // component using d3act
          if (!Number.isInteger(d)) {
            return undefined;
          }
          // eslint-disable-next-line
          const { x } = this.props.values[min([Math.abs(Math.floor(d)), this.props.values.length - 1])];
          return timeFormat('%d/%m/%y %H:%M')(new Date(x));
        }));
    this.el.xContainer.select('.domain').remove();
    splitTextTick(this.el.xContainer);
    styleAxis(this.el.xContainer, this.props.theme);

    this.el.yContainer
      .call(axisLeft(this.el.yScale));
    styleAxis(this.el.yContainer, this.props.theme);

    this.el.graphContainer
      .datum(this.props.values)
      .transition()
      .duration(400)
      .attr('d', this.el.line);

    if (this.props.displayDeviationValues) {
      this.el.areaContainer
        .datum(this.props.values)
        .transition()
        .duration(400)
        .attr('d', this.el.area);
    }
  }

  mousemove() {
    const mX = mouse(this.el.mouseContainer.node())[0];
    let i = this.el.bisector(
      this.props.values.map((d, j) => ({ x: j, y: d.y })),
      this.el.xScale.invert(mX),
      1,
    );
    const d0 = this.props.values[i - 1];
    const d1 = this.props.values[i];
    let d;
    if (d1 === undefined) {
      d = d0;
    } else if (d0 === undefined) {
      d = d1;
    } else {
      const lengthd0 = Math.abs(this.el.xScale(i - 1) - mX);
      const lengthd1 = Math.abs(this.el.xScale(i) - mX);
      d = lengthd0 > lengthd1 ? d1 : d0;
    }

    if (d === d0) {
      i -= 1;
    }

    if (d !== undefined) {
      this.updateFocusContainers(d, i);
    }
  }

  render() {
    return (
      <div
        ref={(node) => {
          this.node = node;
          return undefined;
        }}
      />
    );
  }
}

CurveStableX.propTypes = {
  // eslint-disable-next-line react/forbid-prop-types
  theme: PropTypes.object.isRequired,

  width: PropTypes.number,
  height: PropTypes.number,

  values: PropTypes.arrayOf(PropTypes.shape({
    x: PropTypes.number.isRequired,
    y: PropTypes.number.isRequired,
    deviation: PropTypes.number,
  })).isRequired,
  displayDeviationValues: PropTypes.bool,
  pdf: PropTypes.bool,
};

CurveStableX.defaultProps = {
  displayDeviationValues: false,
  width: 0,
  height: 480,
  pdf: false,
};

export default withTheme(CurveStableX);
