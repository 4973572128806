import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import Card from '@material-ui/core/Card';
import CardHeader from '@material-ui/core/CardHeader';
import CardContent from '@material-ui/core/CardContent';
import Grid from '@material-ui/core/Grid';
import { max } from 'd3-array';

import Translate from '../../components/display/translate';
import ColormapPressure from '../../components/insole/colormap-pressure';
import GraphPressureDistribution from '../../components/graph/pressure-distribution';
import RecordInfo from './record-info';

import usePressureScale from '../../utils/hooks/usePressureScale';

function StaticPressureDistribution(props) {
  const {
    records,
    insoles,
    recordsData,
    pdf,
  } = props;

  const maxPressuresLeft = useMemo(
    () => recordsData.map(i => ((i[0] || {}).maxPressures || {}).left || []),
    [recordsData.length],
  );

  const maxPressuresRight = useMemo(
    () => recordsData.map(i => ((i[0] || {}).maxPressures || {}).right || []),
    [recordsData.length],
  );

  const domain = useMemo(() => {
    const maxDomainLeft = max(maxPressuresLeft.map(pressure => max(pressure)));
    const maxDomainRight = max(maxPressuresRight.map(pressure => max(pressure)));
    return [0.13, Math.max(maxDomainLeft, maxDomainRight)];
  }, [maxPressuresLeft.length, maxPressuresRight.length]);

  const [scale, colorInterpolation] = usePressureScale(domain[0], domain[1]);

  return (
    <Card>
      <CardHeader
        title={<Translate>maximumPressureDistribution</Translate>}
        subheader={<Translate>maxStaticPressures</Translate>}
      />
      <CardContent>
        <Grid container direction="row" justify="space-around" alignItems="center" spacing={pdf ? 0 : 5}>
          { recordsData.map((data, index) => (
            <Grid item xs={pdf ? true : 12} sm={12} md={6} lg={4} key={records[index].key}>
              <RecordInfo record={records[index]} />
              <GraphPressureDistribution
                insoles={insoles[records[index].key]}
                cop={data[3]}
                processedRecord={data[1]}
                maxPressuresLeft={maxPressuresLeft[index]}
                maxPressuresRight={maxPressuresRight[index]}
                scale={scale}
                height={pdf ? 150 : undefined}
                pdf={pdf}
              />
              <ColormapPressure
                colorInterpolation={colorInterpolation}
                domain={domain}
                numberTicks={5}
                width={pdf ? 125 : undefined}
              />
            </Grid>
          ))}
        </Grid>
      </CardContent>
    </Card>
  );
}

StaticPressureDistribution.propTypes = {
  records: PropTypes.arrayOf(PropTypes.shape({
    key: PropTypes.string,
  })).isRequired,
  // eslint-disable-next-line react/forbid-prop-types
  insoles: PropTypes.object.isRequired,
  recordsData: PropTypes.arrayOf(PropTypes.arrayOf(PropTypes.any)).isRequired,
  pdf: PropTypes.bool,
};

StaticPressureDistribution.defaultProps = {
  pdf: false,
};

export default StaticPressureDistribution;
