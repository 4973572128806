import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import MetricGraphContainer from './utils/graph-container';
import BoxCompare from '../d3/box-compare';

import Metric from '../../utils/metrics/metric';
import { filterWithTimestamps, filterStridesWithoutDoubleSupport, getArrayXY } from '../../utils/strides';
import { statArray } from '../../utils/array';

function mapStateToProps(state, ownProps) {
  return ({
    unit: state.settings.unitMetrics[ownProps.metric.key] || ownProps.metric.unit,
    startTimestamp: state.timestampsRecordDisplay.startTimestamp,
    endTimestamp: state.timestampsRecordDisplay.endTimestamp,
  });
}

function BoxCompareMetric(props) {
  const {
    strides,
    unit,
    metric,
    startTimestamp,
    endTimestamp,
    strideTimestampFilter,
    pdf,
  } = props;

  // not really clean,
  // allow us to simplify the 'if isDoubleSupport' without having to call
  // getArrayXY two times
  let arrayXY = strides;

  if (metric.isDoubleSupport) {
    arrayXY = filterStridesWithoutDoubleSupport(strides);
  }

  arrayXY = getArrayXY(arrayXY, metric);

  if (strideTimestampFilter) {
    arrayXY = filterWithTimestamps(arrayXY, startTimestamp, endTimestamp);
  }

  const arrayLeft = arrayXY.filter(i => i.s === 'left').map(i => i.y);
  const arrayRight = arrayXY.filter(i => i.s === 'right').map(i => i.y);

  const meanLeft = metric.convert(statArray(arrayLeft, 'mean'), unit);
  const meanRight = metric.convert(statArray(arrayRight, 'mean'), unit);

  const deviationLeft = metric.convert(statArray(arrayLeft, 'deviation'), unit);
  const deviationRight = metric.convert(statArray(arrayRight, 'deviation'), unit);

  return (
    <MetricGraphContainer metric={metric}>
      <BoxCompare
        meanLeft={meanLeft}
        meanRight={meanRight}
        deviationLeft={deviationLeft}
        deviationRight={deviationRight}
        height={pdf ? 130 : undefined}
        pdf={pdf}
      />
    </MetricGraphContainer>
  );
}

BoxCompareMetric.propTypes = {
  strides: PropTypes.arrayOf(PropTypes.object).isRequired,
  metric: PropTypes.instanceOf(Metric).isRequired,
  unit: PropTypes.string.isRequired,
  startTimestamp: PropTypes.number.isRequired,
  endTimestamp: PropTypes.number.isRequired,
  strideTimestampFilter: PropTypes.bool,
  pdf: PropTypes.bool,
};

BoxCompareMetric.defaultProps = {
  strideTimestampFilter: false,
  pdf: false,
};

export default connect(mapStateToProps)(BoxCompareMetric);
