import React from 'react';
import Grid from '@material-ui/core/Grid';

import ListRecords from '../components/list/records/index';
import LoadMoreRecords from './components/load-more-records';

import { fetchDiosRecords } from '../firebase/firestore';
import { userId } from '../firebase/auth';
import { getStartOfDay, getEndOfDay } from '../utils/date';
import { filterDiosRecords } from '../utils/records';

class Records extends React.Component {
  constructor() {
    super();

    this.state = {
      records: [],
      inProgress: true,
      startAfter: 0,
      endBefore: 0,
    };

    this.handleNewRecords = this.handleNewRecords.bind(this);
    this.handleDateChange = this.handleDateChange.bind(this);

    this.updateRecords(true);
  }

  handleNewRecords(newRecords) {
    const { records } = this.state;
    this.setState({ records: [...records, ...filterDiosRecords(newRecords)] });
  }

  handleDateChange(date) {
    let startAfter = 0;
    let endBefore = 0;

    if (date !== null) {
      startAfter = getStartOfDay(date);
      endBefore = getEndOfDay(date);
    }

    this.updateRecords(false, startAfter, endBefore);

    this.setState({ startAfter, endBefore });
  }

  updateRecords(alreadyInProgress = false, startAfter, endBefore) {
    if (alreadyInProgress === false) {
      this.setState({ inProgress: true });
    }

    fetchDiosRecords(userId(), { startAfter, endBefore }).then(records => this.setState({
      records: filterDiosRecords(records),
      inProgress: false,
    }));
  }

  render() {
    return (
      <Grid container direction="column" justify="center" alignItems="stretch" spacing={2}>
        <Grid item>
          <ListRecords
            records={this.state.records}
            inProgress={this.state.inProgress}
            onDateSelection={this.handleDateChange}
          />
        </Grid>
        { this.state.inProgress === false && (
          <Grid item>
            <LoadMoreRecords
              records={this.state.records}
              newRecords={this.handleNewRecords}
              startAfter={this.state.startAfter}
              endBefore={this.state.endBefore}
            />
          </Grid>
        )}
      </Grid>
    );
  }
}

export default Records;
