import { useMemo } from 'react';

import { parsePressureCop } from '../metric';

function usePressureCop(showMean, processedPressures, leftStridePressures, rightStridePressures) {
  return useMemo(() => {
    let res;
    if (showMean) {
      res = [
        processedPressures.meanCop ? processedPressures.meanCop.left : [],
        processedPressures.meanCop ? processedPressures.meanCop.right : [],
      ];
    } else {
      res = [leftStridePressures.cop || [], rightStridePressures.cop || []];
    }

    return res.map(i => parsePressureCop(i || []));
  }, [showMean, leftStridePressures.key, rightStridePressures.key]);
}

export default usePressureCop;
