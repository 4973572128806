import {
  ADD_SNACKBAR_MESSAGE,
  UPDATE_SNACKBAR_MESSAGES,
} from '../actions/types';

const DEFAULT_SNACKBAR_MESSAGES = [];

function updateSnackbarMessages(state = DEFAULT_SNACKBAR_MESSAGES, action) {
  switch (action.type) {
    case UPDATE_SNACKBAR_MESSAGES:
      return action.messages;
    case ADD_SNACKBAR_MESSAGE:
      return [...state, {
        ...action.message,
        key: Date.now(),
      }];
    default:
      return state;
  }
}

export default updateSnackbarMessages;
