import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import {
  FormControl,
  InputLabel,
  Select,
  MenuItem,
} from '@material-ui/core';

import Translate from '../display/translate';

const styles = theme => ({
  formControl: {
    margin: theme.spacing(3),
    minWidth: 100,
  },
});

function getOptions(options, useEmptyOption, textNone) {
  if (useEmptyOption === false) {
    return options;
  }

  return [{ key: '', label: textNone }, ...options];
}

function SimpleSelect(props) {
  const { classes } = props;

  const options = getOptions(props.options, props.useEmptyOption, props.textNone);

  return (
    <FormControl fullWidth className={classes.formControl} style={props.style}>
      <InputLabel htmlFor={props.id}><Translate>{props.title}</Translate></InputLabel>
      <Select
        value={props.value}
        onChange={ev => props.onChange(ev)}
        inputProps={{ id: props.id, name: props.title }}
      >
        { options.map(option => (
          <MenuItem key={option.key} value={option.key}>
            { option.key === ''
              ? <em><Translate>{option.label}</Translate></em>
              : <Translate>{option.label}</Translate> }
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  );
}

SimpleSelect.propTypes = {
  // eslint-disable-next-line react/forbid-prop-types
  classes: PropTypes.object.isRequired,

  id: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
  value: PropTypes.string.isRequired,
  options: PropTypes.arrayOf(PropTypes.shape({
    key: PropTypes.string,
    label: PropTypes.string,
  })).isRequired,
  onChange: PropTypes.func.isRequired,
  useEmptyOption: PropTypes.bool,
  textNone: PropTypes.string,
  // eslint-disable-next-line react/forbid-prop-types
  style: PropTypes.object,
};

SimpleSelect.defaultProps = {
  useEmptyOption: false,
  textNone: 'none',
  style: {},
};

export default withStyles(styles)(SimpleSelect);
