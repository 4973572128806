import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import Checkboxes from '../../components/selection-controls/checkboxes';

import { updateGaitCycleMetrics } from '../../actions/index';
import { updateGaitCycleMetricsSettings } from '../../firebase/firestore';
import { getAllGaitCycleMetrics } from '../../utils/metric';

function mapStateToProps(state) {
  return ({
    selected: state.settings.gaitCycleMetricsSelected,
  });
}

function GaitCycleMetricsSelection({ selected, settingsUpdated, dispatch }) {
  function handleChange(key) {
    let newSelected;
    if (selected.indexOf(key) === -1) {
      // add the new element
      newSelected = [...selected, key];
    } else {
      // remove the element
      const eltIndex = selected.indexOf(key);
      newSelected = [
        ...selected.slice(0, eltIndex),
        ...selected.slice(eltIndex + 1),
      ];
    }

    dispatch(updateGaitCycleMetrics(newSelected));
    updateGaitCycleMetricsSettings(newSelected).then(() => settingsUpdated());
  }

  return (
    <Checkboxes
      title="gaitCycleMetrics"
      options={getAllGaitCycleMetrics().map(metric => ({
        key: metric.key,
        label: metric.title,
        checked: selected.indexOf(metric.key) > -1,
      }))}
      handleChange={handleChange}
    />
  );
}

GaitCycleMetricsSelection.propTypes = {
  selected: PropTypes.arrayOf(PropTypes.string).isRequired,
  settingsUpdated: PropTypes.func.isRequired,
  dispatch: PropTypes.func.isRequired,
};

export default connect(mapStateToProps)(GaitCycleMetricsSelection);
