import React from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles({
  tooltip: {
    position: 'absolute',
    left: 0,
    background: 'white',
    padding: '0.6em 1em',
    transition: 'all 0.2s ease-out',
    pointerEvents: 'none',
    boxShadow: '0px 4px 8px rgba(36, 46, 66, 0.25)',
    zIndex: 1201,
    marginBottom: '12px',
    '&:before': {
      content: '""',
      position: 'absolute',
      bottom: 0,
      left: '50%',
      width: '12px',
      height: '12px',
      background: 'white',
      transform: 'translate(-50%, 50%) rotate(45deg)',
      transformOrigin: 'center center',
    },
  },
});

function Tooltip(props) {
  const {
    opacity,
    transform,
    children,
  } = props;
  const classes = useStyles();

  return (
    <div
      className={classes.tooltip}
      style={{
        opacity,
        transform,
      }}
    >
      { children }
    </div>
  );
}

Tooltip.propTypes = {
  opacity: PropTypes.number.isRequired,
  transform: PropTypes.string.isRequired,
  children: PropTypes.element.isRequired,
};

export default Tooltip;
