import React from 'react';
import { Grid, Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';

import Translate from '../../display/translate';

const useStyles = makeStyles(() => ({
  line: {
    width: '12px',
    height: '4px',
    background: '#F2994A',
  },
  text: {
    fontSize: '13px',
    color: '#575C67',
  },
}));

function ScaleTrendLine() {
  const classes = useStyles();

  return (
    <Grid container direction="row" justify="center" alignItems="center" spacing={1}>
      <Grid item>
        <div className={classes.line}>&nbsp;</div>
      </Grid>
      <Grid item>
        <Typography className={classes.text}>
          <Translate>trendline</Translate>
        </Typography>
      </Grid>
    </Grid>
  );
}

export default ScaleTrendLine;
