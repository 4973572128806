import React from 'react';
import Grid from '@material-ui/core/Grid';

import FirebaseUIWrapper from './firebaseui-wrapper';

import { getPathImages } from '../../utils/assets';

function FirebaseUILogin() {
  return (
    <Grid container direction="column" justify="flex-start" alignItems="center" spacing={5}>
      <Grid item>
        <img src={`${getPathImages()}/evaluation-logo.png`} alt="FeetMe Logo" width={200} />
      </Grid>
      <Grid item>
        <FirebaseUIWrapper />
      </Grid>
    </Grid>
  );
}

export default FirebaseUILogin;
