import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import {
  Card,
  CardHeader,
  CardContent,
  Grid,
  Typography,
} from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';

import Translate from '../../components/display/translate';
import MetricGraphTrendStable from '../../components/metric/graph-trend-stable';
import ScaleTrendLine from '../../components/graph/utils/scale-trend-line';

import {
  Velocity,
  StrideLength,
  DoubleSupportPercentage,
  Distance,
} from '../../utils/metrics/index';
import { fetchProcessedRecord } from '../../firebase/firestore';

const useStyles = makeStyles(() => ({
  root: {
    overflow: 'inherit',
  },
}));

function EvolutionMetricsCard({ records, inProgress }) {
  if (inProgress === true || records.length <= 2) {
    return null;
  }

  const [processedRecords, setProcessedRecords] = useState([]);
  const classes = useStyles();

  useEffect(() => {
    async function asyncWrapper() {
      setProcessedRecords((await Promise.all(records
        .map(({ recordType, key }) => fetchProcessedRecord(key)
          .then(record => ({ key, recordType, ...record })))))
        .filter(record => record.startTime !== undefined)
        .reverse());
    }
    asyncWrapper();
  }, []);

  if (processedRecords.length <= 2) {
    return null;
  }

  const durationData = processedRecords.filter(record => record.recordType === '6min');

  return (
    <Card className={classes.root}>
      <CardHeader title={<Translate>evolutionMetrics</Translate>} />
      <CardContent>
        <Typography variant="body1" component="p">
          <Translate>metricEvolutionInfo</Translate>
        </Typography>
        <Grid container direction="row" justify="center" alignItems="center">
          <Grid item md={4} xs={12}>
            <MetricGraphTrendStable
              metric={Velocity}
              processedRecords={processedRecords}
              showRecordType
              color="#53D769"
            />
          </Grid>
          <Grid item md={4} xs={12}>
            <MetricGraphTrendStable
              metric={StrideLength}
              processedRecords={processedRecords}
              showRecordType
              color="#53D769"
            />
          </Grid>
          <Grid item md={4} xs={12}>
            <MetricGraphTrendStable
              metric={DoubleSupportPercentage}
              processedRecords={processedRecords}
              showRecordType
              color="#53D769"
            />
          </Grid>
        </Grid>
      </CardContent>
      { durationData.length > 0 && (
        <CardContent>
          <Typography variant="body1" component="p">
            <Translate>distanceEvolutionInfo</Translate>
          </Typography>
          <MetricGraphTrendStable
            metric={Distance}
            processedRecords={durationData}
            color="#2986FF"
          />
        </CardContent>
      )}
      <ScaleTrendLine />
    </Card>
  );
}

EvolutionMetricsCard.propTypes = {
  records: PropTypes.arrayOf(PropTypes.shape({
    recordType: PropTypes.string.isRequired,
    key: PropTypes.string.isRequired,
  })).isRequired,
  inProgress: PropTypes.bool.isRequired,
};

export default EvolutionMetricsCard;
