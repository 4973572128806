import React from 'react';
import PropTypes from 'prop-types';
import {
  Button,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  CircularProgress,
} from '@material-ui/core/';

import Translate from '../display/translate';

function DialogAreYouSure(props) {
  return (
    <Dialog
      open={props.isOpen}
      onClose={props.onNo}
      disableBackdropClick
      disableEscapeKeyDown
      aria-labelledby="are-you-sure-dialog-title"
    >
      { props.isWaiting
        ? (
          <DialogContent>
            <CircularProgress />
          </DialogContent>
        ) : (
          <div>
            <DialogTitle id="are-you-sure-dialog-title">
              { props.children || (<Translate>{props.text}</Translate>)}
            </DialogTitle>
            <DialogActions>
              <Button onClick={props.onNo}>
                <Translate>{props.textNo}</Translate>
              </Button>
              <Button onClick={props.onYes}>
                <Translate>{props.textYes}</Translate>
              </Button>
            </DialogActions>
          </div>
        ) }
    </Dialog>
  );
}

DialogAreYouSure.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  isWaiting: PropTypes.bool.isRequired,
  onYes: PropTypes.func.isRequired,
  onNo: PropTypes.func.isRequired,
  textNo: PropTypes.string.isRequired,
  textYes: PropTypes.string.isRequired,

  text: PropTypes.string,
  children: PropTypes.element,
};

DialogAreYouSure.defaultProps = {
  text: undefined,
  children: undefined,
};

export default DialogAreYouSure;
