import {
  CODE_MSCLEROSIS,
  CODE_PARKINSON,
  CODE_STROKE,
} from './codes';

import {
  SCALE_MSCLEROSIS,
  SCALE_EDSS,
  SCALE_HY,
  SCALE_UPDRS,
  SCALE_STROKE,
} from './scales';

const PATHOLOGIES = [
  { key: CODE_MSCLEROSIS, label: 'multipleSclerosis' },
  { key: CODE_PARKINSON, label: 'parkinsonsDisease' },
  { key: CODE_STROKE, label: 'stroke' },
];

const LINK_PATHO_SCALES = {
  [CODE_MSCLEROSIS]: {
    pathology: SCALE_MSCLEROSIS,
    clinical: SCALE_EDSS,
  },
  [CODE_PARKINSON]: {
    pathology: SCALE_HY,
    clinical: SCALE_UPDRS,
  },
  [CODE_STROKE]: {
    pathology: SCALE_STROKE,
  },
};

function getScale(type, code) {
  return LINK_PATHO_SCALES[code][type];
}

function getPathologyScale(code) {
  return getScale('pathology', code);
}

function getClinicalScale(code) {
  return getScale('clinical', code);
}

function getPathologyLabel(code) {
  return PATHOLOGIES.find(({ key }) => code === key).label;
}

export {
  PATHOLOGIES,

  getScale,
  getPathologyScale,
  getClinicalScale,
  getPathologyLabel,
};
