import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import {
  Button,
  Typography,
} from '@material-ui/core';

import PageHeader from '../components/display/page-header';
import PageTitle from '../components/display/page-title';
import PageSubtitle from '../components/display/page-subtitle';
import Translate from '../components/display/translate';
import DialogAreYouSure from '../components/dialog/are-you-sure';
import ListRecordsPorficWrapper from '../components/list/records/porfic-wrapper';
import ListPatients from '../components/list/patients/index';

import { displayName } from '../utils/patient';
import { updateRecordsNotLinked } from '../actions/index';
import { fetchRecord } from '../firebase/firestore';
import { listRecordsNotLinked, updateRecordLinkPatient } from '../firebase/functions';
import { filterDiosRecords, sortRecords } from '../utils/records';

class RecordsNotLinked extends React.Component {
  constructor() {
    super();

    this.state = {
      records: [],
      patient: undefined,
      inProgress: true,
      selectedRecords: [],

      isDialogOpen: false,
      isDialogWaiting: false,
    };

    this.linkRecords = this.linkRecords.bind(this);
  }

  componentDidMount() {
    listRecordsNotLinked({ recordType: 'dios' })
      .then((res) => {
        this.props.dispatch(updateRecordsNotLinked(res.data));
        return Promise.all(res.data.map(recordId => fetchRecord(recordId).catch(() => undefined)));
      }).then(records => filterDiosRecords(records, true))
      .then(records => sortRecords(records))
      .then(records => this.setState({ records, inProgress: false }));
  }

  linkRecords() {
    this.setState({ isDialogWaiting: true });
    const patientId = this.state.patient.id;
    const recordsId = this.state.selectedRecords.map(i => i.key);
    updateRecordLinkPatient({ patientId, recordsId })
      .then(() => {
        this.setState(prevState => ({
          records: prevState.records
            .filter(record => recordsId.indexOf(record.key) === -1),
          selectedRecords: [],
          patient: undefined,
          isDialogOpen: false,
          isDialogWaiting: false,
        }));
        window.scrollTo(0, 0);
      })
      .catch(() => this.setState({
        selectedRecords: undefined,
        isDialogOpen: false,
        isDialogWaiting: false,
      }));
  }

  render() {
    return (
      <div>
        <PageHeader>
          <PageTitle><Translate>recordsNotLinked</Translate></PageTitle>
          { (this.state.selectedRecords.length > 0) && (
            <PageSubtitle>
              <Translate input={{ NB: this.state.selectedRecords.length }}>
                linkXRecordToPatient
              </Translate>
            </PageSubtitle>
          )}
        </PageHeader>
        <DialogAreYouSure
          isOpen={this.state.isDialogOpen}
          isWaiting={this.state.isDialogWaiting}
          onYes={() => this.linkRecords()}
          onNo={() => this.setState({ isDialogOpen: false, patient: undefined })}
          textNo="no"
          textYes="yes"
        >
          <Typography variant="subtitle2">
            <Translate
              input={{
                NB: this.state.selectedRecords.length,
                VAR: this.state.patient ? displayName(this.state.patient) : '',
              }}
            >
              areYousureYouWantToLinkXrecordstoPatientY
            </Translate>
          </Typography>
        </DialogAreYouSure>
        { (this.state.selectedRecords.length === 0)
          ? (
            <ListRecordsPorficWrapper
              records={this.state.records}
              inProgress={this.state.inProgress}
              onLinkRecord={selectedRecords => this.setState({ selectedRecords })}
            />
          ) : null }
        { (this.state.selectedRecords.length > 0) && (
          <ListPatients
            handleRowClick={patient => this.setState({ patient, isDialogOpen: true })}
            titlebarRightButton={(
              <Button variant="outlined" onClick={() => this.setState({ selectedRecords: [] })}>
                <Translate>cancel</Translate>
              </Button>
            )}
          />
        )}
      </div>
    );
  }
}

RecordsNotLinked.propTypes = {
  dispatch: PropTypes.func.isRequired,
};

export default connect()(RecordsNotLinked);
