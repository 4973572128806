import React from 'react';
import PropTypes from 'prop-types';
import { Button, CircularProgress } from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';

const styles = () => ({
  wrapper: {
    position: 'relative',
  },
  buttonProgress: {
    position: 'absolute',
    top: '50%',
    left: '50%',
    marginTop: -12,
    marginLeft: -12,
  },
});

function ButtonProgress(props) {
  const {
    classes,
    variant,
    inProgress,
    color,
    onClick,
    children,
    ...rest
  } = props;

  return (
    <div className={classes.wrapper}>
      <Button
        variant={variant}
        disabled={inProgress}
        color={color}
        onClick={onClick}
        {...rest}
      >
        { children }
      </Button>
      { inProgress && <CircularProgress size={24} className={classes.buttonProgress} /> }
    </div>
  );
}

ButtonProgress.propTypes = {
  // eslint-disable-next-line react/forbid-prop-types
  classes: PropTypes.object.isRequired,

  inProgress: PropTypes.bool.isRequired,
  children: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.element,
    PropTypes.arrayOf(PropTypes.element),
  ]).isRequired,
  onClick: PropTypes.func,
  variant: PropTypes.string,
  color: PropTypes.string,
};

ButtonProgress.defaultProps = {
  variant: 'text',
  color: 'default',
  onClick: undefined,
};

export default withStyles(styles)(ButtonProgress);
