import React from 'react';
import PropTypes from 'prop-types';
import Typography from '@material-ui/core/Typography';
import InfoIcon from '@material-ui/icons/Info';
import { makeStyles } from '@material-ui/core/styles';

import Translate from '../../components/display/translate';

const useStyles = makeStyles({
  root: {
    backgroundColor: '#F0F4FF',
    color: '#005EDA',
    borderRadius: 8,
    padding: 14,
    display: 'flex',
    alignItems: 'center',
    flexWrap: 'nowrap',
  },
  typography: {
    marginLeft: 10,
  },
});

function DataNotAvailable({ isReplay }) {
  const classes = useStyles();
  return (
    <div className={classes.root}>
      <InfoIcon />
      <Typography className={classes.typography}>
        <Translate>{ isReplay ? 'replayNotAvailable' : 'dataNotAvailable' }</Translate>
      </Typography>
    </div>
  );
}

DataNotAvailable.propTypes = {
  isReplay: PropTypes.bool,
};

DataNotAvailable.defaultProps = {
  isReplay: false,
};

export default DataNotAvailable;
