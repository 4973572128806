import React from 'react';
import PropTypes from 'prop-types';
import {
  accessorPropType,
} from '@feetme/d3act';

import { findTrendInfo } from '../../../utils/d3';

function Trend(props) {
  const {
    data,
    xAccessor,
    xScale,
    yScale,
  } = props;

  if (data.length < 2) {
    return null;
  }

  const [slope, yIntercept] = findTrendInfo(data);

  const x1 = xAccessor(data[0], 0);
  const x2 = xAccessor(data[data.length - 1], data.length - 1);

  const trend = ({
    x1,
    x2,
    y1: (slope * x1) + yIntercept,
    y2: (slope * x2) + yIntercept,
  });

  return (
    <line
      x1={xScale(trend.x1)}
      // we could do this in a prop
      x2={xScale(trend.x2) + (xScale.bandwidth ? xScale.bandwidth() : 0)}
      y1={yScale(trend.y1)}
      y2={yScale(trend.y2)}
      strokeWidth={2}
      stroke="#F2994A"
      style={{
        pointerEvents: 'none',
      }}
    />
  );
}

Trend.propTypes = {
  xAccessor: accessorPropType.isRequired,
  xScale: PropTypes.func.isRequired,
  yScale: PropTypes.func.isRequired,
  data: PropTypes.arrayOf(PropTypes.object).isRequired,
};

export default Trend;
