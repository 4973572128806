import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { KeyboardDatePicker } from '@material-ui/pickers';

import Translate from '../display/translate';

import { getDateFormat } from '../../utils/date';

function mapStateToProps(state) {
  return ({
    locale: state.settings.locale,
  });
}

function DatePickerWrapper(props) {
  return (
    <KeyboardDatePicker
      clearable
      required={props.required}
      id={props.id}
      label={<Translate>{props.label}</Translate>}
      value={props.value}
      disabled={props.disabled}
      format={getDateFormat(props.locale)}
      onChange={date => props.onDateChange(date)}
      onAccept={date => props.onDateAccept(date)}
      animateYearScrolling={false}
      clearLabel={<Translate>clear</Translate>}
      cancelLabel={<Translate>cancel</Translate>}
    />
  );
}

DatePickerWrapper.propTypes = {
  locale: PropTypes.string.isRequired,

  id: PropTypes.string.isRequired,
  label: PropTypes.string.isRequired,
  onDateChange: PropTypes.func.isRequired,
  onDateAccept: PropTypes.func,

  required: PropTypes.bool,
  disabled: PropTypes.bool,
  value: PropTypes.instanceOf(Date),
};

DatePickerWrapper.defaultProps = {
  required: false,
  disabled: false,
  value: null,
  onDateAccept: x => x,
};

export default connect(mapStateToProps)(DatePickerWrapper);
