import React, {
  useEffect,
  useState,
  useMemo,
  useRef,
} from 'react';
import PropTypes from 'prop-types';
import { useDispatch } from 'react-redux';

import ProgressWait from '../components/display/progress-wait';
import RecordMetric from './components/metric';
import RecordDynamic from './components/dynamic';
import RecordStatic from './components/static';

import useWatchRecord from '../utils/hooks/useWatchRecord';
import usePatient from '../utils/hooks/usePatient';
import useWalkingAids from '../utils/hooks/useWalkingAids';
import useInterpolationState from '../utils/hooks/useInterpolationState';
import useDisableFallingRisk from '../utils/hooks/useDisableFallingRisk';
import { isDynamic, isStatic } from '../utils/record';
import { addSnackbarMessage } from '../actions/index';

function Record({ match }) {
  const { recordId, patientId } = match.params;
  const [record, isLoadingRecord] = useWatchRecord(recordId);
  const [patient, isLoadingPatient] = usePatient(patientId, true);
  const [disableFallingRisk] = useDisableFallingRisk(patient, patientId, isLoadingPatient);
  const [walkingAids] = useWalkingAids(recordId);
  const [walkingSpeed, setWalkingSpeed] = useState(undefined);
  const [isInterpolationReady, isInterpolationLoading] = useInterpolationState(record);
  const initDateRef = useRef(Date.now());
  const dispatch = useDispatch();

  useMemo(() => {
    if (isLoadingRecord === false) {
      if (record.recordParameters && record.recordParameters.walkingSpeed) {
        setWalkingSpeed(record.recordParameters.walkingSpeed);
      }
    }
  }, [isLoadingRecord]);

  useEffect(() => {
    // we skip the first updates
    // otherwise we would display snackbar on each record load
    if (Date.now() - initDateRef.current < 1000) {
      return;
    }

    if (isInterpolationReady && !isInterpolationLoading) {
      dispatch(addSnackbarMessage({ type: 'interpolation-success' }));
    } else if (!isInterpolationReady && !isInterpolationLoading) {
      dispatch(addSnackbarMessage({ type: 'interpolation-failed' }));
    }
  }, [isInterpolationReady, isInterpolationLoading]);

  if (isLoadingRecord === true) {
    return (<ProgressWait />);
  }

  if (isDynamic(record)) {
    return (
      <RecordDynamic
        record={record}
        patientFetchInProgress={isLoadingPatient}
        disableFallingRisk={disableFallingRisk}
        walkingSpeed={walkingSpeed}
        walkingAids={walkingAids}
        isInterpolationReady={isInterpolationReady}
        isInterpolationLoading={isInterpolationLoading}
      />
    );
  }

  if (isStatic(record)) {
    return (
      <RecordStatic
        record={record}
        walkingSpeed={walkingSpeed}
        walkingAids={walkingAids}
        isInterpolationReady={isInterpolationReady}
        isInterpolationLoading={isInterpolationLoading}
      />
    );
  }

  return (
    <RecordMetric
      record={record}
      patientFetchInProgress={isLoadingPatient}
      disableFallingRisk={disableFallingRisk}
      walkingSpeed={walkingSpeed}
      walkingAids={walkingAids}
    />
  );
}

Record.propTypes = {
  match: PropTypes.shape({
    params: PropTypes.shape({
      recordId: PropTypes.string.isRequired,
      patientId: PropTypes.string,
    }),
  }).isRequired,
};

export default Record;
