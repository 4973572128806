import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import Grid from '@material-ui/core/Grid';
import { makeStyles } from '@material-ui/core/styles';

import InsolePressure from '../../components/insole/insole-pressure';
import LoadPercentage from './load-percentage';

import { getLeft, getRight } from '../../utils/insoles';

const useStyles = makeStyles({
  root: {
    display: 'flex',
    flexWrap: 'nowrap',
    justifyContent: 'center',
    gap: ({ pdf }) => (pdf ? '5px' : '30px'),
  },
});

function InsolesStaticPressures(props) {
  const {
    leftForeFootLoad,
    rightForeFootLoad,
    leftBackFootLoad,
    rightBackFootLoad,
    insoles,
    leftCop,
    rightCop,
    leftPressures,
    rightPressures,
    scale,
    height,
    pdf,
    ...otherProps
  } = props;

  const classes = useStyles(props);
  const leftInsole = useMemo(() => getLeft(insoles), [insoles.length]);
  const rightInsole = useMemo(() => getRight(insoles), [insoles.length]);

  return (
    <div className={classes.root}>
      <div>
        <Grid container direction="column" justify="flex-start" alignItems="center">
          <Grid item>
            <LoadPercentage value={leftForeFootLoad} />
          </Grid>
          <Grid item>
            <InsolePressure
              id="static-pressure-left"
              cop={leftCop}
              insole={leftInsole}
              pressure={leftPressures}
              scale={scale}
              isStatic
              height={height}
              {...otherProps}
            />
          </Grid>
          <Grid item>
            <LoadPercentage value={leftBackFootLoad} />
          </Grid>
        </Grid>
      </div>
      <div>
        <Grid container direction="column" justify="flex-start" alignItems="center">
          <Grid item>
            <LoadPercentage value={rightForeFootLoad} />
          </Grid>
          <Grid item>
            <InsolePressure
              id="static-pressure-right"
              cop={rightCop}
              insole={rightInsole}
              pressure={rightPressures}
              scale={scale}
              isStatic
              height={height}
              {...otherProps}
            />
          </Grid>
          <Grid item>
            <LoadPercentage value={rightBackFootLoad} />
          </Grid>
        </Grid>
      </div>
    </div>
  );
}

InsolesStaticPressures.propTypes = {
  leftForeFootLoad: PropTypes.number,
  rightForeFootLoad: PropTypes.number,
  leftBackFootLoad: PropTypes.number,
  rightBackFootLoad: PropTypes.number,
  insoles: PropTypes.arrayOf(PropTypes.object).isRequired,
  leftCop: PropTypes.arrayOf(PropTypes.arrayOf(PropTypes.number)).isRequired,
  rightCop: PropTypes.arrayOf(PropTypes.arrayOf(PropTypes.number)).isRequired,
  leftPressures: PropTypes.arrayOf(PropTypes.number),
  rightPressures: PropTypes.arrayOf(PropTypes.number),
  scale: PropTypes.func.isRequired,
  height: PropTypes.number,
  pdf: PropTypes.bool,
};

InsolesStaticPressures.defaultProps = {
  leftForeFootLoad: undefined,
  rightForeFootLoad: undefined,
  leftBackFootLoad: undefined,
  rightBackFootLoad: undefined,
  leftPressures: [],
  rightPressures: [],
  height: 350,
  pdf: false,
};

export default InsolesStaticPressures;
