import React from 'react';
import PropTypes from 'prop-types';
import { Redirect } from 'react-router-dom';
import { connect } from 'react-redux';
import {
  Dialog,
  DialogActions,
  DialogTitle,
  Button,
} from '@material-ui/core';
import * as Sentry from '@sentry/browser';

import Translate from '../../components/display/translate';

import { signOut } from '../../firebase/auth';
import { flush } from '../../actions/index';
import { start } from '../../utils/l10n';

class SignOut extends React.Component {
  constructor() {
    super();

    this.state = {
      open: false,
      userAsSignedOut: false,
    };

    this.handleSignOut = this.handleSignOut.bind(this);
    this.handleCloseDialog = this.handleCloseDialog.bind(this);
    this.handleOpenDialog = this.handleOpenDialog.bind(this);
  }

  handleOpenDialog() {
    this.setState({ open: true });
  }

  handleCloseDialog() {
    this.setState({ open: false });
  }

  handleSignOut() {
    signOut()
      .then(() => {
        Sentry.configureScope(scope => scope.clear());
        this.props.dispatch(flush());
        // set the locale to the default browser
        start(this.props.dispatch);
        this.setState({ userAsSignedOut: true });
      });
  }

  // TODO we should re-use the AreYouSure dialog
  render() {
    const res = this.state.userAsSignedOut ? (
      <Redirect to="/login" />
    ) : (
      <div>
        <Button variant="contained" onClick={this.handleOpenDialog}>
          <Translate>signOut</Translate>
        </Button>
        <Dialog
          open={this.state.open}
          onClose={this.handleCloseDialog}
          aria-labelledby="dialog-sign-out-title"
        >
          <DialogTitle id="dialog-sign-out-title">
            <Translate>confirmSignOut</Translate>
          </DialogTitle>
          <DialogActions>
            <Button onClick={this.handleCloseDialog} color="primary">
              <Translate>No</Translate>
            </Button>
            <Button onClick={this.handleSignOut} color="primary">
              <Translate>Yes</Translate>
            </Button>
          </DialogActions>
        </Dialog>
      </div>
    );

    return res;
  }
}

SignOut.propTypes = {
  dispatch: PropTypes.func.isRequired,
};

export default connect()(SignOut);
