let lastId = 0;

function useUniqueId(prefix = '') {
  lastId += 1;
  return [prefix, lastId].join('-');
}

export {
  // eslint-disable-next-line import/prefer-default-export
  useUniqueId,
};
