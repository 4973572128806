import { translateNoDL } from './i18n';

function formatDate(timestamp, locale = undefined, options = undefined) {
  return new Date(timestamp).toLocaleDateString(locale, options);
}

function formatTime(timestamp, locale = undefined, options = undefined) {
  return new Date(timestamp).toLocaleTimeString(locale, options);
}

function formatFullDate(timestamp, locale = undefined, options = undefined) {
  return new Date(timestamp).toLocaleString(locale, options);
}

function formatDuration(durationMS, hideHours = false) {
  const date = new Date(0);
  date.setMilliseconds(durationMS);

  let res = '';
  if (!hideHours) {
    const hours = Math.floor(durationMS / (60 * 60 * 1000)).toString().padStart(2, '0');
    res = `${hours}:`;
  }

  const minutes = date.getMinutes().toString().padStart(2, '0');
  const seconds = date.getSeconds().toString().padStart(2, '0');

  return `${res}${minutes}:${seconds}`;
}

function formatNumber(number, locale = undefined, options = undefined) {
  return new Intl.NumberFormat(locale, options).format(number);
}

function displayWalkingAids(walkingAids, locale) {
  return walkingAids.map((walkingAid) => {
    let res = translateNoDL(walkingAid.type, locale);
    if (walkingAid.side !== 'not_applied') {
      res += ` (${translateNoDL(`${walkingAid.side}Side`, locale)})`;
    }
    return res;
  }).join(' / ');
}

export {
  formatDate,
  formatFullDate,
  formatTime,
  formatDuration,
  formatNumber,
  displayWalkingAids,
};
