import React from 'react';
import { curveMonotoneX } from 'd3-shape';
import { Path } from '@feetme/d3act';

function CustomPath(props) {
  return (
    <Path
      strokeWidth={2}
      interpolation={curveMonotoneX}
      {...props}
    />
  );
}

export default CustomPath;
