import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import { ListItem, ListItemText } from '@material-ui/core';

import Translate from '../../components/display/translate';

function RecordUploadedLink(props) {
  if (props.isSuccess) {
    let to;

    if (props.patientId !== undefined) {
      to = `/patient/${props.patientId}/record/${props.documentId}`;
    } else {
      to = `/record/${props.documentId}`;
    }

    return (
      <ListItem button component={Link} to={to}>
        <ListItemText primary={props.filename} />
      </ListItem>
    );
  }

  return (
    <ListItem>
      <ListItemText
        primary={props.filename}
        secondary={<Translate>{props.errorType || 'issuePreventedUpload'}</Translate>}
      />
    </ListItem>
  );
}

RecordUploadedLink.propTypes = {
  filename: PropTypes.string.isRequired,
  documentId: PropTypes.string.isRequired,
  isSuccess: PropTypes.bool.isRequired,
  errorType: PropTypes.string,
  patientId: PropTypes.string,
};

RecordUploadedLink.defaultProps = {
  patientId: undefined,
};

RecordUploadedLink.defaultProps = {
  errorType: '',
};

export default RecordUploadedLink;
