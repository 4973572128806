import React, { useState, useMemo } from 'react';
import PropTypes from 'prop-types';
import {
  Grid,
  Card,
  CardHeader,
  CardContent,
  Typography,
} from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown';
import { timeMonday } from 'd3-time';
import { subDays } from 'date-fns';

import Translate from '../../components/display/translate';
import SimpleMenu from '../../components/selection-controls/simple-menu';
import TimelineActivityExo from '../../components/graph/timeline-activity-exo';
import ScaleTrendLine from '../../components/graph/utils/scale-trend-line';

import { isSameDate } from '../../utils/date';
import { EXO_RECORD_TYPES } from '../../utils/record';

const OPTIONS = [{
  key: 'day',
  label: 'progressExercises',
}, {
  key: 'week',
  label: 'weeklyActivity',
}];

const useStyles = makeStyles(() => ({
  root: {
    overflow: 'inherit',
  },
}));

function getOneMonth(records) {
  const today = new Date();
  const lowerDomain = subDays(today, 30);
  return records.filter(({ stopTime }) => stopTime > lowerDomain);
}

function groupByWeeks(records) {
  let tmpRecords = records;
  const today = new Date();
  const lowerDomain = subDays(today, 30);
  const every = timeMonday.every(1).range(lowerDomain, today).reverse();

  return every.map((d) => {
    const res = {
      x: d,
      // duration
      y: 0,
      number: 0,
      meanSuccess: 0,
    };

    let startIndex = -1;

    for (let i = 0; i < tmpRecords.length; i += 1) {
      if (isSameDate(new Date(tmpRecords[i].stopTime), d, 'week')) {
        res.number += 1;
        res.y += (tmpRecords[i].duration || (tmpRecords[i].stopTime - tmpRecords[i].startTime));
        res.meanSuccess += tmpRecords[i].successPercentage;
        startIndex = i;
      } else {
        break;
      }
    }

    if (startIndex < tmpRecords.length) {
      tmpRecords = tmpRecords.slice(startIndex + 1);
    }

    // ms to min
    res.y = Math.round(res.y / (60 * 1000));
    if (res.number > 0) {
      // round with a precision of one
      res.meanSuccess = Math.round((res.meanSuccess / res.number) * 10) / 10;
    }

    return res;
  }).reverse();
}

function PatientExos({ records }) {
  const [displayMode, setDisplayMode] = useState('week');
  const oneMonthRecords = useMemo(() => getOneMonth(records), [records]);

  const classes = useStyles();

  return (
    <Card className={classes.root}>
      <CardHeader
        title={<Translate>rehabilitationExercices</Translate>}
        action={(
          <SimpleMenu
            id="display-mode"
            value={displayMode}
            options={OPTIONS}
            onSelect={display => setDisplayMode(display)}
            endIcon={<KeyboardArrowDownIcon />}
          />
        )}
      />
      <CardContent>
        <Grid container direction="row" justify="center" alignItems="center" spacing={4}>
          { EXO_RECORD_TYPES.map(({ key, label }) => {
            const filteredRecords = oneMonthRecords.filter(({ recordType }) => recordType === key);
            return (
              <Grid item md={3} sm={6} xs={12} key={key}>
                <Typography align="center" variant="subtitle1">
                  <Translate>{ label }</Translate>
                </Typography>
                <Typography align="center" variant="caption" component="p">
                  { displayMode === 'day' ? (
                    <Translate>percentageSuccess</Translate>
                  ) : (
                    <Translate>activity</Translate>
                  )}
                </Typography>
                <TimelineActivityExo
                  dataDays={filteredRecords.map((i) => {
                    const res = {
                      x: i.stopTime,
                      y: i.successPercentage,
                      duration:
                        Math.round((i.duration || (i.stopTime - i.startTime)) / (60 * 1000)),
                    };

                    if (key === 'sittostand') {
                      res.repetitions = i.exoData.repetitions;
                      res.repetitionsPlanned = i.exoData.repetitionsPlanned;
                      res.sitToStandPerf = i.exoData.sitToStandPerf;
                      res.sitToStandMean = i.exoData.repetitions !== 0
                        ? Math.round(i.exoData.sitToStand / i.exoData.repetitions) : 0;
                      res.sitToStand = i.exoData.sitToStand;
                      // ms to minutes
                      res.duration = Math.round((i.duration / (60 * 1000)) * 10) / 10;
                      res.durationPlanned = i.durationPlanned / (60 * 1000);
                    }

                    return res;
                  }).reverse()}
                  dataWeeks={groupByWeeks(filteredRecords)}
                  displayMode={displayMode}
                  recordType={key}
                />
              </Grid>
            );
          })}
        </Grid>
        <ScaleTrendLine />
      </CardContent>
    </Card>
  );
}

PatientExos.propTypes = {
  records: PropTypes.arrayOf(PropTypes.object).isRequired,
};

export default PatientExos;
