import React from 'react';
import PropTypes from 'prop-types';
import { extent } from 'd3-array';
import { InsoleView } from '@feetme/insole-view-react';

import { getPathInsoles } from '../../utils/assets';
import { cleanHardwareVersion, getSensorSize } from '../../utils/insoles';
import { COLORMAP_GRADIENT } from '../../utils/colors';

function transformCOP(cop, imuPosition, height, minOutline, hardware) {
  const res = [];

  const yBlock = (height / 16);
  const yPadding = yBlock / 2;

  let mapClean;
  // The display of COP seems to have changed in hardware version 4.0.0
  // It might only be linked to version 4.0.0 and small sensors
  if (hardware === '4.0.0') {
    mapClean = (i => (i === undefined ? undefined : -i));
  } else {
    mapClean = (i => i);
  }

  cop.map(mapClean).forEach((v, i) => {
    if (v !== 0 && v !== undefined) {
      res.push([imuPosition[0] - v, minOutline + yPadding + (yBlock * i)]);
    }
  });

  return res;
}

class InsoleViewWrapper extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      insole: undefined,
    };

    this.hardware = cleanHardwareVersion(props.version);

    if (props.version !== undefined) {
      // TODO cache data
      fetch(`${getPathInsoles()}/${this.hardware}/sensors/${getSensorSize(this.hardware, props.size)}.json`)
        .then(response => response.json())
        .then((insole) => {
          const extentY = extent(insole.outline, d => d[1]);
          this.setState({ insole, outlineHeight: extentY[1] - extentY[0], minOutline: extentY[0] });
        });
    }
  }

  render() {
    if (this.state.insole === undefined || this.props.version === undefined) {
      return (<div />);
    }

    return (
      <InsoleView
        sensorOutline={this.state.insole.outline}
        cells={this.state.insole.cells}
        side={this.props.side}
        cop={transformCOP(
          this.props.cop,
          this.state.insole.imu[this.props.side],
          this.state.outlineHeight,
          this.state.minOutline,
          this.hardware,
        )}
        copStdMin={transformCOP(
          this.props.copStdMin,
          this.state.insole.imu[this.props.side],
          this.state.outlineHeight,
          this.state.minOutline,
          this.hardware,
        )}
        copStdMax={transformCOP(
          this.props.copStdMax,
          this.state.insole.imu[this.props.side],
          this.state.outlineHeight,
          this.state.minOutline,
          this.hardware,
        )}
        copConfig={{
          opacityArea: '0.3',
        }}
        disableCOP={this.hardware === '4.0.0'}
        displayCopStd={this.props.displayCopStd}
        heatmapValues={this.props.capa}
        height={this.props.height}
        gradient={COLORMAP_GRADIENT}
        arcRadius={this.props.pdf ? 10 : undefined}
      />
    );
  }
}

InsoleViewWrapper.propTypes = {
  version: PropTypes.string,
  size: PropTypes.number.isRequired,
  side: PropTypes.oneOf(['left', 'right']).isRequired,
  capa: PropTypes.arrayOf(PropTypes.number),
  cop: PropTypes.arrayOf(PropTypes.number),
  copStdMin: PropTypes.arrayOf(PropTypes.number),
  copStdMax: PropTypes.arrayOf(PropTypes.number),
  displayCopStd: PropTypes.bool.isRequired,
  height: PropTypes.number,
  pdf: PropTypes.bool,
};

InsoleViewWrapper.defaultProps = {
  version: undefined,
  capa: [],
  cop: [],
  copStdMin: [],
  copStdMax: [],
  height: 500,
  pdf: false,
};

export default InsoleViewWrapper;
