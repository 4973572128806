function getLeft(insoles) {
  return insoles.find(insole => insole.side === 'left');
}

function getRight(insoles) {
  return insoles.find(insole => insole.side === 'right');
}

// different hardware version can share the same version of the sensors
// we're merging them here.
function cleanHardwareVersion(hardware) {
  if (hardware === '3.0.0') {
    return '3.0.0';
  }

  return '4.0.0';
}

function getSensorSize(hardware, insoleSize) {
  if (hardware === '3.0.0') {
    if (insoleSize < 40) {
      return '37-39';
    }

    if (insoleSize < 43) {
      return '40-42';
    }

    return '43-45';
  }

  if (hardware === '4.0.0') {
    if (insoleSize < 38) {
      return '35-37';
    }

    if (insoleSize < 41) {
      return '38-40';
    }

    if (insoleSize < 44) {
      return '41-43';
    }

    return '44-46';
  }

  return '37-39';
}

export {
  getLeft,
  getRight,

  cleanHardwareVersion,
  getSensorSize,
};
