import Metric from './metric';
import Velocity from './velocity';

export default new Metric({
  title: 'distance',
  unit: 'm',
  key: 'distance',
  keyCompute: Velocity.key,
  compute: (arrayVelocity) => {
    // time of the first stride of the current interval
    let startTime;
    let numberStrides = 0;
    // sum of the velocity in the current interval
    let sumVelocity = 0;
    // currently "validated" distance
    let fullDistance = 0;
    // time of the second to last stride of the current interval
    let previousStrideTime = 0;
    let distanceIntervalWithoutCurrentStride = 0;

    arrayVelocity.forEach((i, index) => {
      if (numberStrides === 0) {
        startTime = i.x;
        previousStrideTime = i.x;
      }

      // time of the last stride of the current interval
      const endTime = i.x;

      // From android-product-dios
      // Before trying to compute the distance we check that two consecutives strides don't
      // have a difference of more than 10 seconds
      // If they do, we keep the previous distance computed (distance without the current stride)
      // and reset
      if ((endTime - previousStrideTime) > (10 * 1000)) {
        // Keep interval (without current stride)
        fullDistance += distanceIntervalWithoutCurrentStride;
        // If there is more than 10 seconds, the current stride might not even be correct
        // no need to use it the distance. We can reset everything.
        startTime = 0;
        sumVelocity = 0;
        numberStrides = 0;
        previousStrideTime = 0;
        distanceIntervalWithoutCurrentStride = 0;
      } else {
        previousStrideTime = endTime;
        sumVelocity += i.y;
        numberStrides += 1;

        // Distance in the current interval
        // Math.floor to imitate the result on Android application
        const distanceCurrentInterval = (sumVelocity / numberStrides)
          * ((endTime - startTime) / 1000);
        distanceIntervalWithoutCurrentStride = distanceCurrentInterval;

        if (distanceCurrentInterval >= 5) {
          // we keep the current interval
          fullDistance += distanceCurrentInterval;

          // instead of reseting all variables, we are re-using the current stride
          // as the first stride
          // by doing this we are not skipping a stride
          // ie:
          //
          // '*': a stride
          //
          // start   end
          // v       v
          // *---*---*---*---*--*
          //             ^ this stride will be the next stride but we are missing the
          //               distance with the previous end stride
          startTime = endTime;
          sumVelocity = i.y;
          numberStrides = 1;
          previousStrideTime = startTime;
          distanceIntervalWithoutCurrentStride = 0;
        } else if (index === arrayVelocity.length - 1 && numberStrides > 1) {
          // corner case, if the current interval is not finsihed/valided (ie >= 5)
          // we want to add the remaining distance to the full distance
          fullDistance += distanceCurrentInterval;
        }
      }
    });

    return fullDistance;
  },
});
