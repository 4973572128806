import { useEffect, useState } from 'react';

import { watchRecord } from '../../firebase/firestore';

function useWatchRecord(recordId) {
  const [record, setRecord] = useState({});
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    const unsub = watchRecord(recordId, (newRecord) => {
      setRecord(newRecord);
      if (isLoading === true) {
        // we only track the first load
        setIsLoading(false);
      }
    });

    return () => unsub();
  }, []);

  return [record, isLoading];
}

export default useWatchRecord;
