import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { scaleLinear, scaleBand } from 'd3-scale';
import { max } from 'd3-array';
import {
  Grid,
} from '@material-ui/core';
import {
  Graph,
  Gradient,
  Rects,
  useGraphDimensions,
} from '@feetme/d3act';

import Metric from '../../utils/metrics/metric';
import Axis from './utils/axis';
import Trend from './utils/trend';
import Tooltip from './utils/tooltip';
import TooltipInfo from './utils/tooltip-info';

import { formatNumber, formatDate } from '../../utils/display';

function mapStateToProps(state) {
  return ({
    locale: state.settings.locale,
  });
}

function HistogramTrendStable(props) {
  const {
    data,
    width,
    height,
    color,
    metric,
    unit,
    history,
    locale,
    showRecordType,
  } = props;

  const [tooltipOpacity, setTooltipOpacity] = useState(0);
  const [tooltipTransform, setTooltipTransform] = useState('');

  // current, or last, record hovered
  const [recordDate, setRecordDate] = useState('');
  const [recordValue, setRecordValue] = useState(0);
  const [recordType, setRecordType] = useState('');

  const [ref, dimensions] = useGraphDimensions({ width, height });
  const xAccessor = (d, i) => i;
  const yAccessor = d => d.y;

  const xScale = scaleBand()
    .domain([...Array(data.length).keys()])
    .range([0, dimensions.boundedWidth])
    .paddingInner([0.1])
    .paddingOuter([0.3])
    .align([0.5]);

  const yScale = scaleLinear()
    .domain([0, max(data, yAccessor)])
    .range([dimensions.boundedHeight, 0]);

  const xAccessorScaled = (d, i) => xScale(xAccessor(d, i));
  const yAccessorScaled = (d, i) => yScale(yAccessor(d, i));

  function handleMouseEnter(d, i) {
    const halfWidthBand = xScale.bandwidth() / 2;
    const x = xScale(i) + halfWidthBand + dimensions.marginLeft;
    const y = yScale(d.y) + dimensions.marginTop;

    setTooltipOpacity(1);
    setTooltipTransform(`translate(calc(-50% + ${x}px), calc(-100% + ${y}px - 15px))`);
    setRecordDate(formatDate(d.x, locale));
    setRecordValue(formatNumber(d.y, locale, metric.formatOptions));
    setRecordType(d.type);
  }

  function handleMouseLeave() {
    setTooltipOpacity(0);
  }

  function handleClick(d) {
    history.push(`record/${d.id}`);
  }

  return (
    <div ref={ref} style={{ position: 'relative', height }}>
      <Tooltip
        opacity={tooltipOpacity}
        transform={tooltipTransform}
      >
        <Grid container direction="column" justify="flex-start" alignItems="stretch">
          <Grid item>
            <TooltipInfo title="recordDate" value={recordDate} />
          </Grid>
          <Grid item>
            <TooltipInfo title={metric.title} value={recordValue} unit={unit} />
          </Grid>
          { showRecordType && (
            <Grid item>
              <TooltipInfo title="type" value={recordType} translateValue />
            </Grid>
          )}
        </Grid>
      </Tooltip>
      <Graph dimensions={dimensions}>
        <defs>
          <Gradient
            id={metric.key}
            stops={[
              { offset: '0%', color, opacity: '1' },
              { offset: '100%', color: 'white', opacity: '0' },
            ]}
            x1={0}
            x2={0}
            y1={0}
            y2={1}
          />
        </defs>
        <Axis
          dimension="y"
          scale={yScale}
          keyAccessor={d => d}
        />
        <Rects
          data={data}
          keyAccessor={d => d.id}
          xAccessor={xAccessorScaled}
          yAccessor={yAccessorScaled}
          widthAccessor={xScale.bandwidth()}
          heightAccessor={d => dimensions.boundedHeight - yAccessorScaled(d)}
          fillAccessor={`url(#${metric.key})`}
          rx="6px"
          ry="6px"
          onMouseEnter={handleMouseEnter}
          onMouseLeave={handleMouseLeave}
          onClick={handleClick}
          style={{
            cursor: 'pointer',
          }}
        />
        <Trend
          data={data.map((d, i) => ({
            x: i,
            y: d.y,
          }))}
          xAccessor={xAccessor}
          xScale={xScale}
          yScale={yScale}
        />
      </Graph>
    </div>
  );
}

HistogramTrendStable.propTypes = {
  // eslint-disable-next-line react/forbid-prop-types
  history: PropTypes.object.isRequired,
  locale: PropTypes.string.isRequired,
  data: PropTypes.arrayOf(PropTypes.shape({
    x: PropTypes.number,
    y: PropTypes.number,
    id: PropTypes.string,
    type: PropTypes.string,
  })).isRequired,
  metric: PropTypes.instanceOf(Metric).isRequired,
  unit: PropTypes.string.isRequired,

  width: PropTypes.number,
  height: PropTypes.number,
  color: PropTypes.string,
  showRecordType: PropTypes.bool,
};

HistogramTrendStable.defaultProps = {
  width: 0,
  height: 250,
  color: '#2986FF',
  showRecordType: false,
};

export default withRouter(connect(mapStateToProps)(HistogramTrendStable));
