// from https://github.com/Tom-Alexander/regression-js/blob/master/src/regression.js
function findTrendInfo(values) {
  const sums = [0, 0, 0, 0, 0];
  const len = values.length;

  values.forEach(({ x, y }) => {
    sums[0] += x;
    sums[1] += y;
    sums[2] += (x * x);
    sums[3] += (x * y);
    sums[4] += (y * y);
  });

  const run = (len * sums[2]) - (sums[0] * sums[0]);
  const rise = (len * sums[3]) - (sums[0] * sums[1]);
  const gradient = (run === 0 ? 0 : (rise / run));
  const intercept = (sums[1] / len) - ((gradient * sums[0]) / len);

  return [gradient, intercept];
}

function bisectData(bisect, data, mousePosition, mousePositionScaled, accessor) {
  let i = bisect(data, mousePositionScaled, 1);
  const d0 = data[i - 1];
  const d1 = data[i];
  let d;
  if (d1 === undefined) {
    d = d0;
  } else if (d0 === undefined) {
    d = d1;
  } else {
    const lengthd0 = Math.abs(accessor(d0, i - 1) - mousePosition);
    const lengthd1 = Math.abs(accessor(d1, i) - mousePosition);
    d = lengthd0 > lengthd1 ? d1 : d0;
  }

  if (d === d0) {
    i -= 1;
  }

  return [d, i];
}

function tickFormatHourMinutes(min) {
  if (min < 60) {
    return `${min} min`;
  }

  if (min % 60 === 0) {
    return `${min / 60} h`;
  }

  return `${Math.floor(min / 60)} h ${min % 60} min`;
}

function distanceCoordinates(xA, yA, xB, yB) {
  return Math.sqrt((xA - xB) ** 2 + (yA - yB) ** 2);
}

export {
  findTrendInfo,
  bisectData,
  tickFormatHourMinutes,
  distanceCoordinates,
};
