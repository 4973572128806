import React from 'react';
import PropTypes from 'prop-types';
import { Axis } from '@feetme/d3act';

function CustomAxis(props) {
  const {
    textTickStyle,
    ...otherProps
  } = props;

  return (
    <Axis
      fullTick={props.dimension === 'y'}
      textTickStyle={() => ({
        letterSpacing: '0.03em',
        fontSize: '13px',
        fill: '#9097A4',
        ...textTickStyle,
      })}
      {...otherProps}
    />
  );
}

CustomAxis.propTypes = {
  dimension: PropTypes.oneOf(['x', 'y']).isRequired,
  // eslint-disable-next-line react/forbid-prop-types
  textTickStyle: PropTypes.object,
};

CustomAxis.defaultProps = {
  textTickStyle: undefined,
};

export default CustomAxis;
