// COLLECTIONS
const USERS = 'users';
const SETTINGS = 'settings';
const INSOLES = 'insoles';
const RECORDS = 'records';
const WALKING_AIDS = 'walkingAids';
const STRIDES = 'dios';
const LINKS = 'links';
const ORGANISATIONS = 'organisations';
const MEMBERS = 'members';
const AUTHORIZATIONS = 'authorizations';
const PROCESSED_RECORDS = 'processedRecords';
const PROCESSED_RECORDS_PRESSURES = 'processedRecordsPressures';
const PRESSURES = 'pressures';
const COP = 'COP';
const BIPODAL_COP = 'bipodalCOP';

function checkDoc(ref, id = null) {
  if (id === null) {
    return ref;
  }

  return ref.doc(id);
}

function accessCollection(ref, collection, id) {
  return checkDoc(ref.collection(collection), id);
}

//
// Ref
//
function userRef(ref, userId) {
  return accessCollection(ref, USERS, userId);
}

function recordRef(ref, recordId) {
  return accessCollection(ref, RECORDS, recordId);
}

function insoleRef(ref, insoleId) {
  return accessCollection(ref, INSOLES, insoleId);
}

function walkingAidRef(ref, walkingAidId) {
  return accessCollection(ref, WALKING_AIDS, walkingAidId);
}

function strideRef(ref, strideId) {
  return accessCollection(ref, STRIDES, strideId);
}

function linkRef(ref, linkId) {
  return accessCollection(ref, LINKS, linkId);
}

function orgaRef(ref, orgaId) {
  return accessCollection(ref, ORGANISATIONS, orgaId);
}

function memberRef(ref, memberId) {
  return accessCollection(ref, MEMBERS, memberId);
}

function settingRef(ref, projectId) {
  return accessCollection(ref, SETTINGS, projectId);
}

function authorizationRef(ref, authorizationId) {
  return accessCollection(ref, AUTHORIZATIONS, authorizationId);
}

function processedRef(ref, recordId) {
  return accessCollection(ref, PROCESSED_RECORDS, recordId);
}

function processedPressuresRef(ref, recordId) {
  return accessCollection(ref, PROCESSED_RECORDS_PRESSURES, recordId);
}

function pressureRef(ref, strideId) {
  return accessCollection(ref, PRESSURES, strideId);
}

function copRef(ref, id) {
  return accessCollection(ref, COP, id);
}

function bipodalCopRef(ref, id) {
  return accessCollection(ref, BIPODAL_COP, id);
}

export {
  userRef,
  recordRef,
  insoleRef,
  walkingAidRef,
  strideRef,
  linkRef,
  orgaRef,
  memberRef,
  settingRef,
  authorizationRef,
  processedRef,
  processedPressuresRef,
  pressureRef,
  copRef,
  bipodalCopRef,
};
