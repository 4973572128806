import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import Grid from '@material-ui/core/Grid';

import MetricTitle from './utils/title';
import MetricValue from './utils/value';
import MetricUnit from './utils/unit';
import MetricStandardDeviation from './utils/standard-deviation';

import Metric from '../../utils/metrics/metric';
import { filterWithTimestamps, getArrayXY } from '../../utils/strides';
import { statArray } from '../../utils/array';

function mapStateToProps(state, ownProps) {
  return ({
    unit: state.settings.unitMetrics[ownProps.metric.key] || ownProps.metric.unit,
    startTimestamp: ownProps.startTimestamp || state.timestampsRecordDisplay.startTimestamp,
    endTimestamp: ownProps.endTimestamp || state.timestampsRecordDisplay.endTimestamp,
  });
}

function LargeMetric(props) {
  const {
    strides,
    unit,
    metric,
    value,
    startTimestamp,
    endTimestamp,
    strideTimestampFilter,
    pdf,
  } = props;

  let deviation;
  let arrayXY = getArrayXY(strides, metric);
  if (strideTimestampFilter) {
    arrayXY = filterWithTimestamps(arrayXY, startTimestamp, endTimestamp);
  }
  const mean = value || metric.convert(metric.compute(arrayXY), unit) || 0;
  if (metric.hasDeviation) {
    deviation = metric.convert(statArray(arrayXY.map(i => i.y), 'deviation'), unit);
  }

  return (
    <Grid container direction="column" justify="center" alignItems="center">
      <Grid item>
        <MetricTitle metric={metric} variant="subtitle1" />
      </Grid>
      <Grid item>
        <Grid container direction="row" justify="center" alignItems="center" spacing={1}>
          <Grid item>
            <MetricValue metric={metric} value={mean} variant="h3" />
          </Grid>
          <Grid item>
            <MetricUnit unit={unit} variant="h6" />
          </Grid>
        </Grid>
      </Grid>
      <Grid item>
        <MetricStandardDeviation metric={metric} unit={unit} value={deviation} pdf={pdf} />
      </Grid>
    </Grid>
  );
}

LargeMetric.propTypes = {
  // TODO could be simplified with only an arrayXYSide
  strides: PropTypes.arrayOf(PropTypes.object).isRequired,
  startTimestamp: PropTypes.number.isRequired,
  endTimestamp: PropTypes.number.isRequired,
  metric: PropTypes.instanceOf(Metric).isRequired,
  unit: PropTypes.string.isRequired,
  value: PropTypes.number,
  strideTimestampFilter: PropTypes.bool,
  pdf: PropTypes.bool,
};

LargeMetric.defaultProps = {
  value: undefined,
  strideTimestampFilter: false,
  pdf: false,
};

export default connect(mapStateToProps)(LargeMetric);
