import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import {
  Grid,
  Typography,
} from '@material-ui/core';

import {
  RiskFalling,
} from '../../utils/metrics/index';
import Translate from '../display/translate';

import MetricTitle from './utils/title';

const styles = theme => ({
  parentHoverBlock: {
    position: 'relative',
  },
  hoverBlock: {
    position: 'absolute',
    minWidth: '100%',
    minHeight: '100%',
    background: theme.palette.primary.main,
    borderRadius: theme.shape.borderRadius,
    boxShadow: theme.shadows[2],
    opacity: 1,
    transition: 'opacity 0.2s linear',
  },
  text: {
    color: theme.palette.primary.contrastText,
  },
});

function FallingRiskHover(props) {
  if (props.disable) {
    return null;
  }

  const [isHover, setHover] = useState(false);

  const {
    classes,
    xs,
    sm,
    md,
    lg,
  } = props;

  return (
    <Grid
      item
      xs={xs}
      sm={sm}
      md={md}
      lg={lg}
      className={classes.parentHoverBlock}
      onClick={() => setHover(!isHover)}
      onMouseEnter={() => setHover(true)}
      onMouseLeave={() => setHover(false)}
    >
      <Grid
        container
        direction="column"
        justify="center"
        alignItems="center"
        style={{ opacity: isHover ? 0 : 1 }}
        className={classes.hoverBlock}
        spacing={2}
      >
        <Grid item>
          <MetricTitle metric={RiskFalling} className={classes.text} variant="subtitle1" />
        </Grid>
        <Grid item>
          <Typography variant="subtitle2" className={classes.text} align="justify">
            <Translate>hoverOrClickToShow</Translate>
          </Typography>
        </Grid>
      </Grid>
      { props.children }
    </Grid>
  );
}

FallingRiskHover.propTypes = {
  // eslint-disable-next-line react/forbid-prop-types
  classes: PropTypes.object.isRequired,

  children: PropTypes.element.isRequired,
  disable: PropTypes.bool.isRequired,

  xs: PropTypes.oneOfType([PropTypes.number, PropTypes.bool]),
  sm: PropTypes.oneOfType([PropTypes.number, PropTypes.bool]),
  md: PropTypes.oneOfType([PropTypes.number, PropTypes.bool]),
  lg: PropTypes.oneOfType([PropTypes.number, PropTypes.bool]),
};

FallingRiskHover.defaultProps = {
  xs: false,
  sm: false,
  md: false,
  lg: false,
};

export default withStyles(styles)(FallingRiskHover);
