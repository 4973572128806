import React from 'react';
import PropTypes from 'prop-types';
import {
  Grid,
  Typography,
} from '@material-ui/core';

import Translate from '../../components/display/translate';

import { getScale } from '../../utils/fhir/pathologies';

function ScaleInfo(props) {
  const { code, scale, type } = props;

  return (
    <Grid container direction="column" justify="flex-start" alignItems="stretch" spacing={2}>
      <Grid item>
        <Typography>
          <Translate>{ type === 'pathology' ? 'stage' : 'clinicalScale' }</Translate>
          :&nbsp;
          <Translate>{ getScale(type, code).find(i => i.key === scale.code).label }</Translate>
        </Typography>
      </Grid>
      <Grid item>
        {(scale.value && scale.value.length > 0) && (
          <Typography>
            <Translate>scaleValue</Translate>
            :&nbsp;
            <Translate>{scale.value}</Translate>
          </Typography>
        )}
      </Grid>
    </Grid>
  );
}

ScaleInfo.propTypes = {
  code: PropTypes.string.isRequired,
  type: PropTypes.oneOf(['clinical', 'pathology']).isRequired,
  scale: PropTypes.shape({
    code: PropTypes.string,
    value: PropTypes.string,
  }).isRequired,
};

export default ScaleInfo;
