import React, { useEffect, useMemo, useState } from 'react';
import PropTypes from 'prop-types';
import Grid from '@material-ui/core/Grid';

import PageHeader from '../../components/display/page-header';
import PageTitle from '../../components/display/page-title';
import PageSubtitle from '../../components/display/page-subtitle';
import Translate from '../../components/display/translate';
import DateDisplay from '../../components/display/date';
import ProgressWait from '../../components/display/progress-wait';
import NoReplay from './no-replay';
import Report from '../../components/record/report';

import StaticReplays from './static-replays';
import StaticPressureDistribution from './static-pressure-distribution';
import StaticBipodalCOP from './static-bipodal-cop';
import Romberg from './romberg';
import EllipseInfos from './ellipse-infos';

import {
  fetchProcessedRecordPressures,
  fetchProcessedRecord,
  fetchBipodalCop,
  fetchStaticCop,
} from '../../firebase/firestore';
import { fileDownloadDateFormat } from '../../utils/date';

function ComparisonStatic(props) {
  const {
    records,
    insoles,
  } = props;

  // store the processedRecordsPressure, processedRecord and bipodal cop
  const [recordsData, setRecordsData] = useState([]);

  // load pressure data from records
  useEffect(() => {
    async function wrapper() {
      setRecordsData(await records.reduce(async (acc, record) => {
        const res = await acc;
        res.push(await Promise.all([
          fetchProcessedRecordPressures(record.key),
          fetchProcessedRecord(record.key),
          fetchBipodalCop(record.key),
          fetchStaticCop(record.key),
        ]));
        return Promise.resolve(res);
      }, Promise.resolve([])));
    }
    if (records.length === 0) {
      return;
    }
    wrapper();
  }, [records.length]);

  const isValidRomberg = useMemo(() => {
    if (records.length !== 2) {
      return false;
    }

    const eyesOpened = records.find(i => i.staticSpec.protocol === 'eyesOpened');
    const eyesClosed = records.find(i => i.staticSpec.protocol === 'eyesClosed');
    return (eyesOpened !== undefined && eyesClosed !== undefined
      && eyesOpened.key !== eyesClosed.key);
  }, [records.length]);

  if (recordsData.length === 0) {
    return (<ProgressWait />);
  }

  return (
    <React.Fragment>
      <PageHeader>
        <Grid container direction="row" justify="space-between" alignItems="flex-end">
          <Grid item>
            <PageTitle>
              <Translate input={{ NB: records.length }}>XrecordsSelected</Translate>
            </PageTitle>
            <PageSubtitle>
              <Grid container direction="row" justify="flex-start" alignItems="center" spacing={1}>
                <Grid item>
                  <Translate>fromDate</Translate>
                </Grid>
                <Grid item>
                  <DateDisplay>{records[0].startTime}</DateDisplay>
                </Grid>
                <Grid item>
                  <Translate>toDate</Translate>
                </Grid>
                <Grid item>
                  <DateDisplay>{records[records.length - 1].startTime}</DateDisplay>
                </Grid>
              </Grid>
            </PageSubtitle>
          </Grid>
          <Grid item>
            <Report
              recordsId={records.map(i => i.key)}
              filename={`${fileDownloadDateFormat(new Date(records[records.length - 1].startTime))}.pdf`}
            />
          </Grid>
        </Grid>
      </PageHeader>
      <Grid container direction="column" justify="flex-start" alignItems="stretch" spacing={2} wrap="nowrap" style={{ marginTop: '24px' }}>
        <Grid item>
          { records.length === 2
            ? <StaticReplays records={records} insoles={insoles} />
            : <NoReplay /> }
        </Grid>
        <Grid item>
          <StaticPressureDistribution
            records={records}
            insoles={insoles}
            recordsData={recordsData}
          />
        </Grid>
        <Grid item>
          <StaticBipodalCOP
            records={records}
            recordsData={recordsData}
          />
        </Grid>
        { isValidRomberg && (
          <Grid item>
            <Romberg records={records} recordsData={recordsData} />
          </Grid>
        )}
        <Grid item>
          <EllipseInfos
            records={records}
            recordsData={recordsData}
          />
        </Grid>
      </Grid>
    </React.Fragment>
  );
}

ComparisonStatic.propTypes = {
  records: PropTypes.arrayOf(PropTypes.object).isRequired,
  // eslint-disable-next-line react/forbid-prop-types
  insoles: PropTypes.object.isRequired,
};

export default ComparisonStatic;
