import { useMemo } from 'react';

function usePressureLoad(showMean, processedRecord, leftStride, rightStride) {
  return useMemo(() => {
    if (showMean) {
      return [
        {
          loadHeelStrike1: processedRecord.meanLoadHeelStrike1
            ? processedRecord.meanLoadHeelStrike1.left : undefined,
          loadToeOff: processedRecord.meanLoadToeOff
            ? processedRecord.meanLoadToeOff.left : undefined,
        },
        {
          loadHeelStrike1: processedRecord.meanLoadHeelStrike1
            ? processedRecord.meanLoadHeelStrike1.right : undefined,
          loadToeOff: processedRecord.meanLoadToeOff
            ? processedRecord.meanLoadToeOff.right : undefined,
        },
      ];
    }

    return [
      leftStride,
      rightStride,
    ];
  }, [showMean, leftStride.key, rightStride.key]);
}

export default usePressureLoad;
