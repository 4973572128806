import React, { useMemo } from 'react';
import PropTypes from 'prop-types';

import LoadVariation from './load-variation';

function StaticLoadVariation({ cop }) {
  const [leftLoads, rightLoads] = useMemo(() => {
    const left = cop
      .filter(frame => frame.side === 'left')
      .map(frame => [frame.clientTimestamp, frame.sumLoads]);

    const right = cop
      .filter(frame => frame.side === 'right')
      .map(frame => [frame.clientTimestamp, frame.sumLoads]);

    return [left, right];
  }, [cop.length]);

  return (
    <LoadVariation
      leftLoads={leftLoads}
      rightLoads={rightLoads}
    />
  );
}

StaticLoadVariation.propTypes = {
  cop: PropTypes.arrayOf(PropTypes.shape({
    side: PropTypes.oneOf(['left', 'right']),
    clientTimestamp: PropTypes.number,
    sumPressures: PropTypes.number,
  })).isRequired,
};

export default StaticLoadVariation;
