import { useEffect, useRef, useState } from 'react';

function useFrequency(delay = 1000) {
  const freqRef = useRef(0);
  const [frequency, setFrequency] = useState(0);

  useEffect(() => {
    const intervalId = setInterval(() => {
      setFrequency(freqRef.current);
      freqRef.current = 0;
    }, delay);

    return () => clearInterval(intervalId);
  }, []);

  return [frequency, freqRef];
}

export default useFrequency;
