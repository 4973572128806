import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import MetricGraphContainer from './utils/graph-container';
import CurveInactivity from '../d3/curve-inactivity';

import Metric from '../../utils/metrics/metric';
import { getArrayXY } from '../../utils/strides';
import { savitzkyGolayFilter } from '../../utils/metric';

function mapStateToProps(state, ownProps) {
  return ({
    unit: state.settings.unitMetrics[ownProps.metric.key] || ownProps.metric.unit,
  });
}

function MetricGraphInactivity(props) {
  const {
    strides,
    unit,
    metric,
    intervalDefinedMs,
    pdf,
  } = props;

  return (
    <MetricGraphContainer metric={metric}>
      <CurveInactivity
        intervalDefinedMs={intervalDefinedMs}
        values={savitzkyGolayFilter(getArrayXY(strides, metric)
          .map(({ x, y }) => ({ x, y: metric.convert(y, unit) })))}
        height={pdf ? 300 : undefined}
      />
    </MetricGraphContainer>
  );
}

MetricGraphInactivity.propTypes = {
  strides: PropTypes.arrayOf(PropTypes.object).isRequired,
  intervalDefinedMs: PropTypes.number.isRequired,
  metric: PropTypes.instanceOf(Metric).isRequired,
  unit: PropTypes.string.isRequired,
  pdf: PropTypes.bool,
};

MetricGraphInactivity.defaultProps = {
  pdf: false,
};

export default connect(mapStateToProps)(MetricGraphInactivity);
