import React from 'react';
import PropTypes from 'prop-types';

import InsolesStaticPressures from '../../record/components/insoles-static-pressures';

import useSplitCop from '../../utils/hooks/useSplitCop';

function PressureDistribution(props) {
  const {
    insoles,
    cop,
    processedRecord,
    maxPressuresLeft,
    maxPressuresRight,
    scale,
    height,
    pdf,
  } = props;

  const [leftCop, rightCop] = useSplitCop(cop);

  return (
    <InsolesStaticPressures
      leftForeFootLoad={processedRecord?.meanForeFootLoadPercentage?.left}
      rightForeFootLoad={processedRecord?.meanForeFootLoadPercentage?.right}
      leftBackFootLoad={processedRecord?.meanBackFootLoadPercentage?.left}
      rightBackFootLoad={processedRecord?.meanBackFootLoadPercentage?.right}
      insoles={insoles}
      leftCop={leftCop}
      rightCop={rightCop}
      leftPressures={maxPressuresLeft}
      rightPressures={maxPressuresRight}
      scale={scale}
      height={height}
      pdf={pdf}
    />
  );
}

PressureDistribution.propTypes = {
  processedRecord: PropTypes.shape({
    meanForeFootLoadPercentage: PropTypes.shape({
      left: PropTypes.number,
      right: PropTypes.number,
    }),
    meanBackFootLoadPercentage: PropTypes.shape({
      left: PropTypes.number,
      right: PropTypes.number,
    }),
  }).isRequired,
  insoles: PropTypes.arrayOf(PropTypes.object).isRequired,
  scale: PropTypes.func.isRequired,
  cop: PropTypes.arrayOf(PropTypes.object).isRequired,
  maxPressuresLeft: PropTypes.arrayOf(PropTypes.number).isRequired,
  maxPressuresRight: PropTypes.arrayOf(PropTypes.number).isRequired,
  height: PropTypes.number,
  pdf: PropTypes.bool,
};

PressureDistribution.defaultProps = {
  height: undefined,
  pdf: false,
};

export default PressureDistribution;
