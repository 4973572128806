import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';

const styles = theme => ({
  root: {
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(1),
  },
});

function PageSubtitle({ classes, children }) {
  return (
    <Typography component="h2" variant="h4" className={classes.root}>
      { children }
    </Typography>
  );
}

PageSubtitle.propTypes = {
  // eslint-disable-next-line react/forbid-prop-types
  classes: PropTypes.object.isRequired,

  children: PropTypes.oneOfType([PropTypes.element, PropTypes.string]).isRequired,
};

export default withStyles(styles)(PageSubtitle);
