import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import MetricGraphContainer from './utils/graph-container';
import HistogramDaily from '../d3/histogram-daily';

import Metric from '../../utils/metrics/metric';
import { getArrayXY } from '../../utils/strides';

function mapStateToProps(state, ownProps) {
  return ({
    unit: state.settings.unitMetrics[ownProps.metric.key] || ownProps.metric.unit,
  });
}

function MetricGraphHistogramDaily(props) {
  const {
    strides,
    metric,
    unit,
    pdf,
  } = props;

  return (
    <MetricGraphContainer metric={metric}>
      <HistogramDaily
        values={getArrayXY(strides, metric)
          .map(({ x, y }) => ({ x, y: metric.convert(y, unit) }))}
        domain={metric.domain(props.unit)}
        range={metric.range}
        colorRange={metric.colorRange}
        height={pdf ? 150 : undefined}
        pdf={pdf}
      />
    </MetricGraphContainer>
  );
}

MetricGraphHistogramDaily.propTypes = {
  strides: PropTypes.arrayOf(PropTypes.object).isRequired,
  metric: PropTypes.instanceOf(Metric).isRequired,
  unit: PropTypes.string.isRequired,
  pdf: PropTypes.bool,
};

MetricGraphHistogramDaily.defaultProps = {
  pdf: false,
};

export default connect(mapStateToProps)(MetricGraphHistogramDaily);
