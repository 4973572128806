import { useMemo } from 'react';
import { interpolateRgbBasis } from 'd3-interpolate';
import { scaleLinear } from 'd3-scale';

import { PRESSURE_GRADIENT } from '../colors';

function usePressureScale(min, max) {
  return useMemo(() => {
    const colorInterpolation = interpolateRgbBasis(PRESSURE_GRADIENT);
    const scale = i => colorInterpolation(scaleLinear().domain([min, max]).range([0, 1])(i));
    return [scale, colorInterpolation];
  }, [min, max]);
}

export default usePressureScale;
