const FLAGS = {
  DEFAULT: 0x0,
  MICRO_STEP: 0x01,
  WEIGHT_TRANSFER: 0x02,
  INACTIVITY: 0x03,
  UNCERTAINTY: 0x04,
  UNKNOWN: 0xff,
};

function parseStrideFlag(flag) {
  switch (flag) {
    case FLAGS.DEFAULT:
      return '';
    case FLAGS.MICRO_STEP:
      return 'Micro step';
    case FLAGS.WEIGHT_TRANSFER:
      return 'Weight transfer';
    case FLAGS.INACTIVITY:
      return 'Inactivity';
    case FLAGS.UNCERTAINTY:
      return 'Uncertainty';
    default:
      return 'Unknown';
  }
}

function parseStrideOverflow(overflow) {
  return (overflow ? '1' : '');
}

function encodeStrideFlag(flag) {
  switch (flag) {
    case '':
      return FLAGS.DEFAULT;
    case 'Micro step':
      return FLAGS.MICRO_STEP;
    case 'Weight transfer':
      return FLAGS.WEIGHT_TRANSFER;
    case 'Inactivity':
      return FLAGS.INACTIVITY;
    case 'Uncertainty':
      return FLAGS.UNCERTAINTY;
    default:
      return FLAGS.UNKNOWN;
  }
}

function hasPressures(stride) {
  return 'nbPressures' in stride && stride.nbPressures > 0;
}

// returns an array:
// [
//   [clientTimestampLoad, sumLoad],
//   [clientTimestampLoad, sumLoad],
//   ...,
// ]
function zipLoads(stride) {
  if (!('sumsLoads' in stride && 'clientTimestampsLoads' in stride)) {
    return [];
  }

  return stride.clientTimestampsLoads.map((c, i) => [c, stride.sumsLoads[i]]);
}

export {
  parseStrideFlag,
  parseStrideOverflow,
  encodeStrideFlag,
  hasPressures,
  FLAGS,
  zipLoads,
};
