import React from 'react';
import PropTypes from 'prop-types';
import Avatar from '@material-ui/core/Avatar';

import { getColorAvatar } from '../../utils/colors';

const styles = {
  root: {
    margin: 10,
    color: '#ffffff',
  },
};

function UserAvatar(props) {
  if (props.photoURL) {
    return <Avatar alt={props.displayName} src={props.photoURL} />;
  }

  const newStyle = {
    ...styles.root,
    backgroundColor: getColorAvatar(props.displayName),
  };

  return (
    <Avatar style={newStyle}>
      {
        // take the two first char from the first char of each name (longer
        // than the 3 char) in the display name.
        props.displayName
          .split(' ')
          .map((n) => {
            if (n.length >= 3) {
              return n[0];
            }

            return '';
          })
          .join('')
          .substring(0, 2)
          .toUpperCase()
      }
    </Avatar>
  );
}

UserAvatar.propTypes = {
  displayName: PropTypes.string.isRequired,
  photoURL: PropTypes.string,
};

UserAvatar.defaultProps = {
  photoURL: undefined,
};

export default UserAvatar;
