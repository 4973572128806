import React from 'react';
import PropTypes from 'prop-types';
import Card from '@material-ui/core/Card';
import CardHeader from '@material-ui/core/CardHeader';
import CardContent from '@material-ui/core/CardContent';
import { useSelector } from 'react-redux';

import Translate from '../../components/display/translate';
import CompareRecordReplays from './replays';

import { Load } from '../../utils/metrics';

function DynamicReplays(props) {
  const {
    records,
    insoles,
    walkingAids,
  } = props;
  const loadUnitStore = useSelector(state => state.settings.unitMetrics[Load.key]);

  return (
    <Card>
      <CardHeader
        title={<Translate>replayPressureData</Translate>}
        subheader={(
          <Translate input={{ UNIT: loadUnitStore }}>
            replayPressureDataDescriptionDynamic
          </Translate>
        )}
      />
      <CardContent>
        <CompareRecordReplays
          records={records}
          insoles={insoles}
          walkingAids={walkingAids}
          mode="dynamic"
          minDomain={0.13}
        />
      </CardContent>
    </Card>
  );
}

DynamicReplays.propTypes = {
  records: PropTypes.arrayOf(PropTypes.shape({
    key: PropTypes.string,
  })).isRequired,
  // eslint-disable-next-line react/forbid-prop-types
  walkingAids: PropTypes.object.isRequired,
  // eslint-disable-next-line react/forbid-prop-types
  insoles: PropTypes.object.isRequired,
};

export default DynamicReplays;
