import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { InsoleView } from '@feetme/insole-view-react';
import { InsoleMesh } from '@feetme/pressure-interpolation';

import { getPathInsoles } from '../../utils/assets';
import { cleanHardwareVersion } from '../../utils/insoles';
import useStaticRotation from '../../utils/hooks/useStaticRotation';

function fetchInputCoordinate(hardware, size) {
  return fetch(`${getPathInsoles()}/${cleanHardwareVersion(hardware)}/inputCoordinates/${size}.json`)
    .then(i => i.json());
}

function fetchInsole(hardware, size) {
  return fetch(`${getPathInsoles()}/${cleanHardwareVersion(hardware)}/insoles/${size}.json`)
    .then(i => i.json());
}

function InsolePressure(props) {
  const {
    insole,
    pressure,
    scale,
    displayCopStd,
    copStd,
    isStatic,
    meshX,
    meshY,
    arcRadius,
    height,
  } = props;

  const [insoleMesh, setInsoleMesh] = useState(undefined);
  const [rawOutline, setOutline] = useState([]);
  const { side, size } = insole;
  const hardware = (insole.versions ? insole.versions.hardware : undefined);

  useEffect(() => {
    async function loadInsoleData() {
      setOutline((await fetchInsole(hardware, size)).outline);
    }

    if (size !== undefined) {
      loadInsoleData();
    }
  }, [hardware, size]);

  useEffect(() => {
    async function initInsoleMesh() {
      setInsoleMesh(new InsoleMesh(await fetchInputCoordinate(hardware, size), 3, meshX, meshY));
    }

    if (size !== undefined) {
      initInsoleMesh();
    }
  }, [hardware, size]);

  const outline = useStaticRotation(
    rawOutline,
    insoleMesh,
    isStatic,
  );
  const outputCoordinates = useStaticRotation(
    insoleMesh === undefined ? [] : insoleMesh.getOutputCoordinates(),
    insoleMesh,
    isStatic,
  );
  const cop = useStaticRotation(
    props.cop,
    insoleMesh,
    isStatic,
  );

  if (outline.length === 0 || hardware === undefined
    || size === undefined || insoleMesh === undefined) {
    return null;
  }

  return (
    <InsoleView
      insoleOutline={outline}
      sensorOutline={[]}
      cells={[]}
      side={side}
      cop={cop}
      copStd={copStd}
      displayCopStd={displayCopStd}
      disableMask
      enableMaskInsole
      disableSensorOutline
      enableInsoleOutline
      copConfig={{
        opacityArea: '0.3',
      }}
      heatmapValues={pressure}
      heatmapPosition="interpolation"
      cellColorScale={scale}
      arcRadius={arcRadius}
      interpolationCoordinates={outputCoordinates}
      height={height}
    />
  );
}

InsolePressure.propTypes = {
  pressure: PropTypes.arrayOf(PropTypes.number).isRequired,
  insole: PropTypes.shape({
    size: PropTypes.number,
    side: PropTypes.string,
    versions: PropTypes.shape({
      hardware: PropTypes.string,
    }),
  }),
  cop: PropTypes.arrayOf(PropTypes.arrayOf(PropTypes.number)).isRequired,
  scale: PropTypes.func.isRequired,
  displayCopStd: PropTypes.bool,
  copStd: PropTypes.arrayOf(PropTypes.PropTypes.shape({
    x0: PropTypes.number,
    x1: PropTypes.number,
    y: PropTypes.number,
  })),
  isStatic: PropTypes.bool,
  meshX: PropTypes.number,
  meshY: PropTypes.number,
  arcRadius: PropTypes.number,
  height: PropTypes.number,
};

InsolePressure.defaultProps = {
  insole: {},
  displayCopStd: false,
  copStd: [],
  isStatic: false,
  meshX: undefined,
  meshY: undefined,
  arcRadius: 1.6,
  height: 400,
};

export default InsolePressure;
