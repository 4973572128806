import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import {
  Grid,
  Typography,
  IconButton,
  Card,
  CardContent,
  CardHeader,
  FormControlLabel,
  Switch,
} from '@material-ui/core';
import Slider from '@material-ui/core/Slider';
import PreviousIcon from '@material-ui/icons/KeyboardArrowLeft';
import NextIcon from '@material-ui/icons/KeyboardArrowRight';

import { scaleLinear } from 'd3-scale';
import { extent } from 'd3-array';

import Translate from '../../components/display/translate';
import InsoleViewWrapper from '../../components/insole/insole-view-wrapper';
import Colormap from '../../components/insole/colormap';

import {
  applyScaleCapa,
  computeMeanCapa,
  formatCOP,
  computeStd,
} from '../../utils/metric';
import { filterWithTimestamps } from '../../utils/strides';

function mapStateToProps(state) {
  return ({
    startTimestamp: state.timestampsRecordDisplay.startTimestamp,
    endTimestamp: state.timestampsRecordDisplay.endTimestamp,
  });
}

class InsolesDisplayCard extends React.Component {
  constructor() {
    super();

    this.state = {
      mean: true,
      strideIndex: 0,
      maxIndex: 0,
      capaLeft: {
        mean: [],
        strides: [],
      },
      capaRight: {
        mean: [],
        strides: [],
      },
      copLeft: {
        mean: [],
        std: [],
        strides: [],
      },
      copRight: {
        mean: [],
        std: [],
        strides: [],
      },
    };

    this.scale = undefined;

    this.handleSwitch = this.handleSwitch.bind(this);
    this.handleSlider = this.handleSlider.bind(this);
    this.handlePrevious = this.handlePrevious.bind(this);
    this.handleNext = this.handleNext.bind(this);
  }

  componentDidMount() {
    this.updateCapaState();
  }

  componentDidUpdate(prevProps) {
    if (this.props.startTimestamp !== prevProps.startTimestamp
      || this.props.endTimestamp !== prevProps.endTimestamp) {
      this.updateCapaState();
    }
  }

  handleSwitch() {
    this.setState(prevState => ({ mean: !prevState.mean }));
  }

  handleSlider(ev, value) {
    this.setState({ strideIndex: value });
  }

  handlePrevious() {
    this.setState(prevState => ({ strideIndex: Math.max(0, prevState.strideIndex - 1) }));
  }

  handleNext() {
    this.setState(({ strideIndex, maxIndex }) => ({
      strideIndex: Math.min(strideIndex + 1, maxIndex),
    }));
  }

  filter(values) {
    return filterWithTimestamps(
      values,
      this.props.startTimestamp,
      this.props.endTimestamp,
    ).map(i => i.y);
  }

  updateCapaState() {
    const capaLeft = this.filter(this.props.capaLeft);
    const capaRight = this.filter(this.props.capaRight);
    const copLeft = this.filter(this.props.copLeft);
    const copRight = this.filter(this.props.copRight);

    const scaleCapa = scaleLinear()
    // extent ([min, max])
    // of flattenned array
    // of concat of capa left and right
      .domain(extent([].concat(...([].concat(capaRight, capaLeft)))))
      .range([0, 1]);

    const capaMeanRight = computeMeanCapa(capaRight);
    const capaMeanLeft = computeMeanCapa(capaLeft);

    const scaleMeanCapa = scaleLinear()
      .domain(extent([].concat(...([].concat(capaMeanRight, capaMeanLeft)))))
      .range([0, 1]);

    this.setState({
      capaRight: {
        strides: applyScaleCapa(capaRight, scaleCapa),
        mean: capaMeanRight.map(i => scaleMeanCapa(i)),
      },
      capaLeft: {
        strides: applyScaleCapa(capaLeft, scaleCapa),
        mean: capaMeanLeft.map(i => scaleMeanCapa(i)),
      },
      copRight: formatCOP(copRight),
      copLeft: formatCOP(copLeft),
      maxIndex: Math.max(capaLeft.length - 1, capaRight.length - 1),
    });
  }

  accessData(type, side, stdType) {
    const data = this.state[`${type}${side === 'right' ? 'Right' : 'Left'}`];
    if (this.state.mean) {
      if (type === 'cop' && (stdType === 'min' || stdType === 'max')) {
        return computeStd(data.mean, data.std, stdType);
      }
      return data.mean || [];
    }

    if ((data.strides.length - 1) < this.state.strideIndex) {
      return [];
    }

    return data.strides[this.state.strideIndex];
  }

  render() {
    // check the version of the insoles to see if at least one of them is a Dios
    // if so we will display a COP and we need to update the title and subtitle
    // of the card
    // XXX: this modification should be temporary while we're waiting for more
    // robust algorithm on Dialko
    const hasDiosInsoles = (this.props.versionLeft === '3.0.0' || this.props.versionRight === '3.0.0');

    return (
      <Card>
        <CardHeader
          title={<Translate>{hasDiosInsoles ? 'pressureHeatmapCenterPressure' : 'pressureDistribution'}</Translate>}
          subheader={<Translate>{hasDiosInsoles ? 'centerPressureDescription' : 'pressureDistributionDescription'}</Translate>}
        />
        { !this.props.pdf && (
          <CardContent>
            <Grid container direction="column" justify="center" alignItems="stretch" spacing={2}>
              <Grid item>
                <Typography variant="body1">
                  <Translate>controlPanel</Translate>
                </Typography>
                <Grid container direction="column" justify="center" alignItems="stretch">
                  <Grid item>
                    <FormControlLabel
                      control={<Switch checked={this.state.mean} onChange={this.handleSwitch} />}
                      label={<Translate>meanDisplay</Translate>}
                    />
                  </Grid>
                  <Grid item>
                    <Typography variant="caption">
                      <Translate>strideByStride</Translate>
                    </Typography>
                  </Grid>
                  <Grid item>
                    <Grid container alignItems="center" justify="center">
                      <Grid item xs={1} style={{ textAlign: 'center' }}>
                        <IconButton
                          disabled={this.state.mean || this.state.strideIndex === 0}
                          onClick={this.handlePrevious}
                          aria-label="Previous stride"
                        >
                          <PreviousIcon />
                        </IconButton>
                      </Grid>
                      <Grid item xs>
                        <Slider
                          value={this.state.strideIndex}
                          min={0}
                          max={this.state.maxIndex}
                          step={1}
                          disabled={this.state.mean}
                          onChange={this.handleSlider}
                        />
                      </Grid>
                      <Grid item xs={1} style={{ textAlign: 'center' }}>
                        <IconButton
                          disabled={this.state.mean
                            || this.state.strideIndex === this.state.maxIndex}
                          onClick={this.handleNext}
                          aria-label="Next stride"
                        >
                          <NextIcon />
                        </IconButton>
                      </Grid>
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </CardContent>
        )}
        <CardContent>
          <Typography variant="body2" align="center" gutterBottom>
            { this.state.mean
              ? <Translate>avgPressureMaxPressures</Translate>
              : <Translate>maxPressuresStride</Translate> }
          </Typography>
          <div
            style={{
              display: 'flex',
              flexWrap: 'nowrap',
              justifyContent: 'center',
              gap: '30px',
            }}
          >
            { this.props.sizeLeft && (
              <InsoleViewWrapper
                size={this.props.sizeLeft}
                id="insole-display-left-pdf"
                version={this.props.versionLeft}
                side="left"
                capa={this.accessData('capa', 'left')}
                cop={this.accessData('cop', 'left')}
                copStdMin={this.accessData('cop', 'left', 'min')}
                copStdMax={this.accessData('cop', 'left', 'max')}
                displayCopStd={this.state.mean}
                height={this.props.pdf ? 200 : undefined}
                pdf={this.props.pdf}
              />
            )}
            { this.props.sizeRight && (
              <InsoleViewWrapper
                size={this.props.sizeRight}
                id="insole-display-right-pdf"
                version={this.props.versionRight}
                side="right"
                capa={this.accessData('capa', 'right')}
                cop={this.accessData('cop', 'right')}
                copStdMin={this.accessData('cop', 'right', 'min')}
                copStdMax={this.accessData('cop', 'right', 'max')}
                displayCopStd={this.state.mean}
                height={this.props.pdf ? 200 : undefined}
                pdf={this.props.pdf}
              />
            )}
          </div>
        </CardContent>
        <CardContent>
          <Colormap pdf={this.props.pdf} />
        </CardContent>
      </Card>
    );
  }
}

InsolesDisplayCard.propTypes = {
  sizeLeft: PropTypes.number,
  sizeRight: PropTypes.number,
  versionLeft: PropTypes.string,
  versionRight: PropTypes.string,
  capaLeft: PropTypes.arrayOf(PropTypes.shape({
    x: PropTypes.number,
    capa: PropTypes.arrayOf(PropTypes.number),
  })).isRequired,
  capaRight: PropTypes.arrayOf(PropTypes.shape({
    x: PropTypes.number,
    capa: PropTypes.arrayOf(PropTypes.number),
  })).isRequired,
  copLeft: PropTypes.arrayOf(PropTypes.shape({
    x: PropTypes.number,
    capa: PropTypes.arrayOf(PropTypes.number),
  })).isRequired,
  copRight: PropTypes.arrayOf(PropTypes.shape({
    x: PropTypes.number,
    y: PropTypes.arrayOf(PropTypes.number),
  })).isRequired,
  startTimestamp: PropTypes.number.isRequired,
  endTimestamp: PropTypes.number.isRequired,
  pdf: PropTypes.bool,
};

InsolesDisplayCard.defaultProps = {
  sizeLeft: undefined,
  sizeRight: undefined,
  versionLeft: undefined,
  versionRight: undefined,
  pdf: false,
};

export default connect(mapStateToProps)(InsolesDisplayCard);
