import React from 'react';
import PropTypes from 'prop-types';

import MetricGraphContainer from '../metric/utils/graph-container';
import Histogram from '../d3/histogram';

import Metric from '../../utils/metrics/metric';

function RecordGraphHistogram(props) {
  const {
    metric,
    values,
    pdf,
  } = props;

  return (
    <MetricGraphContainer metric={metric}>
      <Histogram
        values={values}
        width={pdf ? 180 : undefined}
        height={pdf ? 300 : undefined}
        pdf={pdf}
      />
    </MetricGraphContainer>
  );
}

RecordGraphHistogram.propTypes = {
  metric: PropTypes.instanceOf(Metric).isRequired,
  values: PropTypes.arrayOf(PropTypes.shape({
    x: PropTypes.number,
    y: PropTypes.number,
  })).isRequired,
  pdf: PropTypes.bool,
};

RecordGraphHistogram.defaultProps = {
  pdf: false,
};

export default RecordGraphHistogram;
