// wait for each fn to return (a promise) before working on the next element
// of the array
function reduce(array, fn) {
  return array.reduce(
    (acc, elt, i) => acc.then(() => fn(elt, i)),
    Promise.resolve(),
  );
}

export {
  // eslint-disable-next-line import/prefer-default-export
  reduce,
};
