import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import {
  Grid,
  Button,
  CircularProgress,
} from '@material-ui/core';

import Translate from '../components/display/translate';
import PageHeader from '../components/display/page-header';
import PageTitle from '../components/display/page-title';
import PageSubtitle from '../components/display/page-subtitle';
import DialogAreYouSure from '../components/dialog/are-you-sure';
import ListMembers from '../components/list/members/index';

import { updateUserOrgaId, updateRecordsNotLinked, addSnackbarMessage } from '../actions/index';
import { getCurrentUser } from '../firebase/auth';
import { fetchOrga, fetchValidOrgaMembers, fetchUserProfile } from '../firebase/firestore';
import { addLink, leaveOrganisation } from '../firebase/functions';

const styles = {
  buttonProgress: {
    position: 'absolute',
    top: '50%',
    left: '50%',
    marginTop: -12,
    marginLeft: -12,
  },
};

function mapStateToProps(state) {
  return ({
    orgaId: state.userProfile.orgaId,
  });
}

class Organisation extends React.Component {
  constructor() {
    super();

    this.state = {
      isAdmin: false,
      linkIsLoading: false,
      orgaName: '',
      members: [],
      membersInProgress: true,
      isDialogOpen: false,
      isDialogWaiting: false,
    };

    this.onValidateLeaveOrganisation = this.onValidateLeaveOrganisation.bind(this);
    this.onCancelLeaveOrganisation = this.onCancelLeaveOrganisation.bind(this);
    this.startLinkCreation = this.startLinkCreation.bind(this);
  }

  componentDidMount() {
    fetchOrga(this.props.orgaId)
      .then(({ name }) => this.setState({ orgaName: name }));
    fetchValidOrgaMembers(this.props.orgaId)
      .then(members => Promise.all(members.map(member => fetchUserProfile(member.key)
        .then(({ displayName }) => ({ name: displayName, ...member })))))
      .then((members) => {
        this.setState({ members, membersInProgress: false });
        // check if the current user is an admin
        const { uid } = getCurrentUser();
        const currentUserOrga = members.find(member => member.key === uid);
        // this if-statement should always be true
        if (currentUserOrga !== undefined) {
          this.setState({ isAdmin: currentUserOrga.isAdmin });
        }
      });
  }

  onValidateLeaveOrganisation() {
    this.setState({ isDialogWaiting: true });
    leaveOrganisation({ orgaId: this.props.orgaId })
      .then(() => {
        this.props.dispatch(updateUserOrgaId(undefined));
        this.props.dispatch(updateRecordsNotLinked([]));
        this.props.history.push('/');
      })
      .catch(() => this.setState({ isDialogOpen: false, isDialogWaiting: false }));
  }

  onCancelLeaveOrganisation() {
    this.setState({ isDialogOpen: false });
  }

  startLinkCreation() {
    this.setState({ linkIsLoading: true });
    // create a link with the default restriction
    //  - no counter
    //  - 7 days (added on the server)
    addLink({ orgaId: this.props.orgaId, type: 'orga' })
      .then((res) => {
        // show snackbar (use master snackbar ?)
        const url = window.location.href.replace('organisation', `link/${res.data}`);
        this.props.dispatch(addSnackbarMessage({ type: 'link', data: { url } }));
      }).then(() => this.setState({ linkIsLoading: false }));
  }

  render() {
    return (
      <div>
        <DialogAreYouSure
          isOpen={this.state.isDialogOpen}
          isWaiting={this.state.isDialogWaiting}
          onYes={this.onValidateLeaveOrganisation}
          onNo={this.onCancelLeaveOrganisation}
          text="areYouSureYouWantToLeaveTheOrganisation"
          textNo="stay"
          textYes="leave"
        />
        <PageHeader>
          <Grid container direction="row" justify="space-between" alignItems="flex-end">
            <Grid item>
              <PageTitle><Translate>organisation</Translate></PageTitle>
              <PageSubtitle>{this.state.orgaName}</PageSubtitle>
            </Grid>
            <Grid item>
              <Grid container direction="column" justify="space-evenly" alignItems="flex-end" spacing={2}>
                <Grid item>
                  <Button
                    variant="outlined"
                    color="secondary"
                    onClick={() => this.setState({ isDialogOpen: true })}
                  >
                    <Translate>leaveOrganisation</Translate>
                  </Button>
                </Grid>
                { this.state.isAdmin && (
                  <Grid item>
                    <Button
                      variant="outlined"
                      color="primary"
                      disabled={this.state.linkIsLoading}
                      onClick={this.startLinkCreation}
                    >
                      <Translate>generateLink</Translate>
                      { this.state.linkIsLoading && (
                        <CircularProgress size={24} style={styles.buttonProgress} />
                      )}
                    </Button>
                  </Grid>
                )}
              </Grid>
            </Grid>
          </Grid>
        </PageHeader>
        <ListMembers members={this.state.members} inProgress={this.state.membersInProgress} />
      </div>
    );
  }
}

Organisation.propTypes = {
  // eslint-disable-next-line react/forbid-prop-types
  history: PropTypes.object.isRequired,
  dispatch: PropTypes.func.isRequired,

  orgaId: PropTypes.string.isRequired,
};

export default withRouter(connect(mapStateToProps)(Organisation));
