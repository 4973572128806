import Metric from './metric';

export default new Metric({
  title: 'singleSupportPercentage',
  unit: '%',
  key: 'singleSupportPercentage',
  isDoubleSupport: true,
  hasDeviation: true,
  description: () => [{
    txt: ['normativeValue', ': 40%'],
    align: 'center',
  }],
});
