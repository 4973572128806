import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import {
  Button,
  Snackbar,
} from '@material-ui/core';

import Translate from '../../components/display/translate';

import { updateSnackbarMessages } from '../../actions/index';

function getAnchorOrigin() {
  return ({
    vertical: 'bottom',
    horizontal: 'left',
  });
}

function getMessage(message) {
  if (message === undefined) {
    return null;
  }

  const { type } = message;

  if (type === 'settings') {
    return (<Translate>settingsUpdated</Translate>);
  }

  if (type === 'link') {
    return (<Translate>linkCreated</Translate>);
  }

  if (type === 'interpolation-failed') {
    return (<Translate>interpolationFailed</Translate>);
  }

  if (type === 'interpolation-success') {
    return (<Translate>interpolationSuccess</Translate>);
  }

  return null;
}

function getTimeout(message) {
  const DEFAULT_TIMEOUT = 3 * 1000; // 3 seconds
  if (message === undefined) {
    return DEFAULT_TIMEOUT;
  }

  const { type } = message;
  if (type === 'link') {
    // deactivate the timeout
    return null;
  }

  return DEFAULT_TIMEOUT;
}

function mapStateToProps(state) {
  return ({
    snackbarMessages: state.snackbarMessages,
  });
}

class SnackbarCenter extends React.Component {
  constructor() {
    super();

    this.state = {
      open: false,
      message: undefined,
    };

    this.handleClose = this.handleClose.bind(this);
    this.handleExited = this.handleExited.bind(this);
  }

  componentDidUpdate(prevProps) {
    if (this.props.snackbarMessages.length > 0
      && this.props.snackbarMessages.length !== prevProps.snackbarMessages.length) {
      if (this.state.open) {
        // eslint-disable-next-line react/no-did-update-set-state
        this.setState({ open: false });
      } else {
        this.processQueue();
      }
    }
  }

  handleClose(ev, reason) {
    if (reason === 'clickaway') {
      return;
    }

    this.setState({ open: false });
  }

  handleExited() {
    this.processQueue();
  }

  getActions(message) {
    if (message === undefined) {
      return null;
    }

    if (message.type === 'settings') {
      return null;
    }

    if (message.type === 'link') {
      return (
        <Button
          color="secondary"
          size="small"
          onClick={() => {
            // copy message.data.url to clipboard
            const el = document.createElement('textarea');
            el.value = message.data.url;
            document.body.appendChild(el);
            el.select();
            document.execCommand('copy');
            document.body.removeChild(el);
            this.setState({ open: false });
          }}
        >
          <Translate>copy</Translate>
        </Button>
      );
    }

    return null;
  }

  processQueue() {
    if (this.props.snackbarMessages.length > 0) {
      const [message, ...rest] = this.props.snackbarMessages;
      this.setState({ message, open: true });
      this.props.dispatch(updateSnackbarMessages(rest));
    }
  }

  render() {
    return (
      <Snackbar
        key={this.state.message ? this.state.message.key : undefined}
        anchorOrigin={getAnchorOrigin()}
        open={this.state.open}
        autoHideDuration={getTimeout(this.state.message)}
        onClose={this.handleClose}
        onExited={this.handleExited}
        ContentProps={{
          'aria-describedby': 'message-id',
        }}
        message={<span id="message-id">{getMessage(this.state.message)}</span>}
        action={this.getActions(this.state.message)}
      />
    );
  }
}

SnackbarCenter.propTypes = {
  dispatch: PropTypes.func.isRequired,

  snackbarMessages: PropTypes.arrayOf(PropTypes.shape({
    key: PropTypes.number,
    type: PropTypes.string,
    // eslint-disable-next-line react/forbid-prop-types
    data: PropTypes.object,
  })).isRequired,
};

export default connect(mapStateToProps)(SnackbarCenter);
