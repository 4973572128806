import Metric from './metric';

const UNITS = ['kg', 'N'];

function convert(v, unit) {
  switch (unit) {
    case 'kg':
      return v;
    case 'N':
      return v * 9.80665;
    default:
      return v;
  }
}

const Load = new Metric({
  title: 'load',
  key: 'load',
  unit: 'kg',
  convert,
});

export {
  UNITS as LOAD_UNITS,
  Load as default,
};
