import { statArray } from '../array';

/* eslint-disable no-underscore-dangle */
class Metric {
  constructor({
    title,
    unit,
    domain,
    range,
    colorRange,
    description,
    formatType,
    formatOptions,
    convert,
    compute,
    key,
    keyCompute,
    isDoubleSupport,
    hasDeviation,
  }) {
    this._title = title;
    this._unit = unit || '';
    this._domain = domain;
    this._range = range;
    this._colorRange = colorRange;
    this._description = description;
    this._formatType = formatType || 'number';
    this._formatOptions = { maximumFractionDigits: 1, ...formatOptions };
    this._convert = convert;
    this._compute = compute || (values => statArray(values.map(i => i.y), 'mean'));
    this._key = key;
    this._keyCompute = keyCompute;
    this._isDoubleSupport = isDoubleSupport || false;
    this._hasDeviation = hasDeviation || false;
  }

  get unit() {
    return this._unit;
  }

  get title() {
    return this._title;
  }

  domain(unit = this._unit) {
    if (this._domain && unit) {
      return this._domain[unit];
    }

    return undefined;
  }

  get range() {
    return this._range;
  }

  get colorRange() {
    return this._colorRange;
  }

  get key() {
    return this._key;
  }

  get hasDescription() {
    return this._description !== undefined;
  }

  get hasDeviation() {
    return this._hasDeviation;
  }

  get keyCompute() {
    return this._keyCompute;
  }

  get isDoubleSupport() {
    return this._isDoubleSupport;
  }

  get formatType() {
    return this._formatType;
  }

  get formatOptions() {
    return this._formatOptions;
  }

  description(unit = this._unit) {
    if (this._description) {
      return this._description(unit);
    }

    return [];
  }

  convert(value, unit = this._unit) {
    if (this._convert && value !== undefined) {
      return this._convert(value, unit);
    }

    return value;
  }

  compute(...values) {
    return this._compute(...values);
  }
}

export default Metric;
