import React from 'react';
import PropTypes from 'prop-types';
import {
  TableBody,
} from '@material-ui/core';

import ListContainer from '../container';

import ListTableHead from './list-table-head';
import RowMember from './row-member';

function ListMembers(props) {
  return (
    <ListContainer inProgress={props.inProgress}>
      <ListTableHead />
      <TableBody>
        { props.members.map(member => (
          <RowMember
            key={member.key}
            member={member}
          />
        ))}
      </TableBody>
    </ListContainer>
  );
}

ListMembers.propTypes = {
  members: PropTypes.arrayOf(PropTypes.object).isRequired,
  inProgress: PropTypes.bool.isRequired,
};

export default ListMembers;
