import React from 'react';
import PropTypes from 'prop-types';
import Card from '@material-ui/core/Card';
import CardHeader from '@material-ui/core/CardHeader';
import CardContent from '@material-ui/core/CardContent';
import Grid from '@material-ui/core/Grid';

import RecordInfo from './record-info';
import Translate from '../../components/display/translate';
import BipodalCop from '../../components/graph/bipodal-cop';
import EllipseStats from '../../components/record/ellipse-stats';

function StaticBipodalCOP(props) {
  const {
    records,
    recordsData,
    pdf,
  } = props;

  const pdfSize = (recordsData.length <= 2 ? 250 : 140);

  return (
    <Card>
      <CardHeader
        title={<Translate>globalCenterPressure</Translate>}
        subheader={<Translate>distributionAndEllipse</Translate>}
      />
      <CardContent>
        <Grid container direction="row" justify="space-around" alignItems="center" spacing={pdf ? 0 : 5}>
          { recordsData.map((data, index) => (
            <Grid item xs={pdf ? true : 12} sm={12} md={6} lg={4} key={records[index].key}>
              <RecordInfo record={records[index]} />
              <BipodalCop
                data={data[2]}
                ellipse={data[1].ellipseBipodalCop}
                width={pdf ? pdfSize : undefined}
                height={pdf ? pdfSize : undefined}
                pdf
              />
              <EllipseStats ellipse={data[1].ellipseBipodalCop} pdf />
            </Grid>
          ))}
        </Grid>
      </CardContent>
    </Card>
  );
}

StaticBipodalCOP.propTypes = {
  records: PropTypes.arrayOf(PropTypes.shape({
    key: PropTypes.string,
  })).isRequired,
  recordsData: PropTypes.arrayOf(PropTypes.arrayOf(PropTypes.any)).isRequired,
  pdf: PropTypes.bool,
};

StaticBipodalCOP.defaultProps = {
  pdf: false,
};

export default StaticBipodalCOP;
