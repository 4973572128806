import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import {
  Grid,
  Button,
  CircularProgress,
} from '@material-ui/core';

import PageHeader from '../components/display/page-header';
import PageTitle from '../components/display/page-title';
import PageSubtitle from '../components/display/page-subtitle';
import Translate from '../components/display/translate';

import PatientInfo from './components/patient-info';
import PatientExos from './components/patient-exos';
import EvolutionMetricsCard from './components/evolution-metrics-card';
import ActivityExos from './components/activity-exos';
import ListRecordsPorficWrapper from '../components/list/records/porfic-wrapper';

import { addSnackbarMessage } from '../actions/index';
import { fetchRecord } from '../firebase/firestore';
import { addLink, getPatient, listRecordsPatient } from '../firebase/functions';
import { getTraceAndStart } from '../firebase/performance';
import { displayName } from '../utils/patient';
import { filterDiosRecords, filterRehabRecords, sortRecords } from '../utils/records';
import { isStatic } from '../utils/record';

const styles = {
  buttonProgress: {
    position: 'absolute',
    top: '50%',
    left: '50%',
    marginTop: -12,
    marginLeft: -12,
  },
};

function mapStateToProps(state) {
  return ({
    orgaId: state.userProfile.orgaId,
  });
}

function Patient(props) {
  const [patientInfo, setPatientInfo] = useState({
    patient: undefined,
    conditions: undefined,
    isLoading: true,
  });
  const [recordsInfo, setRecordsInfo] = useState({ records: [], isLoading: true });
  const [exosInfo, setExosInfo] = useState({ records: [], isLoading: true });
  const [linkIsLoading, setLinkIsLoading] = useState(false);

  useEffect(() => {
    const traceGetPatient = getTraceAndStart('getPatient');
    getPatient({
      patientId: props.match.params.patientId,
      getConditions: true,
    }).then((res) => {
      traceGetPatient.stop();
      setPatientInfo({
        patient: res.data.patient,
        conditions: res.data.conditions,
        isLoading: false,
      });
    });

    // load dios (metrics) records
    const traceListRecordsPatient = getTraceAndStart('listRecordsPatient');
    const promiseRecordsDios = listRecordsPatient({ patientId: props.match.params.patientId, recordType: 'dios' })
      .then((res) => {
        traceListRecordsPatient.stop();
        return Promise.all([...new Set(res.data)].map(recordId => fetchRecord(recordId)));
      }).then(records => filterDiosRecords(records, true))
      .then(records => sortRecords(records))
      .then((records) => {
        setRecordsInfo({ records, isLoading: false });
        return Promise.resolve(records);
      });

    // load rehab records
    const traceListExosPatient = getTraceAndStart('listRecordsPatient');
    const promiseRecordsRehab = listRecordsPatient({ patientId: props.match.params.patientId, recordType: 'rehab' })
      .then((res) => {
        traceListExosPatient.stop();
        return Promise.all([...new Set(res.data)].map(recordId => fetchRecord(recordId)));
      }).then(records => filterRehabRecords(records))
      .then(records => sortRecords(records))
      .then((records) => {
        setExosInfo({ records, isLoading: false });
        return Promise.resolve(records);
      });

    // wait for both promises to complete before checking for 'walkingtest'
    // exercises and adding them in the list of records
    Promise.all([promiseRecordsDios, promiseRecordsRehab])
      .then(([diosRecords, rehabRecords]) => {
        const freeWalkRecords = rehabRecords.filter(i => i.recordType === 'walkingtest');
        if (freeWalkRecords.length === 0) {
          return;
        }
        const records = sortRecords([...diosRecords, ...freeWalkRecords]);
        setRecordsInfo({ records, isLoading: false });
      });
  }, []);

  function startLinkCreation() {
    setLinkIsLoading(true);
    // create a link with
    // - 1 clicks
    // - 7 days (added on the server)
    addLink({
      orgaId: props.orgaId,
      type: 'authorization',
      counter: 1,
      patientId: props.match.params.patientId,
    }).then((res) => {
      const url = window.location.href.replace(/patient\/.*/, `link/${res.data}`);
      props.dispatch(addSnackbarMessage({ type: 'link', data: { url } }));
      setLinkIsLoading(false);
    });
  }

  return (
    <React.Fragment>
      <PageHeader>
        <Grid container direction="row" justify="space-between" alignItems="flex-end">
          <Grid item>
            <PageTitle><Translate>patient</Translate></PageTitle>
            <PageSubtitle>{patientInfo.patient ? displayName(patientInfo.patient) : ''}</PageSubtitle>
          </Grid>
          <Grid item>
            <Grid container direction="column" justfy="space-evenly" alignItems="flex-end" spacing={2}>
              <Grid item>
                <Button
                  variant="outlined"
                  color="secondary"
                  component={Link}
                  to={`/patient/${props.match.params.patientId}/upload`}
                >
                  <Translate>upload</Translate>
                </Button>
              </Grid>
              <Grid item>
                { (patientInfo.isLoading === false) && (
                  <Button
                    variant="outlined"
                    color="primary"
                    disabled={linkIsLoading}
                    onClick={() => startLinkCreation()}
                  >
                    <Translate>generateAuthorizationLink</Translate>
                    { linkIsLoading && (
                      <CircularProgress size={24} style={styles.buttonProgress} />
                    )}
                  </Button>
                )}
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </PageHeader>
      <Grid container direction="column" justify="flex-start" alignItems="stretch" spacing={2}>
        <Grid item>
          <PatientInfo
            patient={patientInfo.patient}
            conditions={patientInfo.conditions}
            loading={patientInfo.isLoading}
            hasEditButton
          />
        </Grid>
        { exosInfo.records.length > 0 && (
          <Grid item>
            <ActivityExos records={exosInfo.records} />
          </Grid>
        )}
        <Grid item>
          <EvolutionMetricsCard
            records={recordsInfo.records
              .filter(i => i.recordType !== 'synchro' && !isStatic(i))
              .map(({ key, recordType }) => ({ key, recordType }))}
            inProgress={recordsInfo.isLoading}
          />
        </Grid>
        { exosInfo.records.length > 0 && (
          <Grid item>
            <PatientExos records={exosInfo.records} />
          </Grid>
        )}
        <Grid item>
          <ListRecordsPorficWrapper
            records={recordsInfo.records}
            inProgress={recordsInfo.isLoading}
          />
        </Grid>
      </Grid>
    </React.Fragment>
  );
}

Patient.propTypes = {
  match: PropTypes.shape({
    params: PropTypes.shape({
      patientId: PropTypes.string.isRequired,
    }),
  }).isRequired,
  dispatch: PropTypes.func.isRequired,

  orgaId: PropTypes.string.isRequired,
};

export default connect(mapStateToProps)(Patient);
