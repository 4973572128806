import React, { useMemo } from 'react';
import PropTypes from 'prop-types';

import LoadVariation from './load-variation';

import { zipLoads } from '../../utils/stride';

function DynamicLoadVariation({ strides }) {
  const [leftLoads, rightLoads] = useMemo(() => {
    const left = strides
      .filter(stride => stride.side === 'left')
      .map(stride => zipLoads(stride))
      .flat(1)
      .filter(t => t.length > 0);

    const right = strides
      .filter(stride => stride.side === 'right')
      .map(stride => zipLoads(stride))
      .flat(1)
      .filter(t => t.length > 0);

    return [left, right];
  }, [strides.length]);

  const averageLoads = useMemo(() => strides
    .filter(stride => 'avgLoad' in stride && 'clientTimestampsLoads' in stride)
    .map(stride => ({
      // link each average load to the first clientTimestampsLoads
      // available. This ensure the rectangles in the chart stay aligned with
      // the load variation graph.
      x: stride.clientTimestampsLoads[0] || stride.clientTimestamp,
      y: stride.avgLoad,
      side: stride.side,
    })), [strides.length]);

  return (
    <LoadVariation
      leftLoads={leftLoads}
      rightLoads={rightLoads}
      averageLoads={averageLoads}
      isDynamic
    />
  );
}

DynamicLoadVariation.propTypes = {
  strides: PropTypes.arrayOf(PropTypes.shape({
    side: PropTypes.oneOf(['left', 'right']),
  })).isRequired,
};

export default DynamicLoadVariation;
