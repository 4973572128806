import React, { useRef } from 'react';
import PropTypes from 'prop-types';
import Button from '@material-ui/core/Button';
import { makeStyles } from '@material-ui/core/styles';
import GetAppIcon from '@material-ui/icons/GetApp';

import Translate from '../../components/display/translate';

const useStyles = makeStyles(theme => ({
  anchor: {
    display: 'none',
  },
  leftIcon: {
    marginRight: theme.spacing(1),
  },
}));

function DownloadPressures({ filename, loadData }) {
  const classes = useStyles();
  const anchorRef = useRef();

  async function handleClick() {
    const url = await loadData();

    const xhr = new XMLHttpRequest();
    xhr.responseType = 'blob';
    xhr.onload = () => {
      const blob = xhr.response;
      anchorRef.current.href = URL.createObjectURL(blob);
      anchorRef.current.click();
    };
    xhr.open('GET', url);
    xhr.send();
  }

  return (
    <div>
      <a
        download={filename}
        type="text/csv"
        href="/"
        className={classes.anchor}
        ref={anchorRef}
      >
        download pressures
      </a>
      <Button onClick={handleClick} variant="outlined" color="primary" size="small">
        <GetAppIcon className={classes.leftIcon} />
        <Translate>pressuresData</Translate>
      </Button>
    </div>
  );
}

DownloadPressures.propTypes = {
  filename: PropTypes.string.isRequired,
  loadData: PropTypes.func.isRequired,
};

export default DownloadPressures;
