import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import Grid from '@material-ui/core/Grid';

import MetricTitle from './title';
import MetricUnit from './unit';
import Metric from '../../../utils/metrics/metric';

function mapStateToProps(state, ownProps) {
  return ({
    unit: state.settings.unitMetrics[ownProps.metric.key] || ownProps.metric.unit,
  });
}

function MetricGraphContainer(props) {
  const {
    metric,
    unit,
    children,
  } = props;

  return (
    <Grid container direction="column" justify="center" alignItems="stretch">
      <Grid item>
        <Grid container direction="column" justify="center" alignItems="center">
          <Grid item>
            <MetricTitle metric={metric} variant="subtitle1" />
          </Grid>
          <Grid item>
            <MetricUnit unit={unit} variant="caption" />
          </Grid>
        </Grid>
      </Grid>
      <Grid item>
        { children }
      </Grid>
    </Grid>
  );
}

MetricGraphContainer.propTypes = {
  metric: PropTypes.instanceOf(Metric).isRequired,
  unit: PropTypes.string.isRequired,
  children: PropTypes.element.isRequired,
};

export default connect(mapStateToProps)(MetricGraphContainer);
