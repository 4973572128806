import React from 'react';
import PropTypes from 'prop-types';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';

import Translate from '../../components/display/translate';
import MetricUnit from '../../components/metric/utils/unit';
import NumberDisplay from '../../components/display/number';

// re-implementation of `src/components/metric/large.jsx` to bypass all the
// `Metric` logic, which is too cumbersome
function StaticMetric({ title, children, unit }) {
  return (
    <Grid container direction="column" justify="center" alignItems="center">
      <Grid item>
        <Typography variant="subtitle1" color="initial" component="p">
          <Translate>{ title }</Translate>
        </Typography>
      </Grid>
      <Grid item>
        <Grid container direction="row" justify="center" alignItems="center" spacing={1}>
          <Grid item>
            <Typography variant="h3" component="p">
              {children}
            </Typography>
          </Grid>
          { unit && (
            <Grid item>
              <MetricUnit unit={unit} variant="h6" />
            </Grid>
          )}
        </Grid>
      </Grid>
    </Grid>
  );
}

StaticMetric.propTypes = {
  title: PropTypes.string.isRequired,
  children: PropTypes.element.isRequired,
  unit: PropTypes.string,
};

StaticMetric.defaultProps = {
  unit: undefined,
};

function StaticPanel({ record }) {
  return (
    <Card>
      <CardContent>
        <Grid container direction="row" justify="space-around" alignItems="center">
          <Grid item>
            <StaticMetric title="testDuration" unit="s">
              <NumberDisplay options={{ maximumSignificantDigits: 3 }}>
                {(record.durationPlanned || (record.stopTime - record.startTime)) / 1000}
              </NumberDisplay>
            </StaticMetric>
          </Grid>
          <Grid item>
            <StaticMetric title="testProtocol">
              <Translate>{record.staticSpec.protocol}</Translate>
            </StaticMetric>
          </Grid>
        </Grid>
      </CardContent>
    </Card>
  );
}

StaticPanel.propTypes = {
  record: PropTypes.shape({
    durationPlanned: PropTypes.number,
    startTime: PropTypes.number,
    stopTime: PropTypes.number,
    staticSpec: PropTypes.shape({
      protocol: PropTypes.string,
    }),
  }).isRequired,
};

export default StaticPanel;
