import firebase from './index';

const storage = firebase.storage();

function uploadRecordPressures(recordId, file, userId) {
  return storage.ref().child(`records/${recordId}/data_pressures.zip`).put(file, {
    customMetadata: {
      uploaderUserId: userId,
    },
  });
}

function uploadMetricCSV(recordId, file, userId) {
  return storage.ref().child(`records/${recordId}/metrics.csv`).put(file, {
    customMetadata: {
      uploaderUserId: userId,
    },
  });
}

function getRecordPressuresUrl(recordId) {
  return storage.ref().child(`records/${recordId}/data_pressures.zip`).getDownloadURL();
}

export {
  uploadRecordPressures,
  uploadMetricCSV,
  getRecordPressuresUrl,
};
