import React, { useMemo } from 'react';
import PropTypes from 'prop-types';

import InsolesDynamicPressures from '../../record/components/insoles-dynamic-pressures';

import usePressureCop from '../../utils/hooks/usePressureCop';
import usePressureCopStd from '../../utils/hooks/usePressureCopStd';
import usePressureLoad from '../../utils/hooks/usePressureLoad';

function PTI(props) {
  const {
    showMean,
    insoles,
    scale,
    processedPressures,
    processedRecord,
    leftStridePressures,
    rightStridePressures,
    leftStride,
    rightStride,
    pdf,
  } = props;

  const [leftPTI, rightPTI] = useMemo(() => {
    if (showMean) {
      return [
        processedPressures.meanPTI.left || [],
        processedPressures.meanPTI.right || [],
      ];
    }

    return [
      leftStridePressures.PTI || [],
      rightStridePressures.PTI || [],
    ];
  }, [showMean, leftStridePressures.key, rightStridePressures.key]);

  const [leftCop, rightCop] = usePressureCop(
    showMean,
    processedPressures,
    leftStridePressures,
    rightStridePressures,
  );
  const [leftCopStd, rightCopStd] = usePressureCopStd(processedPressures);
  const [leftLoad, rightLoad] = usePressureLoad(
    showMean,
    processedRecord,
    leftStride,
    rightStride,
  );

  return (
    <InsolesDynamicPressures
      insoles={insoles}
      leftCop={leftCop}
      rightCop={rightCop}
      leftCopStd={leftCopStd}
      rightCopStd={rightCopStd}
      leftPressures={leftPTI}
      rightPressures={rightPTI}
      leftLoadHeelStrike={leftLoad.loadHeelStrike1}
      leftLoadToeOff={leftLoad.loadToeOff}
      rightLoadHeelStrike={rightLoad.loadHeelStrike1}
      rightLoadToeOff={rightLoad.loadToeOff}
      scale={scale}
      showMean={showMean}
      pdf={pdf}
    />
  );
}

PTI.propTypes = {
  processedPressures: PropTypes.shape({
    meanPTI: PropTypes.shape({
      left: PropTypes.arrayOf(PropTypes.number),
      right: PropTypes.arrayOf(PropTypes.number),
    }),
  }).isRequired,
  // eslint-disable-next-line react/forbid-prop-types
  processedRecord: PropTypes.object.isRequired,
  insoles: PropTypes.arrayOf(PropTypes.object).isRequired,
  scale: PropTypes.func.isRequired,
  showMean: PropTypes.bool,
  leftStridePressures: PropTypes.shape({
    key: PropTypes.string,
    cop: PropTypes.arrayOf(PropTypes.object),
    PTI: PropTypes.arrayOf(PropTypes.number),
  }),
  rightStridePressures: PropTypes.shape({
    key: PropTypes.string,
    cop: PropTypes.arrayOf(PropTypes.object),
    PTI: PropTypes.arrayOf(PropTypes.number),
  }),
  // eslint-disable-next-line react/forbid-prop-types
  leftStride: PropTypes.object,
  // eslint-disable-next-line react/forbid-prop-types
  rightStride: PropTypes.object,
  pdf: PropTypes.bool,
};

PTI.defaultProps = {
  showMean: true,
  pdf: false,
  leftStridePressures: { cop: [] },
  rightStridePressures: { cop: [] },
  leftStride: {},
  rightStride: {},
};

export default PTI;
