import React from 'react';

import PageTitle from '../../components/display/page-title';
import Translate from '../../components/display/translate';

function RecordPageTitle() {
  return (
    <PageTitle>
      <Translate>record</Translate>
    </PageTitle>
  );
}

export default RecordPageTitle;
