import React from 'react';
import PropTypes from 'prop-types';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';

import Translate from '../display/translate';
import ColormapGraph from './colormap-graph';

function Colormap({ pdf, width }) {
  return (
    <Grid container direction="row" justify="center" alignItems="center" style={{ textAlign: 'center' }}>
      <Grid item xs={pdf ? 4 : 2}>
        <Typography variant="caption" align="right">
          <Translate>minPressure</Translate>
        </Typography>
      </Grid>
      <Grid item xs={pdf ? 4 : 8}>
        <ColormapGraph height={pdf ? 15 : undefined} width={width} pdf={pdf} />
      </Grid>
      <Grid item xs={pdf ? 4 : 2}>
        <Typography variant="caption" align="left">
          <Translate>maxPressure</Translate>
        </Typography>
      </Grid>
    </Grid>
  );
}

Colormap.propTypes = {
  pdf: PropTypes.bool,
  width: PropTypes.number,
};

Colormap.defaultProps = {
  pdf: false,
  width: undefined,
};

export default Colormap;
