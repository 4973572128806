import usePressures from './usePressures';

function usePressuresStrides(recordId, leftStride, rightStride) {
  const [leftPressures, leftInProgress] = usePressures(recordId, leftStride.key);
  const [rightPressures, rightInProgress] = usePressures(recordId, rightStride.key);

  return [leftPressures || {}, rightPressures || {}, leftInProgress || rightInProgress];
}

export default usePressuresStrides;
