import { useMemo } from 'react';

import { hasPressures } from '../stride';

function useStridePressureIndex(strides, index) {
  return useMemo(() => {
    let leftIndex = -1;
    let leftCounter = -1;
    let rightIndex = -1;
    let rightCounter = -1;

    for (let i = 0; i < strides.length && (leftCounter < index || rightCounter < index); i += 1) {
      if (hasPressures(strides[i])) {
        if (strides[i].side === 'left' && leftCounter < index) {
          leftCounter += 1;
          leftIndex = i;
        } else if (strides[i].side === 'right' && rightCounter < index) {
          rightCounter += 1;
          rightIndex = i;
        }
      }
    }

    return [
      (leftIndex !== -1) ? strides[leftIndex] : {},
      (rightIndex !== -1) ? strides[rightIndex] : {},
    ];
  }, [index, strides.length]);
}

export default useStridePressureIndex;
