import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import Typography from '@material-ui/core/Typography';
import Grid from '@material-ui/core/Grid';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import CardHeader from '@material-ui/core/CardHeader';
import ErrorOutlineIcon from '@material-ui/icons/ErrorOutline';
import { makeStyles } from '@material-ui/core/styles';
import { mean, deviation } from 'd3-array';

import Translate from '../../components/display/translate';
import NumberDisplay from '../../components/display/number';

import { getMetricUnitColor } from '../../utils/colors';
import { LOAD_UNITS } from '../../utils/metrics/load';

const useStyles = makeStyles(() => ({
  unit: {
    color: getMetricUnitColor(),
  },
}));

function LoadVariationMetric(props) {
  const {
    title,
    subheader,
    loads,
    startTimestamp,
    endTimestamp,
    loadUnit,
  } = props;
  const classes = useStyles();
  const [meanValue, std, hasValues] = useMemo(() => {
    const filtred = loads.filter(([c]) => c >= startTimestamp && c <= endTimestamp);

    return [
      mean(filtred, d => d[1]) || 0,
      deviation(filtred, d => d[1]) || 0,
      filtred.length > 0,
    ];
  }, [loads.length, startTimestamp, endTimestamp]);

  let child;
  if (!hasValues) {
    child = (
      <Grid container direction="column" justify="center" alignItems="center">
        <Grid item>
          <ErrorOutlineIcon style={{ color: '#868E96' }} />
        </Grid>
        <Grid item>
          <Typography variant="h6" component="p" style={{ color: '#868E96' }}>
            <Translate>noData</Translate>
          </Typography>
        </Grid>
        <Grid item xs={8}>
          <Typography variant="body2" align="center" component="p" style={{ color: '#868E96' }}>
            <Translate>selectGraphWithData</Translate>
          </Typography>
        </Grid>
      </Grid>
    );
  } else {
    // mostly a re-use of metrics/utils/ components
    child = (
      <Grid container direction="column" justify="center" alignItems="center" style={{ minHeight: '100px' }}>
        <Grid item>
          <Grid container direction="row" justify="center" alignItems="center" spacing={1}>
            <Grid item>
              <Typography variant="h3" component="p">
                <NumberDisplay options={{ maximumFractionDigits: 2 }}>{meanValue}</NumberDisplay>
              </Typography>
            </Grid>
            <Grid item>
              <Typography variant="h6" component="p" className={classes.unit}>
                { loadUnit }
              </Typography>
            </Grid>
          </Grid>
        </Grid>
        <Grid item>
          <Grid container direction="row" justify="center" alignItems="center" spacing={1}>
            <Grid item>
              <Typography variant="body2" component="p">
                <Translate>standardDeviation</Translate>
                :
              </Typography>
            </Grid>
            <Grid item>
              <Typography variant="body1" component="p">
                <NumberDisplay options={{ maximumFractionDigits: 2 }}>{std}</NumberDisplay>
              </Typography>
            </Grid>
            <Grid item>
              <Typography variant="body2" component="p" className={classes.unit}>
                { loadUnit }
              </Typography>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    );
  }

  return (
    <Card>
      <CardHeader
        title={title}
        subheader={subheader}
        titleTypographyProps={hasValues ? {} : { style: { color: '#CED4DA' } }}
        subheaderTypographyProps={hasValues ? {} : { style: { color: '#CED4DA' } }}
      />
      <CardContent>
        { child }
      </CardContent>
    </Card>
  );
}

LoadVariationMetric.propTypes = {
  title: PropTypes.node.isRequired,
  subheader: PropTypes.node.isRequired,
  loads: PropTypes.arrayOf(PropTypes.arrayOf(PropTypes.number)).isRequired,
  startTimestamp: PropTypes.number.isRequired,
  endTimestamp: PropTypes.number.isRequired,
  loadUnit: PropTypes.oneOf(LOAD_UNITS).isRequired,
};

export default LoadVariationMetric;
