import React from 'react';
import PropTypes from 'prop-types';
import {
  TableRow,
  TableCell,
  IconButton,
} from '@material-ui/core';
import DeleteIcon from '@material-ui/icons/Delete';

import { formatDate } from '../../../utils/display';

function RowAuthorization(props) {
  return (
    <TableRow>
      <TableCell>{props.authorization.orgaName}</TableCell>
      <TableCell>{formatDate(props.authorization.createdAt, 'fr-FR', { year: 'numeric', month: '2-digit', day: '2-digit' })}</TableCell>
      <TableCell align="right">
        <IconButton onClick={() => props.handleDelete(props.authorization.key)} aria-label="Delete"><DeleteIcon /></IconButton>
      </TableCell>
    </TableRow>
  );
}

RowAuthorization.propTypes = {
  handleDelete: PropTypes.func.isRequired,
  authorization: PropTypes.shape({
    key: PropTypes.string,
    createdAt: PropTypes.number,
    orgaName: PropTypes.string,
  }).isRequired,
};

export default RowAuthorization;
