import React from 'react';
import PropTypes from 'prop-types';
import Typography from '@material-ui/core/Typography';
import Grid from '@material-ui/core/Grid';

import Translate from '../display/translate';

import { ellipseArea, ellipseMedioLateralRange, ellipseAnteroPosteriorRange } from '../../utils/ellipse';

function EllipseStats({ ellipse, pdf }) {
  return (
    <Grid container direction="column" justify="flex-start" alignItems="center">
      <Grid item>
        <Typography variant={pdf ? 'caption' : 'body1'}>
          <Translate>ellipseConfidenceArea</Translate>
          {': '}
          {Math.round(ellipseArea(ellipse.rx, ellipse.ry))}
          {' mm²'}
        </Typography>
      </Grid>
      <Grid item>
        <Typography variant={pdf ? 'caption' : 'body1'}>
          <Translate>medioLateralRange</Translate>
          {': '}
          {Math.round(ellipseMedioLateralRange(ellipse.medioLateralWidth))}
          {' mm'}
        </Typography>
      </Grid>
      <Grid item>
        <Typography variant={pdf ? 'caption' : 'body1'}>
          <Translate>anteroPosteriorRange</Translate>
          {': '}
          {Math.round(ellipseAnteroPosteriorRange(ellipse.anteroPosteriorWidth))}
          {' mm'}
        </Typography>
      </Grid>
    </Grid>
  );
}

EllipseStats.propTypes = {
  ellipse: PropTypes.shape({
    rx: PropTypes.number,
    ry: PropTypes.number,
    medioLateralWidth: PropTypes.number,
    anteroPosteriorWidth: PropTypes.number,
  }).isRequired,
  pdf: PropTypes.bool,
};

EllipseStats.defaultProps = {
  pdf: false,
};

export default EllipseStats;
