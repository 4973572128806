import React from 'react';
import PropTypes from 'prop-types';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';

import Translate from '../../components/display/translate';

function PatientInformationDialog({ isOpen, onValidate, onClose }) {
  return (
    <Dialog
      open={isOpen}
      onClose={onClose}
      aria-labelledby="patient-information-title"
      aria-describedby="patient-information-description"
    >
      <DialogTitle id="patient-information-title">
        <Translate>patientInformation</Translate>
      </DialogTitle>
      <DialogContent>
        <Translate parseHTML id="patient-information-description">
          legalInformationPatient
        </Translate>
      </DialogContent>
      <DialogActions>
        <Button onClick={onValidate} color="primary" autoFocus>
          <Translate>IUnderstand</Translate>
        </Button>
      </DialogActions>
    </Dialog>
  );
}

PatientInformationDialog.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  onValidate: PropTypes.func.isRequired,
  onClose: PropTypes.func.isRequired,
};

export default PatientInformationDialog;
