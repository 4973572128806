import React from 'react';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router-dom';

import PageHeader from '../components/display/page-header';
import PageTitle from '../components/display/page-title';
import Translate from '../components/display/translate';

import ListPatients from '../components/list/patients/index';

function Patients({ history }) {
  return (
    <React.Fragment>
      <PageHeader>
        <PageTitle><Translate>patients</Translate></PageTitle>
      </PageHeader>
      <ListPatients handleRowClick={patient => history.push(`/patient/${patient.id}/`)} />
    </React.Fragment>
  );
}

Patients.propTypes = {
  // eslint-disable-next-line react/forbid-prop-types
  history: PropTypes.object.isRequired,
};

export default withRouter(Patients);
