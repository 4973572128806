import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import Grid from '@material-ui/core/Grid';
import { makeStyles } from '@material-ui/core/styles';

import InsolePressure from '../../components/insole/insole-pressure';
import Load from './load';

import { getLeft, getRight } from '../../utils/insoles';

const useStyles = makeStyles({
  root: {
    display: 'flex',
    flexWrap: 'nowrap',
    justifyContent: 'center',
    gap: ({ pdf }) => (pdf ? '5px' : '30px'),
  },
  load: {
    minHeight: ({ pdf }) => (pdf ? '23.5px' : '52px'),
  },
});

function InsolesDynamicPressures(props) {
  const {
    insoles,
    leftCop,
    rightCop,
    leftCopStd,
    rightCopStd,
    leftPressures,
    rightPressures,
    leftLoadHeelStrike,
    leftLoadToeOff,
    rightLoadHeelStrike,
    rightLoadToeOff,
    scale,
    showMean,
    pdf,
    ...otherProps
  } = props;

  const classes = useStyles(props);
  const leftInsole = useMemo(() => getLeft(insoles), [insoles.length]);
  const rightInsole = useMemo(() => getRight(insoles), [insoles.length]);

  return (
    <div className={classes.root}>
      <div>
        <Grid container direction="column" justify="flex-start" alignItems="center">
          <Grid item className={classes.load}>
            <Load value={leftLoadHeelStrike} />
          </Grid>
          <Grid item>
            <InsolePressure
              pressure={leftPressures}
              cop={leftCop}
              copStd={leftCopStd}
              insole={leftInsole}
              scale={scale}
              displayCopStd={showMean}
              height={pdf ? 150 : undefined}
              {...otherProps}
            />
          </Grid>
          <Grid item className={classes.load}>
            <Load value={leftLoadToeOff} />
          </Grid>
        </Grid>
      </div>
      <div>
        <Grid container direction="column" justify="flex-start" alignItems="center">
          <Grid item className={classes.load}>
            <Load value={rightLoadHeelStrike} />
          </Grid>
          <Grid item>
            <InsolePressure
              pressure={rightPressures}
              cop={rightCop}
              copStd={rightCopStd}
              insole={rightInsole}
              scale={scale}
              displayCopStd={showMean}
              height={pdf ? 150 : undefined}
              {...otherProps}
            />
          </Grid>
          <Grid item className={classes.load}>
            <Load value={rightLoadToeOff} />
          </Grid>
        </Grid>
      </div>
    </div>
  );
}

InsolesDynamicPressures.propTypes = {
  insoles: PropTypes.arrayOf(PropTypes.object).isRequired,
  leftCop: PropTypes.arrayOf(PropTypes.arrayOf(PropTypes.number)).isRequired,
  rightCop: PropTypes.arrayOf(PropTypes.arrayOf(PropTypes.number)).isRequired,
  leftCopStd: PropTypes.arrayOf(PropTypes.shape({
    x: PropTypes.number,
    y: PropTypes.number,
  })),
  rightCopStd: PropTypes.arrayOf(PropTypes.shape({
    x: PropTypes.number,
    y: PropTypes.number,
  })),
  leftPressures: PropTypes.arrayOf(PropTypes.number),
  rightPressures: PropTypes.arrayOf(PropTypes.number),
  leftLoadHeelStrike: PropTypes.number,
  leftLoadToeOff: PropTypes.number,
  rightLoadHeelStrike: PropTypes.number,
  rightLoadToeOff: PropTypes.number,
  scale: PropTypes.func.isRequired,
  showMean: PropTypes.bool,
  pdf: PropTypes.bool,
};

InsolesDynamicPressures.defaultProps = {
  leftLoadHeelStrike: undefined,
  leftLoadToeOff: undefined,
  rightLoadHeelStrike: undefined,
  rightLoadToeOff: undefined,
  leftPressures: [],
  rightPressures: [],
  leftCopStd: [],
  rightCopStd: [],
  showMean: true,
  pdf: false,
};

export default InsolesDynamicPressures;
