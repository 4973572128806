import React from 'react';
import PropTypes from 'prop-types';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import { scaleLinear } from 'd3-scale';

import ColormapPressureGraph from './colormap-pressure-graph';
import MetricUnit from '../metric/utils/unit';

function ColormapPressure(props) {
  const {
    domain,
    colorInterpolation,
    unit,
    width,
    numberTicks,
  } = props;

  return (
    <Grid container direction="column" justify="flex-start" alignItems="center">
      <Grid item style={{ marginBottom: 2 }}>
        <MetricUnit unit={unit} />
      </Grid>
      <Grid item>
        <ColormapPressureGraph colorInterpolation={colorInterpolation} width={width} />
      </Grid>
      <Grid item style={{ width }}>
        <Grid container direction="row" justify="space-between" alignItems="flex-start" style={{ width: '100%' }}>
          { scaleLinear().domain(domain).ticks(numberTicks).map(i => (
            <Grid item key={i}>
              <Typography variant="caption">{ i }</Typography>
            </Grid>
          ))}
        </Grid>
      </Grid>
    </Grid>
  );
}

ColormapPressure.propTypes = {
  colorInterpolation: PropTypes.func.isRequired,
  domain: PropTypes.arrayOf(PropTypes.number).isRequired,
  width: PropTypes.number,
  unit: PropTypes.string,
  numberTicks: PropTypes.number,
};

ColormapPressure.defaultProps = {
  width: 300,
  unit: 'kg/cm²',
  numberTicks: 8,
};

export default ColormapPressure;
