import React from 'react';
import PropTypes from 'prop-types';

import Grid from '@material-ui/core/Grid';
import Slider from '@material-ui/core/Slider';
import IconButton from '@material-ui/core/IconButton';
import Typography from '@material-ui/core/Typography';

import KeyboardArrowLeftIcon from '@material-ui/icons/KeyboardArrowLeft';
import KeyboardArrowRightIcon from '@material-ui/icons/KeyboardArrowRight';

function StrideSlider(props) {
  const {
    index,
    setIndex,
    disabled,
    nbStrides,
  } = props;

  return (
    <Grid container spacing={2} alignItems="center">
      <Grid item>
        <IconButton
          aria-label="previous stride"
          disabled={disabled || index <= 0}
          onClick={() => setIndex(index - 1)}
        >
          <KeyboardArrowLeftIcon />
        </IconButton>
      </Grid>
      <Grid item>
        <IconButton
          aria-label="next stride"
          disabled={disabled || index >= (nbStrides - 1)}
          onClick={() => setIndex(index + 1)}
        >
          <KeyboardArrowRightIcon />
        </IconButton>
      </Grid>
      <Grid item xs>
        <Slider
          value={index}
          max={nbStrides - 1}
          onChange={(ev, v) => setIndex(v)}
          disabled={disabled}
        />
      </Grid>
      <Grid item>
        <Typography>
          {`${index} / ${nbStrides - 1}`}
        </Typography>
      </Grid>
    </Grid>
  );
}

StrideSlider.propTypes = {
  index: PropTypes.number.isRequired,
  setIndex: PropTypes.func.isRequired,
  disabled: PropTypes.bool.isRequired,
  nbStrides: PropTypes.number.isRequired,
};

export default StrideSlider;
