import React from 'react';
import PropTypes from 'prop-types';

import MetricGraphContainer from '../metric/utils/graph-container';
import BoxPlot from '../d3/box-plot';

import Metric from '../../utils/metrics/metric';

function RecordGraphBoxPlot(props) {
  const {
    metric,
    values,
    pdf,
  } = props;

  return (
    <MetricGraphContainer metric={metric}>
      <BoxPlot
        values={values}
        width={pdf ? 180 : undefined}
        height={pdf ? 300 : undefined}
        pdf={pdf}
      />
    </MetricGraphContainer>
  );
}

RecordGraphBoxPlot.propTypes = {
  metric: PropTypes.instanceOf(Metric).isRequired,
  values: PropTypes.arrayOf(PropTypes.shape({
    x: PropTypes.number.isRequired,
    median: PropTypes.number,
    min: PropTypes.number,
    max: PropTypes.number,
    quantile1: PropTypes.number,
    quantile3: PropTypes.number,
  })).isRequired,
  pdf: PropTypes.bool,
};

RecordGraphBoxPlot.defaultProps = {
  pdf: false,
};

export default RecordGraphBoxPlot;
