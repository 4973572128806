import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { withStyles } from '@material-ui/core/styles';
import { Link } from 'react-router-dom';
import {
  Grid,
  Toolbar,
  Button,
  Typography,
} from '@material-ui/core';
import { lighten } from '@material-ui/core/styles/colorManipulator';

import Translate from '../../display/translate';
import DatePicker from '../../pickers/date';
import { isStatic } from '../../../utils/record';

const styles = theme => ({
  root: {
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(2),
  },
  highlight: {
    color: theme.palette.secondary.main,
    backgroundColor: lighten(theme.palette.secondary.light, 0.85),
  },
  toolbarMargin: {
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(2),
  },
});

function getButton(selected) {
  const numSelected = selected.length;

  if (numSelected === 0 || numSelected > 5) {
    return undefined;
  }

  if (numSelected === 1) {
    return (
      <Button variant="outlined" component={Link} to={`record/${selected[0].key}`}>
        <Translate>visualize</Translate>
      </Button>
    );
  }

  const isSameType = selected
    .map(record => isStatic(record))
    .every((elt, i, array) => elt === array[0]);

  if (isSameType) {
    return (
      <Button variant="outlined" component={Link} to={`records/compare/${selected.map(i => i.key).join(',')}`}>
        <Translate>compare</Translate>
      </Button>
    );
  }

  return undefined;
}

class ListToolbar extends React.Component {
  constructor() {
    super();

    this.state = {
      date: null,
    };
  }

  render() {
    const { classes, selected } = this.props;
    const numSelected = selected.length;

    return (
      <Toolbar
        className={classNames(classes.root, {
          [classes.highlight]: numSelected > 0,
        })}
      >
        <Grid container direction="row" justify="space-between" alignItems="flex-start" className={classes.toolbarMargin}>
          <Grid item>
            <Grid container direction="column" justify="flex-start" alignItems="flex-start" spacing={2}>
              <Grid item>
                {numSelected > 0 ? (
                  <Typography color="inherit" variant="h6">
                    <Translate input={{ NB: numSelected }}>XrecordsSelected</Translate>
                  </Typography>
                ) : (
                  <Typography variant="h5">
                    <Translate>recordList</Translate>
                  </Typography>
                )}
              </Grid>
              { this.props.onDateSelection !== undefined && (
                <Grid item>
                  <DatePicker
                    id="date-record"
                    label="searchByDate"
                    value={this.state.date}
                    onDateChange={date => this.setState({ date })}
                    onDateAccept={this.props.onDateSelection}
                  />
                </Grid>
              )}
            </Grid>
          </Grid>
          <Grid item>
            <Grid container direction="row" justify="flex-end" alignItems="flex-end" spacing={2}>
              { (this.props.onLinkRecord && numSelected > 0) && (
                <Grid item>
                  <Button variant="outlined" onClick={() => this.props.onLinkRecord(selected)}>
                    <Translate>addToPatientFolder</Translate>
                  </Button>
                </Grid>
              )}
              <Grid item>
                { getButton(selected) }
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Toolbar>
    );
  }
}

ListToolbar.propTypes = {
  // eslint-disable-next-line react/forbid-prop-types
  classes: PropTypes.object.isRequired,

  selected: PropTypes.arrayOf(PropTypes.object).isRequired,
  onDateSelection: PropTypes.func,
  onLinkRecord: PropTypes.func,
};

ListToolbar.defaultProps = {
  onDateSelection: undefined,
  onLinkRecord: undefined,
};

export default withStyles(styles)(ListToolbar);
