import { isOnClient } from './env';

function getPathAssets(absolute = false) {
  let res = '/assets';
  if (process.env.NODE_ENV === 'production') {
    res = `/dashboard${res}`;
  }

  if (absolute) {
    res = `${window.location.origin}${res}`;
  }

  if (!isOnClient()) {
    // env variable set by backend
    res = `https://${window.pdfData.dialDNS}/dashboard/assets`;
  }

  return res;
}

function getPathLangs(absolute) {
  return `${getPathAssets(absolute)}/lang`;
}

function getPathImages(absolute) {
  return `${getPathAssets(absolute)}/images`;
}

function getPathSvg(absolute) {
  return `${getPathAssets(absolute)}/svg`;
}

function getPathInsoles(absolute) {
  return `${getPathAssets(absolute)}/insoles`;
}

export {
  getPathLangs,
  getPathImages,
  getPathSvg,
  getPathInsoles,
};
