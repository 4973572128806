import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import SimpleMenu from '../../components/selection-controls/simple-menu';

import { updateIntervalDailyGraphs, updateRecordDisplayTimestamps } from '../../actions/index';

const LIST_INTERVALS = [
  { key: `${15 * 60 * 1000}`, label: '15Minutes' },
  { key: `${30 * 60 * 1000}`, label: '30Minutes' },
  { key: `${1 * 60 * 60 * 1000}`, label: '1Hour' },
  { key: `${2 * 60 * 60 * 1000}`, label: '2Hours' },
];

function mapStateToProps(state) {
  return ({
    intervalMs: state.intervalDailyGraphs,
  });
}

class IntervalSelectionDailyGraphs extends React.Component {
  constructor() {
    super();

    this.handleSelect = this.handleSelect.bind(this);
  }

  handleSelect(intervalMs) {
    // XXX: why are we dispatching the timestamps ?
    this.props.dispatch(updateRecordDisplayTimestamps(
      this.props.defaultStartTimestamp,
      this.props.defaultEndTimestamp,
    ));
    this.props.dispatch(updateIntervalDailyGraphs(Number(intervalMs)));
  }

  render() {
    return (
      <div>
        <SimpleMenu
          id="interval-selection"
          value={`${this.props.intervalMs}`}
          options={LIST_INTERVALS}
          onSelect={interval => this.handleSelect(interval)}
        />
      </div>
    );
  }
}

IntervalSelectionDailyGraphs.propTypes = {
  dispatch: PropTypes.func.isRequired,

  intervalMs: PropTypes.number.isRequired,
  defaultStartTimestamp: PropTypes.number.isRequired,
  defaultEndTimestamp: PropTypes.number.isRequired,
};

export default connect(mapStateToProps)(IntervalSelectionDailyGraphs);
