import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { withTheme } from '@material-ui/core/styles';

import { select } from 'd3-selection';
import { scaleBand, scaleLinear } from 'd3-scale';
import { axisBottom } from 'd3-axis';

import { translateNoDL } from '../../utils/i18n';
import { styleAxis } from '../../utils/d3/styling';
import { getLeftColor, getRightColor } from '../../utils/colors';
import { formatNumber } from '../../utils/display';

function mapStateToProps(state) {
  return ({
    locale: state.settings.locale,
  });
}

class BoxCompare extends React.Component {
  componentDidMount() {
    const svg = select(this.node)
      .append('svg');

    const xScale = scaleBand();
    const yScale = scaleLinear();

    const mainContainer = svg.append('g');
    const xContainer = mainContainer.append('g');
    const yContainer = mainContainer.append('g');
    const graphContainer = mainContainer.append('g');

    this.el = {
      svg,
      xScale,
      yScale,
      mainContainer,
      xContainer,
      yContainer,
      graphContainer,
    };

    this.sizes = {
      w: this.props.width,
      h: this.props.height,
      margin: {
        top: 5,
        bottom: 25,
        left: 0,
        right: 0,
      },
    };

    // this.init();
    this.draw();
  }

  componentDidUpdate(prevProps) {
    if (prevProps.meanLeft !== this.props.meanLeft
      || prevProps.meanRight !== this.props.meanRight
      || prevProps.deviationLeft !== this.props.deviationLeft
      || prevProps.deviationRight !== this.props.deviationRight) {
      this.draw();
    }
  }

  draw() {
    const width = this.sizes.w - this.sizes.margin.left - this.sizes.margin.right;
    const height = this.sizes.h - this.sizes.margin.top - this.sizes.margin.bottom;

    this.el.svg
      .attr('width', this.sizes.w)
      .attr('height', this.sizes.h);

    this.el.mainContainer
      .attr('transform', `translate(${this.sizes.margin.left},${this.sizes.margin.top})`);

    this.el.xScale
      .domain([0, 1])
      .range([0, width])
      .paddingInner([0.1])
      .paddingOuter([0.1])
      .align([0.5]);

    this.el.yScale
      .range([height, 0]);

    this.el.xContainer
      .attr('transform', `translate(0, ${height})`)
      .call(axisBottom(this.el.xScale)
        .ticks(2)
        .tickSize(0)
        .tickPadding(7)
        .tickFormat(d => translateNoDL((d === 0 ? 'Left' : 'Right'), this.props.locale)))
      .select('.domain').remove();
    styleAxis(this.el.xContainer, this.props.theme);
    this.el.xContainer.selectAll('text').attr('font-size', this.props.pdf ? '12px' : '1rem');
    this.el.yScale
      .domain([0, Math.max(
        this.props.meanLeft + this.props.deviationLeft,
        this.props.meanRight + this.props.deviationRight,
      )]);

    const rects = this.el.graphContainer.selectAll('rect')
      .data([this.props.meanLeft, this.props.meanRight]);

    rects.exit().remove();

    rects.enter().append('rect')
      .attr('fill', (d, i) => (i === 0 ? getLeftColor() : getRightColor()))
      .merge(rects)
      .attr('x', (d, i) => this.el.xScale(i))
      .attr('width', this.el.xScale.bandwidth())
      .attr('y', d => this.el.yScale(d))
      .attr('height', d => Math.abs(this.el.yScale(d) - this.el.yScale(0)));

    const bandwidth = this.el.xScale.bandwidth();
    const linesName = ['left top', 'left vertical', 'right top', 'right vertical'];

    this.el.graphContainer.selectAll('line').data(linesName).enter().append('line')
      .attr('fill', 'none')
      .attr('stroke', 'black')
      .attr('class', d => d);

    if (this.props.meanLeft > 0 || this.props.meanRight > 0) {
      this.el.graphContainer.selectAll('line.top')
        .attr('x1', (d, i) => this.el.xScale(i) + bandwidth / 4)
        .attr('x2', (d, i) => this.el.xScale(i) + (bandwidth * 3) / 4)
        .attr('opacity', (d, i) => ((i === 0 ? this.props.meanLeft : this.props.meanRight) > 0 ? 1 : 0));
      this.el.graphContainer.selectAll('line.vertical')
        .attr('x1', (d, i) => this.el.xScale(i) + bandwidth / 2)
        .attr('x2', (d, i) => this.el.xScale(i) + bandwidth / 2);
    }

    if (this.props.meanLeft > 0) {
      this.el.graphContainer.selectAll('line.left')
        .attr('y1', () => this.el.yScale(this.props.deviationLeft + this.props.meanLeft));
      this.el.graphContainer.selectAll('line.left.top')
        .attr('y2', () => this.el.yScale(this.props.deviationLeft + this.props.meanLeft));
      this.el.graphContainer.selectAll('line.left.vertical')
        .attr('y2', () => this.el.yScale(this.props.meanLeft));
    }

    if (this.props.meanRight > 0) {
      this.el.graphContainer.selectAll('line.right')
        .attr('y1', () => this.el.yScale(this.props.deviationRight + this.props.meanRight));
      this.el.graphContainer.selectAll('line.right.top')
        .attr('y2', () => this.el.yScale(this.props.deviationRight + this.props.meanRight));
      this.el.graphContainer.selectAll('line.right.vertical')
        .attr('y2', () => this.el.yScale(this.props.meanRight));
    }

    const textsName = ['left', 'right'];
    this.el.graphContainer.selectAll('text').data(textsName).enter().append('text')
      .attr('class', d => d)
      .attr('font-family', 'Roboto')
      .attr('font-size', this.props.pdf ? '14px' : '18px')
      .attr('fill', 'white')
      .style('text-anchor', 'middle')
      .style('dominant-baseline', 'central');

    this.el.graphContainer.selectAll('text')
      .attr('x', (d, i) => this.el.xScale(i) + bandwidth / 2);
    this.el.graphContainer.selectAll('text.left')
      .attr('y', () => this.el.yScale(this.props.meanLeft / 2))
      .text(() => formatNumber(
        this.props.meanLeft,
        this.props.locale,
        { maximumFractionDigits: 1 },
      ));
    this.el.graphContainer.selectAll('text.right')
      .attr('y', () => this.el.yScale(this.props.meanRight / 2))
      .text(() => formatNumber(
        this.props.meanRight,
        this.props.locale,
        { maximumFractionDigits: 1 },
      ));
  }

  render() {
    return (
      <div
        style={{ textAlign: 'center' }}
        ref={(node) => {
          this.node = node;
          return undefined;
        }}
      />
    );
  }
}

BoxCompare.propTypes = {
  // eslint-disable-next-line react/forbid-prop-types
  theme: PropTypes.object.isRequired,

  locale: PropTypes.string.isRequired,
  meanLeft: PropTypes.number,
  meanRight: PropTypes.number,
  deviationLeft: PropTypes.number,
  deviationRight: PropTypes.number,

  width: PropTypes.number,
  height: PropTypes.number,
  pdf: PropTypes.bool,
};

BoxCompare.defaultProps = {
  meanLeft: 0,
  meanRight: 0,
  deviationLeft: 0,
  deviationRight: 0,
  width: 150,
  height: 200,
  pdf: false,
};

export default connect(mapStateToProps)(withTheme(BoxCompare));
