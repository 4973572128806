import { useEffect, useRef, useState } from 'react';

import { getIdToken } from '../../firebase/auth';
import useFrequency from './useFrequency';
import { wsRecordReplay } from '../ws';

function useReplay(recordId, framerate = 20, meshX = 15) {
  const wsRef = useRef(undefined);
  const [recordInfo, setRecordInfo] = useState({});
  const frameRef = useRef(undefined);
  // keep the last index and isPlaying state to restart the ws on timeout
  const lastIndexRef = useRef(0);
  const lastIsPlayingRef = useRef(false);
  // keep the state if are we starting a new ws or is it restart
  const hasRestartedRef = useRef(false);

  const [frequency, frequencyRef] = useFrequency();
  const [error, setError] = useState(undefined);

  function handlePlay() {
    if (wsRef.current !== undefined) {
      wsRef.current.send(Uint8Array.of(1));
    }
  }

  function handlePause() {
    if (wsRef.current !== undefined) {
      wsRef.current.send(Uint8Array.of(2));
    }
  }

  function handleSeek(index) {
    if (wsRef.current !== undefined) {
      const buffer = new ArrayBuffer(1 + 4);
      const dataView = new DataView(buffer);
      dataView.setUint8(0, 3);
      dataView.setUint32(1, index, true);
      wsRef.current.send(buffer);
    }
  }

  async function initWs() {
    if (wsRef.current !== undefined) {
      wsRef.current.close();
    }

    wsRef.current = await wsRecordReplay(recordId, framerate, meshX, meshX * 3);

    wsRef.current.addEventListener('open', async () => {
      const idToken = await getIdToken(false);
      const buffer = new Uint8Array(1 + idToken.length);
      // id
      buffer[0] = 0;

      const encoder = new TextEncoder();
      const view = encoder.encode(idToken);
      view.forEach((value, index) => {
        buffer[index + 1] = value;
      });

      wsRef.current.send(buffer);
    });

    wsRef.current.addEventListener('message', async (ev) => {
      // flush the error
      setError(undefined);
      const data = JSON.parse(ev.data);
      if (data.type === 'frame') {
        frameRef.current = data;
        lastIndexRef.current = frameRef.current.index;
      } else if (data.type === 'meta') {
        // if we're on a restart
        if (hasRestartedRef.current) {
          // we want to set the ws like it previously was, at the proper index
          // and the correct state (pause vs index)
          handleSeek(lastIndexRef.current);
          if (lastIsPlayingRef.current) {
            handlePlay();
          }
          hasRestartedRef.current = false;
        } else {
          setRecordInfo({
            nbFrames: data.nbFrames,
            minPressure: data.minPressure,
            maxPressure: data.maxPressure,
            isPlaying: data.isPlaying,
          });

          lastIsPlayingRef.current = data.isPlaying;
        }
      } else {
        // eslint-disable-next-line no-console
        console.log('ws message: ', data.msg);
      }

      frequencyRef.current += 1;
    });

    wsRef.current.addEventListener('close', (ev) => {
      // if the ws timeout we restart it
      // this happens due to Cloud Run having a timeout request
      if (ev.code === 1006) {
        hasRestartedRef.current = true;
        initWs();
      }
    });

    wsRef.current.addEventListener('error', (ev) => {
      setError(ev);
    });
  }

  useEffect(() => {
    initWs();

    return () => {
      if (wsRef.current !== undefined) {
        wsRef.current.close();
      }
    };
  }, [framerate, meshX]);

  return {
    frameRef,
    recordInfo,
    handlePlay,
    handlePause,
    handleSeek,
    ws: wsRef.current,
    wsFrequency: frequency,
    error,
  };
}

export default useReplay;
