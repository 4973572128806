import React, {
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import PropTypes from 'prop-types';
import Grid from '@material-ui/core/Grid';
import { scaleLinear } from 'd3-scale';
import { bisector, extent } from 'd3-array';
import { zoom } from 'd3-zoom';
import { select, event as currentEvent } from 'd3-selection';
import addMilliseconds from 'date-fns/addMilliseconds';
import intervalToDuration from 'date-fns/intervalToDuration';

import {
  Graph,
  useGraphDimensions,
  Rects,
  MouseContainer,
} from '@feetme/d3act';

import Path from './utils/path';
import Axis from './utils/axis';
import Tooltip from './utils/tooltip';
import TooltipInfo from './utils/tooltip-info';
import Translate from '../display/translate';
import { bisectData, distanceCoordinates } from '../../utils/d3';
import { getLeftColor, getRightColor } from '../../utils/colors';
import { LOAD_UNITS } from '../../utils/metrics/load';

function GraphSumLoadVariation(props) {
  const {
    leftLoads,
    rightLoads,
    xDomain,
    zoomTransform,
    onZoom,
    height,
    width,
    selection,
    loadUnit,
  } = props;

  const [tooltipOpacity, setTooltipOpacity] = useState(0);
  const [tooltipTransform, setTooltipTransform] = useState('');
  const [tooltipValue, setTooltipValue] = useState(32);

  const [ref, dimensions] = useGraphDimensions({
    height,
    width,
  });
  const scrollWarningRef = useRef(null);

  const xAccessor = d => d[0];
  const yAccessor = d => d[1];

  const xScale = useMemo(() => {
    const x = scaleLinear()
      .domain(xDomain)
      .range([0, dimensions.boundedWidth]);

    if (zoomTransform !== null) {
      return zoomTransform.transform.rescaleX(x);
    }

    return x;
  }, [...xDomain, dimensions.boundedWidth, zoomTransform]);

  const yScale = useMemo(() => {
    const leftExtentY = extent(leftLoads, yAccessor);
    const rightExtentY = extent(rightLoads, yAccessor);
    return scaleLinear()
      .domain([0, extent([...leftExtentY, ...rightExtentY])[1]])
      .range([dimensions.boundedHeight, 0])
      .nice();
  }, [leftLoads.length, rightLoads.length]);

  const xAccessorScaled = d => xScale(xAccessor(d));
  const yAccessorScaled = d => yScale(yAccessor(d));

  function handleMouseMove(_, mouseX, mouseY) {
    // we want to skip the mouse move event if the scroll warning is enabled
    if (scrollWarningRef !== null && select(scrollWarningRef.current).style('opacity') === '1') {
      return;
    }

    const bisect = bisector(d => xAccessor(d)).left;
    setTooltipOpacity(1);

    const left = bisectData(
      bisect,
      leftLoads,
      mouseX,
      xScale.invert(mouseX),
      xAccessorScaled,
    );

    const right = bisectData(
      bisect,
      rightLoads,
      mouseX,
      xScale.invert(mouseX),
      xAccessorScaled,
    );

    // find the closest point in the left and right path and find the closest
    // one with the mouse position
    const distanceLeft = distanceCoordinates(
      mouseX,
      mouseY,
      xAccessorScaled(left[0]),
      yAccessorScaled(left[0]),
    );
    const distanceRight = distanceCoordinates(
      mouseX,
      mouseY,
      xAccessorScaled(right[0]),
      yAccessorScaled(right[0]),
    );
    const [d, i] = distanceLeft > distanceRight ? right : left;

    const x = xAccessorScaled(d, i) + dimensions.marginLeft;
    const y = yAccessorScaled(d, i) + dimensions.marginTop;

    setTooltipTransform(`translate(calc(-50% + ${x}px), calc(-100% + ${y}px - 15px))`);
    setTooltipValue(Math.round(yAccessor(d, i)));
  }

  function handleMouseLeave(clearDebounce) {
    // clear the debounce to ensure the tooltip will not show after the mouse
    // left the graph
    if (clearDebounce) {
      clearDebounce();
    }

    setTooltipOpacity(0);
  }

  function debounce(fn, timeout = 100) {
    let timeoutId;
    return [
      (...args) => {
        clearTimeout(timeoutId);
        timeoutId = setTimeout(() => fn.apply(this, args), timeout);
      },
      () => clearTimeout(timeoutId),
    ];
  }

  // debounce the handleMouseMove function. Since the computation of the two
  // `bisect` function can take some time, it is better to wait for the user
  // to stop moving the mouse.
  const [handleMouseMoveDebounced, clearMouseMoveDebounce] = debounce(handleMouseMove);

  function scrollWarningDisplay(shouldDisplay) {
    if (scrollWarningRef === null) {
      return;
    }

    select(scrollWarningRef.current).transition().duration(250).style('opacity', shouldDisplay ? 1 : 0);
    // if we display the scroll warning we also:
    // - clear the mouse move debounce
    // - hide the tooltip
    clearMouseMoveDebounce();
    setTooltipOpacity(0);
  }

  function zoomed() {
    scrollWarningDisplay(false);
    onZoom(currentEvent);
  }

  useEffect(() => {
    const zoomExtent = [
      [0, 0],
      [dimensions.boundedWidth, dimensions.boundedHeight],
    ];

    const z = zoom()
      // enable the zoom if either the ctrl or meta (Mac OS) key is pressed
      .filter(() => ((currentEvent.ctrlKey || currentEvent.metaKey)
        || (currentEvent.type !== 'wheel' && currentEvent.button === 0)))
      .scaleExtent([1, Infinity])
      .translateExtent(zoomExtent)
      .extent(zoomExtent)
      .on('zoom', zoomed);

    // selecting the svg should be enough but since we're changing the origin
    // of the graph (with the `<g>` in the `<Graph>` component), it's easier to
    // use the g component instead of having to update the zoom transform to
    // ignore the left margin
    const svg = select(ref.current).select('svg').select('g');
    svg
      .call(z)
      .on('wheel', () => {
        // if a scroll event is triggered on the svg, show warning if the ctrl
        // key or meta key is pressed
        if (currentEvent.ctrlKey || currentEvent.metaKey) {
          currentEvent.preventDefault();
        } else {
          scrollWarningDisplay(true);
        }
      })
      .on('mouseleave', () => scrollWarningDisplay(false));

    return () => svg.on('.zoom', null);
  }, [dimensions.boundedWidth, dimensions.boundedHeight]);

  return (
    <div ref={ref} style={{ position: 'relative', height }}>
      <Tooltip
        opacity={tooltipOpacity}
        transform={tooltipTransform}
      >
        <Grid container direction="column" justify="flex-start" alignItems="stretch">
          <Grid item>
            <TooltipInfo title="load" value={tooltipValue} unit={loadUnit} />
          </Grid>
        </Grid>
      </Tooltip>
      <Graph dimensions={dimensions}>
        <Axis
          dimension="x"
          scale={xScale}
          keyAccessor={d => d}
          fullTick
          tickStyle={{ strokeDasharray: '5,5' }}
          formatTick={(d) => {
            const start = new Date(0);
            const end = addMilliseconds(new Date(0), d);

            const duration = intervalToDuration({ start, end });
            const zeroPad = num => String(num).padStart(2, '0');

            return `${zeroPad(duration.minutes + duration.hours * 60)}:${zeroPad(duration.seconds)}`;
          }}
        />
        <Axis
          dimension="y"
          scale={yScale}
          keyAccessor={d => d}
        />
        <clipPath id="clip-path">
          <rect
            x={0}
            y={0}
            width={dimensions.boundedWidth}
            height={dimensions.boundedHeight}
          />
        </clipPath>
        <Rects
          data={selection}
          keyAccessor={d => `${d.x1}${d.x2}`}
          xAccessor={d => xScale(d.x1)}
          yAccessor={() => 0}
          widthAccessor={d => xScale(d.x2) - xScale(d.x1)}
          heightAccessor={() => dimensions.boundedHeight}
          fillAccessor="#F0F6FF"
          opacity={0.8}
          clipPath="url(#clip-path)"
        />
        <Path
          data={leftLoads}
          xAccessor={xAccessorScaled}
          yAccessor={yAccessorScaled}
          stroke={getLeftColor()}
          clipPath="url(#clip-path)"
        />
        <Path
          data={rightLoads}
          xAccessor={xAccessorScaled}
          yAccessor={yAccessorScaled}
          stroke={getRightColor()}
          clipPath="url(#clip-path)"
        />
        <MouseContainer
          rootRef={ref}
          onMouseMove={handleMouseMoveDebounced}
          onMouseLeave={() => handleMouseLeave(clearMouseMoveDebounce)}
        />
        <g
          ref={scrollWarningRef}
          pointerEvents="none"
          opacity={0}
        >
          <rect
            x={0}
            y={0}
            width={dimensions.boundedWidth}
            height={dimensions.boundedHeight}
            opacity={0.6}
          />
          <text
            x={dimensions.boundedWidth / 2}
            y={dimensions.boundedHeight / 2}
            textAnchor="middle"
            fill="white"
            fontSize="32px"
          >
            <Translate>{navigator.userAgent.toLowerCase().includes('mac') ? 'cmdScrollToZoom' : 'ctrlScroolToZoom'}</Translate>
          </text>
        </g>
      </Graph>
    </div>
  );
}

GraphSumLoadVariation.propTypes = {
  leftLoads: PropTypes.arrayOf(PropTypes.arrayOf(PropTypes.number)).isRequired,
  rightLoads: PropTypes.arrayOf(PropTypes.arrayOf(PropTypes.number)).isRequired,
  xDomain: PropTypes.arrayOf(PropTypes.number).isRequired,
  // eslint-disable-next-line react/forbid-prop-types
  zoomTransform: PropTypes.object,
  onZoom: PropTypes.func.isRequired,
  selection: PropTypes.arrayOf(PropTypes.shape({
    x1: PropTypes.number,
    x2: PropTypes.number,
  })).isRequired,
  loadUnit: PropTypes.oneOf(LOAD_UNITS).isRequired,

  width: PropTypes.number,
  height: PropTypes.number,
};

GraphSumLoadVariation.defaultProps = {
  width: 0,
  height: 250,
  zoomTransform: null,
};

export default React.memo(GraphSumLoadVariation);
