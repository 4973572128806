import {
  red,
  pink,
  purple,
  deepPurple,
  indigo,
  blue,
  lightBlue,
  cyan,
  teal,
  green,
  lightGreen,
  amber,
  orange,
  deepOrange,
  brown,
  grey,
  blueGrey,
} from '@material-ui/core/colors';

const avatarColors = [
  red,
  pink,
  purple,
  deepPurple,
  indigo,
  blue,
  lightBlue,
  cyan,
  teal,
  green,
  lightGreen,
  amber,
  orange,
  deepOrange,
  brown,
  grey,
  blueGrey,
];

function getColorAvatar(input) {
  let counter = 0;
  input.split('').map((char) => {
    counter += char.charCodeAt();
    return undefined;
  });

  return avatarColors[counter % avatarColors.length][500];
}

function riskFallingColor(isValid) {
  if (isValid) {
    return green[500];
  }

  return red[500];
}

function getPalette() {
  // https://material.io/tools/color/#!/?view.left=0&view.right=0&primary.color=1976D2&secondary.color=FFA000
  return ({
    primary: {
      main: blue[700],
      light: '#63a4ff',
      dark: '#004ba0',
      contrastText: '#ffffff',
    },
    secondary: {
      main: amber[700],
      light: '#ffd149',
      dark: '#c67100',
      contrastText: '#000000',
    },
  });
}

function getGaugeColorEmpty() {
  return '#757575';
}

function getMetricUnitColor() {
  return 'rgba(0, 0, 0, 0.54)';
}

function getMetricDescriptionColor() {
  return 'rgba(0, 0, 0, 0.64)';
}

function getLeftColor() {
  return '#2986FF';
}

function getRightColor() {
  return '#B9D8FF';
}

const COLORMAP_GRADIENT = [
  { value: 0, color: 'white' },
  { value: 0.125, color: '#B6FFFF' },
  { value: 0.25, color: '#61FFFF' },
  { value: 0.375, color: '#32FFFF' },
  { value: 0.5, color: '#0DD1FF' },
  { value: 0.625, color: '#2B75FF' },
  { value: 0.875, color: '#3322FF' },
  { value: 1, color: '#1501FF' },
];

const PRESSURE_GRADIENT = [
  'white',
  '#22FFFF',
  '#04FF73',
  '#39FC00',
  '#DCF300',
  '#FFB600',
  '#FF6900',
  '#FF0000',
];

export {
  getColorAvatar,
  riskFallingColor,
  getPalette,
  getGaugeColorEmpty,
  COLORMAP_GRADIENT,
  getMetricUnitColor,
  getMetricDescriptionColor,
  getLeftColor,
  getRightColor,
  PRESSURE_GRADIENT,
};
