import React from 'react';
import PropTypes from 'prop-types';
import {
  Grid,
  Card,
  CardContent,
  CardHeader,
} from '@material-ui/core';

import Translate from '../../components/display/translate';
import AsymmetricalMetricsSelection from './asymmetrical-metrics-selection';
import GaitCycleMetricsSelection from './gaitcycle-metrics-selection';

function MetricsSelection({ settingsUpdated }) {
  return (
    <Card>
      <CardHeader title={<Translate>metricsSelection</Translate>} />
      <CardContent>
        <Grid container direction="row" justify="flex-start" alignItems="flex-start">
          <Grid item>
            <AsymmetricalMetricsSelection settingsUpdated={settingsUpdated} />
          </Grid>
          <Grid item>
            <GaitCycleMetricsSelection settingsUpdated={settingsUpdated} />
          </Grid>
        </Grid>
      </CardContent>
    </Card>
  );
}

MetricsSelection.propTypes = {
  settingsUpdated: PropTypes.func.isRequired,
};

export default MetricsSelection;
