import Metric from './metric';

export default new Metric({
  title: 'strideCounter',
  //
  // Empty Spaces
  //
  // What shall we use to fill the empty
  // Spaces where we used to talk?
  // How shall I fill the final places
  // How should I complete the wall?
  //
  unit: '\u00A0',
  key: 'strideCounter',
  compute: values => values.length,
});
