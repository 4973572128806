import { useEffect, useState } from 'react';

import {
  Velocity,
  StrideLength,
  RiskFalling,
  Cadence,
  OscillationPhase,
  SupportPhase,
} from '../metrics/index';
import { fetchDiosStrides } from '../../firebase/firestore';
import { filterWithFlag } from '../strides';
import { sortArrayNumber, statArray } from '../array';
import { computeMeanCapa } from '../metric';
import { isMetric } from '../record';

const MAIN_METRICS = [
  Cadence,
  Velocity,
  StrideLength,
];

const SPATIO_TEMPORAL_METRICS = [
  OscillationPhase,
  SupportPhase,
];

function getCapa(strides, side) {
  return strides
    .filter(stride => stride.capa && stride.capa.length > 0 && side === stride.side)
    .map(stride => stride.capa);
}

async function processRecord(record, units) {
  const strides = filterWithFlag(await fetchDiosStrides(record.key));
  if (strides.length === 0) {
    return undefined;
  }

  const recordData = {
    startTime: record.startTime,
    stopTime: record.stopTime,
  };

  [...MAIN_METRICS, RiskFalling, ...SPATIO_TEMPORAL_METRICS].forEach((metric) => {
    recordData[metric.key] = {};

    // TODO check units
    const unit = units[metric.key] || metric.unit;

    if (metric.keyCompute) {
      // on some metric it's not possible to compute other data
      // like for RiskFalling, or Distance metrics
      //
      // We could use `metric.hasCompute` for this 'if' but we choose
      // to use a default function on the Metric object if a metric
      // hasn't specified a compute function
      recordData[metric.key] = {
        y: metric.compute(strides.map(i => ({
          x: i.clientTimestamp,
          y: i[metric.keyCompute],
        }))),
      };
    } else {
      // perform the sort only once
      const sortedValues = sortArrayNumber(strides.map(i => i[metric.key]));
      ['mean', 'deviation', 'median', 'min', 'max', 'quantile1', 'quantile3'].forEach((i) => {
        recordData[metric.key][i === 'mean' ? 'y' : i] = metric.convert(
          statArray(sortedValues, i, false),
          unit,
        );
        return undefined;
      });
    }

    if (isMetric(record)) {
      // get the capa
      recordData.meanCapa = {
        left: computeMeanCapa(getCapa(strides, 'left')),
        right: computeMeanCapa(getCapa(strides, 'right')),
      };
    }

    // will be used a 'x' value
    recordData.clientTimestamp = record.startTime;
  });

  return recordData;
}

function useDynamicRecordsComparaison(records, unitsStore) {
  const [recordsData, setRecordsData] = useState([]);
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    async function wrapper() {
      const data = [];
      await records.reduce(async (acc, record) => {
        await acc;
        data.push(await processRecord(record, unitsStore));
        return Promise.resolve();
      }, Promise.resolve());

      setRecordsData(data.filter(i => i !== undefined));
      setIsLoading(false);
    }

    wrapper();
  }, [records.length]);

  return [recordsData, isLoading];
}

export default useDynamicRecordsComparaison;
