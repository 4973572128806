import React from 'react';
import PropTypes from 'prop-types';
import {
  Grid,
  TextField,
} from '@material-ui/core';

import Translate from '../display/translate';
import SimpleSelect from '../selection-controls/simple-select';

import { getScale } from '../../utils/fhir/pathologies';
import {
  hasValueField,
  getMinValueField,
  getMaxValueField,
  getStepValueField,
  checkValueField,
} from '../../utils/fhir/scales';

class ScaleForm extends React.Component {
  constructor() {
    super();

    this.state = {
      valueHasChanged: false,
    };
  }

  mergeScale(updates) {
    return { ...this.props.scaleObj, ...updates };
  }

  render() {
    const { scaleObj } = this.props;
    const { type } = scaleObj;

    return (
      <Grid container direction="column" justify="flex-start" alignItems="stretch" spacing={2}>
        <Grid item>
          <SimpleSelect
            id={`scale-${this.props.code}`}
            title={type === 'pathology' ? 'stage' : 'clinicalScale'}
            value={scaleObj.code}
            options={getScale(type, this.props.code)}
            onChange={(ev) => {
              // on change of code, reset the value - it is not valable anymore
              this.props.onChange(this.mergeScale({ code: ev.target.value, value: '' }));
              this.setState({ valueHasChanged: false });
            }}
            style={{ margin: 0, minWidth: 200 }}
            useEmptyOption
            // translate to "Aucun" in french instead to "Aucune" by default
            // it's a lot easier than sending input to translate for messageformat
            textNone="noneF"
          />
        </Grid>
        <Grid item>
          {hasValueField(scaleObj.code) && (
            <TextField
              label={<Translate>scaleValue</Translate>}
              error={(!checkValueField(scaleObj.value, scaleObj.code))
                  && this.state.valueHasChanged === true}
              value={scaleObj.value}
              type="number"
              inputProps={{
                min: getMinValueField(scaleObj.code),
                max: getMaxValueField(scaleObj.code),
                step: getStepValueField(scaleObj.code),
              }}
              onChange={(ev) => {
                this.props.onChange(this.mergeScale({ value: ev.target.value }));
                this.setState({ valueHasChanged: true });
              }}
            />
          )}
        </Grid>
      </Grid>
    );
  }
}

ScaleForm.propTypes = {
  code: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
  scaleObj: PropTypes.shape({
    type: PropTypes.oneOf(['clinical', 'pathology']).isRequired,
    code: PropTypes.string.isRequired,
    value: PropTypes.string.isRequired,
  }).isRequired,
};

export default ScaleForm;
