// Conditions codes
const CODE_MSCLEROSIS = '24700007';
const CODE_PARKINSON = '49049000';
const CODE_STROKE = '230690007';

// Pathology scales
const SCALE_CODE_PRIMARY_PROGRESSIVE = '428700003';
const SCALE_CODE_SECONDARY_PROGRESSIVE = '425500002';
const SCALE_CODE_RELAPSING_REMITTING = '426373005';
const SCALE_CODE_HY = '716371002';
const SCALE_CODE_ACUTE = '18131002';
const SCALE_CODE_SUBACUTE = '19939008';
const SCALE_CODE_CHRONIC = '90734009';

// Clinical scales
const SCALE_CODE_EDSS = '273554001';
const SCALE_CODE_UPDRS = '77720-1';

export {
  CODE_MSCLEROSIS,
  CODE_PARKINSON,
  CODE_STROKE,
  SCALE_CODE_PRIMARY_PROGRESSIVE,
  SCALE_CODE_SECONDARY_PROGRESSIVE,
  SCALE_CODE_RELAPSING_REMITTING,
  SCALE_CODE_HY,
  SCALE_CODE_ACUTE,
  SCALE_CODE_SUBACUTE,
  SCALE_CODE_CHRONIC,
  SCALE_CODE_EDSS,
  SCALE_CODE_UPDRS,
};
