import React from 'react';
import PropTypes from 'prop-types';

import Translate from './translate';

function DisplayWalkingAid({ children }) {
  const typeChild = (
    <span style={{ textTransform: 'lowercase' }}>
      <Translate>{ children.type }</Translate>
    </span>
  );

  if (children.side) {
    return (
      <React.Fragment>
        { typeChild }
        {' '}
        <Translate>{ children.side === 'left' ? 'onTheLeft' : 'onTheRight' }</Translate>
      </React.Fragment>
    );
  }
  return typeChild;
}

DisplayWalkingAid.propTypes = {
  children: PropTypes.shape({
    side: PropTypes.oneOf(['left', 'right']),
    type: PropTypes.string.isRequired,
  }).isRequired,
};

export default DisplayWalkingAid;
