import React from 'react';
import PropTypes from 'prop-types';
import { TableBody } from '@material-ui/core';

import ListContainer from '../container';

import ListTableHead from './list-table-head';
import RowAuthorization from './row-authorization';

function ListAuthorizations(props) {
  return (
    <ListContainer inProgress={props.inProgress}>
      <ListTableHead />
      <TableBody>
        { props.authorizations.map(authorization => (
          <RowAuthorization
            handleDelete={props.handleDelete}
            key={authorization.key}
            authorization={authorization}
          />
        ))}
      </TableBody>
    </ListContainer>
  );
}

ListAuthorizations.propTypes = {
  handleDelete: PropTypes.func.isRequired,
  authorizations: PropTypes.arrayOf(PropTypes.object).isRequired,
  inProgress: PropTypes.bool.isRequired,
};

export default ListAuthorizations;
