import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { withStyles } from '@material-ui/core/styles';
import { Switch, Route, Redirect } from 'react-router-dom';

import ToolBarDrawer from './components/toolbar-drawer';
import DialogFormDisplayName from './components/dialog-form-display-name';
import SnackbarCenter from './components/snackbar-center';
import ProgressWait from '../components/display/progress-wait';

import Patients from '../patients/index';
import Patient from '../patient/index';
import Organisation from '../organisation/index';
import RecordsNotLinked from '../records-not-linked/index';
import NewPatient from '../new-patient/index';
import EditPatient from '../edit-patient/index';
import Records from '../records/index';
import CompareRecords from '../compare-records/index';
import Record from '../record/index';
import Exercises from '../exercises/index';
import Upload from '../upload/index';
import Settings from '../settings/index';
import User from '../user/index';
import Link from '../link/index';
import Authorizations from '../authorizations/index';

import { fetchSettings, fetchCurrentUserProfile } from '../firebase/firestore';
import { fetchLangFile } from '../utils/i18n';

import { updateSettings, updateUserOrgaId } from '../actions/index';

const styles = theme => ({
  root: {
    display: 'flex',
  },
  toolbar: theme.mixins.toolbar,
  content: {
    flexGrow: 1,
    padding: theme.spacing(3),
  },
});

function getSwitch(isInOrga) {
  if (isInOrga) {
    return (
      <Switch>
        <Route exact path="/patients" component={Patients} />
        <Route exact path="/patient/new" component={NewPatient} />
        <Route exact path="/patient/:patientId" component={Patient} />
        <Route exact path="/patient/:patientId/edit" component={EditPatient} />
        <Route exact path="/patient/:patientId/upload" component={Upload} />
        <Route exact path="/patient/:patientId/record/:recordId" component={Record} />
        <Route exact path="/patient/:patientId/records/compare/:recordsId" component={CompareRecords} />
        <Route exact path="/records-not-linked" component={RecordsNotLinked} />
        <Route exact path="/records/compare/:recordsId" component={CompareRecords} />
        <Route exact path="/record/:recordId" component={Record} />
        <Route exact path="/organisation" component={Organisation} />
        <Route exact path="/user" component={User} />
        <Route exact path="/settings" component={Settings} />
        <Route exact path="/link/:linkId" component={Link} />
        <Redirect to="/patients" />
      </Switch>
    );
  }

  return (
    <Switch>
      <Route exact path="/records" component={Records} />
      <Route exact path="/records/compare/:recordsId" component={CompareRecords} />
      <Route exact path="/record/:recordId" component={Record} />
      <Route exact path="/exercises" component={Exercises} />
      <Route exact path="/upload" component={Upload} />
      <Route exact path="/authorizations" component={Authorizations} />
      <Route exact path="/user" component={User} />
      <Route exact path="/settings" component={Settings} />
      <Route exact path="/link/:linkId" component={Link} />
      <Redirect to="/records" />
    </Switch>
  );
}

function mapStateToProps(state) {
  return ({
    isInOrga: state.userProfile.orgaId !== undefined,
  });
}

class App extends React.Component {
  constructor() {
    super();

    this.state = {
      isReady: false,
    };
  }

  componentDidMount() {
    fetchSettings().then((settings) => {
      if (settings !== undefined) {
        this.props.dispatch(updateSettings(settings));
        if (settings.locale) {
          return fetchLangFile(settings.locale);
        }
      }
      return Promise.resolve();
    }).then(() => fetchCurrentUserProfile())
      .then((userProfile) => {
        if (userProfile && userProfile.orgaId) {
          this.props.dispatch(updateUserOrgaId(userProfile.orgaId));
        }

        return Promise.resolve();
      })
      .then(() => this.setState({ isReady: true }));
  }

  render() {
    const { classes } = this.props;

    if (this.state.isReady === false) {
      return (<ProgressWait marginTop={40} />);
    }

    return (
      <div className={classes.root}>
        <ToolBarDrawer />
        <DialogFormDisplayName />
        <SnackbarCenter />
        <main className={classes.content}>
          <div className={classes.toolbar} />
          { getSwitch(this.props.isInOrga) }
        </main>
      </div>
    );
  }
}

App.propTypes = {
  // eslint-disable-next-line react/forbid-prop-types
  classes: PropTypes.object.isRequired,
  dispatch: PropTypes.func.isRequired,
  isInOrga: PropTypes.bool.isRequired,
};

export default connect(mapStateToProps)(withStyles(styles)(App));
