import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import { Typography } from '@material-ui/core';
import { blueGrey } from '@material-ui/core/colors';

import Translate from '../../display/translate';

const useStyles = makeStyles({
  root: {
    fontSize: '12px',
    color: blueGrey[600],
  },
  valueAndUnit: {
    color: blueGrey[800],
    fontWeight: 'bold',
  },
});

function TooltipInfo(props) {
  const {
    title,
    value,
    unit,
    translateValue,
  } = props;
  const classes = useStyles();

  return (
    <Typography className={classes.root}>
      <Translate>{title}</Translate>
      { value !== '' && (
        <Fragment>
          {': '}
          <span className={classes.valueAndUnit}>
            { translateValue ? <Translate>{value}</Translate> : <span>{value}</span> }
            { unit !== '' && (
              <Fragment>
                {' '}
                <Translate>{unit}</Translate>
              </Fragment>
            )}
          </span>
        </Fragment>
      )}
    </Typography>
  );
}

TooltipInfo.propTypes = {
  title: PropTypes.string.isRequired,
  value: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  unit: PropTypes.string,
  translateValue: PropTypes.bool,
};

TooltipInfo.defaultProps = {
  value: '',
  unit: '',
  translateValue: false,
};

export default TooltipInfo;
