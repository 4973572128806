import React from 'react';
import PropTypes from 'prop-types';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';

import Metric from '../../../utils/metrics/metric';

import MetricValue from './value';

function MetricSmallStandardDeviation(props) {
  const {
    value,
    metric,
    variant,
  } = props;

  if (value === undefined) {
    return null;
  }

  const typographyArray = [
    // add a non breaking space
    '[σ:\u00a0',
    (<MetricValue metric={metric} value={value} />),
    ']',
  ];

  return (
    <Grid container direction="row" justify="center" alignItems="center">
      { typographyArray.map(i => (
        <Grid item key={i}>
          <Typography variant={variant} component="span">
            { i }
          </Typography>
        </Grid>
      ))}
    </Grid>
  );
}

MetricSmallStandardDeviation.propTypes = {
  metric: PropTypes.instanceOf(Metric).isRequired,
  value: PropTypes.number,
  variant: PropTypes.string,
};

MetricSmallStandardDeviation.defaultProps = {
  variant: 'body2',
  value: undefined,
};

export default MetricSmallStandardDeviation;
