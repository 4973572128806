//
// Internationalization file
//
// used to fetch the langfile, using the locale, and translate a string
//
import * as MessageFormat from 'messageformat';
import { getPathLangs } from './assets';

let mf;
if (process.env.NODE_ENV !== 'test') {
  mf = new MessageFormat();
}
const langObj = {
  locale: undefined,
  obj: {},
};

// Fetch the langfile
//
// Use the current langFile if the locales match
function fetchLangFile(locale) {
  if (langObj.locale === locale) {
    return Promise.resolve(langObj.obj);
  }

  return fetch(`${getPathLangs()}/${locale}.json`)
    .then(response => response.json())
    .then((langfile) => {
      langObj.locale = locale;
      langObj.obj = langfile;
      return langObj.obj;
    });
}

// Find the translated string from a key
//
// if a langfile[key] match returns it
// otherwise return the key
function findValue(key, langfile) {
  if (langfile[key] !== undefined) {
    return langfile[key];
  }

  return key;
}

function formatMessage(message, input) {
  if (input !== undefined && mf !== undefined) {
    return mf.compile(message)(input);
  }

  return message;
}

// translate 'key' in the 'locale' language
//
// retrieve the langfile, if necessary.
function translate(key, locale, input = undefined) {
  if (locale === undefined || key === undefined) {
    return Promise.resolve(key);
  }

  return fetchLangFile(locale)
    .then(langfile => findValue(key, langfile))
    .then(value => formatMessage(value, input))
    .catch((err) => {
      // eslint-disable-next-line no-console
      console.error(`Error while translating ${key} into ${locale} language: ${err}`);
      return Promise.resolve(key);
    });
}

function translateNoDL(key, locale, input = undefined) {
  if (locale === undefined || key === undefined || locale !== langObj.locale) {
    return key;
  }

  return formatMessage(findValue(key, langObj.obj), input);
}

export {
  fetchLangFile,
  translate,
  translateNoDL,
};
