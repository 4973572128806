import React from 'react';
import PropTypes from 'prop-types';
import {
  Grid,
  Card,
  CardContent,
  LinearProgress,
} from '@material-ui/core';

import RecordGraphStable from '../../components/record/graph-stable';
import RecordGraphHistogram from '../../components/record/graph-histogram';
import RecordGraphBoxPlot from '../../components/record/graph-box-plot';
import FallingRiskHover from '../../components/metric/falling-risk-hover';

import Metric from '../../utils/metrics/metric';
import {
  Velocity,
  StrideLength,
  RiskFalling,
  Cadence,
} from '../../utils/metrics/index';

function getGraphComponent(metric, graphVariant, recordsData) {
  const values = recordsData.map(record => ({
    x: record.clientTimestamp,
    ...record[metric.key],
  }));

  if (graphVariant === 'curve-stable-x') {
    return (<RecordGraphStable metric={metric} values={values} />);
  }

  // A metric without deviation cannot use the box plot graph
  if (!metric.hasDeviation) {
    return (<RecordGraphHistogram metric={metric} values={values} />);
  }

  return (<RecordGraphBoxPlot metric={metric} values={values} />);
}

function MainMetricsCard(props) {
  const {
    metrics,
    graphVariant,
    recordsData,
  } = props;

  return (
    <Card>
      { props.inProgress && (
        <LinearProgress />
      )}
      <CardContent>
        <Grid container direction="row" justify="space-around" alignItems="center">
          { metrics.map(metric => (
            <Grid item xs={12} md={6} lg={3} key={metric.key} id={`metric-${metric.key}-graph-pdf`}>
              { getGraphComponent(metric, graphVariant, recordsData) }
            </Grid>
          ))}
          <FallingRiskHover xs={12} md={6} lg={3} disable={props.disableFallingRisk}>
            <div id={`metric-${RiskFalling.key}-graph-pdf`}>
              { getGraphComponent(RiskFalling, graphVariant, recordsData) }
            </div>
          </FallingRiskHover>
        </Grid>
      </CardContent>
    </Card>
  );
}

MainMetricsCard.propTypes = {
  inProgress: PropTypes.bool.isRequired,
  graphVariant: PropTypes.oneOf(['curve-stable-x', 'boxplot']).isRequired,
  recordsData: PropTypes.arrayOf(PropTypes.object).isRequired,
  metrics: PropTypes.arrayOf(PropTypes.instanceOf(Metric)),
  disableFallingRisk: PropTypes.bool,
};

MainMetricsCard.defaultProps = {
  disableFallingRisk: false,
  metrics: [
    Cadence,
    Velocity,
    StrideLength,
  ],
};

export default MainMetricsCard;
