import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import {
  Grid,
  Card,
  CardHeader,
  CardContent,
} from '@material-ui/core';

import Metric from '../../utils/metrics/metric';
import Translate from '../../components/display/translate';
import BoxCompareMetric from '../../components/metric/box-compare';

import { getAllAsymmetricalMetrics } from '../../utils/metric';

function mapStateToProps(state) {
  return ({
    selected: getAllAsymmetricalMetrics()
      .filter(metric => state.settings.asymmetricalMetricsSelected.indexOf(metric.key) > -1),
  });
}

function AsymmetricalParametersCard(props) {
  if (props.selected.length === 0) {
    return null;
  }

  return (
    <Card>
      <CardHeader title={<Translate>asymmetricalParameters</Translate>} />
      <CardContent>
        <Grid container direction="row" justify="space-around" alignItems="center">
          { props.selected.map(metric => (
            <Grid item key={metric.key}>
              <BoxCompareMetric
                metric={metric}
                strides={props.strides}
                pdf={props.pdf}
                strideTimestampFilter
              />
            </Grid>
          ))}
        </Grid>
      </CardContent>
    </Card>
  );
}

AsymmetricalParametersCard.propTypes = {
  selected: PropTypes.arrayOf(PropTypes.instanceOf(Metric)).isRequired,
  strides: PropTypes.arrayOf(PropTypes.object).isRequired,
  pdf: PropTypes.bool,
};

AsymmetricalParametersCard.defaultProps = {
  pdf: false,
};

export default connect(mapStateToProps)(AsymmetricalParametersCard);
