import firebase from './index';
import { getCurrentUser } from './auth';
import {
  userRef,
  recordRef,
  insoleRef,
  walkingAidRef,
  strideRef,
  linkRef,
  orgaRef,
  memberRef,
  settingRef,
  authorizationRef,
  processedRef,
  processedPressuresRef,
  pressureRef,
  copRef,
  bipodalCopRef,
} from './ref';
import { isLocalTarget } from '../utils/env';

const DB = firebase.firestore();

if (isLocalTarget()) {
  DB.settings({
    host: 'localhost:8080',
    ssl: false,
  });
}

const DIAL_SETTINGS_DOCUMENT = 'dial';

const FETCH_RECORDS_LIMIT = 20;

// TODO use a ref files (cf firebase/rules tests)

// save the key of the document into the document data
function saveDocumentKey(doc, key = 'key') {
  if (!doc.exists) {
    return undefined;
  }
  return { ...doc.data(), [key]: doc.id };
}

// convert a firestore snapshot to an array
// the key of the document is available in the document data
function snapshotToArray(querySnapshot, key) {
  return querySnapshot.docs.map(doc => saveDocumentKey(doc, key));
}

function getFirestoreDB() {
  return DB;
}

//
// FETCH
//

// Records
function fetchDiosRecords(
  authorId,
  {
    limit = FETCH_RECORDS_LIMIT,
    startAfter = 0,
    endBefore = 0,
    lastStopTime = undefined,
  } = {},
) {
  let request = recordRef(DB)
    .where('authorId', '==', authorId)
    .where('type', '==', 'dios');

  if (startAfter > 0) {
    request = request.where('stopTime', '>', startAfter);
  }

  if (endBefore > 0) {
    request = request.where('stopTime', '<', endBefore);
  }

  request = request.orderBy('stopTime', 'desc');

  if (lastStopTime !== undefined) {
    request = request.startAfter(lastStopTime);
  }

  return request
    .limit(limit)
    .get()
    .then(querySnapshot => snapshotToArray(querySnapshot));
}

function fetchExercicesRecords(authorId, limit = FETCH_RECORDS_LIMIT) {
  return recordRef(DB)
    .where('authorId', '==', authorId)
    .where('type', '==', 'rehab')
    .orderBy('stopTime', 'desc')
    .limit(limit)
    .get()
    .then(querySnapshot => snapshotToArray(querySnapshot));
}

function fetchRecord(recordId) {
  return recordRef(DB, recordId).get().then(doc => saveDocumentKey(doc));
}

function watchRecord(recordId, callback) {
  return recordRef(DB, recordId).onSnapshot(doc => callback(saveDocumentKey(doc)));
}

function fetchRecordInsoles(recordId) {
  return insoleRef(recordRef(DB, recordId)).get()
    .then(querySnapshot => snapshotToArray(querySnapshot, 'macAddress'));
}

function fetchRecordWalkingAids(recordId) {
  return walkingAidRef(recordRef(DB, recordId)).get()
    .then(querySnapshot => snapshotToArray(querySnapshot));
}

function fetchDiosStrides(recordId) {
  return strideRef(recordRef(DB, recordId)).orderBy('clientTimestamp', 'asc').get()
    .then(querySnapshot => snapshotToArray(querySnapshot));
}

function fetchLink(linkId) {
  return linkRef(DB, linkId).get()
    .then(doc => saveDocumentKey(doc));
}

function fetchOrga(orgaId) {
  return orgaRef(DB, orgaId).get()
    .then(doc => saveDocumentKey(doc));
}

function fetchValidOrgaMembers(orgaId) {
  return memberRef(orgaRef(DB, orgaId)).where('isValid', '==', true).get()
    .then(docs => snapshotToArray(docs));
}

function fetchUserProfile(userId) {
  return userRef(DB, userId).get()
    .then(doc => saveDocumentKey(doc));
}

function fetchCurrentUserProfile() {
  return fetchUserProfile(getCurrentUser().uid);
}

function fetchSettings() {
  return settingRef(userRef(DB, getCurrentUser().uid), DIAL_SETTINGS_DOCUMENT).get()
    .then(doc => saveDocumentKey(doc));
}

function fetchValidAuthorizations() {
  return authorizationRef(userRef(DB, getCurrentUser().uid)).where('isValid', '==', true).get()
    .then(doc => snapshotToArray(doc));
}

function fetchProcessedRecord(recordId) {
  return processedRef(DB, recordId).get().then(doc => saveDocumentKey(doc));
}

function fetchProcessedRecordPressures(recordId) {
  return processedPressuresRef(DB, recordId).get().then(doc => saveDocumentKey(doc));
}

function fetchPressures(recordId, strideId) {
  return pressureRef(recordRef(DB, recordId), strideId).get().then(doc => saveDocumentKey(doc));
}

function fetchStaticCop(recordId) {
  return copRef(recordRef(DB, recordId)).orderBy('clientTimestamp', 'asc').get()
    .then(querySnapshot => snapshotToArray(querySnapshot));
}

function fetchBipodalCop(recordId) {
  return bipodalCopRef(recordRef(DB, recordId)).get()
    .then(querySnapshot => snapshotToArray(querySnapshot));
}

//
// SET
//
function setPatientRecord(orgaId, recordId, patientId) {
  return recordRef(orgaRef(DB, orgaId), recordId).set({ patientId });
}

//
// UPDATE
//
function updateSettings(data) {
  return settingRef(userRef(DB, getCurrentUser().uid), DIAL_SETTINGS_DOCUMENT)
    .set(data, { merge: true });
}

function updateUnitMetricsSettings(unitMetrics) {
  return updateSettings({ unitMetrics });
}

function updateCompareRecordsDisplaySettings(compareRecordsDisplay) {
  return updateSettings({ compareRecordsDisplay });
}

function updateLocaleSettings(locale) {
  return updateSettings({ locale });
}

function updateAsymmetricalMetricsSettings(asymmetricalMetricsSelected) {
  return updateSettings({ asymmetricalMetricsSelected });
}

function updateGaitCycleMetricsSettings(gaitCycleMetricsSelected) {
  return updateSettings({ gaitCycleMetricsSelected });
}

function updateUserProfile(data) {
  return userRef(DB, getCurrentUser().uid).set(data, { merge: true });
}

//
// Batch
//
function getBatch() {
  return DB.batch();
}

function commitBatch(batch) {
  return batch.commit();
}

export {
  FETCH_RECORDS_LIMIT,

  getFirestoreDB,

  fetchDiosRecords,
  fetchExercicesRecords,
  fetchRecord,
  watchRecord,
  fetchDiosStrides,
  fetchRecordInsoles,
  fetchRecordWalkingAids,
  fetchLink,
  fetchOrga,
  fetchValidOrgaMembers,
  fetchCurrentUserProfile,
  fetchUserProfile,
  fetchSettings,
  fetchValidAuthorizations,
  fetchProcessedRecord,
  fetchProcessedRecordPressures,
  fetchPressures,
  fetchStaticCop,
  fetchBipodalCop,

  setPatientRecord,

  updateUnitMetricsSettings,
  updateCompareRecordsDisplaySettings,
  updateLocaleSettings,
  updateAsymmetricalMetricsSettings,
  updateGaitCycleMetricsSettings,
  updateUserProfile,

  getBatch,
  commitBatch,
};
