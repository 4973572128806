import React from 'react';
import PropTypes from 'prop-types';

import { select } from 'd3-selection';
import { scaleLinear } from 'd3-scale';
import { arc } from 'd3-shape';
import { interpolate } from 'd3-interpolate';
import 'd3-transition';

import { getGaugeColorEmpty } from '../../utils/colors';

const END_ANGLE = 90 * (Math.PI / 180);
const START_ANGLE = -END_ANGLE;

const COLOR_GAUGE_EMPTY = getGaugeColorEmpty();

class Gauge extends React.Component {
  componentDidMount() {
    const svg = select(this.node)
      .append('svg');

    const graphContainer = svg.append('g');
    const background = graphContainer.append('path');
    const foreground = graphContainer.append('path');

    const scale = scaleLinear()
      .domain(this.props.domain)
      .range(this.props.range)
      .clamp(true);

    const angleScale = scaleLinear()
      .domain([this.props.range[0], this.props.range[this.props.range.length - 1]])
      .range([START_ANGLE, END_ANGLE])
      .clamp(true);

    this.el = {
      svg,
      arc: arc().startAngle(START_ANGLE),
      graphContainer,
      background,
      foreground,
      scale,
      angleScale,
    };

    this.sizes = {
      w: this.props.width,
      h: 0,
      radius: 0,
      outer_radius: 0,
      inner_radius: 0,
      margin: this.props.pdfMode ? 2 : 25,
    };

    this.init();
  }

  componentDidUpdate(prevProps) {
    if (prevProps.value !== this.props.value) {
      this.update();
    }
  }

  init() {
    if (this.sizes.w === 0) {
      this.sizes.w = this.node.clientWidth;
    }

    this.sizes.h = this.sizes.w / 2;
    this.sizes.radius = this.sizes.h - this.sizes.margin;

    this.sizes.outer_radius = this.sizes.radius;
    this.sizes.inner_radius = this.sizes.radius * 0.45;
    this.sizes.outer_radius_top = this.sizes.outer_radius + (this.sizes.radius * 0.01);
    this.sizes.inner_radius_top = this.sizes.inner_radius - (this.sizes.radius * 0.01);

    this.el.svg
      .attr('width', this.sizes.w)
      .attr('height', this.sizes.h);

    this.el.graphContainer
      .attr('transform', `translate(${this.sizes.w / 2}, ${this.sizes.h})`);

    this.el.background
      .datum({ endAngle: END_ANGLE })
      .attr('fill', COLOR_GAUGE_EMPTY)
      .attr('d', this.el.arc
        .innerRadius(this.sizes.inner_radius)
        .outerRadius(this.sizes.outer_radius));

    this.el.arc
      .innerRadius(this.sizes.inner_radius_top)
      .outerRadius(this.sizes.outer_radius_top);

    this.el.foreground
      .datum({
        endAngle: this.el.angleScale(this.el.scale(this.props.value)),
        valueScaled: this.el.scale(this.props.value),
      })
      .attr('fill', this.props.colorRange(this.el.scale(this.props.value)))
      .attr('d', this.el.arc);
  }

  update() {
    if (this.props.pdfMode) {
      this.el.foreground
        .datum({
          endAngle: this.el.angleScale(this.el.scale(this.props.value)),
          valueScaled: this.el.scale(this.props.value),
        })
        .attr('fill', this.props.colorRange(this.el.scale(this.props.value)))
        .attr('d', this.el.arc);
    } else {
      this.el.foreground.transition()
        .duration(750)
        .attrTween('fill', this.fillTween(this.el.scale(this.props.value)))
        .attrTween('d', this.arcTween(this.el.angleScale(this.el.scale(this.props.value))));
    }
  }

  arcTween(newAngle) {
    return (d) => {
      const fInterpolate = interpolate(d.endAngle, newAngle);
      return (t) => {
        // eslint-disable-next-line no-param-reassign
        d.endAngle = fInterpolate(t);
        return this.el.arc(d);
      };
    };
  }

  fillTween(newValue) {
    return (d) => {
      const fInterpolate = interpolate(d.valueScaled, newValue);
      return (t) => {
        // eslint-disable-next-line no-param-reassign
        d.valueScaled = fInterpolate(t);
        return this.props.colorRange(d.valueScaled);
      };
    };
  }

  render() {
    return (
      <div
        ref={(node) => {
          this.node = node;
          return undefined;
        }}
      />
    );
  }
}

Gauge.propTypes = {
  domain: PropTypes.arrayOf(PropTypes.number).isRequired,
  range: PropTypes.arrayOf(PropTypes.number).isRequired,
  colorRange: PropTypes.func.isRequired,

  value: PropTypes.number,
  width: PropTypes.number,
  pdfMode: PropTypes.bool,
};

Gauge.defaultProps = {
  value: 0,
  width: 0,
  pdfMode: false,
};

export default Gauge;
