import React, { useEffect, useState, useMemo } from 'react';
import PropTypes from 'prop-types';
import { Redirect } from 'react-router-dom';

import ProgressWait from '../components/display/progress-wait';
import ComparisonDynamic from './components/dynamic';
import ComparisonStatic from './components/static';

import useRecords from '../utils/hooks/useRecords';
import usePatient from '../utils/hooks/usePatient';
import useDisableFallingRisk from '../utils/hooks/useDisableFallingRisk';
import { fetchRecordInsoles, fetchRecordWalkingAids } from '../firebase/firestore';
import { isStatic } from '../utils/record';
import { sortRecords } from '../utils/records';

function CompareRecords({ match }) {
  const { recordsId, patientId } = match.params;
  const [records, isLoadingRecords] = useRecords(recordsId.split(','));
  const [patient, isLoadingPatient] = usePatient(patientId, true);
  const [insoles, setInsoles] = useState({});
  const [walkingAids, setWalkingAids] = useState({});
  const [isLoadingInsoles, setLoadingInsoles] = useState(true);
  const [isLoadingWalkingAids, setIsLoadingWalkingAids] = useState(true);
  const [disableFallingRisk] = useDisableFallingRisk(patient, patientId, isLoadingPatient);

  // keep only records of the same type
  // sort the records
  const filteredRecords = useMemo(() => {
    if (records.length < 2) {
      return records;
    }

    const isStaticComparison = isStatic(records[0]);
    return sortRecords(
      records.filter(record => isStatic(record) === isStaticComparison),
      false,
    );
  }, [records.length]);

  useEffect(() => {
    async function wrapper() {
      const res = {};
      await Promise.all(filteredRecords.map(async (record) => {
        res[record.key] = await fetchRecordInsoles(record.key);
        return undefined;
      }));

      setInsoles(res);
      setLoadingInsoles(false);
    }

    if (filteredRecords.length < 1) {
      return;
    }

    wrapper();
  }, [filteredRecords.length]);

  useEffect(() => {
    async function wrapper() {
      const res = {};
      await Promise.all(filteredRecords.map(async (record) => {
        res[record.key] = await fetchRecordWalkingAids(record.key);
        return undefined;
      }));

      setWalkingAids(res);
      setIsLoadingWalkingAids(false);
    }

    if (filteredRecords.length < 1) {
      return;
    }

    wrapper();
  }, [filteredRecords.length]);

  if (isLoadingRecords || isLoadingInsoles || isLoadingWalkingAids) {
    return (<ProgressWait />);
  }

  if (filteredRecords.length === 1) {
    return (<Redirect to={`/record/${filteredRecords[0].key}`} />);
  }

  if (isStatic(filteredRecords[0])) {
    return (
      <ComparisonStatic
        records={filteredRecords}
        insoles={insoles}
      />
    );
  }

  return (
    <ComparisonDynamic
      records={filteredRecords}
      patientFetchInProgress={isLoadingPatient}
      disableFallingRisk={disableFallingRisk}
      walkingAids={walkingAids}
      insoles={insoles}
    />
  );
}

CompareRecords.propTypes = {
  match: PropTypes.shape({
    params: PropTypes.shape({
      recordsId: PropTypes.string.isRequired,
      patientId: PropTypes.string,
    }),
  }).isRequired,
};

export default CompareRecords;
