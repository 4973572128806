import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import Grid from '@material-ui/core/Grid';

import MetricTitle from './utils/title';
import MetricValue from './utils/value';
import MetricUnit from './utils/unit';
import MetricSmallStandardDeviation from './utils/small-standard-deviation';

import Metric from '../../utils/metrics/metric';
import { filterWithTimestamps, getArrayXY } from '../../utils/strides';
import { statArray } from '../../utils/array';

function mapStateToProps(state, ownProps) {
  return ({
    unit: state.settings.unitMetrics[ownProps.metric.key] || ownProps.metric.unit,
    startTimestamp: state.timestampsRecordDisplay.startTimestamp,
    endTimestamp: state.timestampsRecordDisplay.endTimestamp,
  });
}

function LineMetric(props) {
  const {
    strides,
    unit,
    metric,
    startTimestamp,
    endTimestamp,
    strideTimestampFilter,
  } = props;

  let deviation;
  let arrayXY = getArrayXY(strides, metric);
  if (strideTimestampFilter) {
    arrayXY = filterWithTimestamps(arrayXY, startTimestamp, endTimestamp);
  }

  const mean = metric.convert(metric.compute(arrayXY), unit) || 0;
  if (metric.hasDeviation) {
    deviation = metric.convert(statArray(arrayXY.map(i => i.y), 'deviation'), unit);
  }

  return (
    <Grid container direction="row" justify="center" alignItems="center" spacing={1}>
      <Grid item>
        <MetricTitle metric={metric} variant="subtitle1" color="primary" />
      </Grid>
      <Grid item>
        <MetricValue metric={metric} value={mean} />
      </Grid>
      <Grid item>
        <MetricUnit unit={unit} variant="caption" />
      </Grid>
      <Grid item>
        <MetricSmallStandardDeviation metric={metric} value={deviation} />
      </Grid>
    </Grid>
  );
}

LineMetric.propTypes = {
  strides: PropTypes.arrayOf(PropTypes.object).isRequired,
  metric: PropTypes.instanceOf(Metric).isRequired,
  unit: PropTypes.string.isRequired,
  startTimestamp: PropTypes.number.isRequired,
  endTimestamp: PropTypes.number.isRequired,
  strideTimestampFilter: PropTypes.bool,
};

LineMetric.defaultProps = {
  strideTimestampFilter: false,
};

export default connect(mapStateToProps)(LineMetric);
