import React from 'react';
import PropTypes from 'prop-types';
import {
  Grid,
  Typography,
  Card,
  CardHeader,
  CardContent,
} from '@material-ui/core';

import Translate from '../../components/display/translate';
import MetricGraphInactivity from '../../components/metric/graph-inactivity';

import { Velocity } from '../../utils/metrics/index';

// 5 seconds
const INTERVAL_STRIDE_MS = 5 * 1000;

function checkHasHoles(strides) {
  const res = strides.find((stride, i) => (
    i !== 0 && (stride.clientTimestamp - strides[i - 1].clientTimestamp >= INTERVAL_STRIDE_MS)));

  return res !== undefined;
}

function MetricVariationCard(props) {
  const hasHoles = checkHasHoles(props.strides);

  return (
    <Card>
      <CardHeader title={<Translate>metricVariation</Translate>} />
      <CardContent>
        <Grid container direction="row" justify="space-around" alignItems="center">
          {
            (hasHoles && (
              <Grid item xs={props.pdf ? 12 : 2}>
                <Typography>
                  <Translate>inactivityDisplayDashedLines</Translate>
                </Typography>
              </Grid>
            ))
          }
          <Grid item md={hasHoles ? 8 : 12} xs={12} id="metric-variation-graph-pdf">
            <MetricGraphInactivity
              strides={props.strides}
              metric={Velocity}
              intervalDefinedMs={INTERVAL_STRIDE_MS}
              pdf={props.pdf}
            />
          </Grid>
        </Grid>
      </CardContent>
    </Card>
  );
}

MetricVariationCard.propTypes = {
  strides: PropTypes.arrayOf(PropTypes.object).isRequired,
  pdf: PropTypes.bool,
};

MetricVariationCard.defaultProps = {
  pdf: false,
};

export default MetricVariationCard;
