import Metric from './metric';

export default new Metric({
  title: 'strideDuration',
  unit: 'ms',
  key: 'strideDuration',
  hasDeviation: true,
  convert: (v, unit) => {
    if (unit === 's') {
      // convert from ms to s
      return (v / 100);
    }
    return v;
  },
});
