import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import { formatNumber } from '../../utils/display';

function mapStateToProps(state) {
  return ({
    locale: state.settings.locale,
  });
}

function NumberDisplay({ children, locale, options }) {
  return formatNumber(children, locale, options);
}

NumberDisplay.propTypes = {
  locale: PropTypes.string.isRequired,

  children: PropTypes.number.isRequired,
  // eslint-disable-next-line react/forbid-prop-types
  options: PropTypes.object,
};

NumberDisplay.defaultProps = {
  object: undefined,
};

export default connect(mapStateToProps)(NumberDisplay);
