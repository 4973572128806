import React, {
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import PropTypes from 'prop-types';
import { scaleLinear } from 'd3-scale';
import { symbol, symbolCross } from 'd3-shape';
import {
  Graph,
  Path,
  useGraphDimensions,
} from '@feetme/d3act';

function BipodalCopReplayGraph(props) {
  const {
    frame,
    mode,
    reset,
    children,
  } = props;

  const replayRef = useRef(null);
  // keep the current left and right cops in the same reference point
  const cops = useRef([]);
  // keep the list of bipodal cop
  const bipodalCopRef = useRef([]);
  // used to store the dimensions where we will draw the bipodal cops
  const [dimensions, setDimensions] = useState({});

  function domRectToObject(domRect) {
    return ({
      x: domRect.x,
      y: domRect.y,
      width: domRect.width,
      height: domRect.height,
      top: domRect.top,
      left: domRect.left,
      right: domRect.right,
    });
  }

  // function resetPaths() {
  //   bipodalCopRef.current = [];
  // }

  function getParentDim() {
    const res = {
      x: Number.MAX_SAFE_INTEGER,
      y: Number.MAX_SAFE_INTEGER,
      width: Number.MIN_SAFE_INTEGER,
      height: Number.MIN_SAFE_INTEGER,
    };

    const graphElt = Array.from(replayRef.current.getElementsByClassName('insole-graph'))
      .map(i => domRectToObject(i.getBoundingClientRect()));

    if (graphElt.length === 0) {
      return undefined;
    }

    graphElt.forEach((i) => {
      res.x = Math.min(res.x, i.x);
      res.y = Math.min(res.y, i.y);
    });

    graphElt.forEach((i) => {
      res.width = Math.max(res.width, (i.x + i.width) - res.x);
      res.height = Math.max(res.height, (i.y + i.height) - res.y);
    });

    return ({
      ...res,
      y: res.y + window.scrollY,
    });
  }

  function computeBipodalCop(copsFrame, leftLoad, rightLoad) {
    const copDiff = [
      copsFrame[0].x - copsFrame[1].x,
      copsFrame[0].y - copsFrame[1].y,
    ];

    const load = leftLoad / (rightLoad + leftLoad);

    return ({
      x: (load * copDiff[0]) + copsFrame[1].x,
      y: (load * copDiff[1]) + copsFrame[1].y,
    });
  }

  useEffect(() => {
    bipodalCopRef.current = [];
  }, [reset]);

  useEffect(() => {
    if (frame === undefined
      || frame.left === undefined
      || frame.right === undefined
      || mode === 'dynamic') {
      return;
    }
    const newDimensions = getParentDim();
    // if the width change (ie, if the browser window width changes) we
    // want to reset the bipodal cop path
    if (Object.keys(dimensions).length !== 0
      && newDimensions.x !== dimensions.x) {
      bipodalCopRef.current = [];
    }
    setDimensions(newDimensions || {});
    cops.current = Array.from(replayRef.current.getElementsByClassName('cop-last-position'))
      .map(i => domRectToObject(i.getBoundingClientRect()))
      .map(i => ({
        // find the proper center of the cop
        // x and y represents the top left corner
        x: i.x + i.width / 2,
        y: window.scrollY + i.y + i.height / 2,
      }));
    // we check if we had both cop. It's possible only one cop has been draw
    // even if the current frame has both cop
    if (cops.current.length === 2) {
      bipodalCopRef.current.push(computeBipodalCop(
        cops.current,
        frame.left.load,
        frame.right.load,
      ));
    }
  }, [frame.index]);

  const [graphRef, graphDimensions] = useGraphDimensions({
    marginLeft: 0,
    marginRight: 0,
    marginTop: 0,
    marginBottom: 0,
    height: dimensions.height || 100,
    width: dimensions.width || 100,
  });
  const { boundedWidth, boundedHeight } = graphDimensions;

  const xAccessor = d => d.x;
  const yAccessor = d => d.y;

  const xScale = scaleLinear()
    .domain([dimensions.x, dimensions.x + dimensions.width])
    .range([0, boundedWidth]);
  const yScale = scaleLinear()
    .domain([dimensions.y, dimensions.y + dimensions.height])
    .range([0, boundedHeight]);

  const xAccessorScaled = d => xScale(xAccessor(d));
  const yAccessorScaled = d => yScale(yAccessor(d));

  const lastBipodalCop = useMemo(
    () => bipodalCopRef.current[bipodalCopRef.current.length - 1],
    [bipodalCopRef.current.length],
  );

  return (
    <React.Fragment>
      <div
        style={{
          position: 'absolute',
          zIndex: 1,
          width: dimensions ? dimensions.width : 0,
          height: dimensions ? dimensions.height : 0,
          left: dimensions ? dimensions.x : 0,
          top: dimensions ? dimensions.y : 0,
        }}
      >
        { (mode === 'static' && cops.current.length >= 2) && (
          <div ref={graphRef} style={{ position: 'relative' }}>
            <Graph dimensions={graphDimensions}>
              <line
                x1={xAccessorScaled(cops.current[0])}
                y1={yAccessorScaled(cops.current[0])}
                x2={xAccessorScaled(cops.current[1])}
                y2={yAccessorScaled(cops.current[1])}
                stroke="black"
                strokeWidth={1}
              />
              <Path
                data={bipodalCopRef.current}
                xAccessor={xAccessorScaled}
                yAccessor={yAccessorScaled}
                stroke="#212529"
                strokeWidth={2}
              />
              { lastBipodalCop !== undefined && (
                <circle
                  cx={xAccessorScaled(lastBipodalCop)}
                  cy={yAccessorScaled(lastBipodalCop)}
                  r={3}
                  strokeWidth={1.5}
                  fill="black"
                  stroke="white"
                />
              )}
              <path
                d={symbol(symbolCross, 32)()}
                transform={`translate(${xAccessorScaled(cops.current[0])},${yAccessorScaled(cops.current[0])})`}
                strokeWidth={1.5}
                fill="black"
                stroke="white"
              />
              <path
                d={symbol(symbolCross, 32)()}
                transform={`translate(${xAccessorScaled(cops.current[1])},${yAccessorScaled(cops.current[1])})`}
                strokeWidth={1.5}
                fill="black"
                stroke="white"
              />
            </Graph>
          </div>
        )}
      </div>
      <div ref={replayRef}>
        { children }
      </div>
    </React.Fragment>
  );
}

BipodalCopReplayGraph.propTypes = {
  // eslint-disable-next-line react/forbid-prop-types
  frame: PropTypes.object.isRequired,
  mode: PropTypes.oneOf(['static', 'dynamic']).isRequired,
  reset: PropTypes.number.isRequired,
  children: PropTypes.element.isRequired,
};

export default BipodalCopReplayGraph;
