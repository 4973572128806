import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Link, withRouter } from 'react-router-dom';
import * as Sentry from '@sentry/browser';
import {
  Grid,
  Button,
  Card,
  CardContent,
  CardActions,
  Typography,
} from '@material-ui/core';

import ErrorIcon from '@material-ui/icons/Error';
import CheckIcon from '@material-ui/icons/Check';

import Translate from '../components/display/translate';
import ProgressWait from '../components/display/progress-wait';

import { fetchLink, fetchCurrentUserProfile, fetchOrga } from '../firebase/firestore';
import { processLink } from '../firebase/functions';
import { updateUserOrgaId } from '../actions/index';

const TYPE_JOIN_ORGA = 'orga';
const TYPE_JOIN_ORGA_ADMIN = 'orgaAdmin';
const TYPE_AUTHORIZE_ORGA = 'authorization';

function isLinkValid(link) {
  if (link.isValid === false) {
    return false;
  }

  if (link.counter === 0) {
    return false;
  }

  if ((Date.now() - link.createdAt.toDate()) > link.expiration) {
    return false;
  }

  return true;
}

function getStatus(icon, text) {
  return (
    <Grid container direction="row" justify="center" alignItems="center" spacing={3}>
      <Grid item>
        { icon }
      </Grid>
      <Grid item>
        <Typography>
          <Translate>{text}</Translate>
        </Typography>
      </Grid>
    </Grid>
  );
}

class LinkProcessing extends React.Component {
  constructor() {
    super();

    this.state = {
      inProgress: true,
      isLinkValid: true,
      linkProcessed: false,
      linkProcessError: false,
      link: undefined,
      orga: undefined,
      userPreviousOrgaId: undefined,
    };
  }

  componentDidMount() {
    fetchLink(this.props.match.params.linkId)
      .then((link) => {
        if (!isLinkValid(link)) {
          return Promise.reject(new Error(`Link ${this.props.match.params.linkId} is not valid`));
        }

        this.setState({ link });
        return fetchOrga(link.orgaId);
      }).then((orga) => {
        this.setState({ orga });
        return fetchCurrentUserProfile();
      }).then(userProfile => this.setState({
        userPreviousOrgaId: userProfile ? userProfile.orgaId : undefined,
        inProgress: false,
      }))
      .catch((err) => {
        Sentry.captureException(err);
        this.setState({ inProgress: false, isLinkValid: false });
      });

    this.processLink = this.processLink.bind(this);
  }

  getChild() {
    if (this.state.inProgress) {
      return (<ProgressWait />);
    }

    if (this.state.isLinkValid === false) {
      return getStatus((<ErrorIcon color="error" fontSize="large" />), 'linkNotValidAnymore');
    }

    if (this.state.linkProcessed) {
      if (this.state.linkProcessError === false) {
        // XXX: we could simply redirect the user to '/'
        return getStatus((<CheckIcon color="secondary" fontSize="large" />), 'successLinkProcessing');
      }
      return getStatus((<ErrorIcon color="error" fontSize="large" />), 'errorLinkProcessing');
    }

    let text;
    if (this.state.link.type === TYPE_JOIN_ORGA) {
      text = 'joinOrga';
    }
    if (this.state.link.type === TYPE_JOIN_ORGA_ADMIN) {
      text = 'joinOrgaAdmin';
    }
    if (this.state.link.type === TYPE_AUTHORIZE_ORGA) {
      text = 'authorizeAccessOrga';
    }

    return (
      <Grid container direction="column" justify="flex-start" alignItems="stretch" spacing={2}>
        <Grid item>
          <Grid container direction="row" justify="space-between" alignItems="center">
            <Grid item>
              <Typography>
                <Translate>{text}</Translate>
              </Typography>
            </Grid>
            <Grid item>
              <Typography variant="subtitle2">
                {this.state.orga.name}
              </Typography>
            </Grid>
          </Grid>
        </Grid>
        { this.state.userPreviousOrgaId && (
          <Grid item>
            <Typography>
              <Translate>joiningOrgaOverwritePrevious</Translate>
            </Typography>
          </Grid>
        )}
      </Grid>
    );
  }

  processLink() {
    this.setState({ inProgress: true });
    processLink({ linkId: this.props.match.params.linkId })
      .then(() => {
        if (this.state.link.type === TYPE_JOIN_ORGA
          || this.state.link.type === TYPE_JOIN_ORGA_ADMIN) {
          // save orgaId in redux, to update various elements (interface, routes)
          this.props.dispatch(updateUserOrgaId(this.state.link.orgaId));
        }

        this.setState({
          inProgress: false,
          linkProcessed: true,
          linkProcessError: false,
        });
      }).catch(() => this.setState({
        inProgress: false,
        linkProcessed: true,
        linkProcessError: true,
      }));
  }

  render() {
    const showCardCancelAction = this.state.inProgress === false
      && (this.state.isLinkValid === false || this.state.linkProcessed === true);
    const showCardValidateAction = this.state.inProgress === false
      && this.state.isLinkValid === true
      && this.state.linkProcessed === false;

    return (
      <Grid container direction="column" justify="flex-start" alignItems="center">
        <Grid item style={{ width: '600px' }}>
          <Card>
            <CardContent>
              <Typography variant="h6" gutterBottom>
                <Translate>linkProcessing</Translate>
              </Typography>
              { this.getChild() }
            </CardContent>
            { showCardCancelAction && (
              <CardActions>
                <Grid container direction="row" justify="flex-end" alignItems="center">
                  <Grid item>
                    <Button component={Link} to="/" color="primary" variant="contained">
                      Ok
                    </Button>
                  </Grid>
                </Grid>
              </CardActions>
            )}
            { showCardValidateAction && (
              <CardActions>
                <Grid container direction="row" justify="flex-end" alignItems="center" spacing={3}>
                  <Grid item>
                    <Button component={Link} to="/" color="primary" variant="outlined">
                      <Translate>No</Translate>
                    </Button>
                  </Grid>
                  <Grid item>
                    <Button onClick={this.processLink} color="primary" variant="contained">
                      <Translate>Yes</Translate>
                    </Button>
                  </Grid>
                </Grid>
              </CardActions>
            )}
          </Card>
        </Grid>
      </Grid>
    );
  }
}

LinkProcessing.propTypes = {
  match: PropTypes.shape({
    params: PropTypes.shape({
      linkId: PropTypes.string,
    }),
  }).isRequired,
  history: PropTypes.shape({
    push: PropTypes.func,
  }).isRequired,
  dispatch: PropTypes.func.isRequired,
};

export default withRouter(connect()(LinkProcessing));
