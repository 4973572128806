import Metric from './metric';

// we suppose that a duration between two strides longer
// than INTERVAL_INACTIVITY is an inactivity
const INTERVAL_INACTIVITY = 15 * 60 * 1000; // 15 minutes

export default new Metric({
  title: 'activeTime',
  key: 'activeTime',
  formatType: 'duration',
  keyCompute: 'clientTimestamp',
  // Return the time, in ms, of the active time
  // That is, the sum of the time between all consecutive strides less than the
  // fixed INTERVAL_INACTIVITY.
  compute: (arrayClientTimestamps) => {
    let activeTime = 0;
    let previousTimestamp;

    arrayClientTimestamps.forEach(({ x }) => {
      if (previousTimestamp) {
        const timeBetweenTwoStrides = x - previousTimestamp;
        if (timeBetweenTwoStrides < INTERVAL_INACTIVITY) {
          activeTime += (x - previousTimestamp);
        }
      }

      previousTimestamp = x;
    });

    return activeTime;
  },
});
