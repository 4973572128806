import React from 'react';
import PropTypes from 'prop-types';
import { isSameDay } from 'date-fns';

import ListRecords from './index';

class ListRecordsPorficWrapper extends React.Component {
  constructor() {
    super();

    this.state = {
      date: null,
    };

    this.handleDateChange = this.handleDateChange.bind(this);
  }

  handleDateChange(date) {
    this.setState({ date });
  }

  render() {
    return (
      <ListRecords
        records={this.props.records.filter(record => this.state.date === null
          || isSameDay(new Date(record.startTime), new Date(this.state.date)))}
        inProgress={this.props.inProgress}
        onDateSelection={this.handleDateChange}
        onLinkRecord={this.props.onLinkRecord}
      />
    );
  }
}

ListRecordsPorficWrapper.propTypes = {
  records: PropTypes.arrayOf(PropTypes.object).isRequired,
  inProgress: PropTypes.bool.isRequired,
  onLinkRecord: PropTypes.func,
};

ListRecordsPorficWrapper.defaultProps = {
  onLinkRecord: undefined,
};

export default ListRecordsPorficWrapper;
