import React, { useEffect, useState, useMemo } from 'react';
import PropTypes from 'prop-types';
import Grid from '@material-ui/core/Grid';
import { useSelector } from 'react-redux';

import PageHeader from '../../components/display/page-header';
import PageTitle from '../../components/display/page-title';
import PageSubtitle from '../../components/display/page-subtitle';
import Translate from '../../components/display/translate';
import DateDisplay from '../../components/display/date';
import MainMetricsCard from './main-metrics-card';
import SpatioTemporalMetricsCard from './spatio-temporal-metrics-card';
import DynamicReplays from './dynamic-replays';
import PTIs from './ptis';
import MaxPressures from './max-pressures';
import NoReplay from './no-replay';
import Report from '../../components/record/report';

import useDynamicRecordsComparaison from '../../utils/hooks/useDynamicRecordsComparaison';
import { isDynamic, isMetric } from '../../utils/record';
import { fileDownloadDateFormat } from '../../utils/date';
import { fetchProcessedRecordPressures, fetchProcessedRecord } from '../../firebase/firestore';

function ComparisonDynamic(props) {
  const {
    records,
    patientFetchInProgress,
    disableFallingRisk,
    walkingAids,
    insoles,
  } = props;

  const dynamicRecords = useMemo(
    () => records.filter(record => isDynamic(record)),
    [records.length],
  );

  // store the processedRecordsPressure and processedRecord for each dynamic records
  const [dynamicData, setDynamicData] = useState([]);

  const unitsStore = useSelector(state => state.settings.unitMetrics);
  const graphVariant = useSelector(state => (state.settings.compareRecordsDisplay === 'curve'
    ? 'curve-stable-x'
    : state.settings.compareRecordsDisplay));

  const [recordsData, isLoadingRecordsData] = useDynamicRecordsComparaison(records, unitsStore);

  // load pressure data from dynamic records
  useEffect(() => {
    async function wrapper() {
      setDynamicData(await Promise.all(records.map((record) => {
        if (isMetric(record)) {
          return undefined;
        }
        return Promise.all([
          fetchProcessedRecordPressures(record.key),
          fetchProcessedRecord(record.key),
        ]);
      })));
    }
    wrapper();
  }, [records.length]);

  return (
    <React.Fragment>
      <PageHeader>
        <Grid container direction="row" justify="space-between" alignItems="flex-end">
          <Grid item>
            <PageTitle>
              <Translate input={{ NB: records.length }}>XrecordsSelected</Translate>
            </PageTitle>
            <PageSubtitle>
              <Grid container direction="row" justify="flex-start" alignItems="center" spacing={1}>
                <Grid item>
                  <Translate>fromDate</Translate>
                </Grid>
                <Grid item>
                  <DateDisplay>{records[0].startTime}</DateDisplay>
                </Grid>
                <Grid item>
                  <Translate>toDate</Translate>
                </Grid>
                <Grid item>
                  <DateDisplay>{records[records.length - 1].startTime}</DateDisplay>
                </Grid>
              </Grid>
            </PageSubtitle>
          </Grid>
          { (patientFetchInProgress === false) && (
            <Grid item>
              <Report
                recordsId={records.map(i => i.key)}
                disableFallingRisk={disableFallingRisk}
                filename={`${fileDownloadDateFormat(new Date(records[records.length - 1].startTime))}.pdf`}
              />
            </Grid>
          )}
        </Grid>
      </PageHeader>
      <Grid container direction="column" justify="flex-start" alignItems="stretch" spacing={2} wrap="nowrap" style={{ marginTop: '24px' }}>
        <Grid item>
          <MainMetricsCard
            inProgress={isLoadingRecordsData || patientFetchInProgress}
            graphVariant={graphVariant}
            recordsData={recordsData}
            disableFallingRisk={disableFallingRisk}
          />
        </Grid>
        <Grid item>
          <SpatioTemporalMetricsCard
            graphVariant={graphVariant}
            recordsData={recordsData}
          />
        </Grid>
        <Grid item>
          { records.length === 2
            ? (
              <DynamicReplays
                records={records}
                insoles={insoles}
                walkingAids={walkingAids}
              />
            ) : <NoReplay /> }
        </Grid>
        <Grid item>
          { recordsData.length > 0 && (
            <MaxPressures
              records={records}
              insoles={insoles}
              walkingAids={walkingAids}
              dynamicData={dynamicData}
              recordsData={recordsData}
            />
          )}
        </Grid>
        <Grid item>
          { dynamicRecords.length >= 1 && (
            <PTIs
              records={records}
              insoles={insoles}
              walkingAids={walkingAids}
              dynamicData={dynamicData}
            />
          )}
        </Grid>
      </Grid>
    </React.Fragment>
  );
}

ComparisonDynamic.propTypes = {
  records: PropTypes.arrayOf(PropTypes.object).isRequired,
  patientFetchInProgress: PropTypes.bool.isRequired,
  disableFallingRisk: PropTypes.bool.isRequired,
  // eslint-disable-next-line react/forbid-prop-types
  walkingAids: PropTypes.object.isRequired,
  // eslint-disable-next-line react/forbid-prop-types
  insoles: PropTypes.object.isRequired,
};

export default ComparisonDynamic;
