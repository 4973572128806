import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import MetricGraphContainer from './utils/graph-container';
import HistogramTrendStable from '../graph/histogram-trend-stable';

import Metric from '../../utils/metrics/metric';

function mapStateToProps(state, ownProps) {
  return ({
    unit: state.settings.unitMetrics[ownProps.metric.key] || ownProps.metric.unit,
  });
}

function MetricGraphTrendStable(props) {
  const {
    unit,
    color,
    metric,
    showRecordType,
    processedRecords,
  } = props;

  return (
    <div>
      <MetricGraphContainer metric={metric}>
        <HistogramTrendStable
          data={processedRecords.map(i => ({
            id: i.key,
            x: i.startTime,
            y: metric.convert(i[metric.key], unit),
            type: i.recordType,
          })).filter(({ y }) => y > 0)}
          metric={metric}
          unit={unit}
          color={color}
          showRecordType={showRecordType}
        />
      </MetricGraphContainer>
    </div>
  );
}

MetricGraphTrendStable.propTypes = {
  metric: PropTypes.instanceOf(Metric).isRequired,
  unit: PropTypes.string.isRequired,
  processedRecords: PropTypes.arrayOf(PropTypes.shape({
    startTime: PropTypes.number.isRequired,
  })).isRequired,
  color: PropTypes.string,
  showRecordType: PropTypes.bool,
};

MetricGraphTrendStable.defaultProps = {
  color: undefined,
  showRecordType: undefined,
};

export default connect(mapStateToProps)(MetricGraphTrendStable);
