import React from 'react';
import Typography from '@material-ui/core/Typography';
import Grid from '@material-ui/core/Grid';
import { makeStyles } from '@material-ui/core/styles';

import Translate from '../../components/display/translate';

import { getLeftColor, getRightColor } from '../../utils/colors';

const useStyles = makeStyles(() => ({
  block: {
    width: '8px',
    height: '8px',
  },
  text: {
    fontSize: '13px',
    color: '#868E96',
  },
}));

function LegendSide() {
  const classes = useStyles();
  return (
    <Grid container direction="row" justify="center" alignItems="center" spacing={1}>
      <Grid item>
        <div className={classes.block} style={{ background: getLeftColor() }}>&nbsp;</div>
      </Grid>
      <Grid item>
        <Typography className={classes.text}>
          <Translate>leftFoot</Translate>
        </Typography>
      </Grid>
      <Grid item>
        <div className={classes.block} style={{ background: getRightColor() }}>&nbsp;</div>
      </Grid>
      <Grid item>
        <Typography className={classes.text}>
          <Translate>rightFoot</Translate>
        </Typography>
      </Grid>
    </Grid>
  );
}

export default LegendSide;
