import React from 'react';
import PropTypes from 'prop-types';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';

import Translate from '../../display/translate';
import Metric from '../../../utils/metrics/metric';
import MetricValue from './value';
import MetricUnit from './unit';

import { Velocity } from '../../../utils/metrics/index';

function MetricStandardDeviation(props) {
  return (
    <div style={{ minHeight: props.pdf ? '10px' : '30px' }}>
      { props.value && (
        <Grid container direction="row" justify="center" alignItems="center" spacing={1}>
          <Grid item>
            <Typography variant={props.variant} component="p">
              <Translate>standardDeviation</Translate>
              :
            </Typography>
          </Grid>
          <Grid item>
            <MetricValue
              metric={props.metric}
              value={props.value}
              options={props.metric.key === Velocity.key ? { maximumFractionDigits: 2 } : {}}
            />
          </Grid>
          <Grid item>
            <MetricUnit unit={props.unit} />
          </Grid>
        </Grid>
      )}
    </div>
  );
}

MetricStandardDeviation.propTypes = {
  metric: PropTypes.instanceOf(Metric).isRequired,
  unit: PropTypes.string.isRequired,
  value: PropTypes.number,
  variant: PropTypes.string,
  pdf: PropTypes.bool,
};

MetricStandardDeviation.defaultProps = {
  variant: 'body2',
  value: undefined,
  pdf: false,
};

export default MetricStandardDeviation;
