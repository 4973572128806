import { FLAGS } from './stride';
import { DoubleSupportPercentage, SingleSupportPercentage } from './metrics';

function filterWithTimestamps(arrayXY, startTimestamp, endTimestamp) {
  return arrayXY.filter(({ x }) => x >= startTimestamp && x <= endTimestamp);
}

function filterWithFlag(strides) {
  return strides
    .filter((stride) => {
      if ('flag' in stride) {
        return stride.flag === FLAGS.DEFAULT;
      }

      return (stride.timeToeOff !== 0 && stride.strideLength < 5);
    });
}

function filterStridesWithoutDoubleSupport(strides) {
  return strides.filter(stride => stride[SingleSupportPercentage.key] !== 0
    && stride[DoubleSupportPercentage.key] !== 0);
}

function filterWithSide(strides, side) {
  return strides.filter(stride => stride.side === side);
}

function getArrayXY(strides, metric) {
  return strides.map(stride => ({
    x: stride.clientTimestamp,
    y: stride[metric.keyCompute || metric.key],
    s: stride.side,
  }));
}

export {
  filterWithTimestamps,
  filterWithFlag,
  filterStridesWithoutDoubleSupport,
  filterWithSide,

  getArrayXY,
};
