import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import {
  Grid,
  Card,
  CardHeader,
  CardContent,
  IconButton,
  Typography,
  LinearProgress,
} from '@material-ui/core';

import EditIcon from '@material-ui/icons/Edit';

import Translate from '../../components/display/translate';
import DateDisplay from '../../components/display/date';

import PathologyInfo from './pathology-info';

function displayInfo(key, value) {
  return (
    <Typography>
      <Translate>{key}</Translate>
      :&nbsp;
      {value}
    </Typography>
  );
}

function PatientInfo(props) {
  const { patient, conditions } = props;

  return (
    <Card>
      { props.loading && (
        <LinearProgress />
      )}
      <CardHeader
        title={<Translate>patientInfo</Translate>}
        action={(props.loading === false && props.hasEditButton) && (
          <IconButton component={Link} to="edit">
            <EditIcon />
          </IconButton>
        )}
      />
      <CardContent>
        { patient !== undefined && (
          <Grid container direction="row" justify="space-evenly" alignItems="center">
            { patient.isAnonymous
              ? (
                <Grid item>
                  {displayInfo('ID', patient.anonymous)}
                </Grid>
              ) : (
                <React.Fragment>
                  <Grid item>
                    {displayInfo('firstname', patient.firstname)}
                  </Grid>
                  <Grid item>
                    {displayInfo('surname', patient.surname)}
                  </Grid>
                </React.Fragment>
              )}
            <Grid item>
              {displayInfo('birthdate', <DateDisplay disableTimeZone>{patient.birthdate}</DateDisplay>)}
            </Grid>
            <Grid item>
              {displayInfo('gender', <Translate>{patient.gender}</Translate>)}
            </Grid>
          </Grid>
        )}
      </CardContent>
      { (conditions !== undefined && conditions.length > 0) && (
        <div>
          <CardHeader title={<Translate>pathologies</Translate>} />
          <CardContent>
            <Grid container direction="column" justify="flex-start" alignItems="stretch" spacing={3}>
              { conditions.map(condition => (
                <Grid item key={condition.id}>
                  <PathologyInfo condition={condition} />
                </Grid>
              ))}
            </Grid>
          </CardContent>
        </div>
      )}
    </Card>
  );
}

PatientInfo.propTypes = {
  loading: PropTypes.bool.isRequired,
  patient: PropTypes.shape({
    firstname: PropTypes.string,
    surname: PropTypes.string,
    anonymous: PropTypes.string,
    birthdate: PropTypes.string.isRequired,
    isAnonymous: PropTypes.bool,
    gender: PropTypes.oneOf(['male', 'female', 'other', 'unknown']).isRequired,
  }),
  conditions: PropTypes.arrayOf(PropTypes.shape({
    id: PropTypes.string,
  })),
  hasEditButton: PropTypes.bool,
};

PatientInfo.defaultProps = {
  patient: undefined,
  conditions: undefined,
  hasEditButton: false,
};

export default PatientInfo;
