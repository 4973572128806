import React from 'react';
import PropTypes from 'prop-types';
import {
  Button,
  CircularProgress,
  Grid,
} from '@material-ui/core';

import Translate from '../../components/display/translate';

import { FETCH_RECORDS_LIMIT, fetchDiosRecords } from '../../firebase/firestore';
import { userId } from '../../firebase/auth';

class LoadMoreRecords extends React.Component {
  constructor() {
    super();

    this.state = {
      inProgress: false,
      hasMoreRecords: true,
    };

    this.onClick = this.onClick.bind(this);
  }

  onClick() {
    this.setState({ inProgress: true });
    const { startAfter, endBefore, records } = this.props;
    if (records.length === 0) {
      this.setState({ hasMoreRecords: false });
      return;
    }

    const lastStopTime = records[records.length - 1].stopTime;
    fetchDiosRecords(userId(), { startAfter, endBefore, lastStopTime })
      .then((newRecords) => {
        // track if there is still more records available
        if (newRecords.length < FETCH_RECORDS_LIMIT) {
          this.setState({ hasMoreRecords: false });
        } else {
          this.setState({ inProgress: false });
        }
        this.props.newRecords(newRecords);
      });
  }

  render() {
    if (this.state.hasMoreRecords === false) {
      return null;
    }

    return (
      <Grid container direction="row" justify="center" alignItems="center">
        <Grid item>
          {
            this.state.inProgress
              ? <CircularProgress />
              : (
                <Button onClick={this.onClick}>
                  <Translate>loadMoreRecords</Translate>
                </Button>
              )
          }
        </Grid>
      </Grid>
    );
  }
}

LoadMoreRecords.propTypes = {
  records: PropTypes.arrayOf(PropTypes.object).isRequired,
  startAfter: PropTypes.number.isRequired,
  endBefore: PropTypes.number.isRequired,
  newRecords: PropTypes.func.isRequired,
};

export default LoadMoreRecords;
