import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import {
  Grid,
  Card,
  CardHeader,
  CardContent,
} from '@material-ui/core';

import Metric from '../../utils/metrics/metric';
import Translate from '../../components/display/translate';
import GaitCycleGraph from '../../components/d3/gait-cycle';
import LineMetric from '../../components/metric/line';

import {
  computeValueWithStrides,
  computeMainBoxCoordinate,
  addErrorBox,
} from '../../utils/d3/gait-cycle-boxes';
import {
  SupportPhase,
  OscillationPhase,
  SingleSupportPercentage,
} from '../../utils/metrics/index';
import { getAllGaitCycleMetrics } from '../../utils/metric';

function mapStateToProps(state) {
  return ({
    startTimestamp: state.timestampsRecordDisplay.startTimestamp,
    endTimestamp: state.timestampsRecordDisplay.endTimestamp,
    selected: state.settings.gaitCycleMetricsSelected
      .map(key => getAllGaitCycleMetrics().find(metric => metric.key === key))
      .filter(i => i !== undefined),
  });
}

function GaitCycleParametersCard(props) {
  const {
    strides,
    startTimestamp,
    endTimestamp,
    selected,
    pdf,
  } = props;

  const groups = ([[{
    zone: 'support',
    main: {
      metric: SingleSupportPercentage,
      fix: 10,
      widthNorm: 40,
    },
  }], [{
    zone: 'support',
    main: {
      metric: SupportPhase,
      fix: 0,
      widthNorm: [60, 65],
    },
  }, {
    zone: 'oscillation',
    main: {
      metric: OscillationPhase,
      fix: 100,
      widthNorm: 40,
      reverse: true,
    },
  }]]).map(group => group.map(line => ({
    ...line,
    main: computeValueWithStrides(line.main, strides, startTimestamp, endTimestamp),
  })).map(line => ({
    ...line,
    main: computeMainBoxCoordinate(line.main),
  })).map(line => addErrorBox(line)))
  // remove groups who have a box with a value === 0
    .filter(i => i.reduce((acc, cur) => (cur.main.value !== 0 && cur.main.value) && acc, true));

  return (
    <Card>
      <CardHeader title={<Translate>gaitCycleParameters</Translate>} />
      <CardContent>
        <Grid container direction="column" justify="center" alignItems="stretch">
          <Grid item>
            <GaitCycleGraph groups={groups} pdfMode={pdf} height={pdf ? 140 : 225} />
          </Grid>
        </Grid>
        <Grid container direction="row" justify="space-around" alignItems="center">
          {selected.map(metric => (
            <Grid item key={metric.key}>
              <LineMetric metric={metric} strides={props.strides} strideTimestampFilter />
            </Grid>
          ))}
        </Grid>
      </CardContent>
    </Card>
  );
}

GaitCycleParametersCard.propTypes = {
  strides: PropTypes.arrayOf(PropTypes.object).isRequired,
  startTimestamp: PropTypes.number.isRequired,
  endTimestamp: PropTypes.number.isRequired,
  selected: PropTypes.arrayOf(PropTypes.instanceOf(Metric)).isRequired,
  pdf: PropTypes.bool,
};

GaitCycleParametersCard.defaultProps = {
  pdf: false,
};

export {
  computeMainBoxCoordinate,
  addErrorBox,
};

export default connect(mapStateToProps)(GaitCycleParametersCard);
