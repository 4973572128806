import React, { useMemo, useRef, useState } from 'react';
import PropTypes from 'prop-types';

import InsolesDynamicPressures from './insoles-dynamic-pressures';
import InsolesStaticPressures from './insoles-static-pressures';
import ColormapPressure from '../../components/insole/colormap-pressure';
import ReplayPerformance from './replay-performance';
import ReplaySeek from './replay-seek';
import BipodalCopReplayGraph from '../../components/graph/bipodal-cop-replay';

import useAnimationFrame from '../../utils/hooks/useAnimationFrame';
import useReplay from '../../utils/hooks/useReplay';
import usePressureScale from '../../utils/hooks/usePressureScale';
import { isProdTarget } from '../../utils/env';

function RecordReplay(props) {
  const {
    recordId,
    insoles,
    mode,
    framerate,
    meshX,
    minDomain,
    handleReplayDuration,
  } = props;

  const [frame, setFrame] = useState({});
  const previousFrame = useRef(undefined);
  // keep the list of cop for the left insole
  const leftCopRef = useRef([]);
  // keep the list of cop for the right insole
  const rightCopRef = useRef([]);
  const [bipodalCopReset, setBipodalCopReset] = useState(0);

  const {
    frameRef,
    recordInfo,
    handlePlay,
    handlePause,
    handleSeek,
    wsFrequency,
    error,
  } = useReplay(recordId, framerate, meshX);

  const [scale, colorInterpolation] = usePressureScale(minDomain, recordInfo.maxPressure);

  function resetPaths() {
    leftCopRef.current = [];
    rightCopRef.current = [];
    // force an update on the BipodalCopReplayGraph child to clear its bipodal cop
    setBipodalCopReset(i => i + 1);
  }

  function handleSeekWrapper(newIndex) {
    // reset the cop plot on seek
    resetPaths();
    return handleSeek(newIndex);
  }

  function currentFrameHasCop(side) {
    return frameRef.current[recordId][side] && frameRef.current[recordId][side].cop;
  }

  function newStrideId(side) {
    return previousFrame.current[recordId][side] && frameRef.current[recordId][side]
      && frameRef.current[recordId][side].strideId
      !== previousFrame.current[recordId][side].strideId;
  }

  const animationFrequency = useAnimationFrame(() => {
    if (frameRef.current === undefined) {
      return;
    }

    if (previousFrame.current !== undefined
      && previousFrame.current.index > frameRef.current.index) {
      // reset paths when we loop over on the record
      resetPaths();
    }

    if (currentFrameHasCop('left')) {
      leftCopRef.current.push(frameRef.current[recordId].left.cop);
    }

    if (currentFrameHasCop('right')) {
      rightCopRef.current.push(frameRef.current[recordId].right.cop);
    }

    // We want to clear the cop data for each different strides.
    // This is specific to dynamic record but is not easily feasible in
    // `<DynamicReplay/>` since it requires the access to the `leftCopRef`
    // and `rightCopRef` data.
    if (previousFrame.current !== undefined && mode === 'dynamic') {
      if (newStrideId('left')) {
        leftCopRef.current = [];
      }

      if (newStrideId('right')) {
        rightCopRef.current = [];
      }
    }

    setFrame(frameRef.current[recordId]);
    previousFrame.current = frameRef.current;
    frameRef.current = undefined;
  });

  const ReplayComponent = useMemo(
    () => (mode === 'static' ? InsolesStaticPressures : InsolesDynamicPressures),
    [mode],
  );

  return (
    <React.Fragment>
      { !isProdTarget() && (
        <ReplayPerformance
          wsFrequency={wsFrequency}
          animationFrequency={animationFrequency}
        />
      )}
      <BipodalCopReplayGraph frame={frame} mode={mode} reset={bipodalCopReset}>
        <ReplayComponent
          insoles={insoles}
          leftCop={leftCopRef.current}
          rightCop={rightCopRef.current}
          leftPressures={frame.left ? frame.left.interpolatedFrame : []}
          rightPressures={frame.right ? frame.right.interpolatedFrame : []}
          meshX={meshX}
          meshY={meshX * 3}
          arcRadius={6}
          scale={scale}
          // dynamic props
          leftLoadHeelStrike={(frame.left && frame.left.loadHeelStrike)
            ? Math.round(frame.left.loadHeelStrike) : undefined}
          leftLoadToeOff={(frame.left && frame.left.loadToeOff)
            ? Math.round(frame.left.loadToeOff) : undefined}
          rightLoadHeelStrike={(frame.right && frame.right.loadHeelStrike)
            ? Math.round(frame.right.loadHeelStrike) : undefined}
          rightLoadToeOff={(frame.right && frame.right.loadToeOff)
            ? Math.round(frame.right.loadToeOff) : undefined}
          // static props
          leftForeFootLoad={frame.left ? Math.round(frame.left.foreFootLoad) : undefined}
          rightForeFootLoad={frame.right ? Math.round(frame.right.foreFootLoad) : undefined}
          leftBackFootLoad={frame.left ? Math.round(frame.left.backFootLoad) : undefined}
          rightBackFootLoad={frame.right ? Math.round(frame.right.backFootLoad) : undefined}
        />
      </BipodalCopReplayGraph>
      <ColormapPressure
        colorInterpolation={colorInterpolation}
        domain={[minDomain, recordInfo.maxPressure]}
      />
      <ReplaySeek
        onPlay={handlePlay}
        onPause={handlePause}
        onSeek={handleSeekWrapper}
        nbFrames={recordInfo.nbFrames}
        isPlaying={recordInfo.isPlaying}
        framerate={framerate}
        index={frame.index}
        loading={recordInfo.nbFrames === undefined}
        handleReplayDuration={handleReplayDuration}
        hasError={!!error}
      />
    </React.Fragment>

  );
}

RecordReplay.propTypes = {
  recordId: PropTypes.string.isRequired,
  insoles: PropTypes.arrayOf(PropTypes.object).isRequired,
  mode: PropTypes.oneOf(['static', 'dynamic']).isRequired,
  framerate: PropTypes.number,
  meshX: PropTypes.number,
  minDomain: PropTypes.number,
  handleReplayDuration: PropTypes.func,
};

RecordReplay.defaultProps = {
  framerate: 20,
  meshX: 15,
  minDomain: 0,
  handleReplayDuration: undefined,
};

export default RecordReplay;
