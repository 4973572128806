import { gte } from 'semver';
import { encodeStrideFlag } from '../stride';

// 28 metrics + 19 capa + 16 cop
const NB_ELEMENT_VERSION_4 = 28 + 19 + 16;

// 29 metrics + 19 capa + 16 cop
const NB_ELEMENT_VERSION_6 = 29 + 19 + 16;

// 29 metrics + 19 capa + 16 cop + 1 counter + 1 firstore key
const NB_ELEMENT_VERSION_9 = 29 + 19 + 16 + 1 + 1;

function getNbElementVersion(version) {
  if (version === 4 || version === 5) {
    return NB_ELEMENT_VERSION_4;
  }
  if (version === 6 || version === 7 || version === 8) {
    return NB_ELEMENT_VERSION_6;
  }

  if (version === 9) {
    return NB_ELEMENT_VERSION_9;
  }

  return 0;
}

function isValidLine(line, version) {
  if (line === undefined) {
    return false;
  }

  const lengthLine = line.split(',').length;
  if (lengthLine === 0) {
    return false;
  }

  return lengthLine === getNbElementVersion(version);
}

function parseMetrics(line, keys) {
  const res = {};

  keys.forEach((key, index) => {
    if (key === 'side') {
      res[key] = line[index];
    } else if (key === 'flag') {
      res[key] = encodeStrideFlag(line[index]);
    } else if (key === 'overflow') {
      // the overflow metric is store as
      // "1" for true
      // "" for false
      res[key] = (line[index] === '1');
    } else if (key === 'key') {
      res[key] = line[index];
    } else {
      res[key] = Number(line[index]);
    }
  });

  return res;
}

function parseArray(line, arraySize, offset) {
  if (line.slice(offset, arraySize + offset).filter(i => i !== '').length === 0) {
    return [];
  }

  const res = [];
  [...Array(arraySize).keys()].forEach(key => res.push(Number(line[key + offset])));
  return res;
}

function parseStride4(line) {
  const KEYS = ['clientTimestamp', 'timestamp', 'side', 'strideLength', 'widthMotion',
    'timeHeelStrike1', 'timeHeelStrike2', 'timeToeOff', 'strideDuration',
    'velocity', 'cadence', 'swingDuration', 'stanceDuration', 'swingPercentage',
    'stancePercentage', 'stepTime', 'stepLength', 'asymmetryParamStepTime',
    'asymmetryParamStepLength', 'singleSupportDuration', 'doubleSupportDuration',
    'singleSupportPercentage', 'doubleSupportPercentage', 'asymmetryParamStrideDuration',
    'asymmetryParamStrideLength', 'asymmetryParamSingleSupportPercentage', 'strideElevation', 'flag'];
  const NB_METRICS = KEYS.length;
  const NB_CAPA = 19;
  const NB_COP = 16;

  return {
    ...parseMetrics(line, KEYS),
    capa: parseArray(line, NB_CAPA, NB_METRICS),
    cop: parseArray(line, NB_COP, NB_METRICS + NB_CAPA),
  };
}

function parseStride6(line, insoles) {
  const KEYS = ['clientTimestamp', 'timestamp', 'side', 'strideLength', 'widthMotion',
    'timeHeelStrike1', 'timeHeelStrike2', 'timeToeOff', 'strideDuration',
    'velocity', 'cadence', 'swingDuration', 'stanceDuration', 'swingPercentage',
    'stancePercentage', 'stepTime', 'stepLength', 'asymmetryParamStepTime',
    'asymmetryParamStepLength', 'singleSupportDuration', 'doubleSupportDuration',
    'singleSupportPercentage', 'doubleSupportPercentage', 'asymmetryParamStrideDuration',
    'asymmetryParamStrideLength', 'asymmetryParamSingleSupportPercentage', 'strideElevation', 'flag',
    'overflow'];
  const NB_METRICS = KEYS.length;
  const NB_CAPA = 19;
  const NB_COP = 16;

  // if the insole hardware version is 4.0.0, there is only 18 sensors,
  // the csv hasn't changed, we're just shifting the last element of the array
  const capa = parseArray(line, NB_CAPA, NB_METRICS);
  const side = line[2];
  const insole = insoles[side];
  if (insole && insole.versions && gte(insole.versions.hardware, '4.0.0')) {
    capa.shift();
  }

  return {
    ...parseMetrics(line, KEYS),
    capa,
    cop: parseArray(line, NB_COP, NB_METRICS + NB_CAPA),
  };
}

function parseStride8(line, insoles) {
  const KEYS = ['clientTimestamp', 'timestamp', 'side', 'strideLength', 'widthMotion',
    'timeHeelStrike1', 'timeHeelStrike2', 'timeToeOff', 'strideDuration',
    'velocity', 'cadence', 'swingDuration', 'stanceDuration', 'swingPercentage',
    'stancePercentage', 'stepTime', 'stepLength', 'asymmetryParamStepTime',
    'asymmetryParamStepLength', 'singleSupportDuration', 'doubleSupportDuration',
    'singleSupportPercentage', 'doubleSupportPercentage', 'asymmetryParamStrideDuration',
    'asymmetryParamStrideLength', 'asymmetryParamSingleSupportPercentage', 'strideElevation', 'flag',
    'overflow'];
  const NB_METRICS = KEYS.length;
  const NB_CAPA = 19;
  const NB_COP = 16;

  // if the insole hardware version is 4.0.0, there is only 18 sensors,
  // the csv hasn't changed, we're just shifting the last element of the array
  const capa = parseArray(line, NB_CAPA, NB_METRICS);
  const side = line[2];
  const insole = insoles[side];
  if (insole && insole.versions && gte(insole.versions.hardware, '4.0.0') && capa.length > 0) {
    capa.pop();
  }

  return {
    ...parseMetrics(line, KEYS),
    capa,
    cop: parseArray(line, NB_COP, NB_METRICS + NB_CAPA),
  };
}

function parseStride9(line, insoles) {
  const KEYS = ['clientTimestamp', 'timestamp', 'side', 'strideLength', 'widthMotion',
    'timeHeelStrike1', 'timeHeelStrike2', 'timeToeOff', 'strideDuration',
    'velocity', 'cadence', 'swingDuration', 'stanceDuration', 'swingPercentage',
    'stancePercentage', 'stepTime', 'stepLength', 'asymmetryParamStepTime',
    'asymmetryParamStepLength', 'singleSupportDuration', 'doubleSupportDuration',
    'singleSupportPercentage', 'doubleSupportPercentage', 'asymmetryParamStrideDuration',
    'asymmetryParamStrideLength', 'asymmetryParamSingleSupportPercentage', 'strideElevation', 'flag',
    'overflow', 'counter', 'key'];
  const NB_METRICS = KEYS.length;
  const NB_CAPA = 19;
  const NB_COP = 16;

  // if the insole hardware version is 4.0.0, there is only 18 sensors,
  // the csv hasn't changed, we're just shifting the last element of the array
  const capa = parseArray(line, NB_CAPA, NB_METRICS);
  const side = line[2];
  const insole = insoles[side];
  if (insole && insole.versions && gte(insole.versions.hardware, '4.0.0') && capa.length > 0) {
    capa.pop();
  }

  return {
    ...parseMetrics(line, KEYS),
    capa,
    cop: parseArray(line, NB_COP, NB_METRICS + NB_CAPA),
  };
}

function parseStride(line, version, insoles) {
  if (version === 4 || version === 5) {
    return parseStride4(line);
  }
  if (version === 6 || version === 7) {
    return parseStride6(line, insoles);
  }
  if (version === 8) {
    return parseStride8(line, insoles);
  }
  if (version === 9) {
    return parseStride9(line, insoles);
  }

  return undefined;
}

export {
  isValidLine,
  parseStride,
};
