import React from 'react';
import PropTypes from 'prop-types';
import Typography from '@material-ui/core/Typography';

import Metric from '../../../utils/metrics/metric';

import Translate from '../../display/translate';

function MetricTitle(props) {
  return (
    <Typography variant={props.variant} color={props.color} className={props.className} component="p">
      <Translate>{ props.metric.title }</Translate>
    </Typography>
  );
}

MetricTitle.propTypes = {
  metric: PropTypes.instanceOf(Metric).isRequired,
  className: PropTypes.string,
  variant: PropTypes.string,
  color: PropTypes.string,
};

MetricTitle.defaultProps = {
  className: '',
  variant: 'body2',
  color: 'initial',
};

export default MetricTitle;
