import React from 'react';
import PropTypes from 'prop-types';
import {
  Button,
  Grid,
  Typography,
} from '@material-ui/core';

import Translate from '../display/translate';

import ScaleForm from './scale';

import { getPathologyScale, getClinicalScale, getPathologyLabel } from '../../utils/fhir/pathologies';

class PatientConditionForm extends React.Component {
  onChange(scale) {
    this.props.onChange(this.props.condition.scales.map((i) => {
      if (i.type === scale.type) {
        return scale;
      }

      return i;
    }));
  }

  getPathologicScale() {
    if (getPathologyScale(this.props.condition.code) !== undefined) {
      return (
        <ScaleForm
          type="pathology"
          code={this.props.condition.code}
          onChange={scale => this.onChange(scale)}
          scaleObj={this.props.condition.scales.find(i => i.type === 'pathology')}
        />
      );
    }

    return null;
  }

  getClinicalScale() {
    if (getClinicalScale(this.props.condition.code) !== undefined) {
      return (
        <ScaleForm
          type="clinical"
          code={this.props.condition.code}
          onChange={scale => this.onChange(scale)}
          scaleObj={this.props.condition.scales.find(i => i.type === 'clinical')}
        />
      );
    }

    return null;
  }

  render() {
    return (
      <Grid container direction="row" justify="space-around" alignItems="center">
        <Grid item>
          <Typography variant="subtitle1">
            <Translate>{getPathologyLabel(this.props.condition.code)}</Translate>
          </Typography>
        </Grid>
        <Grid item>
          { this.getPathologicScale() }
        </Grid>
        <Grid item>
          { this.getClinicalScale() }
        </Grid>
        <Grid item>
          <Button color="secondary" onClick={() => this.props.onDelete()}>
            <Translate>delete</Translate>
          </Button>
        </Grid>
      </Grid>
    );
  }
}

PatientConditionForm.propTypes = {
  condition: PropTypes.shape({
    id: PropTypes.oneOfType([PropTypes.number, PropTypes.string]).isRequired,
    code: PropTypes.string.isRequired,
    scales: PropTypes.arrayOf(PropTypes.shape({
      type: PropTypes.oneOf(['clinical', 'pathology']).isRequired,
    })).isRequired,
  }).isRequired,
  onChange: PropTypes.func.isRequired,
  onDelete: PropTypes.func.isRequired,
};

export default PatientConditionForm;
