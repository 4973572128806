import React from 'react';
import PropTypes from 'prop-types';
import {
  Grid,
  Card,
  Typography,
  CardHeader,
  CardContent,
  LinearProgress,
} from '@material-ui/core';

import SimpleMenu from '../selection-controls/simple-menu';
import Translate from '../display/translate';
import DateDisplay from '../display/date';
import CurveRehab from '../d3/curve-rehab';
import CurveRehabStable from '../d3/curve-rehab-stable-x';

import { EXO_RECORD_TYPES } from '../../utils/record';

import {
  isSameDate,
  getStartOfDay,
  getStartOfWeek,
  getStartOfMonth,
} from '../../utils/date';

const EXO_TYPES = EXO_RECORD_TYPES.map(({ key }) => key);

function getExoName(exoType) {
  return EXO_RECORD_TYPES.find(i => i.key === exoType).label;
}

const OPTIONS = [{
  key: 'day',
  label: 'day',
}, {
  key: 'week',
  label: 'week',
}, {
  key: 'month',
  label: 'month',
}];

// Count the number of event per type ('day', 'week'...).
// records ([{starTime, stopTime}, ...]) is sorted in decreasing order
// Return [{x: date, y: occurent}, ...]
function formatExoCurve(records, type) {
  const res = [];

  let getStartOf;
  if (type === 'day') {
    getStartOf = getStartOfDay;
  } else if (type === 'week') {
    getStartOf = getStartOfWeek;
  } else {
    getStartOf = getStartOfMonth;
  }

  records.forEach(({ duration, startTime, stopTime }) => {
    const timeSpend = duration || (stopTime - startTime);
    if (res.length === 0 || !isSameDate(res[res.length - 1].x, stopTime, type)) {
      res.push({
        x: getStartOf(stopTime),
        y: 1,
        timeSpend,
      });
    } else {
      res[res.length - 1].y += 1;
      res[res.length - 1].timeSpend += timeSpend;
    }
  });

  return res;
}

class RehabPage extends React.Component {
  constructor() {
    super();

    this.state = {
      display: 'day',
    };
  }

  render() {
    return (
      <Grid container direction="column" justify="flex-start" alignItems="stretch" spacing={2}>
        <Grid item>
          <Card>
            { this.props.loading && (
              <LinearProgress />
            )}
            <CardHeader title={<Translate>rehabInfo</Translate>} />
            { this.props.records.length > 0 && (
              <CardContent>
                <Grid container direction="row" justify="space-around" alignItems="center">
                  <Grid item>
                    <Typography>
                      <Translate>numberExercises</Translate>
                      :&nbsp;
                      {this.props.records.length}
                    </Typography>
                  </Grid>
                  <Grid item>
                    <Typography>
                      <Translate>firstExerciseDate</Translate>
                      :&nbsp;
                      <DateDisplay>
                        {this.props.records[this.props.records.length - 1].stopTime}
                      </DateDisplay>
                    </Typography>
                  </Grid>
                  <Grid item>
                    <Typography>
                      <Translate>lastExerciseDate</Translate>
                      :&nbsp;
                      <DateDisplay>{this.props.records[0].stopTime}</DateDisplay>
                    </Typography>
                  </Grid>
                </Grid>
              </CardContent>
            )}
          </Card>
        </Grid>
        <Grid item>
          <Card>
            { this.props.loading && (
              <LinearProgress />
            )}
            <CardHeader
              title={<Translate>numberExercises</Translate>}
              action={(
                <SimpleMenu
                  id="display-selection"
                  value={this.state.display}
                  options={OPTIONS}
                  onSelect={display => this.setState({ display })}
                />
              )}
            />
            <CardContent>
              <CurveRehab
                values={formatExoCurve(
                  this.props.records.map(({ stopTime, startTime }) => ({ stopTime, startTime })),
                  this.state.display,
                ).reverse()}
              />
            </CardContent>
          </Card>
        </Grid>
        <Grid item>
          <Grid container direction="row" justify="space-around" alignItems="center" spacing={2}>
            {EXO_TYPES
              .map(type => this.props.records.filter(record => record.recordType === type))
              .filter(records => records.length > 0)
              .map(records => (
                <Grid item key={records[0].key} md={6} xs={12}>
                  <Card>
                    <CardHeader
                      title={<Translate>{getExoName(records[0].recordType)}</Translate>}
                      subheader={<Translate>percentageSuccess</Translate>}
                    />
                    <CardContent>
                      <CurveRehabStable
                        values={records
                          .reverse()
                          .map(i => ({
                            x: i.stopTime,
                            y: i.successPercentage,
                            timeSpend: (i.duration || i.stopTime - i.startTime),
                          }))}
                      />
                    </CardContent>
                  </Card>
                </Grid>
              )) }
          </Grid>
        </Grid>
      </Grid>
    );
  }
}

RehabPage.propTypes = {
  loading: PropTypes.bool.isRequired,
  // in descending order
  records: PropTypes.arrayOf(PropTypes.shape({
    duration: PropTypes.number,
    startTime: PropTypes.number,
    stopTime: PropTypes.number,
    successPercentage: PropTypes.number,
    recordType: PropTypes.oneOf(EXO_TYPES),
  })).isRequired,
};

export default RehabPage;
