import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import { translate } from '../../utils/i18n';

function mapStateToProps(state) {
  return ({
    locale: state.settings.locale,
  });
}

class Translate extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      value: '',
    };

    this.translateValue();
  }

  componentDidUpdate(prevProps) {
    if (prevProps.locale !== this.props.locale
      || prevProps.input !== this.props.input
      || prevProps.children !== this.props.children) {
      this.translateValue();
    }
  }

  translateValue() {
    const {
      locale,
      input,
      children,
    } = this.props;

    translate(children, locale, input)
      .then(value => this.setState({ value }));
  }

  render() {
    if (this.props.parseHTML) {
      // eslint-disable-next-line react/no-danger
      return <div dangerouslySetInnerHTML={{ __html: this.state.value }} />;
    }

    return this.state.value;
  }
}

Translate.propTypes = {
  locale: PropTypes.string.isRequired,

  // eslint-disable-next-line react/forbid-prop-types
  input: PropTypes.object,
  children: PropTypes.string,
  parseHTML: PropTypes.bool,
};

Translate.defaultProps = {
  input: undefined,
  children: undefined,
  parseHTML: false,
};

export default connect(mapStateToProps)(Translate);
