import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import {
  Card,
  CardHeader,
  CardContent,
} from '@material-ui/core';

import Translate from '../../components/display/translate';
import SimpleMenu from '../../components/selection-controls/simple-menu';

import { updateLocale } from '../../actions/index';
import { updateLocaleSettings } from '../../firebase/firestore';

function mapStateToProps(state) {
  return ({
    locale: state.settings.locale,
  });
}

const LOCALES = [
  { key: 'fr-FR', label: 'localeFr' },
  { key: 'en-US', label: 'localeEn' },
];

function LangSelection({ dispatch, locale, settingsUpdated }) {
  function handleSelect(newLocale) {
    dispatch(updateLocale(newLocale));
    updateLocaleSettings(newLocale).then(() => settingsUpdated());
  }
  return (
    <Card>
      <CardHeader title={<Translate>languageSelection</Translate>} />
      <CardContent>
        <SimpleMenu
          id="lang-selection"
          value={locale}
          options={LOCALES}
          onSelect={newLocale => handleSelect(newLocale)}
        />
      </CardContent>
    </Card>
  );
}

LangSelection.propTypes = {
  dispatch: PropTypes.func.isRequired,
  locale: PropTypes.string.isRequired,

  settingsUpdated: PropTypes.func.isRequired,
};

export default connect(mapStateToProps)(LangSelection);
