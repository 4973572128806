import React from 'react';
import PropTypes from 'prop-types';
import {
  Grid,
  Typography,
} from '@material-ui/core';

import Translate from '../../components/display/translate';

import ScaleInfo from './scale-info';

import { getPathologyLabel } from '../../utils/fhir/pathologies';
import { isPathologyScale, isClinicalScale } from '../../utils/fhir/scales';

function PathologyInfo(props) {
  const { condition } = props;

  const stage = condition.scales.find(({ code }) => isPathologyScale(code));
  // we only return the first clinical scale, we assume only one is used
  const clinicalScale = condition.scales.find(({ code }) => isClinicalScale(code));

  return (
    <Grid container direction="row" justify="space-around" alignItems="center">
      <Grid item>
        <Typography variant="subtitle1">
          <Translate>{getPathologyLabel(condition.code)}</Translate>
        </Typography>
      </Grid>
      <Grid item>
        { stage !== undefined && (
          <ScaleInfo code={condition.code} type="pathology" scale={stage} />
        )}
      </Grid>
      <Grid item>
        { clinicalScale !== undefined && (
          <ScaleInfo code={condition.code} type="clinical" scale={clinicalScale} />
        )}
      </Grid>
    </Grid>
  );
}

PathologyInfo.propTypes = {
  condition: PropTypes.shape({
    code: PropTypes.string,
    scales: PropTypes.arrayOf(PropTypes.object),
  }).isRequired,
};

export default PathologyInfo;
