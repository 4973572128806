import React from 'react';
import PropTypes from 'prop-types';
import {
  Grid,
  Card,
  CardHeader,
  CardContent,
} from '@material-ui/core';

import Translate from '../../components/display/translate';
import RecordGraphStable from '../../components/record/graph-stable';
import RecordGraphBoxPlot from '../../components/record/graph-box-plot';

import Metric from '../../utils/metrics/metric';
import { OscillationPhase, SupportPhase } from '../../utils/metrics/index';

function getGraphComponent(metric, graphVariant, recordsData, pdf) {
  const values = recordsData.map(record => ({ x: record.clientTimestamp, ...record[metric.key] }));

  if (graphVariant === 'curve-stable-x') {
    return (<RecordGraphStable metric={metric} values={values} pdf={pdf} />);
  }

  return (<RecordGraphBoxPlot metric={metric} values={values} pdf={pdf} />);
}

function SpatioTemporalMetricsCard(props) {
  const {
    metrics,
    graphVariant,
    recordsData,
    pdf,
  } = props;

  return (
    <Card>
      <CardHeader title={<Translate>spatioTemporalParameters</Translate>} />
      <CardContent>
        <Grid container direction="row" justify="space-around" alignItems="center" spacing={5}>
          { metrics.map(metric => (
            <Grid item xs={pdf ? 4 : 12} md={6} lg={4} key={metric.key}>
              { getGraphComponent(metric, graphVariant, recordsData, pdf) }
            </Grid>
          ))}
        </Grid>
      </CardContent>
    </Card>
  );
}

SpatioTemporalMetricsCard.propTypes = {
  graphVariant: PropTypes.oneOf(['curve-stable-x', 'boxplot']).isRequired,
  recordsData: PropTypes.arrayOf(PropTypes.object).isRequired,
  metrics: PropTypes.arrayOf(PropTypes.instanceOf(Metric)),
  pdf: PropTypes.bool,
};

SpatioTemporalMetricsCard.defaultProps = {
  metrics: [
    OscillationPhase,
    SupportPhase,
  ],
  pdf: false,
};

export default SpatioTemporalMetricsCard;
