import { useMemo } from 'react';

import { isStatic } from '../record';

// TODO: use it in record-info.jsx

function useRecordName(record) {
  const [testName, typeName] = useMemo(() => {
    if (isStatic(record)) {
      return ['posturography', 'static'];
    }

    if (record.recordType === 'synchro') {
      return ['importedRecord', 'transfer'];
    }

    return [record.recordType, 'dynamic'];
  }, [record.key]);

  return [testName, typeName];
}

export default useRecordName;
