import React from 'react';
import {
  TableHead,
  TableRow,
  TableCell,
} from '@material-ui/core';

import Translate from '../../display/translate';

function ListTableHead() {
  return (
    <TableHead>
      <TableRow>
        <TableCell><Translate>organisation</Translate></TableCell>
        <TableCell><Translate>createdAt</Translate></TableCell>
        <TableCell align="right" />
      </TableRow>
    </TableHead>
  );
}

export default ListTableHead;
