import { fetchProcessedRecordPressures } from '../../firebase/firestore';
import useFirestore from './useFirestore';

function useProcessedRecordPressures(recordId, isInterpolationReady) {
  return useFirestore(
    () => fetchProcessedRecordPressures(recordId),
    {},
    [recordId, isInterpolationReady],
  );
}

export default useProcessedRecordPressures;
