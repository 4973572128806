import React from 'react';
import PropTypes from 'prop-types';
import Card from '@material-ui/core/Card';
import CardHeader from '@material-ui/core/CardHeader';
import CardContent from '@material-ui/core/CardContent';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';

import Translate from '../../components/display/translate';

import loadingSvg from '../../assets/images/interpolation-loading.svg';
import errorSvg from '../../assets/images/interpolation-error.svg';

function InterpolationLoadingWrapper(props) {
  const {
    title,
    isLoading,
    isReady,
    isDataLoading,
    children,
  } = props;

  if (isReady && !isDataLoading) {
    return children;
  }

  return (
    <Card>
      <CardHeader title={<Translate>{ title }</Translate>} />
      <CardContent>
        <Grid container direction="column" justify="flex-start" alignItems="center">
          <Grid item>
            <img src={(isLoading || isDataLoading) ? loadingSvg : errorSvg} alt="hello" />
          </Grid>
          <Grid item>
            <Typography variant="subtitle2">
              <Translate>{ (isLoading || isDataLoading) ? 'generatingRecordHeatmap' : 'heatmapGenerationImpossible' }</Translate>
            </Typography>
          </Grid>
          <Grid item>
            <Typography variant="caption">
              <Translate>{ (isLoading || isDataLoading) ? 'heatmapGenerationCanTakeAWhile' : 'recordDataIsTooLarge' }</Translate>
            </Typography>
          </Grid>
        </Grid>
      </CardContent>
    </Card>
  );
}

InterpolationLoadingWrapper.propTypes = {
  isLoading: PropTypes.bool.isRequired,
  isReady: PropTypes.bool.isRequired,
  isDataLoading: PropTypes.bool.isRequired,
  children: PropTypes.element.isRequired,
  title: PropTypes.string.isRequired,
};

export default InterpolationLoadingWrapper;
