import { interpolateRdYlGn } from 'd3-scale-chromatic';

import Metric from './metric';

import { formatMetricValue } from '../record';

const UNITS = ['cm/s', 'm/s', 'km/h'];

function convert(v, unit) {
  switch (unit) {
    case 'cm/s':
      return v * 100;
    case 'm/s':
      return v;
    case 'km/h':
      return v * 3.6;
    default:
      return v;
  }
}

const Velocity = new Metric({
  title: 'velocity',
  unit: 'm/s',
  domain: {
    'cm/s': [0, 60, 100, 140, 160],
    'm/s': [0, 0.6, 1, 1.4, 1.6],
    'km/h': [0, 2.16, 3.6, 50.4, 57.6],
  },
  range: [0, 20, 50, 75, 100],
  colorRange: (i => interpolateRdYlGn(i / 100)),
  key: 'velocity',
  convert,
  hasDeviation: true,
  // eslint-disable-next-line
  description: function(unit, locale) {
    return [{
      txt: 'Abellan Van Kan et al., 2009.',
      align: 'center',
    }, {
      txt: 'normativeVelocityInOlderAdults:',
      align: 'justify',
    }, {
      txt: [`> ${formatMetricValue(this, convert(1.4, unit), locale)} ${unit}: `, 'perfect'],
      align: 'left',
    }, {
      txt: [`> ${formatMetricValue(this, convert(1.0, unit), locale)} ${unit}: `, 'community ambulator'],
      align: 'left',
    }, {
      txt: [`< ${formatMetricValue(this, convert(1.05, unit), locale)} ${unit}: `, 'warning'],
      align: 'left',
    }, {
      txt: [`< ${formatMetricValue(this, convert(0.6, unit), locale)} ${unit}: `, 'danger'],
      align: 'left',
    }];
  },
});

export {
  UNITS as VELOCITY_UNITS,
  Velocity as default,
};
